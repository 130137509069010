import _ from 'lodash';

export function setPostageCountry(country) {
  this.dispatch('CART:SET_POSTAGE_COUNTRY', country);
}

export function addItem(item, options) {
  if (item.product && item.product.catNumber && item.product.URL) {
    if (!item.catNumber) {
      item.catNumber = item.product.catNumber;
    }
    item.product = item.product.URL;
  }
  item = _.pick(item, ['catNumber', 'product', 'quantity', 'wishlistItem']);
  this.dispatch('CART:ADD_ITEM', {item: item, options: options});
}

export function updateItem(item, options) {
  this.dispatch('CART:UPDATE_ITEM', {item: item, options: options});
}

export function removeItem(item, options) {
  if (item.product && item.product.catNumber && item.product.URL) {
    if (!item.catNumber) {
      item.catNumber = item.product.catNumber;
    }
    item.product = item.product.URL;
  }
  item = _.pick(item, ['catNumber', 'product', 'quantity', 'wishlistItem']);
  item = this.flux.store('CartStore').cart.items.find(_.matches(item));
  if (item !== undefined) {
    this.dispatch('CART:REMOVE_ITEM', {item: item, options: options});
  }
}

export function open() {
  this.dispatch('CART:OPEN');
}

export function close() {
  this.dispatch('CART:CLOSE');
}

export function toggle(toggle) {
  this.dispatch('CART:TOGGLE', toggle);
}

export function applyCoupon(code) {
  this.dispatch('CART:APPLY_COUPON', code);
}

export function saveForLaterAddItem(item, options) {
  // We only want some of the item attributes.
  item = _.pick(item, [
    'availability',
    'basePrice',
    'catNumber',
    'price',
    'product',
    'productDetail',
    'productListed',
    'quantity',
  ]);
  if (item.basePrice && !item.price) {
    item = _.update(item, 'price', function () {
      return _.get(item, 'basePrice');
    });
  }
  this.dispatch('SAVE_FOR_LATER:ADD_ITEM', {item: item, options: options});
}

export function saveForLaterRemoveItem(item, options) {
  this.dispatch('SAVE_FOR_LATER:REMOVE_ITEM', {item: item, options: options});
}
