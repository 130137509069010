export function loginEmail(options) {
  this.dispatch('AUTH:LOGIN_EMAIL', options);
}

export function guestLogin(credentials) {
  this.dispatch('AUTH:GUEST_LOGIN', credentials);
}

export function login(credentials) {
  this.dispatch('AUTH:LOGIN', credentials);
}

export function logout(options) {
  this.dispatch('AUTH:LOGOUT', options);
}

export function changePassword(credentials) {
  this.dispatch('AUTH:CHANGE_PASSWORD', credentials);
}

export function register(options) {
  this.dispatch('AUTH:REGISTER', options);
}

export function guestUpgradeRequest(options) {
  this.dispatch('AUTH:GUEST_UPGRADE_REQUEST', options);
}

export function guestUpgrade(options) {
  this.dispatch('AUTH:GUEST_UPGRADE', options);
}

export function deleteNotification(options) {
  this.dispatch('NOTIFICATION:DELETE', options);
}
