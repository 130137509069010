import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

export const NotificationType = ImmutablePropTypes.contains({
  id: PropTypes.number,
  dateCreated: PropTypes.number,
  level: PropTypes.oneOf([10, 20, 30, 40, null]),
  levelName: PropTypes.oneOf(['debug', 'info', 'warning', 'error', null]),
  message: PropTypes.string,
  link: PropTypes.string,
  received: PropTypes.date,
});

export const NotificationsType = ImmutablePropTypes.listOf(NotificationType);

const UserProductReviewType = ImmutablePropTypes.contains({
  productId: PropTypes.number,
});

const UserProductReviewsType = ImmutablePropTypes.listOf(UserProductReviewType);

export const UserType = ImmutablePropTypes.contains({
  id: PropTypes.number,
  openOrderCount: PropTypes.number,
  endingSubscriptionCount: PropTypes.number,
  lastLogin: PropTypes.date,
  email: PropTypes.string,
  title: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  displayName: PropTypes.string,
  ageGroup: PropTypes.string,
  reviewsProductIds: ImmutablePropTypes.listOf(PropTypes.number),
  contactNumber: PropTypes.string,
  dateJoined: PropTypes.date,
  isAuthenticated: PropTypes.bool,
  isGuest: PropTypes.bool,
  hasSubscriptionAccount: PropTypes.bool,
  credit: PropTypes.number,
  affiliateCode: PropTypes.string,
  isAuthenticatedOrIsGuest: PropTypes.bool,
  notifications: NotificationsType,
  reviews: UserProductReviewsType,
});
