import _ from "lodash";

import { getCSRFToken } from "../csrf.js";
import { checkStatus } from "../fetch.js";
import { Model, types } from "../models/base.js";
import { restMixin } from "../models/rest.js";

export class Notification extends restMixin(
  Model.withOptions({
    url: (instance) => {
      const base = "/api/site-messages/";
      if (instance.pk !== undefined && instance.pk !== null) {
        return `${base}${instance.pk}/`;
      }
      return base;
    },
    spec: {
      id: types.int,
      level: types.int.oneOf([10, 20, 30, 40]),
      levelName: types.string.oneOf(["debug", "info", "warning", "error"]),
      message: types.string,
      link: types.string,
      received: types.date,
    },
    allowedMethods: ["GET", "HEAD", "DELETE", "OPTIONS"],
  }),
) {}

export class UserProductReview extends Model.withSpec({
  productId: types.int,
}) {}

export class User extends restMixin(Model) {
  constructor(data) {
    super(data, {
      url: "/api/whoami/",
      spec: {
        id: types.int,
        isAuthenticated: types.bool.withDefault(false),
        isGuest: types.bool.withDefault(false),
        email: types.string,
        openOrderCount: types.int.withDefault(0),
        endingSubscriptionCount: types.int.withDefault(0),
        lastLogin: types.date,
        title: types.string,
        firstName: types.string,
        lastName: types.string,
        displayName: types.string,
        ageGroup: types.string,
        reviewsProductIds: types.arrayOf(types.int),
        contactNumber: types.string,
        dateJoined: types.date,
        hasSubscriptionAccount: types.bool.withDefault(false),
        credit: types.float,
        affiliateCode: types.string,
        allowListingBuy: types.bool.withDefault(true),
        isPromotionAdmin: types.bool.withDefault(false),
        notifications: types.modelArray(Notification),
        reviews: types.modelArray(UserProductReview),
      },
      derived: {
        isAuthenticatedOrIsGuest: (instance) =>
          instance.isAuthenticated || instance.isGuest,
      },
      allowedMethods: ["GET", "HEAD", "OPTIONS"],
    });
    this._setFromData = this._setFromData.bind(this);
  }

  _fetchAction(url, options) {
    options = _.merge(
      {
        method: "POST",
        headers: {
          "x-csrftoken": getCSRFToken(),
          "content-type": "application/json",
        },
        credentials: "same-origin",
      },
      options,
    );
    return fetch(url, options)
      .then(checkStatus)
      .then((response) => response.json());
  }

  _setFromData(data) {
    data = this.deserialize(data);
    for (const attr in data) {
      this.set(attr, data[attr]);
    }
    return data;
  }

  logout(options) {
    options = Object.assign({ soft: false }, options || {});
    return this._fetchAction(
      options.soft ? "/api/cart/soft-logout/" : "/api/account/logout/",
    ).then(this._setFromData);
  }

  loginEmail(email) {
    return this._fetchAction("/api/account/login_email/", {
      body: JSON.stringify({ email: email }),
    });
  }

  login(credentials, guest = false) {
    return this._fetchAction(
      guest ? "/api/account/guest/login/" : "/api/account/login/",
      { body: JSON.stringify(credentials) },
    ).then(this._setFromData);
  }

  changePassword(credentials) {
    if (!this.isAuthenticated || this.isGuest) {
      return Promise.reject("User must be logged in and not a guest");
    }
    credentials.email = this.email;
    return this._fetchAction("/api/account/set_password/", {
      body: JSON.stringify(credentials),
    });
  }

  register(options) {
    return this._fetchAction("/api/account/register/", {
      body: JSON.stringify(options),
    }).then(this._setFromData);
  }

  guestUpgradeRequest(options) {
    return this._fetchAction("/api/account/guest/upgrade/request/", {
      body: JSON.stringify(options),
    });
  }

  guestUpgrade(options) {
    return this._fetchAction("/api/account/guest/upgrade/request/", {
      body: JSON.stringify(options),
    }).then(this._setFromData);
  }
}
