import _ from "lodash";

import { checkStatus } from "../fetch.js";
import { getCSRFToken } from "../csrf.js";

const VOLATILE_METHODS = ["POST", "PUT", "PATCH", "DELETE"];

const DEFAULT_OPTIONS = {
  allowedMethods: ["GET", "HEAD", "POST", "PUT", "PATCH", "DELETE", "OPTIONS"],
  readOnly: false,
};

const DEFAULT_FETCH_OPTIONS = {
  parse: true,
  fetchOptions: {
    credentials: "same-origin",
    headers: { Accept: "application/json" },
  },
};

function fetchMixin(Base) {
  return class extends Base {
    constructor() {
      super(...arguments);
      //if (this._options.url === undefined) {
      //  throw new Error('`options.url` is undefined');
      //}
      _.defaults(this._options, DEFAULT_OPTIONS);
      if (this._options.readOnly) {
        this._options.allowedMethods = ["GET", "HEAD", "OPTIONS"];
      }
    }

    _url() {
      if (_.isFunction(this._options.url)) {
        return this._options.url(this);
      }
      return this._options.url;
    }

    _fetch(options) {
      options = Object.assign({}, options);
      if (!this._options.allowedMethods.includes(options.fetchOptions.method)) {
        return Promise.reject(
          new Error(
            `'${options.method}' not allowed. Valid methods are '${this._options.allowedMethods}'`,
          ),
        );
      }
      if (
        this._options.readOnly &&
        VOLATILE_METHODS.includes(options.fetchOptions.method)
      ) {
        return Promise.reject(
          new Error(`'${options.method}' not allowed on readOnly model.'`),
        );
      }
      return fetch(
        options.url ? options.url : this._url(),
        options.fetchOptions,
      )
        .then(checkStatus)
        .then((response) =>
          Promise.all([
            response,
            response.status === 204 ? Promise.resolve(null) : response.json(),
          ]),
        )
        .then((responseData) => {
          const response = responseData[0];
          let data = responseData[1];
          if (_.isFunction(options.preprocess)) {
            data = options.preprocess(response, data);
          }
          if (options.parse) {
            data = this.deserialize(data);
          }
          return Promise.all([response, data]);
        });
    }
  };
}

export function restMixin(Base) {
  return class extends fetchMixin(Base) {
    create(options) {
      options = _.merge(
        {},
        DEFAULT_FETCH_OPTIONS,
        {
          updateFromResponse: true,
          fetchOptions: {
            method: "POST",
            body: JSON.stringify(this.serialize()),
            headers: {
              "X-CSRFToken": getCSRFToken(),
              "Content-Type": "application/json",
            },
          },
        },
        options,
      );
      if (this[this._options.pkField] !== undefined) {
        return Promise.reject(
          new Error("Cannot call `create` on an object with a PK set."),
        );
      }
      return this._fetch(options).then((responseData) => {
        const response = responseData[0];
        let data = responseData[1];
        this.set(this._options.pkField, data[this._options.pkField]);
        if (options.updateFromResponse) {
          for (const attr in data) {
            this.set(attr, data[attr]);
          }
        }
        return Promise.all([response, data]);
      });
    }

    read(options) {
      options = _.merge(
        {},
        DEFAULT_FETCH_OPTIONS,
        { fetchOptions: { method: "GET" } },
        options,
      );
      return this._fetch(options).then((responseData) => {
        const response = responseData[0];
        let data = responseData[1];
        for (const attr in data) {
          this.set(attr, data[attr]);
        }
        return Promise.all([response, data]);
      });
    }

    update(options) {
      options = _.merge(
        {},
        DEFAULT_FETCH_OPTIONS,
        {
          updateFromResponse: true,
          fetchOptions: {
            method: "PUT",
            body: JSON.stringify(this.serialize()),
            headers: {
              "X-CSRFToken": getCSRFToken(),
              "Content-Type": "application/json",
            },
          },
        },
        options,
      );
      return this._fetch(options).then((responseData) => {
        const response = responseData[0];
        let data = responseData[1];
        if (options.updateFromResponse) {
          for (const attr in data) {
            this.set(attr, data[attr]);
          }
        }
        return Promise.all([response, data]);
      });
    }

    delete(options) {
      options = _.merge(
        {},
        DEFAULT_FETCH_OPTIONS,
        {
          updateFromResponse: false,
          fetchOptions: {
            method: "DELETE",
            headers: {
              "X-CSRFToken": getCSRFToken(),
              "Content-Type": "application/json",
            },
          },
        },
        options,
      );
      return this._fetch(options).then((responseData) => {
        const response = responseData[0];
        let data = responseData[1];
        if (options.updateFromResponse) {
          for (const attr in data || {}) {
            this.set(attr, data[attr]);
          }
        }
        this.set(this._options.pkField, undefined);
        return Promise.all([response, data]);
      });
    }
  };
}

export function restArrayMixin(Base) {
  return class extends fetchMixin(Base) {
    _preprocess(response, data) {
      if (_.isPlainObject(data) && "results" in data) {
        return data.results;
      }
      return data;
    }

    read(options) {
      options = _.merge(
        {},
        DEFAULT_FETCH_OPTIONS,
        {
          fetchOptions: { method: "GET" },
          reset: true,
          append: false,
          prepend: false,
          preprocess: this._preprocess.bind(this),
        },
        options,
      );
      return this._fetch(options).then((responseData) => {
        const response = responseData[0];
        let data = responseData[1];
        data = data.map((item) => new this._options.model(item));
        if (options.reset) {
          this.reset(data);
        } else if (options.prepend) {
          this.prepend(data);
        } else if (options.append) {
          this.append(data);
        }
        return Promise.all([response, data]);
      });
    }

    create(data, options) {
      let instanceData = data;
      if (!(instanceData instanceof this._options.model)) {
        instanceData = new this._options.model(instanceData);
      }
      options = _.merge(
        {},
        DEFAULT_FETCH_OPTIONS,
        {
          fetchOptions: {
            method: "POST",
            body: JSON.stringify(instanceData.serialize()),
            headers: {
              "X-CSRFToken": getCSRFToken(),
              "Content-Type": "application/json",
            },
          },
          reset: false,
          append: true,
          prepend: false,
        },
        options,
      );
      return instanceData._fetch(options).then((responseData) => {
        const response = responseData[0];
        const data = responseData[1];
        const instance = new this._options.model(data);
        if (options.reset) {
          this.reset(instance);
        } else if (options.prepend) {
          this.prepend(instance);
        } else if (options.append) {
          this.append(instance);
        }
        return Promise.all([response, instance]);
      });
    }

    delete(instance, options) {
      instance = this.find(_.matches(instance));
      if (!instance) {
        return Promise.reject(new Error("Item does not exist"));
      }
      return instance.delete(options).then((responseData) => {
        const response = responseData[0];
        const index = this.findIndex(_.matches(instance));
        instance = this.splice(index, 1);
        return Promise.all([response, instance]);
      });
    }
  };
}

const PAGE_REGEX = /(?:^|&)page=(\d+)/;

export function pagedRestArrayMixin(Base) {
  return class extends restArrayMixin(Base) {
    constructor() {
      super(...arguments);
      Object.defineProperty(this, "_pages", {
        value: {
          fetched: new Set(),
          available: new Set(),
        },
        enumerable: false,
        configurable: false,
        writable: true,
      });
    }

    _preprocess(response, data) {
      const pageFromURL = (url) => {
        const match = url.search.slice(1).match(PAGE_REGEX);
        if (match && match[1]) {
          return parseInt(match[1]);
        }
        return null;
      };

      if (_.isPlainObject(data) && "results" in data) {
        const url = new URL(response.url);
        let page = pageFromURL(url);
        if (!Number.isFinite(page)) {
          page = 1;
        }
        this._pages.fetched.add(page);

        if ("next" in data && data.next) {
          const nextURL = new URL(data.next);
          let nextPage = pageFromURL(nextURL);
          if (!Number.isFinite(page)) {
            nextPage = 1;
          }
          this._pages.available.add(nextPage);
        }

        if ("previous" in data && data.previous) {
          const previousURL = new URL(data.previous);
          let previousPage = pageFromURL(previousURL);
          if (!Number.isFinite(page)) {
            previousPage = 1;
          }
          this._pages.available.add(previousPage);
        }
        return data.results;
      }
      return data;
    }

    get _nextPageNumber() {
      return Array.from(this._pages.available)
        .sort()
        .find((page) => page > Math.max(...this._pages.fetched));
    }

    get _previousPageNumber() {
      return Array.from(this._pages.available)
        .sort()
        .reverse()
        .find((page) => page < Math.min(...this._pages.fetched));
    }

    get _hasNextPage() {
      return _.isFinite(this._nextPageNumber);
    }

    get _hasPreviousPage() {
      return _.isFinite(this._previousPageNumber);
    }

    fetchNextPage(options) {
      options = options || {};
      const nextPage = this._nextPageNumber;
      const url = new URL(`${document.location.origin}${this._url()}`);
      url.search = `?${url.search.slice(1).replace(PAGE_REGEX, "")}`;
      url.search = `${url.search}${
        url.search.length > 1 ? "&" : ""
      }page=${nextPage}`;
      Object.assign(options, {
        url: url,
        reset: false,
        prepend: false,
        append: true,
      });
      return this.read(options);
    }

    fetchPreviousPage(options) {
      options = options || {};
      const previousPage = this._nextPageNumber;
      const url = new URL(`${document.location.origin}${this._url()}`);
      url.search = `?${url.search.slice(1).replace(PAGE_REGEX, "")}`;
      url.search = `${url.search}${
        url.search.length > 1 ? "&" : ""
      }page=${previousPage}`;
      Object.assign(options, {
        url: url,
        reset: false,
        prepend: true,
        append: false,
      });
      return this.read(options);
    }
  };
}
