import {Model, types} from '../models/base.js';
import {ModelArray} from '../models/arrays.js';
import {restMixin, pagedRestArrayMixin} from '../models/rest.js';

export class WatchedProductTag extends restMixin(
  Model.withOptions({
    url: (instance) => {
      if (Number.isFinite(instance.id)) {
        return `/api/watchedproducttags/${instance.id}/`;
      }
      return '/api/watchedproducttags/';
    },

    spec: {
      id: types.int,
      contentObject: types.object,
    },
  })
) {}

export class WatchedProductTagArray extends pagedRestArrayMixin(
  ModelArray.ofModel(WatchedProductTag, {url: '/api/watchedproducttags/'})
) {}
