import * as React from 'react';
import ReactDOM from 'react-dom';

import {FeedbackSurveyMessages} from './components.jsx';

export function feedbackSurveyMessages(elem, options) {
  if (!options || !options.flux) {
    return;
  }
  ReactDOM.render(<FeedbackSurveyMessages {...options} />, elem);
}
