import * as compat from './compat/actions.js';
import * as console from './console/actions.js';
import * as media from './media/actions.js';
import * as network from './network/actions.js';
import * as settings from './settings/actions.js';
import * as toast from './toast/actions.js';
import * as tooltip from './tooltip/actions.js';

export const actions = {
  initialize: function() {
    this.dispatch('INITIALIZED');
  },
  compat: compat,
  console: console,
  media: media,
  network: network,
  settings: settings,
  toast: toast,
  tooltip: tooltip
};
