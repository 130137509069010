import _ from 'lodash';
import Fluxxor from 'fluxxor';
import Immutable from 'immutable';

import {WatchedProductTag, WatchedProductTagArray} from './models.js';

export const WatchStore = Fluxxor.createStore({
  actions: {
    INITIALIZED: 'initializeHandler',
    'WATCH:WATCH_OBJECT': 'create',
    'WATCH:UNWATCH_OBJECT': 'destroy',
  },

  initialize: function (options) {
    this.options = options;
    this.loading = false;
    this.initialized = false;
  },

  initializeHandler: function () {
    this.watches = new WatchedProductTagArray();

    // Set initial data
    const user = this.flux.store('AuthStore').getState().user;
    if (user.get('isAuthenticated')) {
      this.fetchAll();
    }

    // Handle login success
    this.flux
      .store('AuthStore')
      .addListener('login:success', this.fetchAll.bind(this));
    // Handle Logout success
    this.flux
      .store('AuthStore')
      .addListener('logout:success', () => this.watches.reset());

    this.initialized = true;
    this.emit('initialized');
  },

  getState: function () {
    return {
      watches: Immutable.List(this.watches.toObject()),
      loading: this.loading,
    };
  },

  _loadNext: function () {
    this.loading = true;
    this.emit('change');
    this.watches
      .loadNextPage()
      .then(() => {
        this.loading = false;
        this.emit('change');
        if (this.watches._hasNextPage) {
          this._loadNext();
        }
      })
      .catch(() => {
        this.loading = false;
        this.emit('change');
        this.emit('error');
      });
  },

  fetchAll: function () {
    this.loading = true;
    this.emit('change');
    this.watches
      .read()
      .then(() => {
        this.loading = false;
        this.emit('change');
        if (this.watches._hasNextPage) {
          this._loadNext();
        }
      })
      .catch(() => {
        this.loading = false;
        this.emit('change');
        this.emit('error');
      });
  },

  create: function (payload) {
    this.loading = true;
    this.emit('change');
    const newWatch = new WatchedProductTag({contentObject: payload});
    newWatch
      .create()
      .then(() => {
        this.loading = false;
        this.watches.prepend(newWatch);
        this.emit('change');
      })
      .catch(() => {
        this.loading = false;
        this.emit('change');
        this.emit('error');
      });
  },

  destroy: function (payload) {
    const watch = this.watches.find(_.matches({contentObject: payload}));
    if (watch) {
      this.loading = true;
      this.emit('change');
      watch
        .delete()
        .then(() => {
          this.loading = false;
          const removedIndex = this.watches.findIndex(
            _.matches({contentObject: payload})
          );
          if (removedIndex >= 0) {
            this.watches.splice(removedIndex, 1);
          }
          this.emit('change');
        })
        .catch(() => {
          this.loading = false;
          this.emit('change');
          this.emit('error');
        });
    }
  },
});
