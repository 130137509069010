import _ from 'lodash';
import moment from 'moment';

import {Model} from './base.js';
import {ModelArray} from './arrays.js';
import * as validators from './validators.js';

export class Type {
  constructor() {
    this.validators = [];
  }

  _coerce(value) {
    return value;
  }

  coerce(value, instance) {
    if (value === undefined && 'default' in this) {
      value = _.isFunction(this.default) ? this.default() : this.default;
    }
    if (value === null) {
      return null;
    }
    return this._coerce(value, instance);
  }

  _serialize(value) {
    return value;
  }

  serialize(value, instance) {
    if (value === undefined) {
      if ('default' in this) {
        value = _.isFunction(this.default) ? this.default() : this.default;
      } else {
        return undefined;
      }
    }
    if (value === null) {
      return null;
    }
    return this._serialize(value, instance);
  }

  withDefault(value) {
    const cloned = _.cloneDeep(this);
    cloned.default = value;
    return cloned;
  }

  withValidator(validator) {
    const cloned = _.cloneDeep(this);
    cloned.validators.push(validator);
    return cloned;
  }

  isRequired() {
    return this.withValidator(validators.isRequired);
  }

  notNull() {
    return this.withValidator(validators.notNull);
  }

  oneOf(values) {
    return this.withValidator(validators.oneOf(values));
  }
}

export class BoolType extends Type {
  _coerce(value) {
    return !!value;
  }
}

export class NumberType extends Type {
  _coerce(value) {
    return new Number(value);
  }
}

export class FloatType extends NumberType {
  _coerce(value) {
    return parseFloat(value);
  }
}

export class IntType extends NumberType {
  _coerce(value) {
    return parseInt(value);
  }
}

export class StringType extends Type {
  _coerce(value) {
    return value.toString();
  }
}

export class DateType extends Type {
  _coerce(value) {
    return new Date(value);
  }

  _serialize(value) {
    return value.toISOString();
  }
}

export class MomentType extends DateType {
  _coerce(value) {
    return new moment(value);
  }
}

export class ArrayType extends Type {
  constructor(childType) {
    super();
    if (childType !== undefined) {
      if (!(childType instanceof Type)) {
        throw new Error('`childType` must be an instance of `Type`');
      }
      this.childType = childType;
    } else {
      this.childType = new Type();
    }
  }

  _coerce(value) {
    return value.map((item) => this.childType.coerce(item));
  }

  _serialize(value) {
    return value.map((item) => this.childType.serialize(item));
  }
}

export class InstanceOfType extends Type {
  constructor(constructor) {
    super();
    if (constructor === undefined) {
      throw new Error('You must provide a constructor');
    }
    this._constructor = constructor;
  }

  _coerce(value) {
    return new this._constructor(value);
  }
}

export class ShapeType extends Type {
  constructor(shape) {
    super();
    if (!_.isPlainObject(shape)) {
      throw new Error('`shape` must be a plain object');
    }
    for (const key in shape) {
      if (!(shape[key] instanceof Type)) {
        throw new Error('`shape` values must be `Type` instances');
      }
    }
    this.shape = shape;
  }

  _coerce(value) {
    const coerced = {};
    for (const key in value) {
      if (this.shape[key] && _.isFunction(this.shape[key].coerce)) {
        coerced[key] = this.shape[key].coerce(value[key]);
      } else {
        coerced[key] = new Type().coerce(value[key]);
      }
    }
    return coerced;
  }

  _serialized(value) {
    const serialized = {};
    for (const key in value) {
      if (this.shape[key] && _.isFunction(this.shape[key].serialize)) {
        serialized[key] = this.shape[key].serialize(value[key]);
      } else {
        serialized[key] = new Type().serialize(value[key]);
      }
    }
    return serialized;
  }
}

export class ModelType extends Type {
  constructor(model) {
    super();
    if (model === undefined) {
      throw new Error('You must provide a model');
    }
    this.model = model;
  }

  _coerce(value, instance) {
    const child = this.model.parse(value);
    child._options.parent = instance;
    return child;
  }

  _serialize(value) {
    return value.toJSON();
  }
}

export class ModelArrayType extends Type {
  constructor(modelOrArrayClass) {
    super();
    if (modelOrArrayClass === undefined) {
      this.modelArray = ModelArray;
    } else if (
      modelOrArrayClass.prototype instanceof ModelArray ||
      modelOrArrayClass === ModelArray
    ) {
      this.modelArray = modelOrArrayClass;
    } else if (
      modelOrArrayClass.prototype instanceof Model ||
      modelOrArrayClass === Model
    ) {
      this.modelArray = class extends ModelArray {
        constructor(data, options) {
          options = Object.assign({model: modelOrArrayClass}, options);
          super(data, options);
        }
      };
    }
  }

  _coerce(value, instance) {
    const childArray = new this.modelArray().parse(value);
    childArray._options.parent = instance;
    return childArray;
  }

  _serialize(value) {
    return value.toJSON();
  }
}

export const types = {
  any: new Type(),
  bool: new BoolType(),
  number: new NumberType(),
  float: new FloatType(),
  int: new IntType(),
  string: new StringType(),
  date: new DateType(),
  moment: new MomentType(),

  arrayOf: (childType) => new ArrayType(childType),

  instanceOf: (constructor) => new InstanceOfType(constructor),
  shape: (shape) => new ShapeType(shape),
  model: (model) => new ModelType(model),
  modelArray: (modelOrArrayClass) => new ModelArrayType(modelOrArrayClass),
};
