export const events = {
  addTransitionEndListener: function(element, listener) {
    const endEvents = this.flux.store('CompatStore').transitionEndEvents;
    if (endEvents.length === 0) {
      // If CSS transitions are not supported, trigger an "end animation"
      // event immediately.
      window.setTimeout(listener, 0);
      return;
    }
    endEvents.forEach(function(endEvent) {
      element.addEventListener(endEvent, listener);
    });
  },

  removeTransitionEndListener: function(element, listener) {
    const endEvents = this.flux.store('CompatStore').transitionEndEvents;
    if (endEvents.length === 0) {
      return;
    }
    endEvents.forEach(function(endEvent) {
      element.removeEventListener(endEvent, listener);
    });
  }
};
