import Immutable from 'immutable';
import * as React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';

import {FilterSelectWidget} from './components.jsx';

export function subscriptionFilterSearchWidget(elem, options) {
  if (!options || !options.flux) {
    return;
  }
  const firstInput = elem.querySelector(
    'input[type="checkbox"], input[type="radio"]'
  );
  const name = firstInput.getAttribute('name');
  const label = elem.querySelector('summary').innerText;
  const inputType = firstInput.getAttribute('type');

  const data = Immutable.List(
    _.map(
      elem.querySelectorAll(
        'input[type="checkbox"][checked], input[type="radio"][checked]'
      ),
      (elem) => elem.getAttribute('value')
    )
  );
  const choices = Immutable.List(
    _.map(
      elem.querySelectorAll('input[type="checkbox"], input[type="radio"]'),
      (elem) => {
        const id = elem.getAttribute('id');
        const label = elem
          .closest(`[for=${id}]`)
          .querySelector(`[id="${id}-label"]`)
          .textContent.trim();
        return Immutable.List([elem.getAttribute('value'), label]);
      }
    )
  );
  const filterData = Immutable.Map({
    name: name,
    inputType: inputType,
    choices: choices,
    label: label,
  });
  const dataChangeHandler = (name, value, ev) => {
    ev.target.form.submit();
  };
  _.extend(options, {
    data: data,
    filterData: filterData,
    dataChangeHandler: dataChangeHandler,
    initialInputNumber: 5,
    controlled: false,
  });

  ReactDOM.render(<FilterSelectWidget {...options} />, elem);
}
