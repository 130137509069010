import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import {ProductType} from '../catalog/types.js';

export const CartItemType = ImmutablePropTypes.contains({
  id: PropTypes.number,
  quantity: PropTypes.number,
  quantityConstraint: PropTypes.number,
  maxQuantity: PropTypes.number,
  total: PropTypes.number,
  availability: PropTypes.string,
  productListed: PropTypes.bool,
  dateCreated: PropTypes.date,
  lastModified: PropTypes.date,
  productDetail: ProductType,
});

export const CartType = ImmutablePropTypes.contains({
  items: ImmutablePropTypes.listOf(CartItemType),
});
