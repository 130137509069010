import _ from 'lodash';
import classNames from 'classnames';
import Fluxxor from 'fluxxor';
import PropTypes from 'prop-types';
import * as React from 'react';

export class SliderItem extends React.PureComponent {
  constructor(props) {
    super(props);
    ['preventDefault'].forEach(method => {
      this[method] = this[method].bind(this);
    });
  }

  preventDefault(ev) {
    ev.preventDefault();
  }

  render() {
    const className = classNames(
      this.props.className,
      'js-slider__item-list__item'
    );
    return (
      <li
        ref={node => (this.wrapper = node)}
        onDrag={this.preventDefault}
        onDragStart={this.preventDefault}
        className={className}
        dangerouslySetInnerHTML={{__html: this.props.content}}
      />
    );
  }
}
SliderItem.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string.isRequired
};

export class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      position: this.props.position || 0,
      offset: 0,
      canIncrement:
        (this.props.position || 0) < React.Children.count(this.props.children),
      canDecrement: (this.props.position || 0) > 0
    };
    this.calculateSize = _.debounce(this.calculateSize);
    this.ensureVisible = _.debounce(this.ensureVisible, 250);
    [
      'handleMouseUp',
      'handleTouchMove',
      'handleResize',
      'calculateControlState',
      'decrement',
      'increment',
      'handleWheel',
      'handleMouseDown',
      'handleMouseMove',
      'handleTouchStart',
      'handleTouchEnd'
    ].forEach(method => {
      this[method] = this[method].bind(this);
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(
      _.isEqual(this.props.children, nextProps.children) &&
      _.isEqual(this.state, nextState)
    );
  }

  componentDidMount() {
    this.calculateSize();
    window.addEventListener('mouseup', this.handleMouseUp);
    if (this.itemList) {
      this.itemList.addEventListener('touchmove', this.handleTouchMove, {
        passive: false
      });
    }
    if (this.props.vertical) {
      this.itemList.style.minHeight = _.sum(this.getBreakpoints());
    } else {
      this.itemList.style.minWidth = _.sum(this.getBreakpoints());
    }
    if (this.state.position !== 0) {
      this.setState({
        offset: this.getOffsetValue(-this.getBreakpoints()[this.state.position])
      });
    }
    if (this.props.flux && this.props.flux.store('MediaStore')) {
      this.props.flux.store('MediaStore').on('change', this.handleResize);
    }
    this.calculateControlState();
  }

  componentDidUpdate(prevProps, prevState) {
    this.calculateSize();
    this.calculateControlState();
    if (
      prevProps.ensureVisible !== this.props.ensureVisible &&
      _.isFinite(this.props.ensureVisible)
    ) {
      this.ensureVisible(this.props.ensureVisible);
    }
    if (this.state.position !== prevState.position) {
      this.props.onSlide(this);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.handleMouseUp);
    if (this.itemList) {
      this.itemList.removeEventListener('touchmove', this.handleTouchMove);
    }
    if (this.props.flux && this.props.flux.store('MediaStore')) {
      this.props.flux.store('MediaStore').off('change', this.handleResize);
    }
  }

  getBreakpoints() {
    const breakpoints = [0];
    React.Children.forEach(this.props.children, (child, i) => {
      if (this['item' + i]) {
        let item = this['item' + i];
        if (item.wrapper) {
          item = item.wrapper;
        }
        if (_.isFunction(item.getBoundingClientRect)) {
          if (this.props.vertical) {
            breakpoints.push(
              breakpoints[i] + item.getBoundingClientRect().height
            );
          } else {
            breakpoints.push(
              breakpoints[i] + item.getBoundingClientRect().width
            );
          }
        }
      } else {
        breakpoints.push(0);
      }
    });
    return breakpoints;
  }

  getMaxOffset() {
    if (!this.container) {
      return 0;
    }
    const containerSize = this.props.vertical
      ? this.container.getBoundingClientRect().height
      : this.container.getBoundingClientRect().width;
    const size = this.props.vertical
      ? this.itemList.getBoundingClientRect().height
      : this.itemList.getBoundingClientRect().width;
    return size > containerSize ? size - containerSize : 0;
  }

  getOffsetValue(value) {
    return Math.min(0, Math.max(value, -this.getMaxOffset()));
  }

  handleResize() {
    this.setState({
      offset: this.getOffsetValue(-this.getBreakpoints()[this.state.position])
    });
  }

  handleDelta(delta, state) {
    const newState = _.extend(state || {}, {
      offset: this.getOffsetValue(this.state.offset - delta)
    });
    this.getBreakpoints().forEach(
      function(bp, idx) {
        if (-this.state.offset < bp && -newState.offset > bp) {
          newState.position = idx + 1;
        } else if (-this.state.offset > bp && -newState.offset < bp) {
          newState.position = idx - 1;
        }
      }.bind(this)
    );
    this.setState(newState);
  }

  handleMouseDown(ev) {
    this.setState({dragStart: this.props.vertical ? ev.clientY : ev.clientX});
  }

  handleMouseUp() {
    this.setState({dragStart: undefined});
  }

  handleMouseMove(ev) {
    if (_.isUndefined(this.state.dragStart)) {
      return;
    }
    ev.preventDefault();
    ev.stopPropagation();
    const delta =
      this.state.dragStart - (this.props.vertical ? ev.clientY : ev.clientX);
    this.handleDelta(delta, {
      dragStart: this.props.vertical ? ev.clientY : ev.clientX
    });
  }

  handleTouchStart(ev) {
    this.setState({
      touchStart: this.props.vertical
        ? ev.touches[0].clientY
        : ev.touches[0].clientX
    });
  }

  handleTouchEnd() {
    this.setState({touchStart: undefined});
  }

  handleTouchMove(ev) {
    if (_.isUndefined(this.state.touchStart)) {
      return;
    }
    ev.preventDefault();
    ev.stopPropagation();
    const delta =
      this.state.touchStart -
      (this.props.vertical ? ev.touches[0].clientY : ev.touches[0].clientX);
    this.handleDelta(delta, {
      touchStart: this.props.vertical
        ? ev.touches[0].clientY
        : ev.touches[0].clientX
    });
  }

  handleWheel(ev) {
    if (!this.props.hijackScroll) {
      return;
    }
    ev.preventDefault();
    ev.stopPropagation();
    let wheelDelta;
    if (Math.abs(ev.deltaX) > Math.abs(ev.deltaY)) {
      wheelDelta = ev.deltaX;
    } else {
      wheelDelta = ev.deltaY;
    }
    if (Math.abs(wheelDelta) > this.props.increment) {
      if (wheelDelta > 0) {
        this.increment();
      } else {
        this.decrement();
      }
    }
  }

  calculateSize() {
    if (!this.itemList) {
      return;
    }
    const list = this.itemList;
    const size =
      _.sum(
        Array.prototype.map.call(
          list.children,
          child => child.getBoundingClientRect().width
        )
      ) + 'px';
    if (this.props.vertical) {
      list.style.minHeight = size;
    } else {
      list.style.minWidth = size;
    }
  }

  calculateControlState() {
    const containerSize = this.props.vertical
      ? this.container.getBoundingClientRect().height
      : this.container.getBoundingClientRect().width;
    const size = this.props.vertical
      ? this.itemList.getBoundingClientRect().height
      : this.itemList.getBoundingClientRect().width;
    this.setState({
      canDecrement:
        this.state.position > 0 &&
        this.state.offset < 0 &&
        size > containerSize,
      canIncrement:
        this.state.position < React.Children.count(this.props.children) - 1 &&
        Math.abs(this.state.offset) < this.getMaxOffset() &&
        size > containerSize
    });
  }

  decrement(callback) {
    if (!this.state.canDecrement) {
      return;
    }
    if (!_.isFunction(callback)) {
      callback = _.identity;
    }
    const newPosition = Math.max(this.state.position - this.props.increment, 0);
    this.setState(
      {
        position: newPosition,
        offset: this.getOffsetValue(-this.getBreakpoints()[newPosition])
      },
      callback
    );
  }

  increment(callback) {
    if (!this.state.canIncrement) {
      return;
    }
    if (!_.isFunction(callback)) {
      callback = _.identity;
    }
    const newPosition = Math.min(
      this.state.position + this.props.increment,
      React.Children.count(this.props.children) - 1
    );
    this.setState(
      {
        position: newPosition,
        offset: this.getOffsetValue(-this.getBreakpoints()[newPosition])
      },
      callback
    );
  }

  ensureVisible(position, iteration = 0) {
    const item = this['item' + position];
    if (!item || iteration > React.Children.count(this.props.children)) {
      return;
    }
    const itemRect = item.getBoundingClientRect();
    const parentRect = item.parentNode.parentNode.getBoundingClientRect();
    if (position === 0) {
      this.setState({
        position: 0,
        offset: 0
      });
    } else if (
      (this.props.vertical && itemRect.top < parentRect.top) ||
      (!this.props.vertical && itemRect.left < parentRect.left)
    ) {
      this.decrement(this.ensureVisible.bind(this, position, iteration + 1));
    } else if (
      (this.props.vertical && itemRect.bottom > parentRect.bottom) ||
      (!this.props.vertical && itemRect.right > parentRect.right)
    ) {
      this.increment(this.ensureVisible.bind(this, position, iteration + 1));
    }
  }

  render() {
    const includeArrows =
      this.props.includeArrows &&
      (this.props.includeArrowsIfScrollDisabled
        ? true
        : this.state.canIncrement || this.state.canDecrement);
    const items = React.Children.map(this.props.children, (item, idx) => {
      return React.cloneElement(item, {
        ref: node => (this['item' + idx] = node)
      });
    });
    const style = this.props.vertical
      ? {
          transform: `translateY(${this.state.offset}px)`
        }
      : {
          transform: `translateX(${this.state.offset}px)`
        };
    const moving =
      !_.isUndefined(this.state.dragStart) ||
      !_.isUndefined(this.state.touchStart);
    const itemListClassName = classNames(
      'js-slider__item-list',
      this.props.className,
      {'js-slider__item-list--moving': moving}
    );
    const wrapperClassName = classNames(
      'js-slider',
      this.props.wrapperClassName,
      {
        'js-slider--vertical': this.props.vertical,
        'js-slider--horizontal': !this.props.vertical
      }
    );
    const containerClassName = classNames(
      'js-slider__container',
      this.props.containerClassName
    );
    const id = _.uniqueId('slider-container-');
    return (
      <div
        onLoad={this.calculateControlState}
        ref={node => (this.wrapper = node)}
        className={wrapperClassName}
      >
        {includeArrows ? (
          <button
            className="js-slider__control js-slider__control--prev button-ctrl"
            aria-hidden={true}
            disabled={!this.state.canDecrement}
            onClick={this.decrement}
          >
            <span className="button__text visually-hidden">Previous</span>
            <svg aria-hidden="true" focusable="false" viewBox="0 0 20 20">
            <path d="M14.7 20L3.5 10 14.7 0l1.2 1.4L6.4 10l9.5 8.6z"></path>
          </svg>
          </button>
        ) : (
          ''
        )}
        <div
          ref={node => (this.container = node)}
          id={id}
          className={containerClassName}
        >
          <ul
            className={itemListClassName}
            ref={node => (this.itemList = node)}
            style={style}
            onWheel={this.handleWheel.bind(this)}
            onMouseDown={this.handleMouseDown.bind(this)}
            onMouseUp={this.handleMouseUp.bind(this)}
            onMouseMove={this.handleMouseMove.bind(this)}
            onTouchStart={this.handleTouchStart.bind(this)}
            onTouchEnd={this.handleTouchEnd.bind(this)}
          >
            {items}
          </ul>
        </div>
        {includeArrows ? (
          <button
            className="js-slider__control js-slider__control--next button-ctrl"
            aria-hidden={true}
            disabled={!this.state.canIncrement}
            onClick={this.increment}
          >
            <span className="button__text visually-hidden">Next</span>
            <svg aria-hidden="true" focusable="false" viewBox="0 0 20 20">
            <path d="M5.3 0l11.1 10L5.3 20 4 18.6l9.6-8.6L4 1.4z"></path>
            </svg>
          </button>
        ) : (
          ''
        )}
      </div>
    );
  }
}
Slider.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  includeArrows: PropTypes.bool,
  includeArrowsIfScrollDisabled: PropTypes.bool,
  hijackScroll: PropTypes.bool,
  position: PropTypes.number,
  ensureVisible: PropTypes.number,
  increment: PropTypes.number,
  onSlide: PropTypes.func,
  vertical: PropTypes.bool,
  flux: PropTypes.instanceOf(Fluxxor.Flux)
};
Slider.defaultProps = {
  includeArrows: true,
  includeArrowsIfScrollDisabled: false,
  hijackScroll: false,
  position: 0,
  increment: 1,
  onSlide: _.identity,
  vertical: false
};
