import Immutable from 'immutable';
import * as React from 'react';
import ReactDOM from 'react-dom';

import {
  ProductPageNav,
  ProductPageNavStickyMenu,
} from '../components/product-page-nav.jsx';
import {
  ProductImageCarousel,
  ProductImageModal,
} from '../components/images.jsx';

export function productImageCarousel(elem, options) {
  if (!options || !options.flux) {
    return;
  }
  options.product =
    options.product || options.flux.store('ProductStore').getState().product;
  if (!Immutable.isImmutable(options.product)) {
    options.product = Immutable.fromJS(options.product);
  }
  if (!options.product) {
    return;
  }
  ReactDOM.render(<ProductImageCarousel {...options} />, elem);
}

export function productImageModal(elem, options) {
  if (!options || !options.flux) {
    return;
  }
  options.product =
    options.product || options.flux.store('ProductStore').getState().product;
  if (!Immutable.isImmutable(options.product)) {
    options.product = Immutable.fromJS(options.product);
  }
  if (!options.product) {
    return;
  }
  ReactDOM.render(<ProductImageModal {...options} />, elem);
}

export function productPageNav(elem, options) {
  if (!options || !options.flux) {
    return;
  }
  ReactDOM.render(<ProductPageNav flux={options.flux} />, elem);
}

export function productPageNavStickyMenu(elem, options) {
  ReactDOM.render(<ProductPageNavStickyMenu flux={options.flux} />, elem);
}
