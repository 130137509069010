import _ from "lodash";
import classNames from "classnames";
import Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import PropTypes from "prop-types";
import * as React from "react";
import { CSSTransition } from "react-transition-group";

import SimpleImmutableComponent from "prometheus/components/immutable.jsx";
import { SimpleImmutableFluxComponent } from "prometheus/flux/components.jsx";
import { Form } from "prometheus/forms/components/form.jsx";
import { Input } from "prometheus/forms/components/inputs/input.jsx";

import { Loading } from "../components.jsx";
import { LoginForm } from "../auth/components.jsx";
import { WishListType } from "./types.js";

class CreateWishListForm extends SimpleImmutableComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
    };
    ["handleNameChange", "handleSubmit"].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  handleNameChange(ev) {
    this.setState({
      name: ev.target.value,
    });
  }

  handleSubmit() {
    this.props.create({
      name: this.state.name,
      item: this.props.item.toJS(),
    });
    this.props.closeCreateDialog();
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Input
          name="name"
          id="new_wishlist_name"
          label="Name"
          onChange={this.handleNameChange}
          value={this.state.name}
          attrs={Immutable.Map({ required: true })}
        />
        <div className="t-small clr-neutral--lighter">
          Enter a name for your new wishlist. If you wish to make the new list
          public, or edit any of other details on it, you can do so later from
          your account.
        </div>
        <button type="button" onClick={this.props.closeCreateDialog}>
          Cancel
        </button>
        <button type="submit">Create a new wishlist</button>
      </Form>
    );
  }
}
CreateWishListForm.propTypes = {
  item: ImmutablePropTypes.mapContains({
    product: PropTypes.number.isRequired,
    catNumber: PropTypes.string.isRequired,
  }).isRequired,
  create: PropTypes.func.isRequired,
  closeCreateDialog: PropTypes.func.isRequired,
};

class AddToWishListList extends SimpleImmutableComponent {
  render() {
    const wishlists = this.props.wishlists.toArray().map(
      function renderAddToWishListOption(wishlist) {
        return (
          <li key={wishlist.get("id")} className="mz one-whole">
            <button
              className="txt-left mz bg-white clr-neutral one-whole"
              aria-label={`Add to ${wishlist.get("name")}`}
              style={{ height: "auto" }}
              onClick={_.partial(this.props.addItem, wishlist.get("id"))}
            >
              <span>{wishlist.get("name")} </span>
              <em className="t-small">
                ({wishlist.get("public") ? "Public" : "Private"})
              </em>
            </button>
          </li>
        );
      }.bind(this),
    );
    return (
      <ul className="pz oh striplist">
        {wishlists}
        <li key="new" className="mz one-whole ">
          <button
            onClick={this.props.createNew}
            aria-controls={this.props.createNewId}
            aria-haspopup="dialog"
            className="txt-left mz bg-white clr-neutral one-whole"
          >
            <em>Create a new list</em>
          </button>
        </li>
      </ul>
    );
  }
}
AddToWishListList.propTypes = {
  wishlists: ImmutablePropTypes.listOf(WishListType).isRequired,
  createNew: PropTypes.func.isRequired,
  createNewId: PropTypes.string.isRequired,
  addItem: PropTypes.func.isRequired,
};

export class AddToWishListForm extends SimpleImmutableFluxComponent {
  constructor(props) {
    super(props);
    this.state = _.extend(this.state || {}, {
      open: false,
      showCreateDialog: false,
      message: null,
    });
    this.timeouts = [];
    [
      "added",
      "exists",
      "handleBodyClick",
      "close",
      "toggle",
      "addToWishList",
      "closeCreateDialog",
      "openCreateDialog",
      {
        boundName: "addToWishListUndefined",
        originalName: "addToWishList",
        args: undefined,
      },
    ].forEach((method) => {
      if (_.isPlainObject(method)) {
        this[method.boundName] = this[method.originalName].bind(
          this,
          method.args,
        );
      } else {
        this[method] = this[method].bind(this);
      }
    });
  }

  getStateFromFlux() {
    return _.extend(
      {},
      _.pick(this.flux.store("ProductStore").getState(), [
        "product",
        "variant",
      ]),
      _.pick(this.flux.store("AuthStore").getState(), ["user", "loading"]),
      this.flux.store("WishListStore").getState(),
    );
  }

  componentDidMount() {
    super.componentDidMount();
    this.flux.store("WishListStore").addListener("exists", this.exists);
    this.flux.store("WishListStore").addListener("added", this.added);
    document.addEventListener("click", this.handleBodyClick);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.flux.store("WishListStore").removeListener("exists", this.exists);
    this.flux.store("WishListStore").removeListener("added", this.added);
    document.removeEventListener("click", this.handleBodyClick);
    this.timeouts.forEach((timeout) => clearTimeout(timeout));
  }

  addToWishList(wishlistId) {
    this.setState({ open: false });
    this.flux.actions.wishlist.addItem({
      item: {
        product: this.state.product.get("id"),
        catNumber: this.state.variant
          ? this.state.variant.get("catNumber")
          : this.state.product.get("catNumber"),
      },
      wishlist: wishlistId,
    });
  }

  exists(payload) {
    if (payload.item.product !== this.state.product.get("id")) {
      return;
    }
    this.setState(
      {
        message: Immutable.Map({
          status: "exists",
          wishlist: Immutable.fromJS(payload.wishlist.toObject()),
        }),
      },
      this.clearMessage.bind(this),
    );
  }

  added(payload) {
    if (payload.item.product !== this.state.product.get("id")) {
      return;
    }
    this.setState(
      {
        message: Immutable.Map({
          status: "added",
          wishlist: Immutable.fromJS(payload.wishlist.toObject()),
        }),
      },
      this.clearMessage.bind(this),
    );
  }

  clearMessage() {
    this.timeouts.forEach((timeout) => clearTimeout(timeout));
    this.timeouts.push(
      setTimeout(this.setState.bind(this, { message: null }), 5000),
    );
  }

  handleBodyClick(ev) {
    if (
      document.body.contains(ev.target) &&
      this.container &&
      !this.container.contains(ev.target)
    ) {
      this.setState({
        open: false,
        showCreateDialog: false,
      });
    }
  }

  toggle() {
    this.setState({ open: !this.state.open });
  }

  close() {
    this.setState({ open: false });
  }

  closeCreateDialog() {
    this.setState({
      showCreateDialog: false,
      open: false,
    });
  }

  openCreateDialog() {
    this.setState({
      showCreateDialog: true,
      open: false,
    });
  }

  render() {
    // User not logged in
    if (!this.state.user.get("isAuthenticated")) {
      const dialogId = _.uniqueId("wishlist-login");
      return (
        <div
          ref={(node) => (this.container = node)}
          className="relative"
          style={{ zIndex: 1, width: "20rem" }}
        >
          <dialog open={this.state.open} id={dialogId} style={{ zIndex: 2 }}>
            <p className="t-small mzt">
              Login or create an account to <strong>add</strong> to a&nbsp;
              <strong>wishlist</strong>
            </p>
            {this.state.open ? (
              <LoginForm
                handleLoginSuccess={this.close}
                handleLogout={this.close}
                handleLogoutSuccess={this.close}
              />
            ) : null}
          </dialog>
          <button
            className="phl pr button--white dfbx"
            aria-controls={dialogId}
            aria-haspopup="dialog"
            onClick={this.toggle}
          >
            <i className="icon icon--med vam icon-15"></i> Add to wishlist
          </button>
        </div>
      );
    }
    // Product has variants and the variant is not selected
    if (this.state.product.get("variants").count() && !this.state.variant) {
      return (
        <div className="relative" style={{ minHeight: "6rem" }}>
          <span className="brad ph bg clr-neutral">
            Select an option to add to wishlist
          </span>
        </div>
      );
    }

    const iconClassName = classNames("icon icon--med vam", {
      "icon-44": this.state.open,
      "icon-46": !this.state.open,
    });
    let messages = null;
    if (!_.isNull(this.state.message)) {
      const messageStatus = this.state.message.get("status");
      const messageWishlist = this.state.message.get("wishlist");
      if (messageStatus === "added") {
        messages = (
          <p key="added" className="mz bg-info ph">
            Added to{" "}
            <strong>
              <em>{messageWishlist.get("name")}</em>
            </strong>
          </p>
        );
      } else if (messageStatus === "exists") {
        messages = (
          <p key="exists" className="mz bg-info ph">
            Already in{" "}
            <strong>
              <em>{messageWishlist.get("name")}</em>
            </strong>
          </p>
        );
      }
    }
    const createProps = {
      item: Immutable.Map({
        product: this.state.product.get("id"),
        catNumber: this.state.variant
          ? this.state.variant.get("catNumber")
          : this.state.product.get("catNumber"),
      }),
      closeCreateDialog: this.closeCreateDialog,
      create: this.flux.actions.wishlist.createNew,
    };
    const createId = _.uniqueId("wishlist-new");
    const menuId = _.uniqueId("wishlist-menu");
    return (
      <div
        aria-busy={this.state.loading}
        className="relative dfbx"
        style={{ zIndex: 1, minHeight: "6rem" }}
      >
        <div className="dfbx dfbx--end row owl-off mz" style={{ zIndex: 2 }}>
          <button
            className=" phr phl button--white dfbx mzr"
            onClick={this.addToWishListUndefined}
            title="Add to default wishlist"
            disabled={this.state.loading}
            aria-label="Add to default wishlist"
          >
            <i className="icon icon--med vam icon-15" />
            Add to Wishlist
          </button>
          <div className="relative">
            <button
              className="button--white oh mzt mzl"
              onClick={this.toggle}
              title="Add to another wishlist"
              disabled={this.state.loading}
              aria-label="Add to another wishlist"
              aria-expanded={this.state.open}
              aria-haspopup="menu"
              aria-controls={menuId}
              aria-owns={menuId}
            >
              <i className={iconClassName} />
            </button>
            <div
              id={menuId}
              role="menu"
              className="mz pa pa--tr pa--t js-dropdown-menu__container pq bg-white"
              style={{
                zIndex: 3,
                width: "300px",
                top: "47px",
                right: "-120px",
                display: this.state.open || messages ? "block" : "none",
              }}
            >
              <CSSTransition
                appear={true}
                classNames="ra-wishlist-button-message-"
                timeout={200}
                in={this.state.open}
              >
                {messages ? (
                  messages
                ) : (
                  <AddToWishListList
                    wishlists={this.state.wishlists}
                    createNew={this.openCreateDialog}
                    createNewId={createId}
                    addItem={this.addToWishList}
                  />
                )}
              </CSSTransition>
            </div>
          </div>
        </div>
        <dialog
          id={createId}
          open={this.state.showCreateDialog}
          style={{ zIndex: 4 }}
        >
          <CreateWishListForm {...createProps} />
        </dialog>
      </div>
    );
  }
}
AddToWishListForm.watchedStores = [
  "ProductStore",
  "WishListStore",
  "AuthStore",
];

class MoveToWishListList extends SimpleImmutableComponent {
  render() {
    const currentWishlist = this.props.wishlists.find(
      (wishlist) => wishlist.get("id") === this.props.currentWishlistId,
    );
    const wishlists = this.props.wishlists.toArray().map(
      function renderMoveToWishListOption(wishlist) {
        return (
          <li key={wishlist.get("id")} className="mz one-whole">
            <button
              className="txt-left mz bg-white clr-neutral one-whole"
              aria-label={`Add to ${wishlist.get("name")}`}
              disabled={wishlist.get("id") === currentWishlist.get("id")}
              style={{ height: "auto" }}
              onClick={_.partial(this.props.moveItem, wishlist)}
            >
              <span>{wishlist.get("name")} </span>
              <em className="t-small">
                ({wishlist.get("public") ? "Public" : "Private"})
              </em>
            </button>
          </li>
        );
      }.bind(this),
    );
    return <ul className="pz oh striplist">{wishlists}</ul>;
  }
}
MoveToWishListList.propTypes = {
  wishlists: ImmutablePropTypes.listOf(WishListType).isRequired,
  currentWishlistId: PropTypes.number.isRequired,
  moveItem: PropTypes.func.isRequired,
};

export class MoveToWishListForm extends SimpleImmutableFluxComponent {
  constructor(props) {
    super(props);
    this.menuId = _.uniqueId("wishlist-menu");
    this.timeouts = [];
    ["handleBodyClick", "moveToWishList", "toggle"].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  getStateFromFlux() {
    return _.extend(
      {},
      _.pick(this.flux.store("AuthStore").getState(), ["user"]),
      this.flux.store("WishListStore").getState(),
    );
  }

  componentDidMount() {
    super.componentDidMount();
    document.addEventListener("click", this.handleBodyClick);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    document.removeEventListener("click", this.handleBodyClick);
    this.timeouts.forEach((timeout) => clearTimeout(timeout));
  }

  moveToWishList(wishlist) {
    const currentWishlist = this.state.wishlists.find(
      (wishlist) => wishlist.get("id") === this.props.wishlistId,
    );
    const item = currentWishlist
      .get("items")
      .find((item) => item && item.get("id") === this.props.itemId);
    const callback = _.after(item ? 2 : 1, () => location.reload());
    this.flux.store("WishListStore").once("added", callback);
    this.flux.store("WishListStore").once("exists", callback);
    this.flux.store("WishListStore").once("removed", callback);
    this.flux.actions.wishlist.addItem({
      item: {
        product: item.get("product"),
        catNumber: item.get("catNumber"),
      },
      wishlist: wishlist.get("id"),
    });
    this.flux.actions.wishlist.removeItem({
      item: item.get("id"),
      wishlist: currentWishlist.get("id"),
    });
  }

  handleBodyClick(ev) {
    if (
      this.menu &&
      this.menu.open &&
      document.body.contains(ev.target) &&
      this.container &&
      !this.container.contains(ev.target)
    ) {
      if (_.isFunction(this.menu.close)) {
        this.menu.close();
      } else {
        this.menu.removeAttribute("open");
      }
    }
  }

  toggle() {
    if (!this.menu) {
      return;
    }
    if (this.menu.open) {
      if (_.isFunction(this.menu.close)) {
        this.menu.close();
      } else {
        this.menu.removeAttribute("open");
      }
    } else {
      if (_.isFunction(this.menu.show)) {
        this.menu.show();
      } else {
        this.menu.setAttribute("open", "");
      }
    }
  }

  render() {
    return (
      <div ref={(node) => (this.container = node)}>
        <button
          className="button button--link dfbx dfbx--aic mb"
          onClick={this.toggle}
          title="Move to another wishlist"
          aria-label="Move to another wishlist"
          aria-controls={this.menuId}
        >
          Move to another list
        </button>
        <dialog
          id={this.menuId}
          ref={(node) => (this.menu = node)}
          className=""
          style={{ zIndex: 4 }}
        >
          {this.state.loading ? (
            <Loading />
          ) : (
            <MoveToWishListList
              wishlists={this.state.wishlists}
              currentWishlistId={this.props.wishlistId}
              moveItem={this.moveToWishList}
            />
          )}
          <button className="button brad one-whole mt" onClick={this.toggle}>
            Cancel
          </button>
        </dialog>
      </div>
    );
  }
}
MoveToWishListForm.propTypes = _.extend(
  _.clone(SimpleImmutableFluxComponent.propTypes),
  {
    wishlistId: PropTypes.number.isRequired,
    itemId: PropTypes.number.isRequired,
  },
);
MoveToWishListForm.watchedStores = ["WishListStore", "AuthStore"];
