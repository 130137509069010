import _ from 'lodash';

import {Product} from '../catalog/models.js';

import {Model, types} from '../models/base.js';
import {ModelArray} from '../models/arrays.js';
import {restMixin, restArrayMixin} from '../models/rest.js';

class CartPostage extends restMixin(
  Model.withOptions({
    url: '/api/cart/postage/',
    spec: {
      country: types.string.withDefault('GB').isRequired(),
      amount: types.float.withDefault(0.0),
    },
    clientOnly: ['amount'],
  }),
) {}

export class CartItem extends restMixin(
  Model.withOptions({
    url: (instance) => {
      if (Number.isFinite(instance.id)) {
        return `/api/cart/items/${instance.id}/`;
      }
      return '/api/cart/items/';
    },
    spec: {
      id: types.int,
      product: types.string.isRequired(),
      wishlistItem: types.int,
      quantity: types.int.withDefault(1).isRequired(),
      catNumber: types.string.isRequired(),
      dateCreated: types.date,
      lastModified: types.date,
      sitePrice: types.float,
      basePrice: types.float,
      couponPrice: types.float,
      total: types.float,
      quantityConstraint: types.int,
      quantityAvailable: types.int,
      availability: types.string,
      productListed: types.bool,
      variantName: types.string,
      strapline: types.string,
      productDetail: types.model(Product),
      packaging: types.string,
    },
    clientOnly: [
      'dateCreated',
      'lastModified',
      'sitePrice',
      'basePrice',
      'couponPrice',
      'total',
      'quantityConstraint',
      'quantityAvailable',
      'availability',
      'productListed',
      'strapline',
      'productDetail',
      'packaging',
      'customOrderLabel',
    ],
    derived: {
      availabilityDisplayName: (instance) => {
        if (instance.availability == 'custom-order') {
          return instance.productDetail.customOrderLabel;
        }
        return {
          discontinued: 'Discontinued',
          preorder: 'Pre-Order',
          'out-of-stock': 'Currently Unavailable',
          'in-stock': 'In Stock',
        }[instance.availability];
      },
      maxQuantity: (instance) =>
        _.min([instance.quantityConstraint, instance.quantityAvailable]),
      productId: (instance) => {
        const re = /.*\/api\/products\/(\d+)/i;
        const match = instance.product.match(re);
        return match ? _.toInteger(match[1]) : null;
      },
    },
  }),
) {}

export class CartItemArray extends restArrayMixin(
  ModelArray.ofModel(CartItem, {url: '/api/cart/items/'}),
) {}

export class Cart extends restMixin(
  Model.withOptions({
    url: '/api/cart/',
    readOnly: true,
    spec: {
      id: types.int,
      totalItems: types.int.withDefault(0),
      totalLines: types.int.withDefault(0),
      taxable: types.bool.withDefault(false),
      subTotal: types.float.withDefault(0.0),
      couponDiscount: types.float.withDefault(0.0),
      total: types.float.withDefault(0.0),
      totalExcludingPostage: types.float.withDefault(0.0),
      timeout: types.date,
      dateCreated: types.date,
      lastModified: types.date,
      completed: types.bool,
      postage: types.model(CartPostage).withDefault(new CartPostage()),
      items: types.modelArray(CartItemArray).withDefault([]),
    },
  }),
) {}
