import _ from 'lodash';
import PropTypes from 'prop-types';
import * as React from 'react';
import ReactDOM from 'react-dom';

import {Projectile} from './trajectory.js';

export const RocketImageContext = React.createContext(null);

export class Rocket extends React.Component {
  constructor(props) {
    super(props);
    this.bodyHeight = document.body.getBoundingClientRect().height;
    const originRect = this.props.origin.getBoundingClientRect();
    const targetRect = this.props.target.getBoundingClientRect();
    const scrollX = _.isFinite(window.scrollX)
      ? window.scrollX
      : window.pageXOffset;
    const scrollY = _.isFinite(window.scrollY)
      ? window.scrollY
      : window.pageYOffset;
    const origin = [
      scrollX + originRect.left + this.props.originOffset[0],
      -scrollY + this.bodyHeight - originRect.top + this.props.originOffset[1],
    ];
    const target = [
      scrollX + targetRect.left + this.props.targetOffset[0],
      -scrollY + this.bodyHeight - targetRect.top + this.props.targetOffset[1],
    ];
    const projectile = new Projectile(origin, target);
    this.state = {
      projectile: projectile,
      start: null,
      countdown: null,
      position: origin,
      directionVector: projectile.directionVectorAtTime(0),
      destroy: false,
      wobble: 0,
    };
    this.animationFrames = [];
    ['calculateState', 'countdown'].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  componentDidMount() {
    this.countdown();
  }

  componentWillUnmount() {
    this.cancelFrames();
  }

  preventDefault(ev) {
    ev.preventDefault();
    ev.stopPropagation();
  }

  cancelFrames() {
    this.animationFrames.map(function (frame) {
      window.cancelAnimationFrame(frame);
    });
  }

  generateWobble() {
    const maxWobble = +20;
    const minWobble = -20;
    const wobbleVariance = 5;
    const wobble =
      Math.floor(Math.random() * (2 * wobbleVariance + 1)) - wobbleVariance;
    return Math.min(Math.max(this.state.wobble + wobble, minWobble), maxWobble);
  }

  countdown() {
    const t = new Date();
    const bounce = [20, 20];
    if (
      this.state.countdown !== null &&
      t - this.state.countdown > this.props.delay
    ) {
      this.cancelFrames();
      this.fire();
      return;
    }
    const countdownProgress = (t - this.state.countdown) / this.props.delay;
    const theta = 2 * Math.PI * countdownProgress;
    const bounceFunc = Math.pow(Math.sin(theta), 2);
    this.setState(
      {
        countdown: this.state.countdown || t,
        wobble: this.generateWobble(),
        position: [
          this.state.projectile.origin[0] +
            countdownProgress * bounce[0] -
            bounce[0],
          this.state.projectile.origin[1] + bounce[1] * bounceFunc,
        ],
      },
      function () {
        this.cancelFrames();
        this.animationFrames.push(window.requestAnimationFrame(this.countdown));
      }.bind(this)
    );
  }

  fire() {
    window.addEventListener('scroll', this.preventDefault);
    window.addEventListener('wheel', this.preventDefault);
    this.setState(
      {
        start: new Date(),
        wobble: this.state.wobble / 2,
      },
      function () {
        this.cancelFrames();
        this.animationFrames.push(
          window.requestAnimationFrame(this.calculateState)
        );
      }.bind(this)
    );
  }

  calculateState() {
    const t =
      ((new Date() - this.state.start) / 1000) * this.props.timeMultiplier;
    if (t >= this.state.projectile.maxT) {
      this.destroy();
      return;
    }
    this.setState(
      {
        position: this.state.projectile.positionAtTime(t),
        directionVector: this.state.projectile.directionVectorAtTime(t),
        wobble: 0,
      },
      function () {
        this.cancelFrames();
        this.animationFrames.push(
          window.requestAnimationFrame(this.calculateState)
        );
      }.bind(this)
    );
  }

  destroy() {
    window.removeEventListener('scroll', this.preventDefault);
    window.removeEventListener('wheel', this.preventDefault);
    const parent = this.container.parentElement;
    parent.style.transition = 'opacity 0.1s ease-in-out';
    parent.style.opacity = 0;
    function remove() {
      ReactDOM.unmountComponentAtNode(parent);
      if (parent) {
        parent.parentElement.removeChild(parent);
      }
    }
    setTimeout(remove, 200);
  }

  render() {
    const scrollX = _.isFinite(window.scrollX)
      ? window.scrollX
      : window.pageXOffset;
    const [dx, dy] = this.state.directionVector;
    let angle;
    if (dy === 0 && dx === 0) {
      angle = 0;
    } else if (dy === 0) {
      angle = dx > 0 ? 90 : 270;
    } else if (dx === 0) {
      angle = dy > 0 ? 0 : 180;
    } else {
      angle = (360 * Math.atan(Math.abs(dx) / Math.abs(dy))) / (2 * Math.PI);
      if (dx > 0 && dy < 0) {
        angle = 180 - angle;
      } else if (dx < 0 && dy < 0) {
        angle = 270 - angle;
      } else {
        angle = 360 - angle;
      }
    }
    const style = {
      position: 'absolute',
      zIndex: 1000,
      opacity: this.state.destroy ? 0 : 1,
      transition: 'opacity 0.1s ease-in-out',
      left: scrollX + this.state.position[0] + 'px',
      transform: 'rotate(' + (angle + this.state.wobble) + 'deg)',
      top: this.bodyHeight - this.state.position[1] + 'px',
    };
    let size = this.props.size;
    if (this.state.start) {
      const t =
        ((new Date() - this.state.start) / 1000) * this.props.timeMultiplier;
      const multiplier =
        0.4 + (this.state.projectile.maxT - t) / this.state.projectile.maxT;
      size *= Math.min(multiplier, 1);
    }
    return (
      <RocketImageContext.Consumer>
        {function (image) {
          return image || this.props.image ? (
            <div ref={(node) => (this.container = node)} style={style}>
              <img src={image || this.props.image} height={size} />
            </div>
          ) : null;
        }}
      </RocketImageContext.Consumer>
    );
  }
}
Rocket.propTypes = {
  origin: PropTypes.instanceOf(Element).isRequired,
  target: PropTypes.instanceOf(Element).isRequired,
  originOffset: PropTypes.arrayOf(PropTypes.number),
  targetOffset: PropTypes.arrayOf(PropTypes.number),
  delay: PropTypes.number,
  image: PropTypes.string,
  size: PropTypes.number,
  timeMultiplier: PropTypes.number,
};
Rocket.defaultProps = {
  originOffset: [0, 0],
  targetOffset: [0, 0],
  delay: 600,
  image: '',
  size: 100,
  timeMultiplier: 0.1,
};
