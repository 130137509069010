import _ from 'lodash';

const levels = ['DEBUG', 'INFO', 'WARN', 'ERROR'];

const on = !_.isUndefined(window.console);

function match(level) {
  const position = _.indexOf(
    levels,
    this.flux.store('SettingsStore').getState().debugLevel || 'DEBUG'
  );
  return _.indexOf(levels, level) >= position;
}

function prefix() {
  return ['[', new Date().toISOString(), ']'].join('');
}

export function log() {
  if (!on || !_.isFunction(window.console.log) || !match.bind(this)('DEBUG')) {
    return;
  }
  Array.prototype.unshift.call(arguments, prefix());
  window.console.log.apply(window.console, arguments);
}
export function debug() {
  if (
    !on ||
    !_.isFunction(window.console.debug) ||
    !match.bind(this)('DEBUG')
  ) {
    return;
  }
  Array.prototype.unshift.call(arguments, prefix());
  window.console.debug.apply(window.console, arguments);
}
export function info() {
  if (!on || !_.isFunction(window.console.info) || !match.bind(this)('INFO')) {
    return;
  }
  Array.prototype.unshift.call(arguments, prefix());
  window.console.info.apply(window.console, arguments);
}
export function warn() {
  if (!on || !_.isFunction(window.console.warn) || !match.bind(this)('WARN')) {
    return;
  }
  Array.prototype.unshift.call(arguments, prefix());
  window.console.warn.apply(window.console, arguments);
}
export function error() {
  if (
    !on ||
    !_.isFunction(window.console.error) ||
    !match.bind(this)('ERROR')
  ) {
    return;
  }
  Array.prototype.unshift.call(arguments, prefix());
  window.console.error.apply(window.console, arguments);
}
