export function addItem(payload) {
  this.dispatch('WISHLIST:ADD_ITEM', payload);
}

export function createNew(payload) {
  this.dispatch('WISHLIST:CREATE_NEW', payload);
}

export function refresh() {
  this.dispatch('WISHLIST:REFRESH');
}
