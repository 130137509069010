import PropTypes from 'prop-types';
import * as React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import {LoadingSVG} from './loading-svg.jsx';

export class Loading extends React.PureComponent {
  render() {
    const className = classNames('js-loading', this.props.className);
    const props = _.defaults(_.omit(this.props, ['className', 'size', 'src']), {
      title: 'Please wait…',
      'aria-label': 'Please wait…',
    });
    const size = this.props.size;
    let image;
    if (!this.props.src) {
      image = <LoadingSVG size={size} />;
    } else if (this.props.src.indexOf('.svg') >= 0) {
      image = (
        <svg height={size} alt="Loading…">
          <use xlinkHref={this.props.src} />
        </svg>
      );
    } else {
      image = <img src={this.props.src} height={size} alt="Loading…" />;
    }
    return (
      <div className={className} {...props}>
        {image}
      </div>
    );
  }
}
Loading.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  src: PropTypes.string,
};
Loading.defaultProps = {
  size: 130,
};

export class PlaceholderFigure extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {loaded: false};
    this.handleLoad = this.handleLoad.bind(this);
  }

  handleLoad() {
    this.setState({loaded: true});
  }

  render() {
    const className = classNames('placeholder-image', this.props.className, {
      loaded: this.state.loaded,
      loading: !this.state.loaded,
    });
    const props = _.omit(this.props, ['className', 'children']);
    return (
      <figure onLoad={this.handleLoad} className={className} {...props}>
        {this.props.children}
      </figure>
    );
  }
}
PlaceholderFigure.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
