import _ from 'lodash';
import Immutable from 'immutable';
import * as React from 'react';
import PropTypes from 'prop-types';
import FluxComponent from 'prometheus/flux/components.jsx';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';

export class SiteMessage extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !(
      this.props.dismiss === nextProps.dismiss &&
      this.props.message.equals(nextProps.message)
    );
  }

  render() {
    const message = this.props.message.toObject();
    const className = classNames(
      'mz',
      'g-site-width centered txt-left dfbx dfbx--aic dfbx--sb',
      message.level ? `bg-${message.level}` : 'bg-info',
      message.dismissable ? `pq` : 'ph'
    );
    return (
      <li className="vert-list__item" key={message.id}>
        <div className={className}>
          <div
            className="phl phr"
            dangerouslySetInnerHTML={{__html: message.message}}
          />
          {_.defaultTo(message.dismissable, true) ? (
            <button
              className="brad--sm t-small button--icon icon-button mhr mhl g-close"
              onClick={() => this.props.dismiss(message)}
            >
              <i
                className="icon icon-11 icon--med"
                aria-hidden="true"
                focusable="false"
              ></i>
              <span className="visually-hidden">Dismiss</span>
            </button>
          ) : null}
        </div>
      </li>
    );
  }
}
SiteMessage.propTypes = {
  message: ImmutablePropTypes.contains({
    id: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
    level: PropTypes.string,
  }).isRequired,
  dismiss: PropTypes.func.isRequired,
};

export class SiteMessages extends FluxComponent {
  shouldComponentUpdate(nextProps, nextState) {
    return !(
      Immutable.isImmutable(this.state.messages) &&
      Immutable.isImmutable(nextState.messages) &&
      this.state.messages.equals(nextState.messages)
    );
  }

  getStateFromFlux() {
    return this.flux.store('SiteMessagesStore').getState();
  }

  render() {
    if (this.state.messages.size <= 0) {
      return null;
    }
    const messages = this.state.messages.map((message) => (
      <SiteMessage
        message={message}
        key={message.get('id')}
        dismiss={this.flux.actions.siteMessages.dismiss}
      />
    ));
    return <ul className="striplist vert-list pz owlq mqt mqb">{messages}</ul>;
  }
}
SiteMessages.watchedStores = ['SiteMessagesStore'];
