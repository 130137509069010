const G = 9.80665 * 4000; // Actual G times a suitable multiplier to account for m -> px units

export class Projectile {
  constructor(origin, target) {
    this.origin = origin;
    this.target = target;
    this.delta = [target[0] - origin[0], target[1] - origin[1]];
    if (this.delta[0] < 0) {
      this.delta[0] = -this.delta[0];
      this.reversed = true;
    } else {
      this.reversed = false;
    }
    if (this.delta[0] > 0 && this.delta[1] === 0) {
      // Flat ground going right
      this.slope = 0;
      this.theta = Math.PI / 4;
      this.modV = Math.sqrt((this.delta[0] * G) / Math.sin(2 * this.theta));
      this.maxT = (2 * this.modV * Math.sin(this.theta)) / G;
    } else if (this.delta[0] > 0 && this.delta[1] > 0) {
      // Going up and right
      this.slope = Math.atan(this.delta[1] / this.delta[0]);
      this.theta =
        this.slope + Math.min(Math.PI / 2 - this.slope, this.slope) / 2;
      const rangeRatio =
        (1 / Math.cos(this.slope)) *
        (1 - (1 / Math.tan(this.theta)) * Math.tan(this.slope));
      const slopeLength = Math.sqrt(
        Math.pow(this.delta[0], 2) + Math.pow(this.delta[1], 2)
      );
      this.modV = Math.sqrt(
        Math.abs((slopeLength * G) / (rangeRatio * Math.sin(2 * this.theta)))
      );
      this.maxT =
        (this.modV * Math.sin(this.theta)) / G -
        Math.sqrt(
          Math.abs(
            Math.pow(this.modV, 2) * Math.pow(Math.sin(this.theta), 2) +
              2 * G * -this.delta[1]
          )
        ) /
          G;
    } else if (this.delta[0] === 0 && this.delta[1] > 0) {
      // Going straight up
      this.slope = Math.PI / 2;
      this.theta = this.slope;
      this.modV = Math.sqrt(2 * this.delta[1] * G);
      this.maxT = this.modV / G;
    } else if (this.delta[0] === 0 && this.delta[1] < 0) {
      // Going straight down
      this.slope = (3 * Math.PI) / 2;
      this.theta = this.slope;
      this.modV = 0;
      this.maxT = Math.sqrt(Math.abs((2 * this.delta[1]) / G));
    } else if (this.delta[0] > 0 && this.delta[1] < 0) {
      // Going down and to the right
      this.slope = 2 * Math.PI - Math.atan(-this.delta[1] / this.delta[0]);
      this.theta = 0;
      this.maxT = Math.sqrt(Math.abs((2 * this.delta[1]) / G));
      this.modV = this.delta[0] / this.maxT;
    }
  }

  positionAtTime(t) {
    const x = this.modV * t * Math.cos(this.theta);
    const y = this.modV * t * Math.sin(this.theta) - (G * Math.pow(t, 2)) / 2;
    let positionX;
    if (this.reversed) {
      positionX = this.origin[0] - x;
    } else {
      positionX = this.origin[0] + x;
    }
    return [positionX, this.origin[1] + y];
  }

  directionVectorAtTime(t) {
    const initialPosition = this.positionAtTime(t);
    const nextPosition = this.positionAtTime(t + 0.0000001);
    const delta = [
      nextPosition[0] - initialPosition[0],
      nextPosition[1] - initialPosition[1],
    ];
    const factor = Math.max.apply(null, delta);
    return [delta[0] / factor, delta[1] / factor];
  }
}
