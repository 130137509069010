import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import * as React from 'react';
import _ from 'lodash';
import fuzzy from 'fuzzy';

import {SimpleImmutableFluxComponent} from 'prometheus/flux/components.jsx';
import {Input} from 'prometheus/forms/components/inputs/input.jsx';
import {
  CheckboxInput,
  RadioInput,
} from 'prometheus/forms/components/inputs/input-radio.jsx';

import {FilterType} from '../../types.js';

class FilterGroup extends SimpleImmutableFluxComponent {
  constructor(props) {
    super(props);
    this.state = _.extend(this.state || {}, {
      expanded: false,
      filter: '',
    });
    ['toggleExpand', 'setFilter'].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  toggleExpand(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    this.setState({expanded: !this.state.expanded});
  }

  setFilter(ev) {
    const filter = ev.target.value;
    this.setState({filter: filter});
  }

  render() {
    const length = _.sum(
      this.props.filters
        .map((filter) => {
          const choices = filter.get('choices');
          return choices && choices.size ? choices.size : 1;
        })
        .toArray(),
    );
    let items = this.props.filters.flatMap((filter) => {
      const name = filter.get('name');
      const label = filter.get('label');
      const data = filter.get('data');
      let fields = [];
      let choices = filter.get('choices');
      if (!choices.size && !filter.get('isRange')) {
        fields = [
          <CheckboxInput
            key={'filter-' + name}
            className={
              name == 'hide_pre_orders' ? 'form__field--checkbox--invert' : ''
            }
            onChange={this.props.onChange}
            checked={data === 'on'}
            value="on"
            label={
              name === 'hide_pre_orders'
                ? [
                    <span key="pre-orders-hide" className="visually-hidden">
                      Do Not
                    </span>,
                    'Include Pre-orders',
                  ]
                : label
            }
            name={name}
          />,
        ];
      } else if (choices.size && !filter.get('isRange')) {
        const isNumeric = choices.every(
          (choice) =>
            _.isFinite(choice.get(0)) || (choice.get(0) || '').match(/^\d+$/),
        );
        if (isNumeric && name != 'average_rating') {
          choices = choices.sortBy((choice) => choice.get(0));
        }
        if (this.state.filter) {
          choices = Immutable.List(
            fuzzy
              .filter(this.state.filter, choices.toArray(), {
                extract: (choice) => choice.get(1),
              })
              .map((result) => result.original),
          );
        }
        fields = choices
          .map((choice, idx) => {
            const [choiceValue, choiceLabel] = Immutable.Map.isMap(choice)
              ? choice.values()
              : choice.toArray();
            const checked =
              (Immutable.List.isList(data) && data.includes(choiceValue)) ||
              (!choiceValue && !data) ||
              choiceValue === data ||
              (_.isNumber(choiceValue) && choiceValue === parseInt(data));
            let displayLabel = choiceLabel;
            let countMatch = null;
            if (name === 'average_rating') {
              displayLabel = (
                <span dangerouslySetInnerHTML={{__html: choiceLabel}} />
              );
            } else if ((countMatch = displayLabel.match(/^(.+) \((\d+)\)$/))) {
              const [count, text] = countMatch.reverse();
              displayLabel = (
                <React.Fragment>
                  {text}{' '}
                  {this.props.displayCounts ? (
                    <span className="clr-neutral t-small">({count})</span>
                  ) : null}
                </React.Fragment>
              );
            }
            const props = {
              key: 'filter-' + name + '-' + choiceValue,
              id: 'id_' + name + '_' + idx,
              onChange: this.props.onChange,
              checked: checked,
              label: displayLabel,
              name: name,
              value: choiceValue || '',
            };
            return filter.get('isMultipleChoice') ? (
              <CheckboxInput {...props} />
            ) : (
              <RadioInput {...props} />
            );
          })
          .toArray();
      } else if (filter.get('isRange') && Immutable.isMap(data)) {
        data.map((value, key) => {
          fields.push(
            <Input
              key={'filter-' + name + '-' + key}
              name={name + '_' + key}
              onChange={this.props.onChange}
              label={label}
              value={value || ''}
            />,
          );
        });
      }
      return fields.map((field) => (
        <li key={field.key} className="form__input-group__fields__field ">
          {field}
        </li>
      ));
    });

    let expander = null;
    if (length > 5) {
      if (!this.state.expanded) {
        items = items.take(5);
      }
      expander = (
        <button
          className="button--link mht ml"
          type="button"
          onClick={this.toggleExpand}
        >
          {this.state.expanded ? 'Show Less' : 'Show More'}
        </button>
      );
    }

    let search = null;
    if (length > 15) {
      search = (
        <div className="form__input-group__fields__field form__input-group__fields__field--search">
          <span className="form__field__input one-whole ">
            <input
              type="search"
              aria-label="Filter choices"
              placeholder="Search"
              onChange={this.setFilter}
            />
          </span>
        </div>
      );
    }

    return (
      <div className="">
        {search}
        <ul className="form__input-group__fields  oya relative mh--40">
          {items.toArray()}
        </ul>
        {expander}
      </div>
    );
  }
}
FilterGroup.propTypes = {
  filters: ImmutablePropTypes.listOf(FilterType).isRequired,
  onChange: PropTypes.func.isRequired,
  displayCounts: PropTypes.bool,
};
FilterGroup.defaultProps = {
  displayCounts: true,
};

class PriceFilter extends SimpleImmutableFluxComponent {
  constructor(props) {
    super(props);
    ['setPrice', 'checkValidity'].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  setPrice() {
    if (!this.checkValidity()) {
      return;
    }
    let min = this.min.value ? parseInt(this.min.value) : null;
    let max = this.max.value ? parseInt(this.max.value) : null;
    if (_.isFinite(min)) {
      min = Math.max(min, 0);
    }
    if (_.isFinite(max)) {
      max = Math.max(max, 0);
    }
    if (_.isFinite(min) && _.isFinite(max) && min > max) {
      [min, max] = [max, min];
    }
    if (
      (_.isFinite(max) || _.isNull(min)) &&
      (_.isFinite(min) || _.isNull(min))
    ) {
      this.props.setPrice(min, max);
    }
  }

  checkValidity() {
    if (!this.min || !this.max) {
      return;
    }
    if (!_.isFunction(HTMLInputElement.prototype.checkValidity)) {
      return (
        (_.isFinite(parseInt(this.min.value)) || !this.min.value) &&
        (_.isFinite(parseInt(this.max.value)) || !this.max.value)
      );
    }
    return this.min.checkValidity() && this.max.checkValidity();
  }

  render() {
    const inputStyle = {
      width: 'calc(100% - 1.5em)',
    };
    const data = this.props.filter.get('data');
    const min = data.get('0') || '';
    const max = data.get('1') || '';
    let pricePoints = [];
    const breaks = [10, 30, 50, 100, 250];
    if (!min && !max) {
      let pointStart = 0;
      breaks.push(Infinity);
      breaks.forEach((pointEnd) => {
        if (
          !(_.isFinite(this.props.min) && pointEnd <= this.props.min) &&
          !(_.isFinite(this.props.max) && pointStart >= this.props.max)
        ) {
          pricePoints.push([
            pointStart,
            pointEnd > this.props.max ? Infinity : pointEnd,
          ]);
        }
        pointStart = pointEnd;
      });
      pricePoints = pricePoints
        .map((pricePoint) => {
          let label;
          if (!pricePoint[0]) {
            label = `Under £${pricePoint[1]}`;
          } else if (!_.isFinite(pricePoint[1])) {
            label = `Over £${pricePoint[0]}`;
          } else {
            label = `£${pricePoint[0]} to £${pricePoint[1]}`;
          }
          return {
            min: pricePoint[0] || null,
            max: _.isFinite(pricePoint[1]) ? pricePoint[1] : null,
            label: label,
          };
        })
        .map((pricePoint) => {
          return (
            <li key={pricePoint.label} className="vert-list__item">
              <button
                key={pricePoint.label}
                className="block button--link"
                onClick={_.partial(
                  this.props.setPrice,
                  pricePoint.min,
                  pricePoint.max,
                )}
              >
                {pricePoint.label}
              </button>
            </li>
          );
        });
    } else {
      pricePoints = (
        <li className="vert-list__item pqt">
          <button
            className="button--link"
            onClick={_.partial(this.props.setPrice, null, null)}
          >
            All prices
          </button>
        </li>
      );
    }
    return (
      <div className="row owl-off one-whole mz phl phr phb">
        <div className="one-whole mhb">
          <ul className="striplist vert-list owlh pzl">{pricePoints}</ul>
        </div>
        <hr className="block one-whole brdr--top brdr--top--thin clr-neutral--lighter" />
        <div className="one-whole pqb dfbx dfbx--wrp dfbx--aic pq">
          <div
            style={{width: '37.5%'}}
            className="dib three-eighths owl-off form__field__input v-center"
          >
            <span className="pqr pql clr-neutral t-small">£</span>
            <input
              ref={(node) => (this.min = node)}
              type="number"
              aria-label="Minimum price"
              min={0}
              step={1}
              onBlur={this.checkValidity}
              style={inputStyle}
              className="pzr clr-black"
              defaultValue={min}
            />
          </div>
          <div className="dib owl-off mhl p-l p-r mzt clr-black"> to </div>
          <div
            style={{width: '37.5%'}}
            className="dib three-eighths owl-off form__field__input v-center mzt"
          >
            <span className="pqr pql clr-neutral t-small">£</span>
            <input
              ref={(node) => (this.max = node)}
              type="number"
              aria-label="Maximum price"
              min={min || 0}
              step={1}
              onBlur={this.checkValidity}
              style={inputStyle}
              className="pzr clr-black"
              defaultValue={max}
            />
          </div>
          <button
            className="button brad one-whole mht"
            type="button"
            onClick={this.setPrice}
            disabled={!this.props.online}
          >
            {this.props.online ? 'Go' : 'You appear to be offline…'}
          </button>
        </div>
      </div>
    );
  }
}
PriceFilter.propTypes = {
  setPrice: PropTypes.func.isRequired,
  filter: FilterType.isRequired,
  online: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
};
PriceFilter.defaultProps = {
  online: true,
};

class FilterWrapper extends React.PureComponent {
  render() {
    return (
      <div className="form__input-group mzt">
        <details
          ref={(el) => (this.details = el)}
          open={this.props.open}
          className="details_group"
        >
          <summary onClick={this.props.toggle}>
            <span>{this.props.label}</span>{' '}
            {this.props.selected ? (
              <span className="details_group_summary_selected-options pqb one-whole t-thin t-small clr-neutral">
                {this.props.selected}
              </span>
            ) : null}
          </summary>
          {this.props.children}
        </details>
      </div>
    );
  }
}
FilterWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  selected: PropTypes.node,
  open: PropTypes.bool,
  toggle: PropTypes.func,
};
FilterWrapper.defaultProps = {
  toggle: _.identity,
  open: true,
};

export class FilterWidget extends SimpleImmutableFluxComponent {
  constructor(props) {
    super(props);
    this.state = _.extend(this.state || {}, {
      // openFilters:
      //   is a bool map -- true/false or undefined if not in the map
      openFilters: Immutable.Map(),
      // we keep track of the last query so to detect if we need to reopen
      //   the filters on desktop
      query: '',
      online: true,
      offline: false,
    });
    ['handleChange', 'resetOpenStates', 'setPrice', 'toggleOpenState'].forEach(
      (method) => {
        this[method] = this[method].bind(this);
      },
    );
    this.filterWrappers = [];
    this.groups = {
      Availability: ['hide_pre_orders', 'show_out_of_stock'],
      Rarities: ['limited_edition', 'signed'],
      Discounts: ['sale', 'offers'],
      'Subscription Options': ['subscription'],
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    super.componentDidMount();
    this.flux
      .store('MediaStore')
      .addListener('change:breakpoint', this.resetOpenStates);
    this.resetOpenStates();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.flux
      .store('MediaStore')
      .removeListener('change:breakpoint', this.resetOpenStates);
  }

  getStateFromFlux() {
    const activeMedia = this.flux.store('MediaStore').getState().active;
    const newState = _.extend(
      {activeMedia: activeMedia},
      this.flux.store('NetworkStore').getState(),
      _.pick(this.flux.store('ProductListingStore').getState(), [
        'query',
        'filters',
        'loading',
        'products',
        'minPrice',
        'maxPrice',
        'forceDisplayCounts',
      ]),
    );
    /* if a filter is no longer available it should not
       be in the open list for mobile view
    */
    if (newState.query !== this.state.query) {
      return _.extend(newState, {openFilters: Immutable.Map()});
    } else if (activeMedia && !activeMedia.get('lap')) {
      const availableFilters = newState.filters.map((item) => item.get('name'));
      const openFilters =
        this.state.openFilters &&
        this.state.openFilters.filter((v, k) => {
          return k in this.groups
            ? this.groups[k].some((x) => availableFilters.includes(x))
            : availableFilters.includes(k);
        });
      return _.extend(
        newState,
        this.state.openFilters &&
          this.state.openFilters.size != openFilters.size
          ? {openFilters: openFilters}
          : {},
      );
    }
    return newState;
  }

  handleChange(ev) {
    if (this.state.offline) {
      ev.preventDefault();
      ev.stopPropagation();
      window.Hooks.showToast({
        msg: 'You appear to be offline…',
        level: 'error',
        timeout: 30,
      });
      return;
    }
    const {value, checked, name} = ev.target;
    if (
      !this.state.filters.find((filter) => filter.name === name) &&
      name.match(/_\d+$/)
    ) {
      let index = name.split('_').reverse()[0];
      index = parseInt(index) || index;
      this.flux.actions.catalog.productListing.addFilter(
        name,
        _.fromPairs([[index, value]]),
      );
    } else if (checked) {
      this.flux.actions.catalog.productListing.addFilter(name, value);
    } else {
      this.flux.actions.catalog.productListing.removeFilter(name, value);
    }
  }

  resetOpenStates() {
    this.setState({openFilters: Immutable.Map()});
  }

  isFilterOpen(label) {
    return this.state.openFilters.get(
      label,
      this.state.activeMedia ? this.state.activeMedia.get('lap') : true,
    );
  }

  toggleOpenState(label, ev) {
    if (ev) {
      ev.preventDefault();
    }
    this.setState({
      openFilters: this.state.openFilters.set(label, !this.isFilterOpen(label)),
    });
  }

  setPrice(min, max, ev) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    if (this.state.offline) {
      window.Hooks.showToast({
        msg: 'You appear to be offline…',
        level: 'error',
        timeout: 30,
      });
      return;
    }
    this.flux.actions.catalog.productListing.addFilter('price', {
      0: min,
      1: max,
    });
  }

  render() {
    if (
      !this.state.filters ||
      !this.state.filters.filter((filter) => !filter.get('isHidden')).size
    ) {
      return null;
    }

    const isGrouped = (filter) =>
      _.includes(_.flatten(_.values(this.groups)), filter.get('name'));

    const haveActiveFilters =
      this.state.filters.filter((filter) => {
        if (filter.get('isHidden')) {
          return false;
        }
        let data = filter.get('data');
        if (Immutable.Map.isMap(data)) {
          data = data.toList();
        }
        if (Immutable.List.isList(data)) {
          return data.filter((i) => !!i).size > 0;
        }
        return !!data;
      }).size > 0;

    let filterGroups = [];
    for (const label in this.groups) {
      // Include filters in this group but exclude any multiple choice filters
      // that have no options
      const inGroup = this.state.filters.filter(
        (filter) =>
          _.includes(this.groups[label], filter.get('name')) &&
          (!filter.get('isMultipleChoice') ||
            (filter.get('choices') && filter.get('choices').size)),
      );

      if (inGroup.size) {
        const selectedOptions = _.compact(
          inGroup
            .map((filter) => {
              const choices = filter.get('choices');
              const data = filter.get('data');
              const name = filter.get('name');
              if (Immutable.List.isList(choices) && choices.size) {
                return choices
                  .filter((choice) => data && data.includes(choice.get(0)))
                  .map((choice) => choice.get(1).replace(/\s*\(\d+\)$/, ''));
              } else if (data && name !== 'hide_pre_orders') {
                return filter.get('label');
              } else if (!data && name === 'hide_pre_orders') {
                return 'Include Pre-orders';
              }
            })
            .flatten()
            .filter((choice) => new Boolean(choice))
            .toArray(),
        );
        let selected = '';
        if (selectedOptions.length && selectedOptions.length < 4) {
          selected = selectedOptions.join(', ');
        } else if (selectedOptions.length && selectedOptions.length >= 4) {
          selected =
            selectedOptions.slice(0, 3).join(', ') +
            ', and ' +
            (selectedOptions.length - 3) +
            ' more';
        }

        /* This is ostensibly just to handle sale and offers, if we have a
         * single-option filter and a filter that has multiple choices we
         * break them into two groups in a single wrapper
         */
        const multiGroup =
          inGroup.size > 1 &&
          inGroup.filter((filter) => {
            const choices = filter.get('choices');
            return Immutable.List.isList(choices) && choices.size;
          }).size;
        if (multiGroup) {
          const inGroupFilters = inGroup.map((filter) => (
            <FilterGroup
              onChange={this.handleChange}
              key={filter}
              filters={Immutable.List.of(filter)}
              displayCounts={
                !haveActiveFilters || this.state.forceDisplayCounts
              }
              flux={this.flux}
            />
          ));
          filterGroups.push(
            <FilterWrapper
              open={this.isFilterOpen(label)}
              toggle={_.partial(this.toggleOpenState, label)}
              selected={selected}
              key={label}
              label={label}
            >
              {inGroupFilters}
            </FilterWrapper>,
          );
        } else {
          filterGroups.push(
            <FilterWrapper
              open={this.isFilterOpen(label)}
              toggle={_.partial(this.toggleOpenState, label)}
              selected={selected}
              key={label}
              label={label}
            >
              <FilterGroup
                onChange={this.handleChange}
                filters={inGroup}
                displayCounts={
                  !haveActiveFilters || this.state.forceDisplayCounts
                }
                flux={this.flux}
              />
            </FilterWrapper>,
          );
        }
      }
    }

    const renderPriceFilter = (filter) => {
      const name = filter.get('name');
      const label = filter.get('label');
      let selectedPrice = '';
      const minPrice = filter ? filter.get('data').get('0') : null;
      const maxPrice = filter ? filter.get('data').get('1') : null;
      if (minPrice && maxPrice) {
        selectedPrice = `£${minPrice} to £${maxPrice}`;
      } else if (minPrice) {
        selectedPrice = `Over £${minPrice}`;
      } else if (maxPrice) {
        selectedPrice = `Under £${maxPrice}`;
      }
      return (
        <FilterWrapper
          key={name}
          label={label}
          selected={selectedPrice}
          open={this.isFilterOpen(name)}
          toggle={_.partial(this.toggleOpenState, name)}
        >
          <PriceFilter
            setPrice={this.setPrice}
            min={this.state.minPrice}
            max={this.state.maxPrice}
            filter={filter}
            online={this.state.online}
            flux={this.flux}
          />
        </FilterWrapper>
      );
    };

    const renderStandardFilter = (filter) => {
      let selected = '';
      const data = filter.get('data');
      const name = filter.get('name');
      const label = filter.get('label');
      if (data) {
        if (name === 'average_rating') {
          selected = `${data} and up`;
        } else {
          const selectedOptions = filter
            .get('choices')
            .filter((choice) => {
              return Immutable.isList(data)
                ? data.includes(choice.get(0))
                : data === choice.get(0);
            })
            .map((choice) => choice.get(1).replace(/\s*\(\d+\)$/, ''))
            .toArray();
          if (selectedOptions.length && selectedOptions.length < 4) {
            selected = selectedOptions.join(', ');
          } else if (selectedOptions.length && selectedOptions.length >= 4) {
            selected =
              selectedOptions.slice(0, 3).join(', ') +
              ', and ' +
              (selectedOptions.length - 3) +
              ' more';
          }
        }
      }
      return (
        <FilterWrapper
          key={name}
          label={label}
          selected={selected}
          open={this.isFilterOpen(name)}
          toggle={_.partial(this.toggleOpenState, name)}
        >
          <FilterGroup
            label={label}
            onChange={this.handleChange}
            filters={Immutable.List.of(filter)}
            displayCounts={!haveActiveFilters || this.state.forceDisplayCounts}
            flux={this.flux}
          />
        </FilterWrapper>
      );
    };

    const ungrouped = this.state.filters
      .filter((filter) => !filter.get('isHidden') && !isGrouped(filter))
      .map((filter) => {
        if (filter.get('name') === 'price') {
          return renderPriceFilter(filter);
        } else {
          return renderStandardFilter(filter);
        }
      });

    filterGroups = filterGroups.concat(ungrouped);
    return <div ref={this.ref}>{filterGroups}</div>;
  }

  componentDidUpdate() {
    if (this.ref.current && window.CustomEvent) {
      const event = new CustomEvent('fp-productlisting-filter-did-update', {
        bubbles: true,
      });
      this.ref.current.dispatchEvent(event);
    }
  }
}
FilterWidget.watchedStores = [
  'MediaStore',
  'ProductListingStore',
  'NetworkStore',
];
