import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

export const FilterType = ImmutablePropTypes.contains({
  label: PropTypes.string,
  name: PropTypes.string,
  choices: ImmutablePropTypes.list,
  data: PropTypes.any,
  isHidden: PropTypes.bool,
  isMultipleChoice: PropTypes.bool,
  isRange: PropTypes.bool,
});

export const ProductVariantType = ImmutablePropTypes.contains({
  name: PropTypes.string,
  inStock: PropTypes.bool,
});

export const ProductVariantsType = ImmutablePropTypes.listOf(
  ProductVariantType
);

export const ProductCategoryType = ImmutablePropTypes.contains({
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  siteUrl: PropTypes.string,
  flourishMount: PropTypes.bool,
  parentCategory: ImmutablePropTypes.map,
});

export const ProductImageType = ImmutablePropTypes.contains({
  aspectRatio: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  image: PropTypes.string,
});

export const ProductType = ImmutablePropTypes.contains({
  exclusive: PropTypes.bool,
  signed: PropTypes.bool,
  adult: PropTypes.bool,
  limitedEdition: PropTypes.bool,
  beforeRelease: PropTypes.bool,
  inStock: PropTypes.bool,
  hasVariants: PropTypes.bool,
  rrp: PropTypes.number,
  sitePrice: PropTypes.number,
  salePrice: PropTypes.number,
  leadTime: PropTypes.number,
  reviewCount: PropTypes.number,
  reviewScore: PropTypes.number,
  releaseDate: PropTypes.date,
  absoluteUrl: PropTypes.string,
  title: PropTypes.string,
  customOrderLabel: PropTypes.string,
  type: ImmutablePropTypes.contains({
    singularName: PropTypes.string,
  }),
  tags: ImmutablePropTypes.listOf(
    ImmutablePropTypes.contains({
      slug: PropTypes.string,
      propertySlug: PropTypes.string,
      nameSlug: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  images: ImmutablePropTypes.listOf(ProductImageType),
  multimedia: ImmutablePropTypes.listOf(
    ImmutablePropTypes.contains({
      thumbnail: PropTypes.string,
      rendered: PropTypes.string,
    })
  ),
  productCategories: ImmutablePropTypes.listOf(ProductCategoryType),
  variants: ImmutablePropTypes.listOf(ProductVariantType),
});

export const DisplayReviewType = ImmutablePropTypes.contains({
  ageGroup: PropTypes.string,
  author: PropTypes.string,
  content: PropTypes.string,
  downVotes: PropTypes.number,
  id: PropTypes.number,
  rating: PropTypes.number,
  title: PropTypes.string,
  upVotes: PropTypes.number,
  verifiedPurchase: PropTypes.bool,
  votes: PropTypes.number,
  voteStatus: PropTypes.oneOf(['up', 'down', null]),
});

export const DisplayReviewRatingType = ImmutablePropTypes.contains({
  count: PropTypes.number,
  percentage: PropTypes.number,
  rating: PropTypes.number,
});

export const FauxProductType = ImmutablePropTypes.contains({
  title: PropTypes.string,
  slug: PropTypes.string,
  shortDescription: PropTypes.string,
  description: PropTypes.string,
  descriptionHtml: PropTypes.node,
  callToActionText: PropTypes.string,
  image: PropTypes.string,
  linkTo: PropTypes.string,
});
