import {Model, types} from '../models/base.js';
import {ModelArray} from '../models/arrays.js';
import {restMixin, restArrayMixin} from '../models/rest.js';

export class WishListItem extends restMixin(
  Model.withOptions({
    url: (instance) => {
      const wishlistId = instance.wishlistId;
      if (wishlistId) {
        const root = `/api/wishlists/${wishlistId}/items/`;
        if (instance.id) {
          return `${root}${instance.id}/`;
        }
        return root;
      }
      return null;
    },
    spec: {
      id: types.int,
      product: types.int,
      dateAdded: types.date,
      wishlistId: types.int,
      catNumber: types.string,
    },
  })
) {}

export class WishListItemArray extends restArrayMixin(
  ModelArray.ofModel(WishListItem, {
    url: (instance) => {
      if (
        !instance._options.parent ||
        !Number.isFinite(instance._options.parent.id)
      ) {
        return null;
      }
      return `/api/wishlists/${instance._options.parent.id}/items/`;
    },
  })
) {}

export class WishList extends restMixin(
  Model.withOptions({
    url: (instance) => {
      const root = '/api/wishlists/';
      if (Number.isFinite(instance.id)) {
        return `${root}${instance.id}/`;
      }
      return root;
    },
    spec: {
      id: types.int,
      name: types.string,
      default: types.bool,
      public: types.bool,
      publicUrl: types.string,
      secret: types.bool,
      dateAdded: types.date,
      eventDate: types.date,
      christmasSpecial: types.bool,
      items: types.modelArray(WishListItemArray).withDefault([]),
    },
  })
) {}

export class WishListArray extends restArrayMixin(
  ModelArray.ofModel(WishList, {url: '/api/wishlists/'})
) {}
