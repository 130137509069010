import _ from 'lodash';
import Immutable from 'immutable';
import * as React from 'react';

export default class SimpleImmutableComponent extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return !(
      Immutable.fromJS(_.omit(this.props || {}, ['children'])).equals(
        Immutable.fromJS(_.omit(nextProps || {}, ['children']))
      ) &&
      Immutable.fromJS(this.state || {}).equals(
        Immutable.fromJS(nextState || {})
      )
    );
  }
}
