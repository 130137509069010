import {AuthStore} from './auth/stores.js';
import {CartStore} from './cart/stores.js';
import {FeedbackSurveyMessagesStore} from './feedback/stores.js';
import {LedgerStore, OrderStore} from './account/stores.js';
import {
  ProductStore,
  ProductListingStore,
  RecommendedProductStore,
} from './catalog/stores.js';
import {WatchStore} from './watch/stores.js';
import {WishListStore} from './wishlist/stores.js';

export const stores = {
  AuthStore: new AuthStore(),
  CartStore: new CartStore(),
  FeedbackSurveyMessagesStore: new FeedbackSurveyMessagesStore(),
  LedgerStore: new LedgerStore(),
  OrderStore: new OrderStore(),
  ProductListingStore: new ProductListingStore(),
  ProductStore: new ProductStore(),
  RecommendedProductStore: new RecommendedProductStore(),
  WatchStore: new WatchStore(),
  WishListStore: new WishListStore(),
};
