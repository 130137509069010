import _ from 'lodash';
import classNames from 'classnames';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import * as React from 'react';

import {FormErrorMessage} from './form.jsx';

export class Fieldset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: ''
    };
    ['validate'].forEach(method => {
      this[method] = this[method].bind(this);
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(
      _.isEqual(this.props.children, nextProps.children) &&
      _.isEqual(this.state, nextState)
    );
  }

  componentDidMount() {
    const elem = this.node;
    if (elem) {
      const form = elem.closest('form');
      elem.addEventListener('-pr-validate', this.validate);
      if (form) {
        form.addEventListener('submit', this.validate);
        form.addEventListener('-pr-validate', this.validate);
      }
    }
  }

  componentWillUnmount() {
    const elem = this.node;
    if (elem) {
      const form = elem.closest('form');
      elem.removeEventListener('-pr-validate', this.validate);
      if (form) {
        form.removeEventListener('submit', this.validate);
        form.removeEventListener('-pr-validate', this.validate);
      }
    }
  }

  validate(ev) {
    const validated = this.props.validator.call(this);
    if (!validated) {
      this.setState({error: this.props.validationMessage});
    } else {
      this.setState({error: ''});
    }

    const fieldset = this.node;
    let cancelled = false;
    if (!ev || ev.target !== fieldset) {
      const validateEvent = new CustomEvent('-pr-validate', {
        bubbles: false,
        cancelable: true
      });
      cancelled = !fieldset.dispatchEvent(validateEvent);
    }
    if (ev && (!validated || cancelled)) {
      ev.preventDefault();
    }
  }

  render() {
    const attrs = _.extend(this.props.attrs.toObject(), {
      className: classNames(this.props.attrs.className, 'form__fieldset')
    });
    let legend;
    if (this.props.legend) {
      legend = <legend>{this.props.legend}</legend>;
    } else {
      legend = null;
    }
    return (
      <fieldset ref={node => (this.node = node)} {...attrs}>
        {legend}
        {this.state.error ? (
          <FormErrorMessage
            className="form__fieldset__error"
            error={this.props.validationMessage}
          />
        ) : (
          ''
        )}
        {this.props.children}
      </fieldset>
    );
  }
}
Fieldset.propTypes = {
  attrs: ImmutablePropTypes.map,
  children: PropTypes.node,
  legend: PropTypes.string,
  validationMessage: PropTypes.string,
  validator: PropTypes.func
};
Fieldset.defaultProps = {
  attrs: Immutable.Map(),
  validator: function() {
    return true;
  },
  validationMessage: 'There were problems with your submission.'
};
