import {CompatStore} from './compat/stores.js';
import {DataStore} from './data/stores.js';
import {MediaStore} from './media/stores.js';
import {NetworkStore} from './network/stores.js';
import {SettingsStore, UserSettingsStore} from './settings/stores.js';
import {ToastStore} from './toast/stores.js';
import {TooltipStore} from './tooltip/stores.js';

export const stores = {
  CompatStore: new CompatStore(),
  DataStore: new DataStore({namespace: window.namespace}),
  MediaStore: new MediaStore({namespace: window.namespace}),
  NetworkStore: new NetworkStore(),
  SettingsStore: new SettingsStore({namespace: window.namespace}),
  ToastStore: new ToastStore(),
  TooltipStore: new TooltipStore(),
  UserSettingsStore: new UserSettingsStore()
};
