import Fluxxor from 'fluxxor';
import * as React from 'react';

export const FluxContext = React.createContext(new Fluxxor.Flux());

export function withFlux(Component, flux) {
  return function FluxComponent(props) {
    const consumer = (
      <FluxContext.Consumer>
        {flux => <Component {...props} flux={flux} />}
      </FluxContext.Consumer>
    );
    if (flux !== undefined) {
      return (
        <FluxContext.Provider value={flux}>{consumer}</FluxContext.Provider>
      );
    }
    return consumer;
  };
}
