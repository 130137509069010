import _ from "lodash";
import PropTypes from "prop-types";
import * as React from "react";
import classNames from "classnames";

import SimpleImmutableFluxComponent from "prometheus/flux/components.jsx";
import SimpleImmutableComponent from "prometheus/components/immutable.jsx";

import { ProductCategoryType } from "../../types.js";

class CategoryLink extends SimpleImmutableComponent {
  render() {
    let onClick;
    let text;
    if (this.props.category) {
      onClick = _.partial(this.props.onClick, this.props.category);
      text = this.props.category.get("name");
    } else {
      onClick = _.partial(this.props.onClick, null);
      text = "All Categories";
    }
    const className = classNames(
      this.props.className,
      "product-list__nav__categories__category",
      "vert-list__item",
      {
        "product-list__nav__categories__category--all": !this.props.category,
        "product-list__nav__categories__category--ancestor": this.props.parent,
      },
    );
    return (
      <li className={className}>
        <button onClick={onClick} className="button--link">
          {this.props.parent ? "<" : ""} {text}
        </button>
      </li>
    );
  }
}
CategoryLink.propTypes = {
  category: ProductCategoryType,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  parent: PropTypes.bool,
  query: PropTypes.string,
};
CategoryLink.defaultProps = {
  parent: false,
  query: "",
};

export class CategoryNavigation extends SimpleImmutableFluxComponent {
  constructor(props) {
    super(props);
    this.state = _.extend(this.state || {}, {
      open: true,
    });
    ["toggle", "resetOpenState", "setCategory"].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  componentDidMount() {
    super.componentDidMount();
    this.flux
      .store("MediaStore")
      .addListener("change:breakpoint", this.resetOpenState);
    this.resetOpenState();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.flux
      .store("MediaStore")
      .removeListener("change:breakpoint", this.resetOpenState);
  }

  getStateFromFlux() {
    return _.extend(
      { activeMedia: this.flux.store("MediaStore").getState().active },
      _.pick(this.flux.store("ProductListingStore").getState(), [
        "categories",
        "category",
        "isCategoryRelaxed",
      ]),
    );
  }

  resetOpenState() {
    this.setState({
      open: this.state.activeMedia && this.state.activeMedia.get("lap"),
    });
  }

  toggle(ev) {
    if (ev) {
      ev.preventDefault();
    }
    this.setState({ open: !this.state.open });
  }

  setCategory(category, ev) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    this.flux.actions.catalog.productListing.setCategory(category || null);
  }

  render() {
    const query = window.location.search.replace(
      /(page=\d+|utm_[a-z]*=[^&=]+)&?/,
      "",
    );
    const slugs = this.state.categories.map((category) => category.get("slug"));
    const leaves = slugs.filter(
      (slug) =>
        slugs.filter((other) => other !== slug && other.indexOf(slug) === 0)
          .length === 0,
    );
    if (leaves.length <= 1 && !this.state.category) {
      return null;
    }

    let categories;
    if (this.state.category && !this.state.isCategoryRelaxed) {
      categories = [];
      let category = this.state.category;
      for (;;) {
        category = category.get("parentCategory");
        if (!category) {
          break;
        }
        categories.push(
          <CategoryLink
            key={category.get("slug")}
            parent={true}
            onClick={this.setCategory}
            category={category}
            query={query}
          />,
        );
      }
      categories.push(
        <CategoryLink
          key="__all__"
          parent={true}
          onClick={this.setCategory}
          query={query}
        />,
      );
      categories.reverse();
      const children = this.state.categories
        .filter(
          (child) =>
            child.get("parentCategory") &&
            child.get("parentCategory").get("slug") ===
              this.state.category.get("slug"),
        )
        .map((child) => (
          <CategoryLink
            key={child.get("slug")}
            className="phl"
            onClick={this.setCategory}
            category={child}
            query={query}
          />
        ));
      const currentClassName = classNames(
        "product-list__nav__categories__category",
        "product-list__nav__categories__category--current",
        "vert-list__item owlq",
      );
      categories.push(
        <li key="__current__" className={currentClassName}>
          <span className="bold phl">{this.state.category.get("name")}</span>
          <ul className="vert-list owlq">{children}</ul>
        </li>,
      );
    } else {
      categories = this.state.categories
        .filter((category) => category.get("parentCategory") === null)
        .map((category) => (
          <CategoryLink
            key={category.get("slug")}
            category={category}
            onClick={this.setCategory}
            query={query}
          />
        ));
    }

    return (
      <details
        ref={(el) => (this.container = el)}
        className="owl-off details_group"
        open={this.state.open}
      >
        <summary onClick={this.toggle}>
          <span>Categories</span>{" "}
          {this.state.category && !this.state.isCategoryRelaxed ? (
            <span className="mql mzt  t-thin t-small clr-neutral">
              {this.state.category.get("name")}
            </span>
          ) : null}
        </summary>
        <div className="phl phr phb product-list-filter__categories">
          <ul className="vert-list owlq pqt">{categories}</ul>
        </div>
      </details>
    );
  }
}
CategoryNavigation.watchedStores = ["MediaStore", "ProductListingStore"];
