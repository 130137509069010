import _ from "lodash";
import Fluxxor from "fluxxor";
import Immutable from "immutable";

import { WishListArray } from "./models.js";

export const WishListStore = Fluxxor.createStore({
  actions: {
    INITIALIZED: "initializeHandler",
    "WISHLIST:ADD_ITEM": "addItem",
    "WISHLIST:CREATE_NEW": "createWishList",
    "WISHLIST:REFRESH": "refresh",
  },

  initialize(options) {
    this.options = options;
    this.initialized = false;
    this.loading = false;
  },

  initializeHandler() {
    this.wishlists = new WishListArray();
    //
    // Set initial data
    const user = this.flux.store("AuthStore").getState().user;
    if (user.get("isAuthenticated")) {
      this.loading = true;
      this.emit("change");
      this.wishlists
        .read()
        .then(() => {
          this.loading = false;
          this.emit("change");
        })
        .catch(() => {
          this.loading = false;
          this.emit("change");
          this.emit("error");
        });
    }

    // Handle login success
    this.flux.store("AuthStore").addListener("login:success", () => {
      this.loading = true;
      this.emit("change");
      this.wishlists
        .read()
        .then(() => {
          this.loading = false;
          this.emit("change");
        })
        .catch(() => {
          this.loading = false;
          this.emit("change");
          this.emit("error");
        });
    });
    // Handle Logout success
    this.flux.store("AuthStore").addListener("logout:success", () => {
      this.wishlists.reset();
      this.emit("change");
    });

    this.initialized = true;
    this.emit("initialized");
  },

  getState: function () {
    return {
      wishlists: Immutable.fromJS(this.wishlists.toObject()),
      loading: this.loading,
    };
  },

  createWishList: function (payload) {
    this.loading = true;
    this.emit("change");
    const item = payload.item;
    this.wishlists
      .create(_.omit(payload, ["item"]))
      .then((responseData) => {
        const data = responseData[1];
        if (item) {
          const newList = this.wishlists.find(_.matches({ id: data.id }));
          if (newList) {
            item.wishlistId = newList.id;
            newList.items
              .create(item)
              .then(() => {
                this.emit("change");
              })
              .catch(() => {
                this.emit("error");
              });
          }
        }
        this.loading = false;
        this.emit("change");
      })
      .catch(() => {
        this.loading = false;
        this.emit("change");
        this.emit("error");
      });
  },

  addItem: function (payload) {
    if (Number.isFinite(payload.wishlist)) {
      payload.wishlist = { id: payload.wishlist };
    }
    const wishlist =
      (payload.wishlist && this.wishlists.find(_.matches(payload.wishlist))) ||
      this.wishlists.find(_.matches({ default: true })) ||
      this.wishlists[0];
    let prom;
    if (!wishlist) {
      this.loading = true;
      this.emit("change");
      prom = this.wishlists.create({ name: "Wish List", default: true });
    } else {
      prom = Promise.resolve([null, wishlist]);
    }
    prom
      .then((responseData) => {
        const list = wishlist || responseData[1];
        const existing = list.items.find(_.matches(payload.item));
        if (existing) {
          this.emit("exists", { item: payload.item, wishlist: wishlist });
        } else {
          const item = payload.item;
          item.wishlistId = list.id;
          list.items
            .create(item)
            .then((responseData) => {
              this.emit("added", { item: responseData[1], wishlist: list });
              this.emit("change");
            })
            .catch(() => {
              this.emit("error");
            });
        }
        this.loading = false;
        this.emit("change");
      })
      .catch(() => {
        this.loading = false;
        this.emit("change");
        this.emit("error");
      });
  },
});
