import _ from 'lodash';
import * as React from 'react';
import ReactDOM from 'react-dom';
import {LastSearchResultsLink} from '../components/recently-viewed.jsx';

export function lastSearchResultsLink(elem, options) {
  if (!options || !(options.flux || options.url)) {
    return;
  }
  if (options.flux && !options.url) {
    const root = options.flux.store('SettingsStore').getState().rootUrl;
    options.url =
      document.referrer && _.startsWith(document.referrer, root)
        ? document.referrer
        : options.flux.store('ProductListingStore').getState()
            .lastSearchResults;
  }
  if (options.url) {
    ReactDOM.render(<LastSearchResultsLink url={options.url} />, elem);
  } else {
    return;
  }
}
