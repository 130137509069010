import _ from 'lodash';

import FluxComponent from 'prometheus/flux/components.jsx';

export class FluxMenuComponent extends FluxComponent {
  constructor(props) {
    super(props);
    this.state = _.extend(this.state || {}, {loaded: false});
    ['handleBodyClick', 'handleKeyUp', 'updatePosition'].forEach(method => {
      this[method] = this[method].bind(this);
    });
  }

  componentDidMount() {
    super.componentDidMount();
    document.body.addEventListener('click', this.handleBodyClick);
    document.addEventListener('keyup', this.handleKeyUp);
    this.flux.store('MediaStore').addListener('change', this.updatePosition);
    window.addEventListener('resize', this.updatePosition);
    this.updatePosition();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    document.body.removeEventListener('click', this.handleBodyClick);
    document.removeEventListener('keyup', this.handleKeyUp);
    this.flux.store('MediaStore').removeListener('change', this.updatePosition);
    window.removeEventListener('resize', this.updatePosition);
  }

  componentDidUpdate() {
    this.updatePosition();
  }

  updatePosition() {
    if (!this.state.open) {
      return;
    }
    const parent = this.parent;
    const child = this.child;
    const parentBox = parent.getBoundingClientRect();
    const childBox = child.getBoundingClientRect();

    const maxLeft =
      document.body.clientWidth - childBox.width - parentBox.x - 5;
    const minLeft = -parentBox.x + 5;

    child.style.left =
      _.clamp((parentBox.width - childBox.width) / 2, minLeft, maxLeft) + 'px';
  }

  handleKeyUp(ev) {
    if (
      (ev.key === 'Escape' || ev.code === 'Escape' || ev.keyCode === 0x1b) &&
      this.state.open
    ) {
      this.setState({open: false});
    }
  }

  handleBodyClick(ev) {
    const elem = this.parent;
    if (elem && !elem.contains(ev.target) && this.state.open) {
      this.setState({open: false});
    }
  }

  toggle() {
    this.setState({open: !this.state.open});
  }
}
FluxMenuComponent.propTypes = _.clone(FluxComponent.propTypes);
FluxMenuComponent.contextTypes = _.clone(FluxComponent.contextTypes);
FluxMenuComponent.childContextTypes = _.clone(FluxComponent.childContextTypes);
FluxMenuComponent.watchedStores = [];
