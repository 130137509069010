import _ from 'lodash';
import Fluxxor from 'fluxxor';
import Immutable from 'immutable';

import {User} from './models.js';

export const AuthStore = Fluxxor.createStore({
  actions: {
    INITIALIZED: 'initializeHandler',
    'AUTH:LOGIN_EMAIL': 'handleLoginEmail',
    'AUTH:UNDO_LOGIN_EMAIL': 'handleUndoLoginEmail',
    'AUTH:LOGIN': 'handleLogin',
    'AUTH:GUEST_LOGIN': 'handleGuestLogin',
    'AUTH:LOGOUT': 'handleLogout',
    'AUTH:REGISTER': 'handleRegistration',
    'AUTH:GUEST_UPGRADE_REQUEST': 'handleGuestUpgradeRequest',
    'AUTH:GUEST_UPGRADE': 'handleGuestUpgrade',
    'AUTH:CHANGE_PASSWORD': 'handleChangePassword',
    'NOTIFICATION:DELETE': 'handleDeleteNotification',
  },

  initialize: function (options) {
    this.options = options;
    this.initialized = false;
    this.user = null;
    this.loading = false;
    this.loginBlocked = false;
    this._revision = new Date().getTime();
    this._name = '__auth_store__';
  },

  /*
   * State serialization
   */

  getState: function () {
    return {
      loading: this.loading,
      user: this.user ? Immutable.fromJS(this.user.toJSON()) : null,
      loginBlocked: this.loginBlocked,
    };
  },

  /*
   * Initialization callbacks
   */

  initializeHandler: function () {
    const userData = this.flux.store('DataStore').getState().user;
    if (userData) {
      this.user = User.parse(userData);
    } else {
      this.user = new User().read();
    }

    // Set up cross-window updating
    try {
      window.localStorage.setItem(
        this._name,
        JSON.stringify(this.user.toJSON())
      );
      window.addEventListener('storage', this.handleStorageEvent.bind(this));
    } catch (e) {
      this.flux.actions.console.error(e);
    }

    this.addListener('login:success', () =>
      window.Hooks.showToast({
        msg: 'Logged in',
        level: 'success',
        timeout: 5,
      })
    );
    this.addListener('logout:success', () =>
      window.Hooks.showToast({
        msg: 'Logged out',
        level: 'success',
        timeout: 5,
      })
    );

    this.addListener('login:error', (err) => {
      window.Hooks.showToast({
        msg: 'There was an error logging you in',
        level: 'error',
        timeout: 10,
      });
      err.response.json().then((data) => {
        if (data.detail === 'Too many failed login attempts') {
          this.loginBlocked = true;
          this.emitChange();
        }
      });
    });
    this.addListener('logout:error', () =>
      window.Hooks.showToast({
        msg: 'There was an error logging you out',
        level: 'error',
        timeout: 10,
      })
    );

    this.initialized = true;
    this.emit('change');
    this.emit('initialized');
  },

  handleChange: function () {
    this.emit('change');
    this.emit('change:user', ...arguments);
    try {
      window.localStorage.setItem(
        this._name,
        JSON.stringify(this.user.toJSON())
      );
    } catch (e) {
      this.flux.actions.console.error(e);
    }
  },

  fetchUser: function () {
    this.setLoading();
    return this.user
      .read({fetchOptions: {cache: 'reload'}})
      .then(() => {
        this.handleChange();
        this.resetLoading();
      })
      .catch(() => {
        this.handleChange();
        this.resetLoading();
      });
  },

  /*
   * Cross-window messaging handlers
   */

  handleStorageEvent: function (ev) {
    if (ev.key !== this._name) {
      return;
    }
    try {
      this.user = User.parse(JSON.parse(ev.newValue));
    } catch (e) {
      this.fetchUser();
      this.flux.actions.console.error(e);
    }
    this.emit('change');
    this.emit('change:user', this.user, ev);
  },

  setLoading: function () {
    this.loading = true;
    this.emit('change');
  },

  resetLoading: function () {
    this.loading = false;
    this.emit('change');
  },

  /*
   * Login handlers
   */

  resetWindowLocation: function (action) {
    const urlPathName = window.location.pathname;
    const urlRegex = RegExp(
      '^/(account|affiliates|cart|checkout|evouchers/' +
        'purchase|ledger|orders|subscriptions/series|' +
        'subscriptions/account|wallet|watch|wishlist)/'
    );
    if (urlRegex.test(urlPathName)) {
      // We only move the user back to the homepage if it is a restricted page
      // like in the account.
      const urlRestrictedRegex = RegExp(
        '^/(account|affiliates|ledger|orders|subscriptions/' +
          'account|wallet|watch|wishlist)/'
      );
      if (action === 'logout' && urlRestrictedRegex.test(urlPathName)) {
        window.location.replace('/');
      } else {
        window.location.reload(true);
      }
    }
  },

  emitAccountActionEvent: function (action, isSuccess, ...args) {
    const status = isSuccess ? 'success' : 'error';
    this.emit('account:action:' + action + ':' + status, ...args);
    this.emit('account:action:' + status, action, ...args);
  },

  handleLoginEmail: function (options) {
    this.setLoading();
    this.user
      .loginEmail(options.email)
      .then((data) => {
        let isSuccessful = false;
        if (
          'status' in data &&
          _.includes(['new', 'standard', 'guest'], data.status)
        ) {
          options.successCallback(data.status);
          isSuccessful = true;
        }
        this.emitAccountActionEvent('emailLogin', isSuccessful, data);
        this.resetLoading();
      })
      .catch((err) => {
        this.emitAccountActionEvent('emailLogin', false, err);
        this.resetLoading();
      });
  },

  handleLogin: function (credentials) {
    this.setLoading();
    this.user
      .login(credentials)
      .then((data) => {
        this.resetWindowLocation('login');
        this.emit('login:success', data);
        this.fetchUser();
      })
      .catch((err) => {
        this.emit('login:error', err);
        this.fetchUser();
      });
  },

  handleGuestLogin: function (credentials) {
    this.setLoading();
    this.user
      .login(credentials, true)
      .then((data) => {
        this.resetWindowLocation('login');
        this.emitAccountActionEvent('guestLogin', true, data);
        this.fetchUser();
      })
      .catch((err) => {
        this.emitAccountActionEvent('guestLogin', false, err);
        this.fetchUser();
      });
  },

  handleLogout: function () {
    this.setLoading();
    this.user
      .logout()
      .then((data) => {
        this.resetWindowLocation('logout');
        this.emit('logout:success', data);
        this.resetLoading();
        this.fetchUser();
      })
      .catch((err) => {
        this.emit('logout:error', err);
        this.resetLoading();
        this.fetchUser();
      });
  },

  handleRegistration: function (options) {
    this.setLoading();
    this.user
      .register(options)
      .then((data) => {
        this.emitAccountActionEvent('register', true, data);
        this.resetWindowLocation('login');
        this.emit('login:success');
        this.fetchUser();
      })
      .catch((err) => {
        this.emitAccountActionEvent('register', false, err);
        this.emit('login:error');
        this.fetchUser();
      });
  },

  handleGuestUpgradeRequest: function (options) {
    this.setLoading();
    this.user
      .guestUpgradeRequest(options)
      .then((data) => {
        this.emitAccountActionEvent('guestUpgradeRequest', true, data);
        this.resetLoading();
      })
      .catch((err) => {
        this.emitAccountActionEvent('guestUpgradeRequest', false, err);
        this.resetLoading();
      });
  },

  handleGuestUpgrade: function (options) {
    this.setLoading();
    this.user
      .guestUpgrade(options)
      .then((data) => {
        this.emitAccountActionEvent('guestUpgrade', true, data);
        this.resetWindowLocation('login');
        this.emit('login:success');
        this.fetchUser();
      })
      .catch((err) => {
        this.emitAccountActionEvent('guestUpgrade', false, err);
        this.fetchUser();
      });
  },

  handleChangePassword: function (credentials) {
    this.setLoading();
    this.user
      .changePassword(credentials)
      .then((data) => {
        this.emitAccountActionEvent('changePassword', true, data);
        this.resetLoading();
      })
      .catch((err) => {
        this.emitAccountActionEvent('changePassword', false, err);
        this.resetLoading();
      });
  },

  handleDeleteNotification: function (payload) {
    const notificationIndex = _.findIndex(this.user.notifications, payload);
    if (notificationIndex >= 0) {
      const notification = this.user.notifications.splice(
        notificationIndex,
        1
      )[0];
      notification
        .delete()
        .then(() => this.handleChange())
        .catch(() => this.handleChange());
    }
  },
});
