import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

export const OrderItemType = ImmutablePropTypes.contains({
  id: PropTypes.number,
  dateCreated: PropTypes.date,
  itemType: PropTypes.string,
  itemId: PropTypes.number,
  itemTitle: PropTypes.string,
  catNumber: PropTypes.string,
  value: PropTypes.number,
  progress: PropTypes.string,
  state: PropTypes.string,
  amountDiscounted: PropTypes.number,
  lastModified: PropTypes.date,
  taxableAtCheckout: PropTypes.bool,
  leadTimeAtCheckout: PropTypes.number,
  affiliate: PropTypes.string,
  coupon: PropTypes.number,
  extra: PropTypes.oneOfType([
    // product item
    ImmutablePropTypes.contains({
      image: PropTypes.string,
      url: PropTypes.string,
      releaseDate: PropTypes.string,
      leadTime: PropTypes.number,
    }),
    // evoucher item
    ImmutablePropTypes.contains({
      dateExpires: PropTypes.date,
      deliverOn: PropTypes.number,
      delivered: PropTypes.bool,
      isExpired: PropTypes.bool,
      isRedeemed: PropTypes.bool,
      isVoid: PropTypes.bool,
      maskedCode: PropTypes.string,
      redeemedOn: PropTypes.number,
      toEmail: PropTypes.string,
      toName: PropTypes.string,
      displayStatus: PropTypes.string,
      recoverUrl: PropTypes.string,
    }),
  ]),
});

const AddressType = ImmutablePropTypes.contains({
  id: PropTypes.number,
  occupierName: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postcode: PropTypes.string,
  country: PropTypes.string,
  contactNumber: PropTypes.string,
  masked: PropTypes.bool,
});

const PaymentMethodType = ImmutablePropTypes.contains({
  id: PropTypes.string,
  display_text: PropTypes.string,
  display_html: PropTypes.string,
  display_icon: PropTypes.string,
  payment_type: PropTypes.string,
  created: PropTypes.date,
  modified: PropTypes.date,
});

const OrderPaymentMethodType = ImmutablePropTypes.contains({
  payment_method: PaymentMethodType,
  priority: PropTypes.number,
  maximum_charge: PropTypes.number,
  created: PropTypes.date,
  modified: PropTypes.date,
});

export const OrderType = ImmutablePropTypes.contains({
  id: PropTypes.number,
  billingEditable: PropTypes.bool,
  credit: PropTypes.number,
  dateConfirmed: PropTypes.date,
  dateCreated: PropTypes.date,
  dateModified: PropTypes.date,
  deliveryAddressEditable: PropTypes.bool,
  isOpen: PropTypes.bool,
  isEvoucherOrder: PropTypes.bool,
  isSubscriptionOrder: PropTypes.bool,
  status: PropTypes.string,
  total: PropTypes.number,
  deliveryAddress: AddressType,
  paymentMethods: ImmutablePropTypes.listOf(OrderPaymentMethodType),
  items: ImmutablePropTypes.listOf(OrderItemType),
});
