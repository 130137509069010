import _ from 'lodash';
import classNames from 'classnames';
import fuzzy from 'fuzzy';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import * as React from 'react';
import {
  CheckboxInput,
  RadioInput,
} from 'prometheus/forms/components/inputs/input-radio.jsx';

import {SimpleImmutableFluxComponent} from 'prometheus/flux/components.jsx';
import SimpleImmutableComponent from 'prometheus/components/immutable.jsx';
import {FilterDataType} from './types.js';

class FilterCountInput extends React.PureComponent {
  constructor(props) {
    super(props);
    ['handleChange'].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  handleChange(ev) {
    if (this.props.controlled) {
      ev.preventDefault();
    }
    ev.stopPropagation();
    this.props.handleDataChange(this.props.value, ev.target.checked, ev);
  }

  render() {
    const props = _.assign(
      _.pick(this.props, ['id', 'name', 'label', 'value']),
      {
        [this.props.controlled ? 'checked' : 'defaultChecked']: this.props
          .checked,
      }
    );
    let countMatch = null;
    if ((countMatch = props.label.match(/^(.+) \((\d+)\)$/))) {
      const [text, count] = countMatch.slice(1, 3);
      props.label = (
        <div className="form__field__label">
          {text}
          <span className="clr-neutral"> ({count})</span>
        </div>
      );
    }
    return (
      <li className="form__input-group__fields__field">
        {this.props.type === 'checkbox' ? (
          <CheckboxInput onChange={this.handleChange} {...props} />
        ) : (
          <RadioInput onChange={this.handleChange} {...props} />
        )}
      </li>
    );
  }
}

FilterCountInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
  idx: PropTypes.number.isRequired,
  handleDataChange: PropTypes.func.isRequired,
  controlled: PropTypes.bool.isRequired,
};

class FilterSelect extends SimpleImmutableComponent {
  constructor(props) {
    super(props);
    this.state = _.extend(
      this.state || {},
      FilterSelect.defaultState(this.props)
    );
    ['showMore', 'showLess', 'handleSearchChange', 'handleDataChange'].forEach(
      (method) => {
        this[method] = this[method].bind(this);
      }
    );
  }

  static defaultState(props) {
    const numberInputShown = _.isNil(props.initialInputNumber)
      ? null
      : Math.max(props.data.size, props.initialInputNumber);
    return {
      fuzzyFilterQuery: '',
      numberInputShown: numberInputShown,
      choicesSize: props.choices.size,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.choices.size != state.choicesSize) {
      return FilterSelect.defaultState(props);
    }
    return null;
  }

  showMore(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    if (this.state.numberInputShown) {
      this.setState({
        numberInputShown: null,
      });
    }
  }

  showLess(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    if (
      !_.isNil(this.props.initialInputNumber) &&
      _.isNil(this.state.numberInputShown)
    ) {
      this.setState({
        numberInputShown: this.props.initialInputNumber,
      });
    }
  }

  handleSearchChange(ev) {
    this.setState(
      _.assign(
        {
          fuzzyFilterQuery: ev.target.value,
        },
        !_.isNil(this.props.initialInputNumber) &&
          !ev.target.value &&
          !_.isNil(this.state.aToZSelection)
          ? {numberInputShown: this.props.initialInputNumber}
          : {}
      )
    );
  }

  handleDataChange(slug, selected, ev) {
    const data =
      this.props.inputType === 'checkbox'
        ? selected
          ? this.props.data.push(slug)
          : this.props.data.filter((x) => x != slug)
        : Immutable.List([slug]);
    if (this.props.dataChangeHandler) {
      this.props.dataChangeHandler(this.props.name, data, ev);
    }
  }

  render() {
    const filtered = this.props.choices
      .map((datum, i) => {
        return {
          name: this.props.name,
          type: this.props.inputType === 'radio' ? 'radio' : 'checkbox',
          id: 'id_' + this.props.name + '_' + i,
          value: datum.get(0),
          label: datum.get(1),
          idx: i,
          checked: this.props.data.includes(datum.get(0)),
        };
      })
      .filter((props) => {
        return (
          props.checked ||
          !this.state.fuzzyFilterQuery ||
          fuzzy.test(this.state.fuzzyFilterQuery, props.label)
        );
      });
    const children = filtered
      .filter((props, i) => {
        return (
          _.isNil(this.state.numberInputShown) ||
          i < this.state.numberInputShown
        );
      })
      .map((props) => {
        return (
          <FilterCountInput
            key={props.id}
            handleDataChange={this.handleDataChange}
            controlled={this.props.controlled}
            {...props}
          />
        );
      });

    const searchInput = (
      <label
        aria-live="polite"
        className={classNames(
          'form__input-group__fields__field form__input-group__fields__field--search form__field--text-input'
        )}
      >
        <div key="search_input" className="form__field__input">
          <input
            name=""
            type="search"
            onChange={this.handleSearchChange}
            value={this.state.fuzzyFilterQuery}
            placeholder="Search"
          />
        </div>
      </label>
    );
    const more =
      !_.isNil(this.state.numberInputShown) &&
      filtered.size > this.state.numberInputShown ? (
        <button
          className="button--link mhl"
          type="button"
          onClick={this.showMore}
        >
          Show More
        </button>
      ) : null;
    const less =
      _.isNil(this.state.numberInputShown) &&
      !_.isNil(this.props.initialInputNumber) &&
      filtered.size > this.props.initialInputNumber ? (
        <button
          className="button--link mhl"
          type="button"
          onClick={this.showLess}
        >
          Show Less
        </button>
      ) : null;

    const searchArea =
      !_.isNil(this.props.initialInputNumber) &&
      this.props.choices.size > this.props.initialInputNumber
        ? searchInput
        : null;

    const className = classNames('form__input-group__fields', {
      'oya mh--60': this.props.lapActive,
    });

    const mainArea = (
      <React.Fragment>
        <ul className={className}>{children}</ul>
        {more || less ? (
          <div key="more_section" className="mzt">
            {more ? more : less}
          </div>
        ) : null}
      </React.Fragment>
    );

    return (
      <div className="mqb">
        {searchArea}
        {mainArea}
      </div>
    );
  }
}

FilterSelect.propTypes = {
  choices: ImmutablePropTypes.iterableOf(
    ImmutablePropTypes.listOf(PropTypes.string)
  ),
  data: ImmutablePropTypes.listOf(PropTypes.string.isRequired),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  inputType: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  initialInputNumber: PropTypes.number,
  dataChangeHandler: PropTypes.func,
  controlled: PropTypes.bool,
  lapActive: PropTypes.bool,
};

FilterSelect.defaultProps = {
  initialInputNumber: 6,
  controlled: true,
  lapActive: false,
};

export class FilterWrapper extends SimpleImmutableFluxComponent {
  constructor(props) {
    super(props);
    this.state = _.extend(this.state || {}, {
      open: true,
    });
    ['toggleOpenState'].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  getStateFromFlux() {
    const activeMedia = this.flux.store('MediaStore').getState().active;
    const lapActive = activeMedia && activeMedia.get('lap');
    return lapActive !== this.state.lapActive ? {lapActive: lapActive} : {};
  }

  setStateFromFlux() {
    this.setState((prevState) => {
      const newState = this.getStateFromFlux();
      return _.assign(
        newState,
        !_.isNil(newState.lapActive) &&
          newState.lapActive !== prevState.lapActive
          ? {open: !!newState.lapActive}
          : {}
      );
    });
  }

  componentDidMount() {
    super.componentDidMount();
    this.setState({open: !!this.state.lapActive});
  }

  toggleOpenState(ev) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    this.setState({
      open: !this.state.open,
    });
  }

  render() {
    const childrenProps = _.extend(this.props.childrenProps.toObject(), {
      lapActive: this.state.lapActive,
    });
    return (
      <div className="form__input-group ">
        <details open={this.state.open}>
          <summary onClick={this.toggleOpenState}>
            <div className="form__input-group__label">{this.props.label}</div>{' '}
            {this.props.selected &&
            !this.state.open &&
            !this.state.lapActive ? (
              <span className="mql mht t-thin t-xsmall clr-neutral">
                {this.props.selected}
              </span>
            ) : null}
          </summary>
          {this.props.childrenFunc(childrenProps)}
        </details>
      </div>
    );
  }
}
FilterWrapper.propTypes = _.extend(
  _.clone(SimpleImmutableFluxComponent.propTypes),
  {
    childrenFunc: PropTypes.func.isRequired,
    childrenProps: PropTypes.any,
    label: PropTypes.string.isRequired,
    selected: PropTypes.string,
  }
);

FilterWrapper.watchedStores = ['MediaStore'];

export class FilterSelectWidget extends SimpleImmutableFluxComponent {
  constructor(props) {
    super(props);
    ['getChildren'].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }
  getChildren(childrenProps) {
    return <FilterSelect {...childrenProps} />;
  }
  render() {
    const filterData = this.props.filterData;
    const data = this.props.data || Immutable.List();
    const choices = this.props.filterData.get('choices');
    let selected = '';
    if (data) {
      const selectedOptions = choices
        .filter((choice) => data.includes(choice.get(0)))
        .map((choice) => choice.get(1).replace(/\s*\(\d+\)$/, ''))
        .toArray();
      if (selectedOptions.length && selectedOptions.length < 4) {
        selected = selectedOptions.join(', ');
      } else if (selectedOptions.length && selectedOptions.length >= 4) {
        selected =
          selectedOptions.slice(0, 3).join(', ') +
          ', and ' +
          (selectedOptions.length - 3) +
          ' more';
      }
    }
    const props = _.assign(
      {
        name: filterData.get('name'),
        label: filterData.get('label'),
        data: data,
        inputType: filterData.get('inputType'),
        default: filterData.get('default'),
        choices: choices,
        controlled: this.props.controlled,
        dataChangeHandler: this.props.dataChangeHandler,
      },
      _.omit(this.props, ['name', 'filterData', 'flux'])
    );
    const childrenProps = Immutable.Map(props);
    return (
      <FilterWrapper
        label={filterData.get('label')}
        selected={selected}
        childrenFunc={this.getChildren}
        childrenProps={childrenProps}
        flux={this.flux}
      />
    );
  }
}

FilterSelectWidget.propTypes = _.extend(
  _.clone(SimpleImmutableFluxComponent.propTypes),
  {
    data: ImmutablePropTypes.listOf(PropTypes.string.isRequired),
    filterData: FilterDataType.isRequired,
    initialInputNumber: PropTypes.number,
    dataChangeHandler: PropTypes.func,
    controlled: PropTypes.bool,
  }
);

FilterSelectWidget.defaultProps = {
  initialInputNumber: null,
  controlled: true,
};

FilterSelectWidget.watchedStores = [];
