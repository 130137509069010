import _ from 'lodash';

import {Model, types} from '../models/base.js';
import {ModelArray} from '../models/arrays.js';
import {restMixin, pagedRestArrayMixin} from '../models/rest.js';

/*
 * Product
 */
class ProductImage extends Model.withOptions({
  spec: {
    image: types.string,
    url: types.string,
    order: types.int,
    width: types.int,
    height: types.int,
    detailImage: types.bool,
    imageType: types.string,
    isPlaceholder: types.bool,
  },
  derived: {
    aspectRatio: (instance) => instance.width / instance.height,
  },
}) {}

export class ProductCategory extends Model {
  constructor(data, options) {
    options = Object.assign(
      {
        spec: {
          id: types.int,
          name: types.string,
          slug: types.string,
          parentCategory: types.model(ProductCategory),
          flourishBookmark: types.bool,
          flourishDisc: types.bool,
          flourishMount: types.bool,
          flourishStaples: types.bool,
          imageShadow01: types.bool,
          imageShadow02: types.bool,
          imageShadow03: types.bool,
          imageTrim: types.bool,
        },
        derived: {
          siteUrl: (instance) => `/catalog/${instance.slug}/`,
        },
      },
      options
    );
    super(_.omit(data || {}, 'parentCategory'), options);
    this._options.spec.parentCategory = types.model(this.constructor);
    this.set('parentCategory', data.parentCategory);
  }
}

export class ProductCategoryArray extends ModelArray.ofModel(ProductCategory) {}

class Offer extends Model.withSpec({
  id: types.int,
  name: types.string,
  slug: types.string,
  offerType: types.int,
  valueX: types.string,
  valueY: types.string,
  active: types.bool,
  startDate: types.date,
  endDate: types.date,
  shortName: types.string,
  fullDisplayName: types.string,
  fullDisplayNameHtml: types.string,
  available: types.bool,
}) {}

export class Product extends restMixin(
  Model.withOptions({
    url: (instance) => {
      if (Number.isFinite(instance.id)) {
        return `/api/products/${instance.id}/`;
      }
      return null;
    },
    spec: {
      id: types.int,
      URL: types.string,
      absoluteUrl: types.string,
      adult: types.bool,
      availability: types.string,
      catNumber: types.string,
      description: types.string,
      discontinued: types.bool,
      ean: types.string,
      exclusive: types.bool,
      firstonwebDate: types.date,
      hasVariants: types.bool,
      inStock: types.bool,
      stockCount: types.int,
      isbn: types.string,
      lastModified: types.date,
      leadTime: types.int,
      customOrderLabel: types.string,
      limitedEdition: types.bool,
      limitedTo: types.int,
      listed: types.bool,
      preorderDate: types.date,
      price: types.float,
      releaseDate: types.date,
      beforeRelease: types.bool,
      reviewCount: types.int,
      reviewScore: types.float,
      rrp: types.float,
      salePrice: types.float,
      salesQuantityConstraint: types.int,
      shortDescription: types.string,
      signed: types.bool,
      sitePrice: types.float,
      sitePriceCurrency: types.string,
      slug: types.string,
      supplierCode: types.string,
      title: types.string,
      type: types.object,
      primaryImage: types.model(ProductImage),
      tags: types.arrayOf(
        types.shape({
          URL: types.string,
          slug: types.string,
          name: types.string,
          propertyName: types.string,
          nameSlug: types.string,
          propertySlug: types.string,
        })
      ),
      properties: types.arrayOf(
        types.shape({
          name: types.string,
          value: types.string,
        })
      ),
      images: types.modelArray(ProductImage),
      multimedia: types.arrayOf(
        types.shape({
          url: types.string,
          thumbnail: types.string,
          order: types.int,
          rendered: types.string,
        })
      ),
      variants: types.arrayOf(
        types.shape({
          name: types.string,
          catNumber: types.string,
          inStock: types.bool,
          stockCount: types.int,
        })
      ),
      stockMessage: types.shape({
        message: types.string,
        level: types.string,
      }),
      productCategories: types.modelArray(ProductCategory),
      currentOffer: types.model(Offer),
    },
  })
) {}

export class ProductArray extends pagedRestArrayMixin(
  ModelArray.ofModel(Product)
) {}

/*
 * Related Product
 */

class RelatedProduct extends Model.withOptions({
  spec: {
    id: types.int,
    title: types.string,
    absoluteUrl: types.string,
    sitePrice: types.float,
    type: types.object,
    reviewCount: types.int,
    reviewScore: types.float,
    primaryImage: types.model(ProductImage),
    images: types.modelArray(ProductImage),
    productCategories: types.modelArray(ProductCategory),
  },
  derived: {
    reviewUrl: (instance) => `${instance.absoluteUrl}#product-reviews`,
  },
}) {}

export class RelatedProductArray extends pagedRestArrayMixin(
  ModelArray.ofModel(RelatedProduct)
) {}

/*
 * Reviews
 */

class DisplayReview extends Model.withSpec({
  id: types.int,
  author: types.string,
  rating: types.int,
  title: types.string,
  content: types.string,
  upVotes: types.int,
  downVotes: types.int,
  votes: types.int,
  voteStatus: types.string.oneOf(['up', 'down', null]),
  verifiedPurchase: types.bool,
  ageGroup: types.string,
}) {}

export class DisplayReviewArray extends pagedRestArrayMixin(
  ModelArray.ofModel(DisplayReview)
) {}

class DisplayReviewRating extends Model.withSpec({
  rating: types.int,
  count: types.int,
  percentage: types.float,
}) {}

export class DisplayReviewFilterInfo extends restMixin(
  Model.withSpec({
    hasAgeGroup: types.bool,
    hasVerifiedPurchase: types.bool,
    ratings: types.modelArray(DisplayReviewRating).withDefault([]),
  })
) {}
