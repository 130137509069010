import _ from "lodash";
import Fluxxor from "fluxxor";
import Immutable from "immutable";

import { OrderArray } from "./models.js";

export const OrderStore = Fluxxor.createStore({
  actions: {
    INITIALIZED: "initializeHandler",
    "ORDER:CANCEL_ITEMS": "cancelItems",
  },

  initialize: function (options) {
    this.options = options;
    this.orders = new OrderArray();
    this.initialized = false;
    this.emitChange = this.emitChange.bind(this);
  },

  initializeHandler: function () {
    const ordersData = this.flux.store("DataStore").getState().orders || [];
    if (ordersData.length) {
      this.orders = this.orders.parse(ordersData);
      Promise.all(this.orders.map((order) => order.read()))
        .then(() => this.emitChange())
        .catch(() => {});
    }
  },

  getState() {
    return {
      orders: Immutable.List(
        this.orders && this.orders.length
          ? Immutable.fromJS(this.orders.toObject())
          : null,
      ),
    };
  },

  emitChange: function () {
    this.emit("change");
  },

  cancelItems: function (payload) {
    const items = payload.items;
    const order = this.orders.find((order) => order.id === payload.orderId);
    if (order && _.isArray(items) && items.length) {
      order
        .cancelItems(items.map((item) => item.id))
        .then(() => this.emitChange())
        .catch(() => {});
    }
  },
});
