import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

export const FilterDataType = ImmutablePropTypes.mapContains({
  choices: ImmutablePropTypes.iterableOf(
    ImmutablePropTypes.listOf(PropTypes.string)
  ),
  data: ImmutablePropTypes.iterableOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  inputType: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  default: PropTypes.any,
});
