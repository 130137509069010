import EventEmitter from 'events';

export class OffCanvasState extends EventEmitter {
  constructor(props) {
    super();
    Object.defineProperty(this, 'props', {
      configurable: false,
      writable: false,
      enumerable: false,
      value: Object.assign(
        {leftOpen: false, rightOpen: false, allowBothOpen: false},
        props
      )
    });
    for (const key in this.props) {
      Object.defineProperty(this, key, {
        get() {
          return this.props[key];
        },
        set(value) {
          const original = this.props[key];
          this.props[key] = value;
          if (original !== this.props[key]) {
            this.emit(`change:${key}`);
            this.emit('change');
          }
        },
        enumerable: true,
        configurable: false
      });
    }

    this.actions = {
      openLeft: () => {
        if (!this.allowBothOpen) {
          this.actions.closeRight();
        }
        this.leftOpen = true;
        this.emit('open:left');
      },

      openRight: () => {
        if (!this.allowBothOpen) {
          this.actions.closeLeft();
        }
        this.rightOpen = true;
        this.emit('open:right');
      },

      closeLeft: () => {
        this.leftOpen = false;
        this.emit('close:left');
      },

      closeRight: () => {
        this.rightOpen = false;
        this.emit('close:right');
      },

      toggleLeft: () => {
        if (this.leftOpen) {
          this.actions.closeLeft();
        } else {
          this.actions.openLeft();
        }
      },

      toggleRight: () => {
        if (this.rightOpen) {
          this.actions.closeRight();
        } else {
          this.actions.openRight();
        }
      }
    };
  }

  set(props) {
    for (const key in props) {
      this[key] = props[key];
    }
  }
}
