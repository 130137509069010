import _ from 'lodash';
import classNames from 'classnames';
import Fluxxor from 'fluxxor';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import * as React from 'react';

export class FormErrorMessage extends React.PureComponent {
  render() {
    if (!this.props.error) {
      return null;
    }
    const className = classNames('form__error', this.props.className);
    return <span className={className}>{this.props.error}</span>;
  }
}
FormErrorMessage.propTypes = {
  error: PropTypes.string,
  className: PropTypes.string
};
FormErrorMessage.defaultProps = {
  error: '',
  className: 'error'
};

export class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: ''
    };
    ['handleSubmit'].forEach(method => {
      this[method] = this[method].bind(this);
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(
      _.isEqual(this.props.children, nextProps.children) &&
      _.isEqual(this.state, nextState)
    );
  }

  validate() {
    const validateEvent = new CustomEvent('-pr-validate', {
      bubbles: false,
      cancelable: true
    });
    const cancelled = !this.form.dispatchEvent(validateEvent);
    const validated = this.props.validator.call(this);
    if (!validated) {
      this.setState({error: this.props.validationMessage});
    } else {
      this.setState({error: ''});
    }
    return validated && !cancelled;
  }

  handleSubmit(ev) {
    if (!this.validate()) {
      ev.preventDefault();
      ev.stopPropagation();
      return;
    }
    if (!_.isUndefined(this.props.onSubmit)) {
      ev.preventDefault();
      ev.stopPropagation();
      this.props.onSubmit(ev);
    }
  }

  render() {
    const className = classNames(
      this.props.className,
      this.props.attrs ? this.props.attrs.className : undefined
    );
    const attrs = _.omit(this.props.attrs.toObject(), ['className']);
    return (
      <form
        {...attrs}
        ref={node => (this.form = node)}
        className={className}
        onSubmit={this.handleSubmit}
        action={this.props.action}
        method={this.props.method}
      >
        {this.state.error ? (
          <FormErrorMessage className="form__error" error={this.state.error} />
        ) : (
          ''
        )}
        {this.props.children}
      </form>
    );
  }
}
Form.propTypes = {
  action: PropTypes.string,
  method: PropTypes.oneOf(['get', 'post', 'put', 'patch', 'delete']),
  attrs: ImmutablePropTypes.map,
  className: PropTypes.string,
  validator: PropTypes.func,
  validationMessage: PropTypes.string,
  onSubmit: PropTypes.func,
  children: PropTypes.node
};
Form.defaultProps = {
  action: '.',
  attrs: Immutable.Map(),
  method: 'post',
  validator: function() {
    return true;
  },
  validationMessage: 'There was an error with your submission.'
};

export class FormWizard extends Form {
  constructor(props) {
    super(props);
    this.state.currentStage = this.props.currentStage;
    this.state.peakStage = this.props.currentStage;
    this.state.transitioning = false;
    [
      'goToNextStage',
      'goToPrevStage',
      'transitionToPrevStage',
      'transitionToNextStage',
      'handleSubmit'
    ].forEach(method => {
      this[method] = this[method].bind(this);
    });
  }

  hasNextStage() {
    return (
      this.state.currentStage < React.Children.count(this.props.children) - 1
    );
  }

  hasPrevStage() {
    return this.state.currentStage > 0;
  }

  validateStage() {
    const fieldset = this.current.querySelector('fieldset');
    const validateEvent = new CustomEvent('-pr-validate', {
      bubbles: false,
      cancelable: true
    });
    return fieldset.dispatchEvent(validateEvent);
  }

  addTransitionEndListener(element, callback, stop) {
    this.setState({transitioning: true});
    const handler = function() {
      callback();
      this.props.flux.actions.compat.events.removeTransitionEndListener(
        element,
        handler
      );
      if (!stop) {
        this.addTransitionEndListener(
          element,
          function() {
            this.setState({transitioning: false});
          }.bind(this),
          true
        );
      }
    }.bind(this);
    this.props.flux.actions.compat.events.addTransitionEndListener(
      element,
      handler
    );
  }

  goToStage(stage) {
    if (stage > this.state.peakStage) {
      return;
    }
    if (stage > this.state.currentStage && !this.validateStage()) {
      return;
    }
    this.setState({currentStage: stage});
  }

  goToNextStage(ev) {
    if (!_.isUndefined(ev)) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    if (!this.validateStage() || !this.hasNextStage()) {
      return;
    }
    this.setState({
      currentStage: this.state.currentStage + 1,
      peakStage: Math.max(this.state.peakStage, this.state.currentStage + 1)
    });
  }

  goToPrevStage(ev) {
    if (!_.isUndefined(ev)) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    if (!this.hasPrevStage()) {
      return;
    }
    this.setState({currentStage: this.state.currentStage - 1});
  }

  transitionToStage(stage) {
    if (
      stage === this.state.currentStage ||
      stage > this.state.peakStage ||
      (stage > this.state.currentStage && !this.validateStage())
    ) {
      return;
    }
    this.addTransitionEndListener(
      this.current,
      this.goToStage.bind(this, stage)
    );
  }

  transitionToNextStage(ev) {
    if (!_.isUndefined(ev)) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    if (!this.validateStage() || !this.hasNextStage()) {
      return;
    }
    this.addTransitionEndListener(this.current, this.goToNextStage);
  }

  transitionToPrevStage(ev) {
    if (!_.isUndefined(ev)) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    if (!this.hasPrevStage()) {
      return;
    }
    this.addTransitionEndListener(this.current, this.goToPrevStage);
  }

  render() {
    const navigation = React.Children.map(
      this.props.children,
      function(child, idx) {
        const className = classNames('form-wizard__nav__item', {
          'form-wizard__nav__item--current': idx === this.state.currentStage,
          'form-wizard__nav__item--allowed': idx <= this.state.peakStage
        });
        const title = child.props.title
          ? child.props.title
          : child.props.legend ? child.props.legend : 'Step ' + (idx + 1);
        return (
          <li
            key={'nav-stage-' + idx}
            className={className}
            onClick={this.transitionToStage.bind(this, idx)}
          >
            <span className="up-to-tablet-hidden form-wizard__nav__item--title">
              {title}
            </span>
            <span className="lap-hidden form-wizard__nav__item--number">
              <span className="up-to-smart-hidden">Step</span> {idx + 1}
            </span>
          </li>
        );
      },
      this
    );
    const children = React.Children.map(
      this.props.children,
      function(child, idx) {
        let nextText = 'Next';
        let prevText = 'Back';
        if (child.props.buttonTexts && child.props.buttonTexts.next) {
          nextText = child.props.buttonTexts.next;
        }
        if (child.props.buttonTexts && child.props.buttonTexts.prev) {
          prevText = child.props.buttonTexts.prev;
        }
        if (idx === this.state.currentStage) {
          const className = classNames({
            'form-wizard__stage': true,
            'form-wizard__stage--transitioning': this.state.transitioning,
            'form-wizard__stage--current': true
          });
          if (this.props.minHeight) {
            const attrs = child.props.attrs || {};
            const style = attrs.style || {};
            attrs.style = _.extend(style, {
              minHeight: this.props.minHeight + 'px'
            });
            child = React.cloneElement(child, {attrs: attrs});
          }
          return (
            <div
              key={'stage-' + idx}
              aria-busy={this.state.transitioning}
              ref={node => (this.current = node)}
              className={className}
            >
              {child}
              <div className="form-wizard__controls">
                {this.hasPrevStage() ? (
                  <button onClick={this.transitionToPrevStage}>
                    {prevText}
                  </button>
                ) : (
                  ''
                )}
                {this.hasNextStage() ? (
                  <button onClick={this.transitionToNextStage}>
                    {nextText}
                  </button>
                ) : (
                  <button type="submit">{this.props.submitButtonText}</button>
                )}
              </div>
            </div>
          );
        } else {
          return (
            <div
              key={'stage-' + idx}
              className="form-wizard__stage"
              style={{display: 'none'}}
              aria-hidden={true}
            >
              {child}
            </div>
          );
        }
      },
      this
    );
    return (
      <form
        ref={node => (this.form = node)}
        aria-live="polite"
        onSubmit={this.handleSubmit}
        action={this.props.action}
        method={this.props.method}
        {...this.props.attrs.toObject()}
      >
        <nav>
          <ul className="form-wizard__nav">{navigation}</ul>
        </nav>
        {this.state.error ? (
          <FormErrorMessage className="form__error" error={this.state.error} />
        ) : (
          ''
        )}
        {children}
      </form>
    );
  }
}
FormWizard.propTypes = {
  action: PropTypes.string,
  attrs: ImmutablePropTypes.map,
  currentStage: PropTypes.number.isRequired,
  flux: PropTypes.instanceOf(Fluxxor.Flux),
  method: PropTypes.oneOf(['get', 'post', 'put', 'patch', 'delete']),
  onSubmit: PropTypes.func,
  submitButtonText: PropTypes.string.isRequired,
  validationMessage: PropTypes.string,
  validator: PropTypes.func
};
FormWizard.defaultProps = {
  action: '.',
  attrs: Immutable.Map(),
  currentStage: 0,
  method: 'post',
  submitButtonText: 'Submit',
  validationMessage: 'There was an error with your submission.',
  validator: function() {
    return true;
  }
};
