import _ from 'lodash';
import classNames from 'classnames';
import * as React from 'react';

import {Input} from './input.jsx';

export class NumberInput extends Input {
  constructor(props) {
    super(props);
    ['handleDecrement', 'handleIncrement'].forEach(method => {
      this[method] = this[method].bind(this);
    });
  }

  getType() {
    return 'number';
  }

  handleIncrement() {
    const step = _.isFinite(parseFloat(this.props.attrs.get('step')))
      ? parseFloat(this.props.attrs.get('step'))
      : 1;
    const max = _.isFinite(parseFloat(this.props.attrs.get('max')))
      ? parseFloat(this.props.attrs.get('max'))
      : Infinity;
    let value = _.isFinite(parseFloat(this.state.value))
      ? parseFloat(this.state.value)
      : 1;
    value = Math.min(_.round(value + step, Math.log10(step)), max);
    if (value !== parseFloat(this.state.value)) {
      this.setState({value: value, changed: true});
    }
  }

  handleDecrement() {
    const step = _.isFinite(parseFloat(this.props.attrs.get('step')))
      ? parseFloat(this.props.attrs.get('step'))
      : 1;
    const min = _.isFinite(parseFloat(this.props.attrs.get('min')))
      ? parseFloat(this.props.attrs.get('min'))
      : -Infinity;
    let value = _.isFinite(parseFloat(this.state.value))
      ? parseFloat(this.state.value)
      : 1;
    value = Math.max(_.round(value - step, Math.log10(step)), min);
    if (value !== parseFloat(this.state.value)) {
      this.setState({value: value, changed: true});
    }
  }

  getInputElement() {
    const attrs = _.defaults(this.props.attrs.toObject(), {
      type: this.getType()
    });
    attrs.className = classNames(attrs.className, 'number-input__input');
    const min = _.isFinite(parseFloat(attrs.min))
      ? parseFloat(attrs.min)
      : -Infinity;
    const max = _.isFinite(parseFloat(attrs.max))
      ? parseFloat(attrs.max)
      : Infinity;
    const value = _.isFinite(parseFloat(this.state.value))
      ? parseFloat(this.state.value)
      : 1;
    return (
      <div className="number-input dfbx">
        <button
          className="button--icon"
          type="button"
          aria-controls={this.getId()}
          aria-label="Decrement"
          onClick={this.handleDecrement}
          disabled={value <= min}
        >
          -
        </button>
        <input
          {...attrs}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          ref={node => (this.input = node)}
          name={this.props.name}
          id={this.getId()}
          aria-labelledby={this.getLabelId()}
          aria-describedby={this.props.helpText ? this.getHelpTextId() : ''}
          value={value}
        />
        <button
          className="button--icon"
          type="button"
          aria-controls={this.getId()}
          aria-label="Increment"
          onClick={this.handleIncrement}
          disabled={value >= max}
        >
          +
        </button>
      </div>
    );
  }
}
