import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import * as React from 'react';
import _ from 'lodash';
import moment from 'moment';

import SimpleImmutableComponent from 'prometheus/components/immutable.jsx';
import {SimpleImmutableFluxComponent} from 'prometheus/flux/components.jsx';
import {NumberInput} from 'prometheus/forms/components/inputs/input-number.jsx';

import {OrderType, OrderItemType} from './types.js';
import {Loading, ActionItem, Content, Action} from '../../components.jsx';
import Thumbor from '../../thumbor.js';

class CancelItemForm extends React.PureComponent {
  render() {
    return (
      <div className="ph">
        {this.props.itemsCount > 1 ? (
          <NumberInput
            ref={(node) => (this.quantity = node)}
            name="quantity"
            label="Cancel items?"
            attrs={Immutable.Map({
              max: this.props.itemsCount,
              min: 1,
              step: 1,
            })}
            value={this.props.itemsCount}
          />
        ) : (
          <p className="clr-black">Really cancel?</p>
        )}
        <button
          className="button--error brad one-whole"
          type="button"
          onClick={this.props.cancelItems}
        >
          Remove {this.props.itemsCount === 1 ? 'Item' : 'Items'}
        </button>
        <button
          className="button--link dfbx dfbx--vc m owlq pa pa--tr"
          type="button"
          onClick={this.props.closeAction}
        >
          <i className="icon icon-27 icon--med brad-100 bg-error--original" />
          <span className="clr-black">Close</span>
        </button>
      </div>
    );
  }
}
CancelItemForm.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  cancelItems: PropTypes.func.isRequired,
  closeAction: PropTypes.func.isRequired,
};

class OrderItemListDisplay extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !(
      this.props.items.equals(nextProps.items) &&
      this.props.cancelButton === nextProps.cancelButton
    );
  }

  render() {
    const item = this.props.items.first();

    let mainTitle = (
      <strong key="title">
        {_.truncate(item.get('itemTitle'), {length: 80})}
      </strong>
    );
    if (item.getIn(['extra', 'url'])) {
      mainTitle = (
        <a
          className="glnk"
          key="title-link"
          href={item.getIn(['extra', 'url'])}
        >
          {mainTitle}
        </a>
      );
    }

    const titleBlock = [mainTitle];

    if (!this.props.noImages) {
      let imageBlock = (
        <span className="left">
          <div style={{width: '70px', height: '70px'}} />
        </span>
      );
      if (
        item.get('itemType') === 'PRODUCT' &&
        item.getIn(['extra', 'image'])
      ) {
        let img;
        if (item.getIn(['extra', 'imageIsPlaceholder'])) {
          img = item.getIn(['extra', 'image']);
        } else {
          img = new Thumbor()
            .setup()
            .setImagePath(item.getIn(['extra', 'image']))
            .filter('fill(white)')
            .fitIn(70, 70)
            .trim()
            .buildUrl();
        }
        imageBlock = (
          <img loading="lazy" className="left mhr mb" width="70" src={img} />
        );
      } else if (item.get('itemType') === 'EVOUCHER') {
        imageBlock = (
          <span className="left">
            <div style={{width: '40px', height: 'auto'}} className="icon-13" />
          </span>
        );
      }

      titleBlock.unshift(React.cloneElement(imageBlock, {key: 'image'}));
    }

    const titleExtraBlock = [];
    if (item.get('itemType') === 'EVOUCHER') {
      const eVoucherInfo = [];
      let toSuffix = '';
      const deliverOn = item.getIn(['extra', 'deliverOn'])
        ? moment(deliverOn)
        : null;
      if (deliverOn && deliverOn > moment()) {
        toSuffix = ' on ' + deliverOn.format('D MMMM YYYY');
      }
      if (item.getIn(['extra', 'toEmail'])) {
        if (item.getIn(['extra', 'delivered'])) {
          eVoucherInfo.push('Was sent to ');
        } else {
          eVoucherInfo.push('Will be sent to ');
        }
        eVoucherInfo.push(
          <strong key="to-email">{item.getIn(['extra', 'toEmail'])}</strong>,
        );
        eVoucherInfo.push(toSuffix);
        if (item.getIn(['extra', 'recoverUrl'])) {
          eVoucherInfo.push(' if recovered');
        }
        eVoucherInfo.push('. ');
      }
      titleExtraBlock.push(
        <p key="evoucher-info" className="t-small mb">
          {eVoucherInfo}
        </p>,
      );
    } else if (
      item.get('itemType') === 'PRODUCT' &&
      !_.includes(['SHIPPED', 'CANCELLED'], item.get('progress'))
    ) {
      const releaseDate = moment(item.getIn(['extra', 'releaseDate']));
      if (releaseDate > moment()) {
        titleExtraBlock.push(
          <p key="release-date" className="t-small mb">
            Currently due for release on{' '}
            <strong>{releaseDate.format('D MMMM YYYY')}</strong>
          </p>,
        );
      } else if (item.getIn(['extra', 'leadTime'])) {
        titleExtraBlock.push(
          <p key="lead-time" className="t-small mb">
            Delivery may take up to{' '}
            <strong>{item.getIn(['extra', 'leadTime'])} days</strong> to
            despatch
          </p>,
        );
      }
    }
    const statusLabel =
      item.get('state') === 'custom-order'
        ? 'PROCESSING'
        : _.upperCase(item.get('state'));
    const statusQuantityDisplay =
      item.get('itemType') === 'PRODUCT' ? (
        <React.Fragment>
          <li className="left">
            <span className="">Status: </span>
            <strong className="clr-strong">{statusLabel}</strong>
          </li>
          <li className="left">
            <span className="">Quantity: </span>
            <strong className="clr-strong">{this.props.items.count()}</strong>
          </li>
        </React.Fragment>
      ) : (
        <li className="left">
          <span className="">Status: </span>
          <strong className="clr-strong">
            {_.upperCase(item.getIn(['extra', 'displayStatus']))}
          </strong>
        </li>
      );

    const evoucherRecoverLink =
      item.get('itemType') === 'EVOUCHER' &&
      item.getIn(['extra', 'recoverUrl']) ? (
        <li className="left">
          <a href={item.getIn(['extra', 'recoverUrl'])}>Recover eVoucher</a>
        </li>
      ) : null;

    return (
      <ul className="inline-list inline-list--half clr-black pqb">
        <li className="left owl-off">{titleBlock.concat(titleExtraBlock)}</li>
        {statusQuantityDisplay}
        <li className="left">
          <span className="">Value: </span>
          <strong className="clr-strong">
            £{_.sumBy(this.props.items.toJS(), 'value').toFixed(2)}
          </strong>
        </li>
        {evoucherRecoverLink}
        <li className="left">{this.props.cancelButton}</li>
      </ul>
    );
  }
}
OrderItemListDisplay.propTypes = {
  items: PropTypes.oneOfType([
    OrderItemType,
    ImmutablePropTypes.listOf(OrderItemType),
  ]).isRequired,
  cancelButton: PropTypes.node,
  noImages: PropTypes.bool,
};
OrderItemListDisplay.defaultProps = {
  noImages: false,
};

export class OrderItemListEntry extends SimpleImmutableFluxComponent {
  constructor(props) {
    super(props);
    this.state = _.extend(this.state || {}, {
      loading: false,
      openAction: null,
    });
    this.containerId = _.uniqueId('order-item-list-entry-');
    this.swipeThreshold = 60;
    this.handleSwiping = _.throttle(this.handleSwiping, 100, {
      leading: true,
      trailing: false,
    });
    [
      'handleSwiping',
      'handleSwiped',
      'handleSwipedRight',
      'handleSwipedLeft',
      'cancelItems',
      'toggleAction',
      'closeAction',
    ].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  getCancellableItems() {
    return this.props.items
      .filter(
        (item) =>
          _.includes(
            ['PROCESSING', 'BOOKED_IN', 'ACTION_NEEDED', 'AWAITING_STOCK'],
            item.get('progress'),
          ) &&
          item.get('itemType') === 'PRODUCT' &&
          item.get('appliedOffer') === null,
      )
      .toJS();
  }

  cancelItems() {
    const cancellableItems = this.getCancellableItems();
    let quantity = 1;
    if (cancellableItems.length > 1) {
      quantity = this.cancelForm.quantity.state.value;
    }
    this.setState({loading: true, openAction: null});
    this.flux.store('OrderStore').once(
      'change',
      function () {
        this.setState({loading: false});
      }.bind(this),
    );
    this.flux.actions.orders.cancelItems({
      items: cancellableItems.slice(0, quantity),
      orderId: this.props.orderId,
    });
  }

  openAction() {
    if (!this.getCancellableItems().length) {
      return;
    }
    this.setState({openAction: 'cancel-action'});
  }

  closeAction() {
    if (!this.getCancellableItems().length) {
      return;
    }
    this.setState({openAction: null});
  }

  toggleAction() {
    if (!this.getCancellableItems().length) {
      return;
    }
    this.setState({
      openAction:
        this.state.openAction === 'cancel-action' ? null : 'cancel-action',
    });
  }

  handleSwiping(ev, dx) {
    const el = document.getElementById(this.containerId);
    let delta;
    if (this.state.openAction === 'cancel') {
      delta = _.clamp(-dx, -50, 0);
    } else {
      delta = _.clamp(-dx, 0, 50);
    }
    if (el) {
      el.style.transform = `translateX(${delta}px)`;
    }
  }

  handleSwiped() {
    const el = document.getElementById(this.containerId);
    if (el) {
      el.style.transform = 'none';
    }
  }

  handleSwipedRight(ev, x) {
    if (Math.abs(x) > this.swipeThreshold) {
      this.openAction();
    }
  }

  handleSwipedLeft(ev, x) {
    if (Math.abs(x) > this.swipeThreshold) {
      this.closeAction();
    }
  }

  render() {
    if (this.props.items.isEmpty()) {
      return null;
    }
    const cancellableItems = this.getCancellableItems();
    return (
      <ActionItem
        open={this.state.openAction}
        ref={(node) => (this.container = node)}
        id={this.containerId}
        className="relative"
        onSwiping={this.handleSwiping}
        onSwiped={this.handleSwiped}
        onSwipedRight={this.handleSwipedRight}
        onSwipedLeft={this.handleSwipedLeft}
      >
        <Content className="bg-white">
          <OrderItemListDisplay
            items={this.props.items}
            noImages={this.props.noImages}
          />
          {this.state.loading ? (
            <Loading
              className="pa pa--t pz mz one-whole"
              style={{background: 'hsla(0, 0%, 100%, 0.8)'}}
            />
          ) : null}
        </Content>
        {cancellableItems.length ? (
          <Action id="cancel-action" className="bg-error relative">
            <CancelItemForm
              ref={(node) => (this.cancelForm = node)}
              itemsCount={cancellableItems.length}
              cancelItems={this.cancelItems}
              closeAction={this.closeAction}
            />
          </Action>
        ) : null}
      </ActionItem>
    );
  }
}
OrderItemListEntry.propTypes = _.extend(
  _.clone(SimpleImmutableFluxComponent.propTypes),
  {
    items: PropTypes.oneOfType([
      OrderItemType,
      ImmutablePropTypes.listOf(OrderItemType),
    ]).isRequired,
    orderId: PropTypes.number.isRequired,
    noImages: PropTypes.bool,
  },
);
OrderItemListEntry.defaultProps = _.extend(
  _.clone(SimpleImmutableFluxComponent.defaultProps || {}),
  {
    noImages: false,
  },
);

class OrderItemGroup extends SimpleImmutableComponent {
  render() {
    const items = this.props.items.groupBy(
      (item) =>
        `${item.get('itemType')}__${item.get('itemId')}__${item.get(
          'catNumber',
        )}__${item.get('state')}`,
    );
    const itemGroup = [];
    let itemGroupRemainingCount = 0;
    for (const [key, value] of items.entries()) {
      if (itemGroup.length < this.props.groupLimit) {
        itemGroup.push(
          <OrderItemListEntry
            flux={this.props.flux}
            key={key}
            items={value}
            orderId={this.props.orderId}
            noImages={this.props.noImages}
          />,
        );
      } else {
        itemGroupRemainingCount += 1;
      }
    }

    return (
      <dl className="dfbx dl-dd-brdr-top dfbx--fdc mhl clr-black txt-left owl-off clr-lightgrey">
        {this.props.title ? (
          <dt className="mzl h5 clr-black">{this.props.title}</dt>
        ) : null}
        <dd
          className={
            'pzl mzl owl-off brdr--list ' + (this.props.title ? ' mzt' : '')
          }
        >
          {itemGroup}
        </dd>
        {itemGroupRemainingCount > 0 ? (
          <dd className="mzl clr-black">
            ...and{' '}
            <a className="glnk" href={`/orders/${this.props.orderId}`}>
              {`${itemGroupRemainingCount} more `}
              {itemGroupRemainingCount === 1 ? 'item' : 'items'}
            </a>
          </dd>
        ) : null}
      </dl>
    );
  }
}
OrderItemGroup.propTypes = {
  items: PropTypes.oneOfType([
    OrderItemType,
    ImmutablePropTypes.listOf(OrderItemType),
  ]).isRequired,
  orderId: PropTypes.number.isRequired,
  title: PropTypes.string,
  groupLimit: PropTypes.number,
  noImages: PropTypes.bool,
};
OrderItemGroup.defaultProps = {
  groupLimit: 3,
  noImages: false,
};

class OrderTitle extends SimpleImmutableComponent {
  _generateEvoucherInfo(item) {
    let firstPart = null;
    let secondPart = null;
    if (item.getIn(['extra', 'isVoid'])) {
      firstPart = 'Void';
      secondPart = 'no longer valid';
    } else if (!_.includes(['SHIPPED', 'CHARGED'], item.get('progress'))) {
      firstPart = 'Incomplete';
      secondPart = 'unless recovered';
    } else if (item.getIn(['extra', 'isRedeemed'])) {
      const redeemedOn = moment(
        item.getIn(['extra', 'dateRedeemed']),
      ).fromNow();
      firstPart = 'Redeemed';
      secondPart = redeemedOn;
    } else if (item.getIn(['extra', 'isExpired'])) {
      const expiredOn = moment(item.getIn(['extra', 'dateExpires'])).fromNow();
      firstPart = 'Expired';
      secondPart = expiredOn;
    } else {
      const expiresIn = moment(item.getIn(['extra', 'dateExpires'])).fromNow();
      firstPart = 'Expired';
      secondPart = `${expiresIn} unless redeemed`;
    }
    return (
      <React.Fragment>
        <p className="grid-col-1 grid-row-1">
          <strong>{firstPart}</strong> {secondPart}
        </p>
      </React.Fragment>
    );
  }

  render() {
    const dateCreated = moment(this.props.order.get('dateCreated'));
    const evoucherItem = this.props.order
      .get('items')
      .filter((item) => item.get('itemType') == 'EVOUCHER')
      .first(null);
    const itemSummary = evoucherItem ? (
      <React.Fragment>
        {this._generateEvoucherInfo(evoucherItem)}
      </React.Fragment>
    ) : (
      <React.Fragment>
        <p className="grid-col-1 grid-row-1">
          <strong>{this.props.undispatchedCount}</strong>
          {' item'}
          {this.props.undispatchedCount === 1 ? '' : 's'} awaiting dispatch
        </p>
        <p className="grid-col-2 grid-row-1">
          <strong>{this.props.dispatchedCount}</strong>
          {' item'}
          {this.props.dispatchedCount === 1 ? '' : 's'} shipped or cancelled
        </p>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <p className="left clr-black grid-col-gap grid owl-off">
          <span className="grid-col-2 grid-row-1">Order Number</span>
          <a
            className="grid-col-2 grid-row-2 grid-i-jsl"
            href={`/orders/${this.props.order.get('id')}/`}
          >
            {this.props.order.get('id')}
          </a>
          <span className="clr-black  grid-col-1 grid-row-1">
            {this.props.order.get('isSubscriptionOrder')
              ? 'Generated'
              : 'Placed'}{' '}
            on
          </span>{' '}
          <strong className="clr-black grid-col-1 grid-row-2">
            {dateCreated.format('D MMMM YYYY')}
          </strong>{' '}
          <span className="visually-hidden">with a</span>
          <span className="grid-col-3 grid-row-1">Total</span>
          <strong className="grid-col-3 grid-row-2">
            £{this.props.order.get('total').toFixed(2)}
          </strong>
        </p>
        <div className="right clr-black up-to-lap-pht grid-col-gap grid owl-off">
          {itemSummary}
        </div>
      </React.Fragment>
    );
  }
}
OrderTitle.propTypes = {
  order: OrderType.isRequired,
  undispatchedCount: PropTypes.number,
  dispatchedCount: PropTypes.number,
};

export class Order extends SimpleImmutableFluxComponent {
  getStateFromFlux() {
    const storeState = this.flux.store('OrderStore').getState();
    return {
      order:
        storeState.orders.find(
          (order) => order.get('id') === this.props.orderId,
        ) || null,
    };
  }

  render() {
    if (_.isNull(this.state.order) || this.state.order.isEmpty()) {
      return null;
    }
    const order = this.state.order;
    const deliveryParts = _.compact([
      order.getIn(['deliveryAddress', 'line1']) ||
        order.getIn(['deliveryAddress', 'line2']),
      order.getIn(['deliveryAddress', 'postcode']),
    ]);

    const items = Immutable.Seq(order)
      .get('items')
      .filter(
        (item) =>
          !_.includes(['post', 'credit'], item.get('itemType').toLowerCase()),
      )
      .sortBy((item) => item.get('lastModified'))
      .reverse();
    const undispatched = items.filter((item) =>
      _.includes(
        ['awaiting_stock', 'action_needed', 'processing'],
        item.get('progress').toLowerCase(),
      ),
    );
    const dispatched = items.filter(
      (item) =>
        !_.includes(
          ['awaiting_stock', 'action_needed', 'processing'],
          item.get('progress').toLowerCase(),
        ),
    );
    const itemSummary =
      order.get('extra') && order.get('extra').get('itemSummary');

    const undispatchedSize = itemSummary
      ? itemSummary
          .filter((v, k) =>
            _.includes(['awaitingStock', 'actionNeeded', 'processing'], k),
          )
          .reduce((r, v) => r + v, 0)
      : undispatched.size;
    const dispatchedSize = itemSummary
      ? itemSummary
          .filter(
            (v, k) =>
              !_.includes(['awaitingStock', 'actionNeeded', 'processing'], k),
          )
          .reduce((r, v) => r + v, 0)
      : dispatched.size;

    const itemGroups = [];
    if (this.props.splitUndispatched && !order.get('isEvoucherOrder')) {
      if (!undispatched.isEmpty()) {
        itemGroups.push(
          <OrderItemGroup
            flux={this.flux}
            key="undispatched"
            items={undispatched}
            orderId={order.get('id')}
            title={`Items awaiting dispatch (${undispatched.size})`}
            noImages={this.props.noImages}
          />,
        );
      }
      if (!dispatched.isEmpty()) {
        itemGroups.push(
          <OrderItemGroup
            flux={this.flux}
            key="dispatched"
            items={dispatched}
            orderId={order.get('id')}
            title={`Items dispatched or cancelled (${dispatched.size})`}
            noImages={this.props.noImages}
          />,
        );
      }
    } else {
      itemGroups.push(
        <OrderItemGroup
          flux={this.flux}
          key="ungrouped"
          items={items}
          orderId={order.get('id')}
          noImages={this.props.noImages}
        />,
      );
    }

    let orderPaymentMethod =
      order.get('paymentMethods').get(0) || Immutable.Map({});

    if (
      orderPaymentMethod.getIn(['paymentMethod', 'paymentType']) ===
        'INTERNAL' &&
      order.get('paymentMethods').size() > 1
    ) {
      orderPaymentMethod = order.get('paymentMethods').get(1);
    }

    return (
      <React.Fragment>
        <header
          id={`order-${order.get('id')}`}
          className=" clr-black txt-left clearfix clr-neutral--lighter owlh"
        >
          <div className="clearfix owl-off phl phr grid grid-340 grid-col-gap">
            <OrderTitle
              order={order}
              undispatchedCount={undispatchedSize}
              dispatchedCount={dispatchedSize}
            />
          </div>
          <div className="clearfix owl-off phl phr brdr--top brdr--top--thin grid grid-340 grid-col-gap">
            {order.get('isEvoucherOrder') ? null : (
              <p className=" left owl-off clr-black">
                <span className="t-small left">Delivery Address:</span>
                <span className="block both left">
                  {deliveryParts.join(', ')}
                </span>
                {order.get('deliveryAddressEditable') ? (
                  <a
                    href={`/orders/${order.get(
                      'id',
                    )}/update/delivery/address/select/`}
                    className="glnk t-small left mhl pqt"
                  >
                    Update
                  </a>
                ) : null}
              </p>
            )}
            <p className="owlq clr-black">
              <span className="t-small block">Billing details</span>
              {orderPaymentMethod.getIn(['paymentMethod', 'displayIcon']) ? (
                <img
                  className="card-logo pqt phr left both"
                  width="40"
                  src={`${orderPaymentMethod.getIn([
                    'paymentMethod',
                    'displayIcon',
                  ])}`}
                  alt=""
                  title={`[${orderPaymentMethod.getIn([
                    'paymentMethod',
                    'displayText',
                  ])}]`}
                />
              ) : null}
              <span className="block left">
                {order.get('credit') > 0 &&
                order.get('credit') >= order.get('total')
                  ? 'Site credit used'
                  : orderPaymentMethod.getIn(['paymentMethod', 'displayText'])}
              </span>
              {order.get('billingEditable') ? (
                <a
                  href={`/orders/${order.get('id')}/update/billing/`}
                  className="glnk t-small left mhl pqt"
                >
                  Update
                </a>
              ) : null}
            </p>
          </div>
        </header>
        {itemGroups}
      </React.Fragment>
    );
  }
}
Order.propTypes = {
  orderId: PropTypes.number.isRequired,
  noImages: PropTypes.bool,
  splitUndispatched: PropTypes.bool,
};
Order.defaultProps = {
  noImages: false,
  splitUndispatched: false,
};
Order.watchedStores = ['OrderStore'];
