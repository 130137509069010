import * as React from 'react';

import SimpleImmutableComponent from 'prometheus/components/immutable.jsx';
import {SimpleImmutableFluxComponent} from 'prometheus/flux/components.jsx';

import Thumbor from '../../../thumbor.js';

import {FauxProductType} from '../../types.js';

class FauxProduct extends SimpleImmutableComponent {
  render() {
    const item = this.props.fauxProduct;
    let thumbor = new Thumbor()
      .setup()
      .setImagePath(item.get('image'))
      .fitIn(150, 150);
    const imageUrl = thumbor.buildUrl();
    return (
      <li
        className={`faux-product faux-product--${item.get('slug')} list__item`}
      >
        <a href={item.get('linkTo')}>
          <img loading="lazy" src={imageUrl} alt={`[${item.get('title')}]`} />
          <div>
            <p className="h3">{item.get('title')}</p>
            <p className="short-description">{item.get('shortDescription')}</p>
            <div
              className="description"
              dangerouslySetInnerHTML={{__html: item.get('descriptionHtml')}}
            />
          </div>
          <button type="button">{item.get('callToActionText')}</button>
        </a>
      </li>
    );
  }
}
FauxProduct.propTypes = {fauxProduct: FauxProductType.isRequired};

export class FauxProductsList extends SimpleImmutableFluxComponent {
  getStateFromFlux() {
    return {
      fauxProducts: this.flux.store('ProductListingStore').getState()
        .fauxProducts,
    };
  }

  render() {
    if (!this.state.fauxProducts || !this.state.fauxProducts.size > 0) {
      return null;
    }
    const fauxProducts = this.state.fauxProducts
      .map((fauxProduct) => (
        <FauxProduct
          key={`faux-product-${fauxProduct.get('slug')}`}
          fauxProduct={fauxProduct}
        />
      ))
      .toArray();
    return <ul className="faux-products">{fauxProducts}</ul>;
  }
}
FauxProductsList.watchedStores = ['ProductListingStore'];
