import PropTypes from 'prop-types';
import * as React from 'react';

export class LastSearchResultsLink extends React.PureComponent {
  render() {
    let text = 'Go back';
    if (this.props.url.match(/[&?]q=/) && this.props.url.match(/\/catalog\//)) {
      text = 'Back to search results';
    } else if (this.props.url.match(/\/catalog\//)) {
      text = 'Back to product listing';
    }
    return <a href={this.props.url}>{text}</a>;
  }
}
LastSearchResultsLink.propTypes = {
  url: PropTypes.string.isRequired,
};
