import _ from "lodash";
import Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import PropTypes from "prop-types";
import * as React from "react";

import FluxComponent from "prometheus/flux/components.jsx";
import SimpleImmutableComponent from "prometheus/components/immutable.jsx";

import { ProductCategoryType } from "../../types.js";

class ResultCount extends React.PureComponent {
  render() {
    let count;
    if (!_.isFinite(this.props.count) || this.props.count <= 0) {
      count = "No results ";
    } else if (this.props.count === 1) {
      count = "One result ";
    } else if (this.props.count < 10) {
      const numbers = [
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
      ];
      count = numbers[this.props.count - 2] + " results ";
    } else {
      count = this.props.count.toLocaleString() + " results ";
    }
    return <React.Fragment>{count}</React.Fragment>;
  }
}
ResultCount.propTypes = {
  count: PropTypes.number,
};

class SearchInstead extends React.PureComponent {
  render() {
    if (this.props.category && this.props.isCategoryRelaxed) {
      return (
        <React.Fragment>
          <span className="t-normal">No results for </span>
          {'"' + this.props.category.get("name") + '"'}
          <span className="t-normal">
            , searching in all categories instead.{" "}
          </span>
        </React.Fragment>
      );
    }

    if (
      this.props.query &&
      this.props.actualQuery &&
      this.props.actualQuery !== this.props.query
    ) {
      return (
        <React.Fragment>
          <span className="t-normal">No results for </span>
          {'"' + this.props.query + '"'}
          <span className="t-normal">, showing results for </span>
          {' "' + this.props.actualQuery + '"'}
          <span className="t-normal"> instead. </span>
        </React.Fragment>
      );
    }
    return null;
  }
}
SearchInstead.propTypes = {
  query: PropTypes.string,
  actualQuery: PropTypes.string,
  category: ProductCategoryType,
  isCategoryRelaxed: PropTypes.bool,
};

class CategorySegment extends SimpleImmutableComponent {
  render() {
    if (!this.props.category) {
      return null;
    }

    let categories = [];
    let current = this.props.category;
    categories.push(
      <React.Fragment key={`root-${current.get("slug")}`}>
        {current.get("name")}
      </React.Fragment>,
    );
    for (;;) {
      current = current.get("parentCategory");
      if (!current) {
        break;
      }
      const name = current.get("name");
      const slug = current.get("slug");
      categories.push(
        <React.Fragment key={`category-${slug}`}>{name}</React.Fragment>,
      );
    }
    categories.reverse();
    categories = _.flatten(
      categories.map(function (category, idx, array) {
        if (idx === array.length - 1) {
          return category;
        }
        return [category, <span key={`category-level-${idx}`}> ‣ </span>];
      }),
    );

    return (
      <React.Fragment>
        <span className="t-normal">{" in "}</span>
        {categories}
      </React.Fragment>
    );
  }
}
CategorySegment.propTypes = {
  category: ProductCategoryType.isRequired,
};

class QuerySegment extends React.PureComponent {
  render() {
    if (!this.props.query) {
      return null;
    }
    return [
      <span key="start" className="t-normal">
        {" for "}
      </span>,
      <React.Fragment key="content">“{this.props.query}” </React.Fragment>,
    ];
  }
}
QuerySegment.propTypes = {
  query: PropTypes.string,
};

class CategoryRecommendationsSegment extends React.PureComponent {
  render() {
    if (this.props.categoryRecommendations.size == 0) {
      return null;
    }
    const suggestions = this.props.categoryRecommendations
      .map((category) => (
        <a
          href={"/catalog/" + category.get("slug") + "/"}
          key={category.get("slug")}
        >
          {category.get("name")}
        </a>
      ))
      .reduce((reduction, elem) => {
        return reduction.size > 0
          ? reduction.concat(", ", elem)
          : reduction.concat(elem);
      }, Immutable.List());
    return <React.Fragment>See all products in {suggestions}</React.Fragment>;
  }
}
CategoryRecommendationsSegment.propTypes = {
  categoryRecommendations: ImmutablePropTypes.listOf(ProductCategoryType),
};

export class ListingHeader extends FluxComponent {
  getStateFromFlux() {
    return this.flux.store("ProductListingStore").getState();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.count !== nextState.count ||
      this.state.loading !== nextState.loading
    );
  }

  render() {
    const heading = [
      <SearchInstead
        key="search-instead"
        query={this.state.query}
        actualQuery={this.state.actualQuery}
        category={this.state.category}
        isCategoryRelaxed={this.state.isCategoryRelaxed}
      />,
      <ResultCount key="result-count" count={this.state.count} />,
    ];

    const query = this.state.actualQuery || this.state.query;
    if (query) {
      heading.push(<QuerySegment key="query-segment" query={query} />);
    }

    if (this.state.category && !this.state.isCategoryRelaxed) {
      heading.push(
        <CategorySegment
          key="category-segment"
          category={this.state.category}
        />,
      );
    }

    if (this.state.categoryRecommendations) {
      heading.push(
        <CategoryRecommendationsSegment
          key="category-recs"
          categoryRecommendations={this.state.categoryRecommendations}
        />,
      );
    }

    const Wrapper = this.props.element;
    return (
      <Wrapper className="txt-left t-p pl pr mhb">
        {!this.state.loading ? heading : null}{" "}
      </Wrapper>
    );
  }
}
ListingHeader.propTypes = _.extend(
  {
    element: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  },
  _.clone(FluxComponent.propTypes),
);
ListingHeader.defaultProps = _.extend(
  {
    element: "h1",
  },
  _.clone(FluxComponent.defaultProps),
);
ListingHeader.watchedStores = ["ProductListingStore"];
