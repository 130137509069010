import _ from 'lodash';
import PropTypes from 'prop-types';
import * as React from 'react';
import {Swipeable} from 'react-swipeable';
import classNames from 'classnames';

const BASE_CLASS_NAME = 'js-interactive-list-item';

export class Action extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props.children, nextProps.children);
  }

  render() {
    const props = _.omit(this.props, ['children']);
    props.className = classNames(props.className, `${BASE_CLASS_NAME}__action`);
    return <div {...props}>{this.props.children}</div>;
  }
}
Action.propTypes = {
  children: PropTypes.node.isRequired,
};

export class Content extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props.children, nextProps.children);
  }

  render() {
    const props = _.omit(this.props, ['children']);
    props.className = classNames(
      props.className,
      `${BASE_CLASS_NAME}__content`
    );
    return <div {...props}>{this.props.children}</div>;
  }
}
Content.propTypes = {
  children: PropTypes.node.isRequired,
};

export class ActionItem extends React.Component {
  /*
    'this.props.open':
      string: Action element 'id'
      integer: Array Key
  */
  shouldComponentUpdate(nextProps) {
    return !(
      _.isEqual(this.props.children, nextProps.children) &&
      this.props.open === nextProps.open
    );
  }

  render() {
    const props = _.defaults(_.omit(this.props, ['children', 'open']), {
      'aria-live': 'polite',
      'aria-expanded': !_.isNull(this.props.open),
    });
    const content = React.Children.toArray(this.props.children).filter(
      (child) => child.type === Content
    )[0] || <div />;
    const actions = React.Children.toArray(this.props.children)
      .filter((child) => child.type === Action)
      .map((child, idx) => React.cloneElement(child, {key: `action-${idx}`}));
    let selectedAction = null;
    if (_.isString(this.props.open)) {
      selectedAction =
        actions.filter(
          function (action) {
            return action.props.id === this.props.open;
          }.bind(this)
        )[0] || null;
    } else if (_.isFinite(this.props.open)) {
      selectedAction = actions[this.props.open] || null;
    }
    props.className = classNames(
      props.className,
      BASE_CLASS_NAME,
      _.isNull(selectedAction) ? undefined : `${BASE_CLASS_NAME}--is-open`
    );
    return (
      <Swipeable {...props}>
        {selectedAction}
        {content}
      </Swipeable>
    );
  }
}
ActionItem.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  open: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
ActionItem.defaultProps = {
  open: null,
};
