import _ from "lodash";
import Fluxxor from "fluxxor";

import { actions as prometheusActions } from "prometheus/actions.js";
import { stores as prometheusStores } from "prometheus/stores.js";
import { offCanvas } from "prometheus/off-canvas/views.js";
import * as utils from "prometheus/utils.js";

import { actions as localActions } from "./actions.js";
import { stores as localStores } from "./stores.js";
import Thumbor from "./thumbor.js";

import { order } from "./account/orders/views.jsx";
import { walletAddressSelector } from "./account/wallet-addresses/views.jsx";
import { addToCartForm, addToCartButton } from "./cart/views.jsx";
import { addCommentForm, commentThreadPagination } from "./comments/views.jsx";
import {
  productImageCarousel,
  productImageModal,
} from "./catalog/views/product-detail.jsx";
import {
  categoryNavigation,
  listingHeader,
  productCartCount,
  productListing,
  productListingClearFilters,
  productListingFilterWidget,
  productListingLoading,
  productListingSortWidget,
  productListingFauxProducts,
} from "./catalog/views/product-listing.jsx";
import { productReviewPanel } from "./catalog/views/product-reviews.jsx";
import { lastSearchResultsLink } from "./catalog/views/recently-viewed.jsx";
import { searchForm } from "./navigation/search-form/views.jsx";
import { feedbackSurveyMessages } from "./feedback/views.jsx";
import { subscriptionFilterSearchWidget } from "./subscriptions/views.jsx";
import { addToWishListForm, moveToWishListForm } from "./wishlist/views.jsx";
import { FluxContext } from "prometheus/flux/context.jsx";

utils.run("fp", () => {
  const stores = _.extend(localStores, prometheusStores);
  const actions = _.extend(localActions, prometheusActions);

  const flux = new Fluxxor.Flux(stores, actions);
  flux.actions.initialize();

  window.flux = flux;

  FluxContext._defaultValue = flux;

  const applyAndObserve = _.partial(utils.applyAndObserve, _, _, flux);

  Thumbor.prototype.settings = flux.store("SettingsStore").getState().thumbor;
  Thumbor.prototype._setImagePath = Thumbor.prototype.setImagePath;
  Thumbor.prototype.setImagePath = function (imagePath) {
    if (!_.startsWith(imagePath, this.settings.mediaUrl)) {
      imagePath = this.settings.mediaUrl + imagePath;
    }
    return this._setImagePath(imagePath);
  };
  Thumbor.prototype.setup = function () {
    this.THUMBOR_SECURITY_KEY = this.settings.securityKey;
    this.THUMBOR_URL_SERVER = _.trimEnd(this.settings.server, "/");
    return this;
  };

  utils.ready("details-open-tracking", () => {
    const attr = "data-details-open";
    applyAndObserve(
      "details details, [data-details-notify-open] details",
      (elem) => {
        const parent = elem.parentNode.closest(
          "details, [data-details-notify-open]",
        );
        if (!parent) {
          return;
        }
        const handler = _.debounce(() => {
          if (elem.open || parent.querySelector("details[open]")) {
            parent.setAttribute(attr, "true");
          } else {
            parent.removeAttribute(attr);
          }
        });
        const summary = elem.querySelector("summary");
        summary.addEventListener("click", handler);
        summary.addEventListener("focus", handler);
        summary.addEventListener("blur", handler);
        summary.addEventListener("keypress", handler);
        flux.store("MediaStore").on("change", handler);
        handler();
      },
    );

    applyAndObserve("[data-details-notify-open]", (elem) => {
      const handler = _.debounce(() => {
        if (elem.querySelector("details[open]")) {
          elem.setAttribute(attr, "true");
        } else {
          elem.removeAttribute(attr);
        }
      });
      elem.addEventListener("fp-productlisting-filter-did-update", handler);
    });
  });

  utils.ready("off-canvas", () => {
    utils.jsonConfigViewAll("off-canvas", offCanvas, { flux: flux });
  });

  utils.ready("product-listing", () => {
    const rocketImage = flux.store("SettingsStore").getState().rocketImage;
    const elem = document.querySelector("[data-product-listing]");
    if (elem) {
      productListing(elem, { flux: flux, rocketImage: rocketImage });
    }
  });

  utils.ready("product-listing-faux-products", () => {
    const elem = document.querySelector("[data-product-listing-faux-products]");
    if (elem) {
      productListingFauxProducts(elem, { flux: flux });
    }
  });

  utils.ready("product-listing-sort", () => {
    const elem = document.querySelector("[data-product-listing-sort]");
    if (elem) {
      productListingSortWidget(elem, { flux: flux });
    }
  });

  utils.ready("listing-header", () => {
    utils.jsonConfigViewAll("listing-header", listingHeader, { flux: flux });
  });

  utils.ready("product-listing-category-navigation", () => {
    const elem = document.querySelector(
      "[data-product-listing-category-navigation]",
    );
    if (elem) {
      categoryNavigation(elem, { flux: flux });
    }
  });

  utils.ready("product-listing-filters", () => {
    const elem = document.querySelector("[data-product-listing-filters]");
    if (elem) {
      productListingFilterWidget(elem, { flux: flux });
    }
  });

  utils.ready("product-listing-clear-filters", () => {
    const elem = document.querySelector("[data-product-listing-clear-filters]");
    if (elem) {
      productListingClearFilters(elem, { flux: flux });
    }
  });

  utils.ready("product-listing-loading", () => {
    const elem = document.querySelector("[data-product-listing-loading]");
    if (elem) {
      productListingLoading(elem, {
        flux: flux,
        root: elem.parentElement,
      });
    }
  });

  utils.ready("product-page-image-carousel", () => {
    const elem = document.querySelector("[data-product-image-carousel]");
    if (elem) {
      productImageCarousel(elem, { flux: flux });
    }
  });

  utils.ready("product-page-image-modal", () => {
    const elem = document.querySelector("[data-product-image-modal]");
    if (elem) {
      productImageModal(elem, { flux: flux });
    }
  });

  utils.ready("add-to-cart", () => {
    const rocketImage = flux.store("SettingsStore").getState().rocketImage;
    applyAndObserve("[data-add-to-cart]", (elem) =>
      utils.jsonConfigView(elem, "add-to-cart", addToCartForm, {
        flux: flux,
        rocketImage: rocketImage,
      }),
    );
  });

  utils.ready("add-to-cart-button", () => {
    const rocketImage = flux.store("SettingsStore").getState().rocketImage;
    applyAndObserve("[data-add-to-cart-button]", (elem) =>
      utils.jsonConfigView(elem, "add-to-cart-button", addToCartButton, {
        flux: flux,
        rocketImage: rocketImage,
      }),
    );
  });

  utils.ready("search-form", () => {
    Array.prototype.forEach.call(
      document.querySelectorAll("[data-search-form]"),
      (elem) => searchForm(elem, { flux: flux }),
    );
  });

  utils.ready("feedback-messages", () => {
    const elem = document.querySelector("[data-feedback-messages]");
    if (elem) {
      feedbackSurveyMessages(elem, { flux: flux });
    }
  });

  utils.ready("add-to-wishlist", () => {
    applyAndObserve("[data-add-to-wishlist]", (elem) =>
      addToWishListForm(elem, { flux: flux }),
    );
  });

  utils.ready("move-to-wishlist", () => {
    applyAndObserve("[data-move-to-wishlist]", (elem) =>
      utils.jsonConfigView(elem, "move-to-wishlist", moveToWishListForm, {
        flux: flux,
      }),
    );
  });

  utils.ready("product-cart-count", () => {
    applyAndObserve("[data-product-cart-count]", (elem) =>
      utils.jsonConfigView(elem, "product-cart-count", productCartCount, {
        flux: flux,
      }),
    );
  });

  utils.ready("product-review-panel", () => {
    Array.prototype.forEach.call(
      document.querySelectorAll("[data-product-review-panel]"),
      (elem) => productReviewPanel(elem, { flux: flux }),
    );
  });

  utils.ready("last-search-results-link", () => {
    applyAndObserve("[data-last-search-results-link]", (elem) => {
      utils.jsonConfigView(
        elem,
        "last-search-results-link",
        lastSearchResultsLink,
        { flux: flux },
      );
    });
  });

  utils.ready("order-list-entry", () => {
    applyAndObserve("[data-order-list-entry]", (elem) => {
      utils.jsonConfigView(elem, "order-list-entry", order, {
        flux: flux,
      });
    });
  });

  utils.ready("subscription-filter-search-widget", () => {
    applyAndObserve("[data-subscription-filter-search-widget]", (elem) =>
      subscriptionFilterSearchWidget(elem, { flux: flux }),
    );
  });

  utils.ready("add-comment-form", () => {
    applyAndObserve("[data-add-comment-form]", (elem) => {
      utils.jsonConfigView(elem, "add-comment-form", addCommentForm, {
        flux: flux,
      });
    });
  });

  utils.ready("comment-thread-pagination", () => {
    const elem = document.getElementById("comment-list");
    if (elem) {
      const options = {
        paginationCount: 5,
      };
      return commentThreadPagination(elem, options);
    }
  });

  utils.ready("select-wallet-addresses", () => {
    applyAndObserve("[data-select-wallet-addresses]", (elem) => {
      utils.jsonConfigView(
        elem,
        "select-wallet-addresses",
        walletAddressSelector,
        {},
      );
    });
  });

  utils.ready("feedback-survey-form-screen-size", () => {
    applyAndObserve("[data-feedback-survey-form-screen-size]", (elem) => {
      if (window.screen && window.screen.width && window.screen.height) {
        elem.querySelector('input[name="detected_screen_width"]').value =
          window.screen.width;
        elem.querySelector('input[name="detected_screen_height"]').value =
          window.screen.height;
      }
    });
  });

  utils.ready("text-input-disable-submit", () => {
    applyAndObserve("input[data-text-input-disable-submit]", (elem) => {
      elem.addEventListener("keypress", (evt) => {
        if (evt.key == "Enter") {
          evt.stopPropagation();
          evt.preventDefault();
        }
      });
    });
  });
});
