import _ from 'lodash';

export function setQuery(query) {
  this.dispatch('PRODUCT_LISTING:SET_QUERY', query);
}

export function setCategory(category_or_slug) {
  let category;
  if (_.isString(category_or_slug)) {
    category = this.flux
      .store('ProductListingStore')
      .getState()
      .categories.findWhere({slug: category_or_slug});
  } else {
    category = category_or_slug;
  }
  if (!_.isUndefined(category)) {
    // Note that category can be null which removes it but shouldn't be
    // undefined
    this.dispatch('PRODUCT_LISTING:SET_CATEGORY', category);
  }
}

export function addFilter(name, value) {
  this.dispatch('PRODUCT_LISTING:ADD_FILTER', {name: name, value: value});
}

export function removeFilter(name, value) {
  this.dispatch('PRODUCT_LISTING:REMOVE_FILTER', {name: name, value: value});
}

export function clearFilters() {
  this.dispatch('PRODUCT_LISTING:CLEAR_FILTERS');
}

export function setSort(name, value) {
  this.dispatch('PRODUCT_LISTING:SET_SORT', {name: name, value: value});
}

export function setMulti(payload) {
  this.dispatch('PRODUCT_LISTING:SET_MULTI', payload);
}

export function fetchNext(pages) {
  this.dispatch('PRODUCT_LISTING:FETCH_NEXT', pages || 1);
}

export function fetchPrevious(pages) {
  this.dispatch('PRODUCT_LISTING:FETCH_PREVIOUS', pages || 1);
}

export function preloadNext() {
  _.defer(this.dispatch, 'PRODUCT_LISTING:PRELOAD_NEXT');
}

export function preloadPrevious() {
  _.defer(this.dispatch, 'PRODUCT_LISTING:PRELOAD_PREVIOUS');
}

export function savePage(page) {
  this.dispatch('PRODUCT_LISTING:SAVE_PAGE', _.isFinite(page) ? page : 1);
}

export function setListType(type) {
  this.dispatch('PRODUCT_LISTING:SET_LIST_TYPE', type);
}
