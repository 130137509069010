import _ from 'lodash';
import * as React from 'react';

import {Input} from './input.jsx';

export class TextareaInput extends Input {
  getType() {
    return 'textarea';
  }

  getInputElement() {
    return (
      <textarea
        {...this.props.attrs.toObject()}
        ref={node => (this.input = node)}
        name={this.props.name}
        id={this.getId()}
        aria-labelledby={this.getLabelId()}
        aria-describedby={this.props.helpText ? this.getHelpTextId() : ''}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        value={this.state.value}
      />
    );
  }
}
TextareaInput.propTypes = _.clone(Input.propTypes);
TextareaInput.defaultProps = _.clone(Input.defaultProps);
