import _ from "lodash";

import { camelKeys, snakeKeys } from "prometheus/utils.js";

import { ModelArray } from "./arrays.js";
import { Type } from "./types.js";

export { types } from "./types.js";

const DEFAULT_SPEC = Object.freeze({ type: new Type() });
const DEFAULT_OPTIONS = Object.freeze({
  spec: Object.assign({}, DEFAULT_SPEC),
  derived: {},
  pkField: "id",
  clientOnly: [],
});

export class Model {
  constructor(data, options) {
    Object.defineProperty(this, "_options", {
      value: Object.assign({}, DEFAULT_OPTIONS, options || {}),
      enumerable: false,
      configurable: false,
      writable: false,
    });
    Object.defineProperty(this, "_attrs", {
      value: new Map(),
      enumerable: false,
      configurable: false,
      writable: false,
    });
    Object.defineProperty(this, "_derived", {
      value: new Map(),
      enumerable: false,
      configurable: false,
      writable: false,
    });

    for (const key in this._options.derived) {
      this._createDerived(key, this._options.derived[key]);
    }

    for (const key in this._options.spec) {
      if (this._options.spec[key] instanceof Type) {
        this._options.spec[key] = { type: this._options.spec[key] };
      }
      this._options.spec[key] = Object.assign(
        {},
        DEFAULT_SPEC,
        this._options.spec[key],
      );
      this.set(key, _.cloneDeep(this._options.spec[key].type.default));
    }

    for (const attr in data) {
      if (!this._derived.has(attr)) {
        this.set(attr, data[attr]);
      }
    }
  }

  _createAttr(attr) {
    this._attrs.set(attr, undefined);
    Object.defineProperty(this, attr, {
      get() {
        return this.get(attr);
      },
      set(value) {
        this.set(attr, value);
      },
      configurable: false,
      enumerable: true,
    });
  }

  get(attr) {
    return this._attrs.get(attr);
  }

  set(attr, value) {
    if (!this._attrs.has(attr)) {
      this._createAttr(attr);
    }
    if (value === undefined) {
      return;
    }
    const spec = this._options.spec[attr];
    if (spec && _.isFunction(spec.coerce)) {
      this._attrs.set(attr, spec.coerce(value, this));
    } else if (spec && spec.type instanceof Type) {
      this._attrs.set(attr, spec.type.coerce(value, this));
    } else {
      this._attrs.set(attr, value);
    }
    return this._attrs.get(attr);
  }

  setMany(data) {
    for (const attr in data) {
      this.set(attr, data[attr]);
    }
  }

  _createDerived(attr, value) {
    this._derived.set(attr, value);
    Object.defineProperty(this, attr, {
      get() {
        return this.getDerived(attr);
      },
      configurable: false,
      enumerable: true,
    });
  }

  getDerived(attr) {
    const methodOrValue = this._derived.get(attr);
    if (_.isFunction(methodOrValue)) {
      return methodOrValue(this);
    }
    return methodOrValue;
  }

  toObject() {
    const repr = {};
    this._attrs.forEach((value, attr) => {
      if (value === undefined) {
        return;
      }
      if (value instanceof Model || value instanceof ModelArray) {
        repr[attr] = value.toObject();
      } else {
        repr[attr] = value;
      }
    });
    this._derived.forEach((value, attr) => {
      repr[attr] = this.getDerived(attr);
    });
    return repr;
  }

  toJSON() {
    const repr = {};
    this._attrs.forEach((value, attr) => {
      if (value === undefined) {
        return;
      }
      const spec = this._options.spec[attr];
      if (spec && _.isFunction(spec.serialize)) {
        repr[attr] = spec.serialize(value, this);
      } else if (spec && spec.type instanceof Type) {
        repr[attr] = spec.type.serialize(value, this);
      } else {
        repr[attr] = value;
      }
    });
    this._derived.forEach((value, attr) => {
      repr[attr] = this.getDerived(attr);
    });
    return repr;
  }

  serialize() {
    const repr = {};
    this._attrs.forEach((value, attr) => {
      if (value === undefined || this._options.clientOnly.includes(attr)) {
        return;
      }
      if (value instanceof Model || value instanceof ModelArray) {
        repr[attr] = value.serialize();
        return;
      }
      const spec = this._options.spec[attr];
      if (spec && _.isFunction(spec.serialize)) {
        repr[attr] = spec.serialize(value, this);
      } else if (spec && spec.type instanceof Type) {
        repr[attr] = spec.type.serialize(value, this);
      } else {
        repr[attr] = value;
      }
    });
    return snakeKeys(repr, true);
  }

  deserialize(data) {
    if (!data) {
      return data;
    }
    data = camelKeys(data, true);
    if (
      _.isString(data.url) &&
      _.isUndefined(data.id) &&
      data.url.match(/\/(\d+)\/$/)
    ) {
      data.id = parseInt(data.url.match(/\/(\d+)\/$/)[1]);
    }
    return data;
  }

  static withOptions(defaultOptions) {
    return class extends this {
      constructor(data, options) {
        options = _.merge({}, defaultOptions, options);
        super(data, options);
      }
    };
  }

  static withSpec(defaultSpec) {
    return class extends this {
      constructor(data, options) {
        options = _.merge({ spec: defaultSpec }, options);
        super(data, options);
      }
    };
  }

  static parse(data, options) {
    return new this(this.prototype.deserialize(data), options);
  }
}
