import _ from "lodash";
import jump from "jump.js";
import Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import PropTypes from "prop-types";
import * as React from "react";
import { Waypoint } from "react-waypoint";
import classNames from "classnames";
import moment from "moment";

import FluxComponent, {
  SimpleImmutableFluxComponent,
} from "prometheus/flux/components.jsx";
import SimpleImmutableComponent from "prometheus/components/immutable.jsx";
import { camelKeys, windowHeight } from "prometheus/utils.js";
import { CheckboxInput } from "prometheus/forms/components/inputs/input-radio.jsx";

import { checkStatus } from "../../../fetch.js";
import { CartType, CartItemType } from "../../../cart/types.js";
import {
  FilterType,
  ProductType,
  ProductVariantType,
  ProductCategoryType,
} from "../../types.js";
import { Offer } from "../../models";
import { ComposedProductImage } from "../images.jsx";
import { ReviewStars } from "../product-reviews.jsx";
import { AddToCartButtonComponent } from "../../../cart/components.jsx";

class ProductListItemIcons extends SimpleImmutableComponent {
  render() {
    const icons = [];
    const bbfcRatings = this.props.product
      .get("tags")
      .filter((tag) => tag.get("propertySlug") === "bbfc-rating");
    bbfcRatings.forEach((bbfcRating) => {
      icons.push(
        <li key={bbfcRating.get("name")} className="vert-list__item ">
          <p>{bbfcRating.get("name")}</p>
        </li>,
      );
    });

    return icons.length ? (
      <ul className="vert-list product-listing-icons pa pa--tl striplist">
        {icons.slice(0, 2)}
      </ul>
    ) : null;
  }
}
ProductListItemIcons.propTypes = {
  product: ProductType.isRequired,
};

class ProductListItemSubtitle extends SimpleImmutableComponent {
  render() {
    const extraInfo = [];

    const seriesTags = this.props.product
      .get("tags")
      .filter((tag) => tag.get("propertySlug") === "series");
    const universeTags = this.props.product
      .get("tags")
      .filter((tag) => tag.get("propertySlug") === "universe");
    const authorTags = this.props.product
      .get("tags")
      .filter((tag) => tag.get("propertySlug") === "author");
    const publisherTags = this.props.product
      .get("tags")
      .filter((tag) => tag.get("propertySlug") === "publisher");
    const actorTags = this.props.product
      .get("tags")
      .filter((tag) => tag.get("propertySlug") === "actor");
    const directorTags = this.props.product
      .get("tags")
      .filter((tag) => tag.get("propertySlug") === "director");
    const brandTags = this.props.product
      .get("tags")
      .filter((tag) => tag.get("propertySlug") === "brand");
    const manufacturerTags = this.props.product
      .get("tags")
      .filter((tag) => tag.get("propertySlug") === "manufacturer");
    [
      [seriesTags, "From"],
      [universeTags, null],
      [authorTags, "By"],
      [publisherTags, "Published by"],
      [actorTags, "Stars"],
      [directorTags, "Directed by"],
      [brandTags, null],
      [manufacturerTags, null],
    ].forEach((tagSpec) => {
      const [tagGroup, tagPrefix] = tagSpec;
      if (!tagGroup.size) {
        return;
      }
      let suffix = null;
      if (tagGroup.size === 2) {
        suffix = ", and 1 other";
      } else if (tagGroup.size > 2) {
        suffix = ", and " + (tagGroup.size - 1) + " others";
      }
      extraInfo.push(
        <dt
          key={`${tagGroup.get(0).get("propertySlug")}`}
          className="txt-left t-thin pqr mqt hidden_at_mini left both"
        >
          {tagPrefix || tagGroup.get(0).get("propertyName")}:{" "}
        </dt>,
      );
      extraInfo.push(
        <dd
          className="mzl mqt t-500 left"
          key={`${tagGroup.get(0).get("propertySlug")}${tagGroup
            .get(0)
            .get("name")}`}
        >
          <a
            href={"/catalog/?tag=" + tagGroup.get(0).get("slug")}
            className=" SubtitleItems hidden_at_mini"
          >
            {tagGroup.get(0).get("name")}
          </a>
          {suffix ? (
            <span className=" SubtitleItems hidden_at_mini">{suffix}</span>
          ) : null}
        </dd>,
      );
    });

    return <React.Fragment>{extraInfo}</React.Fragment>;
  }
}
ProductListItemSubtitle.propTypes = {
  product: ProductType.isRequired,
};

class ProductListItemFlags extends SimpleImmutableComponent {
  render() {
    const product = this.props.product;
    const flagClassName = _.partial(
      classNames,
      "clr-white bold pql pqr p-b mqr brad left mqt",
      " inline-list__item zi2",
    );
    const flags = [];

    if (!product.get("inStock")) {
      flags.push(
        <li key="out-of-stock" className={flagClassName("bg-info--original")}>
          Currently Unavailable
        </li>,
      );
    } else if (product.get("leadTime")) {
      flags.push(
        <li key="custom-order" className={flagClassName("bg-info--original")}>
          {product.get("customOrderLabel")}
        </li>,
      );
    } else if (product.get("beforeRelease")) {
      flags.push(
        <li key="pre-order" className={flagClassName("bg-info--original")}>
          Pre-Order
        </li>,
      );
    }
    if (product.get("signed")) {
      flags.push(
        <li key="signed" className={flagClassName("bg-black")}>
          Signed
        </li>,
      );
    }
    if (product.get("limitedEdition")) {
      flags.push(
        <li key="ltd-ed" className={flagClassName("bg-black")}>
          Limited Edition
        </li>,
      );
    }
    if (product.get("exclusive")) {
      flags.push(
        <li key="ltd-ed" className={flagClassName("bg-black")}>
          Exclusive
        </li>,
      );
    }
    if (product.get("adult")) {
      flags.push(
        <li key="adult" className={flagClassName("bg-error--original")}>
          Adult
        </li>,
      );
    }
    // This shouldn't look at tags but FileBreaker don't know how to do shit
    const packagingTags = this.props.product
      .get("tags")
      .filter((tag) => tag.get("propertySlug") === "packaging");
    const packagingProps = this.props.product
      .get("properties")
      .filter((prop) => prop.get("nameSlug") === "packaging");
    const blindBoxed = !!(
      packagingTags.find((tag) => tag.get("nameSlug") === "blind-boxed") ||
      packagingProps.find((prop) => prop.get("valueSlug") === "blind-boxed")
    );
    const bagged = !!(
      packagingTags.find((tag) => tag.get("nameSlug") === "bagged-boarded") ||
      packagingProps.find((prop) => prop.get("valueSlug") === "bagged-boarded")
    );
    if (bagged) {
      flags.push(
        <li
          key="sale"
          className={flagClassName("brdr brdr--thin clr-black bg-white")}
        >
          Bagged &amp; Boarded
        </li>,
      );
    }
    if (blindBoxed) {
      flags.push(
        <li
          key="sale"
          className={flagClassName("brdr brdr--thin clr-black bg-white")}
        >
          Blind Boxed
        </li>,
      );
    }
    return (
      <ul className="inline-list inline-list--q ord--02 zi2 one-whole phb">
        {flags}
      </ul>
    );
  }
}
ProductListItemFlags.propTypes = {
  product: ProductType.isRequired,
};

class ProductListItemTypes extends SimpleImmutableComponent {
  render() {
    const product = this.props.product;
    const typeClassName = classNames(
      "block right crsr-txt zi1",
      "pqr pql p-b mzt type-tag inline-list__item",
    );
    const type = product.get("type");
    return (
      <p key="type" className={typeClassName}>
        {type.get("singularName")}
      </p>
    );
  }
}
ProductListItemTypes.propTypes = {
  product: ProductType.isRequired,
};

class ProductListItemSale extends SimpleImmutableComponent {
  render() {
    const product = this.props.product;
    const saleClassName = classNames(
      "sale-tag block right crsr-txt pa--tl pa zi2 ",
      "pqr pql mht mr ml clr-white bold zshd-00",
    );
    if (product.get("salePrice")) {
      return <p className={saleClassName}>Sale</p>;
    } else {
      return null;
    }
  }
}
ProductListItemSale.propTypes = {
  product: ProductType.isRequired,
};

class ProductOfferBox extends SimpleImmutableComponent {
  render() {
    return (
      <div className="t-center product-offer-box brdr g-brdr-clr--lnk owl-off pqb pql pqr relative one-whole mhl mhr lap-mr lap-ml">
        <p
          className="product-offer-display-name"
          dangerouslySetInnerHTML={{
            __html: this.props.offer.get("fullDisplayNameHtml"),
          }}
        ></p>
        <p className="product-offer-link">
          <a
            className="link-banner glnk"
            href={"/catalog/?offers=" + this.props.offer.get("slug")}
          >
            View All
          </a>
        </p>
      </div>
    );
  }
}
ProductOfferBox.propTypes = {
  offer: Offer,
};

class CartCountIcon extends React.PureComponent {
  render() {
    if (!this.props.numberInCart) {
      return null;
    }
    const message = this.props.numberInCart + " in Basket";
    return (
      <div
        className="clr-white pa pa--tl relative zi1"
        aria-label={message}
        title={message}
      >
        <p className="visually-hidden">
          {this.props.numberInCart > 99 ? "99+" : this.props.numberInCart} in
          your basket
        </p>
        <i
          className="icon icon-67 clr-white icon--med"
          data-in-basket={
            this.props.numberInCart > 99 ? "99+" : this.props.numberInCart
          }
        ></i>
      </div>
    );
  }
}
CartCountIcon.propTypes = {
  numberInCart: PropTypes.number,
};

class ProductListItemHeader extends SimpleImmutableComponent {
  render() {
    const product = this.props.product;
    const productUrlQuery = this.props.productUrlQuery;

    const hasMiniPrint =
      product
        .get("images", Immutable.List())
        .filter((image) => image.get("imageType") === "mini_print")
        .get(0) &&
      product
        .get("images", Immutable.List())
        .filter((image) => image.get("imageType") !== "mini_print")
        .get(0);

    const delClassName = "price-rrp clr-neutral t-normal t-xsmall mzt block";
    const rrp =
      product.get("rrp") > product.get("sitePrice") &&
      !product.get("leadTime") ? (
        <del className={delClassName}>
          <span className="visually-hidden">RRP</span> £
          {product.get("rrp").toFixed(2)}
        </del>
      ) : (
        ""
      );

    const className = classNames(
      "owlh dtb row mzb relative one-whole mzt relative",
      {
        "has-mini-print": hasMiniPrint,
      },
    );

    return (
      <header className={className}>
        <div className="pl_image_wrap relative one-whole ord--01 oh">
          <div className="js-product-image">
            <ComposedProductImage product={product} noFill={true} />
          </div>
          <ProductListItemTypes product={product} />
          <ProductListItemSale product={product} />
          <ProductListItemFlags product={product} />
        </div>
        <div className="ord--03 dfbx--sb dfbx--wrp phl phr lap-pr lap-pl owl-off one-whole">
          <h3 className=" t-p bold clr-black smart-one-whole phablet-three-quarters mqt txt-left dtb--fg owl-off">
            <a
              href={
                productUrlQuery.count() > 0
                  ? product.get("absoluteUrl") +
                    "?" +
                    productUrlQuery.toArray().join("&")
                  : product.get("absoluteUrl")
              }
              className="block one-whole clearfix dfbx dfbx--fdc link-banner link--black"
            >
              {product.get("title")}
            </a>
          </h3>
          <p className="pl_price bold txt-right phablet-one-quarter">
            <span className="clr-price">
              £{product.get("sitePrice").toFixed(2).slice(0, -3)}
            </span>
            <span className="t-small clr-price ">
              {product.get("sitePrice").toFixed(2).slice(-3)}
            </span>
            {rrp}
          </p>
        </div>
        <ProductListItemIcons product={product} />
      </header>
    );
  }
}
ProductListItemHeader.propTypes = {
  imageFlourishes: PropTypes.bool,
  product: ProductType.isRequired,
  productUrlQuery: ImmutablePropTypes.listOf(PropTypes.string),
};
ProductListItemHeader.defaultProps = {
  imageFlourishes: true,
  productUrlQuery: Immutable.List(),
};

class ProductVariantListItem extends SimpleImmutableComponent {
  render() {
    const variant = this.props.variant;
    const name = variant.get("name");
    const inStock = variant.get("inStock");
    const className = classNames("inline-list__item product__variant", {
      "normal product__variant--instock bold": inStock,
      "product__variant--out-of-stock clr-neutral--lighter": !inStock,
    });
    const inStockClassName = classNames(
      "bg-info--lighter  mzl t-small block pql pqr p-b clr-success--original",
    );
    const outOfStockClassName = classNames(
      "t-small block mzl pqr pql bg-neutral--lightest clr-neutral brad",
    );
    return (
      <li className={className}>
        {inStock ? (
          <strong className={inStockClassName} aria-label={name} title={name}>
            {name}
          </strong>
        ) : (
          <del
            className={outOfStockClassName}
            aria-label={name + ": Currently Unavailable"}
            title={name + ": Currently Unavailable"}
          >
            {name}
          </del>
        )}
      </li>
    );
  }
}
ProductVariantListItem.propTypes = {
  product: ProductType.isRequired,
  variant: ProductVariantType.isRequired,
};

class ProductListItem extends SimpleImmutableFluxComponent {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (!this.props.page) {
      return;
    }
    const page = this.props.page;
    let newLocation;
    if (window.location.search.search(/page=\d+/) >= 0) {
      newLocation = window.location.search.replace(/page=\d+/, "page=" + page);
    } else {
      newLocation = window.location.search
        ? window.location.search + "&page=" + page
        : "?page=" + page;
    }
    const state = history.state || {};
    if (state && state.url) {
      if (state.url.search(/page=\d+/) >= 0) {
        state.url = state.url.replace(/page=\d+/, "page=" + page);
      } else {
        state.url =
          state.url.indexOf("?") >= 0
            ? state.url + "&page=" + page
            : state.url + "?page=" + page;
      }
    }
    state.page = page;
    if (newLocation !== window.location.search) {
      history.pushState(state, "", newLocation);
      this.flux.actions.catalog.productListing.savePage(page);
    }
  }

  render() {
    const product = this.props.product;
    const className = classNames(
      "list__item list__item--product product-list__list__item",
      "row clearfix",
      this.props.className,
      { "in-cart": this.props.cartProductTotal > 0 },
    );
    let productUrlQuery = [];

    let reviews = null;
    if (product.get("reviewCount")) {
      reviews = [
        <dt className="visually-hidden" key="reviews-dt">
          Reviews
        </dt>,
        <dd className="mzl mqt" key="reviews-dd">
          <ReviewStars
            className=""
            count={product.get("reviewCount")}
            score={product.get("reviewScore")}
            url={product.get("absoluteUrl") + "#product-reviews"}
          />
        </dd>,
      ];
    }

    let releaseDate = null;
    if (
      product.get("beforeRelease") ||
      this.props.filters.find(
        (filter) => filter.get("name") === "new_releases",
      ) ||
      this.props.sorts.find((sort) => sort.get("data") === "release-date")
    ) {
      let formattedDate = "";
      // Value in days
      const releaseDateShowDayLimit = 180;
      const date = moment(product.get("releaseDate"));
      if (date.isAfter(moment().add(releaseDateShowDayLimit, "d"), "month")) {
        formattedDate = date.format("MMMM YYYY");
      } else {
        formattedDate = date.format("D MMMM YYYY");
      }
      releaseDate = [
        <div key="release-date-title" className="dfbx--wrp">
          <dt className="mzl mqt clr-info both">
            {product.get("beforeRelease") ? "Due for release: " : "Released: "}
          </dt>
          <dd key="release-date" className="mzl mqt pql pqr">
            <strong>{formattedDate}</strong>
          </dd>
        </div>,
      ];
    }

    let firstOnWebDate = null;
    if (
      this.props.sorts.find((sort) => sort.get("data") === "recently-added")
    ) {
      firstOnWebDate = [
        <dt key="firstonweb-date-title" className="mzl mqt clr-info both">
          Date Added:
        </dt>,
        <dd
          key="firstonweb-date"
          className="mzl bg-info left both clr-info mqt pql pqr"
        >
          <strong>
            {moment(product.get("firstonwebDate")).format("d MMMM YYYY")}
          </strong>
        </dd>,
      ];
    }

    let category = null;
    const categories = [];
    if (this.props.showCategory && product.get("productCategories").size) {
      let current = product.get("productCategories").get(0);
      for (;;) {
        categories.push(
          <li key={current.get("slug")} className="inline-list__item ">
            <a className="" href={"/catalog/" + current.get("slug") + "/"}>
              {current.get("name")}
            </a>
          </li>,
        );
        current = current.get("parentCategory");
        if (!current) {
          break;
        }
      }
      categories.reverse();
      category = <ol className="inline-list inline-list--h">{categories}</ol>;
    }

    const tags = product.get("tags").filter((tag) => {
      const matchedFilter = this.props.filters.find(
        (filter) => filter.get("name") === "tag__" + tag.get("propertySlug"),
      );
      return (
        matchedFilter && matchedFilter.get("data").includes(tag.get("nameSlug"))
      );
    });

    let tagBlock = null;
    const filterCount = _.sum(
      this.props.filters
        .map((filter) => {
          const data = filter.get("data");
          if (Immutable.List.isList(data)) {
            return data.size;
          }
          if (Immutable.Map.isMap(data)) {
            return data.toList().some((val) => !!val) ? 1 : 0;
          }
          return data ? 1 : 0;
        })
        .toArray(),
    );
    if (tags.size && filterCount > 1) {
      tagBlock = (
        <ul className="inline-list inline-list--half owlh">
          {tags
            .map((tag) => (
              <li key={tag.get("slug")} className="inline-list__item">
                <a
                  href={"/catalog/?tag=" + tag.get("slug")}
                  className="bg-info dib pq brad"
                >
                  {tag.get("name")}
                </a>
              </li>
            ))
            .toArray()}
        </ul>
      );
    }

    let offer = null;
    let offerTag = null;
    if (product.get("currentOffer")) {
      let currentOffer = product.get("currentOffer");
      offerTag = [
        <ul
          className="inline-list inline-list--half mzt pa pa--tl pl offer_tag zi2"
          key="offer-ul"
        >
          <li className="bg-white brdr g-brdr-clr--lnk t-p pql pqr mhl left mht bold">
            {currentOffer.get("shortName")}
          </li>
        </ul>,
      ];
      offer = [
        <ProductOfferBox
          offer={currentOffer}
          key={"offer-" + currentOffer.get("slug")}
        />,
      ];
    }

    let variants = null;
    if (product.get("hasVariants")) {
      variants = product.get("variants").map((variant) => {
        return (
          <ProductVariantListItem
            product={product}
            variant={variant}
            key={"variant-" + variant.get("name")}
          />
        );
      });
      variants = [
        <dt className=" clr-neutral--light one-whole" key="variants-dt">
          Available Sizes
        </dt>,
        <dd className="mzl mzt" key="variants-dd">
          <ul className="inline-list inline-list--half t-p pqb owl-off">
            {variants}
          </ul>
        </dd>,
      ];
      const variantFilter = this.props.filters.find(
        (filter) => filter.get("name") === "variant_name",
      );
      if (variantFilter && variantFilter.get("data").size === 1) {
        // This allows us to pre-select the correct variant for
        // the user automatically on the product page.
        // We can only do this when they have filtered for one option though.
        productUrlQuery.push(
          variantFilter.get("name") +
            "=" +
            encodeURIComponent(variantFilter.get("data").get(0)),
        );
      }
    }

    let inCart = null;
    if (this.props.cartProductTotal) {
      inCart = [
        <dt className="vert-list__item visually-hidden" key="cart-dt">
          This is in your cart
        </dt>,
        <dd className="mzl cart-dd" key="cart-dd">
          <CartCountIcon numberInCart={this.props.cartProductTotal} />
        </dd>,
      ];
    }

    const identifier = `product-${product.get("id")}`;
    const cartUpdatingProductId = parseInt(this.props.cartUpdatingProductId);
    const updatingProduct =
      Number.isFinite(cartUpdatingProductId) &&
      cartUpdatingProductId === product.get("id");

    let button;
    if (!this.props.userAllowListingBuy) {
      // Don't show anything if listing buy buttons are disabled..
      button = null;
    } else if (!product.get("variants").isEmpty()) {
      // Display "Choose Options" if there are variants...
      button = (
        <div className="product-list__list_item_buying-options mb dfbx dfbx--ctr dfbx--fdc one-whole pl pr">
          <a
            className="button btn--border btn-options button--lg zshd-00 one-whole brad"
            href={product.get("absoluteUrl")}
          >
            Choose Options
          </a>
        </div>
      );
    } else {
      // Otherwise display buy button.
      button = (
        <AddToCartButtonComponent
          product={product}
          catNumber={product.get("catNumber")}
          cartItem={this.props.cartItem}
          cartItemProductTotal={this.props.cartProductTotal}
          lastForceUpdate={this.props.cartLastForceUpdate}
          updatingProductId={this.props.cartUpdatingProductId}
          addItem={this.flux.actions.cart.addItem}
          updateItem={this.flux.actions.cart.updateItem}
          removeItem={this.flux.actions.cart.removeItem}
          loading={this.props.cartLoading}
          offline={this.props.offline}
          quiet={true}
          source="product-listing"
        />
      );
    }

    return (
      <li
        className={className}
        id={this.props.id}
        onClick={this.handleClick}
        data-updating-product={updatingProduct || null}
      >
        <section
          id={identifier}
          className="zshd-00 bg-white mzb dtb one-whole relative oh"
        >
          <ProductListItemHeader
            productUrlQuery={Immutable.List(productUrlQuery)}
            {...this.props}
          />
          {offerTag}
          {offer}
          <dl className="txt-left owlq mqt mhl mhr lap-mr lap-ml">
            {reviews}
            {variants}
            {releaseDate}
            {firstOnWebDate}
            {inCart}
            {category
              ? [
                  <dt className="visually-hidden" key="category-dt">
                    In
                  </dt>,
                  <dd className="mzl hidden_at_mini both" key="category-dd">
                    {category}
                  </dd>,
                ]
              : null}
            {tagBlock
              ? [
                  <dt className="visually-hidden" key="tag-dt">
                    Matches
                  </dt>,
                  <dd className="mzl hidden_at_mini both" key="tag-dd">
                    {tagBlock}
                  </dd>,
                ]
              : null}
            <ProductListItemSubtitle product={product} />
          </dl>
          {button}
        </section>
      </li>
    );
  }
}
ProductListItem.propTypes = {
  className: PropTypes.string,
  filters: ImmutablePropTypes.listOf(FilterType),
  id: PropTypes.string,
  product: ProductType.isRequired,
  cartItem: CartItemType,
  cartProductTotal: PropTypes.number,
  showCategory: PropTypes.bool,
  sorts: ImmutablePropTypes.list,
  userAllowListingBuy: PropTypes.bool,
  cartLoading: PropTypes.bool,
  cartLastForceUpdate: PropTypes.number.isRequired,
  cartUpdatingProductId: PropTypes.number,
  offline: PropTypes.bool,
};
ProductListItem.defaultProps = {
  filters: Immutable.List(),
  showCategory: false,
  sorts: Immutable.List(),
  userAllowListingBuy: true,
  cartLoading: false,
  offline: false,
};
ProductListItem.watchedStores = ["CartStore"];

class ProductList extends SimpleImmutableFluxComponent {
  jumpToPage(page, ev) {
    ev.preventDefault();
    ev.stopPropagation();
    const elem = document.getElementById("page-" + page);
    if (elem) {
      jump(elem, { offset: -200, duration: 200 });
    }
  }

  render() {
    const children = this.props.products
      .flatMap((product, idx) => {
        const cartItem = product.get("variants").isEmpty()
          ? this.props.cart
              .get("items")
              .filter((cartItem) =>
                _.endsWith(
                  cartItem.get("product"),
                  "/" + product.get("id") + "/",
                ),
              )
              .first()
          : null;
        const cartProductTotal = this.props.cart
          .get("items")
          .filter((cartItem) =>
            _.endsWith(cartItem.get("product"), "/" + product.get("id") + "/"),
          )
          .map((cartItem) => cartItem.get("quantity"))
          .reduce((total, quantity) => total + quantity, 0);
        const page =
          this.props.startPage + Math.floor(idx / this.props.perPage);
        const isFirstInPage = idx % this.props.perPage === 0;
        const childItems = [
          <ProductListItem
            key={"product-" + product.get("id")}
            page={page}
            id={isFirstInPage ? "page-" + page : undefined}
            product={product}
            cartItem={cartItem}
            cartProductTotal={cartProductTotal}
            cartLoading={this.props.cartLoading}
            cartLastForceUpdate={this.props.cartLastForceUpdate}
            cartUpdatingProductId={this.props.cartUpdatingProductId}
            offline={this.props.offline}
            flux={this.flux}
            userAllowListingBuy={this.props.userAllowListingBuy}
            {...this.props.childProps.toObject()}
          />,
        ];
        if (isFirstInPage && this.props.startPage !== this.props.endPage) {
          childItems.unshift(
            <li
              key={"page-header-" + page}
              className="support-links product-list__list__separator owl-off bg-white phl phr pht pb brdr--top brdr--top--thin brdr--top--dotted"
            >
              <p className="mhl mhr">
                <strong>Page {page}</strong>
              </p>
              <p className=" owl-off">
                {page !== this.props.startPage ? (
                  <a
                    className=" pz dib glnk clr-neutral"
                    href={"#page-" + (page - 1)}
                    onClick={this.jumpToPage.bind(this, page - 1)}
                  >
                    ↑ Previous {this.props.perPage}
                  </a>
                ) : (
                  ""
                )}
                {page !== this.props.endPage ? (
                  <a
                    className=" pz dib glnk clr-neutral ml"
                    href={"#page-" + (page + 1)}
                    onClick={this.jumpToPage.bind(this, page + 1)}
                  >
                    Next {this.props.perPage} ↓
                  </a>
                ) : (
                  ""
                )}
              </p>
            </li>,
          );
        }
        return childItems;
      })
      .toArray();

    const className = classNames(
      "list--product_listing list--product product-list__list list--product_listing--grid",
      "list t-small row dfbx--ctr row--gutter striplist pqt pb pr pl",
    );
    return (
      <ul
        data-updating-product={this.props.cartUpdatingProductId}
        className={className}
      >
        {_.flatten(children)}
      </ul>
    );
  }
}
ProductList.propTypes = {
  products: ImmutablePropTypes.listOf(ProductType),
  cart: CartType,
  childProps: ImmutablePropTypes.map,
  startPage: PropTypes.number,
  endPage: PropTypes.number,
  perPage: PropTypes.number,
  userAllowListingBuy: PropTypes.bool,
  cartLoading: PropTypes.bool,
  cartLastForceUpdate: PropTypes.number.isRequired,
  cartUpdatingProductId: PropTypes.number,
  offline: PropTypes.bool,
};
ProductList.defaultProps = {
  startPage: 1,
  endPage: 1,
  perPage: 24,
  userAllowListingBuy: true,
  cartLoading: false,
  offline: false,
};

class NoResults extends SimpleImmutableComponent {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: null,
    };
  }

  getSuggestions(query) {
    if (!query) {
      return;
    }

    if (!query || query.length < 3) {
      this.setState({ suggestions: null });
    }

    const url =
      "/api/search-suggestions/?q=" +
      encodeURIComponent(query.trim()) +
      "&domain=all";
    fetch(url, { credentials: "same-origin" })
      .then(checkStatus)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ suggestions: Immutable.fromJS(camelKeys(data)) });
      })
      .catch(() => {
        this.setState({ suggestions: null });
      });
  }

  componentDidMount() {
    if (this.props.query) {
      this.getSuggestions(this.props.query);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.query &&
      (!prevProps.query || this.props.query != prevProps.query)
    ) {
      this.getSuggestions(this.props.query);
    }
  }

  render() {
    const choices = [
      <li key="all">
        …seeing <a href="/catalog/">absolutely everything</a>?
      </li>,
    ];
    if (this.props.showOOS) {
      choices.unshift(
        <li key="include-oos">
          …including{" "}
          <button
            onClick={this.props.showOOS}
            className="button--link pz mz"
            style={{ paddingBottom: 0 }}
          >
            out of stock or unavailable product
          </button>
          ?
        </li>,
      );
    }
    if (this.props.category && (this.props.filters.size || this.props.query)) {
      choices.push(
        <li key="whole-cat">
          …seeing{" "}
          <a href={"/catalog/" + this.props.category.get("slug")}>
            everything in {this.props.category.get("name")}
          </a>
          ?
        </li>,
      );
    }
    if (this.props.query && (this.props.filters.size || this.props.category)) {
      choices.push(
        <li key="query-everywhere">
          …searching for{" "}
          <a href={"/catalog/?q=" + this.props.query}>
            “{this.props.query}” in everything
          </a>
          ?
        </li>,
      );
    }
    if (this.props.query) {
      choices.push(<li key="query">…another search query?</li>);
    }
    if (this.state.suggestions) {
      const categorySlug = this.props.category
        ? `${this.props.category.get("slug")}/`
        : "";
      if (this.state.suggestions.get("spellingSuggestion")) {
        choices.push(
          <li key="query-suggest">
            …searching for{" "}
            <a
              href={`/catalog/${categorySlug}?q=${this.state.suggestions.get(
                "spellingSuggestion",
              )}`}
            >
              “{this.state.suggestions.get("spellingSuggestion")}”
            </a>{" "}
            instead?
          </li>,
        );
      }
      if (
        this.state.suggestions.get("categorySuggestions") &&
        !this.state.suggestions.get("categorySuggestions").isEmpty()
      ) {
        this.state.suggestions
          .get("categorySuggestions")
          .filter((suggestion) => suggestion.get("category") !== null)
          .forEach((suggestion, idx) => {
            choices.push(
              <li key={`query-cat-suggest-${idx}`}>
                …searching for{" "}
                <a
                  href={`/catalog/${categorySlug}?q=${this.state.suggestions.get(
                    "spellingSuggestion",
                  )}`}
                >
                  “{suggestion.get("query")}” in{" "}
                  <strong>{suggestion.getIn(["category", "name"])}</strong>
                </a>
                ?
              </li>,
            );
          });
      }
    }

    let filters = null;
    if (this.props.filters.size) {
      const singleFilters = this.props.filters
        .map((filter) => {
          const data = filter.get("data");
          const choices = filter.get("choices");
          const name = filter.get("name");
          const label = filter.get("label");
          if (Immutable.List.isList(data)) {
            return data
              .map((val) => {
                return (
                  <li key={val}>
                    <button
                      onClick={() =>
                        this.props.clearFilters({ name: name, value: val })
                      }
                      type="button"
                    >
                      × {label}:{" "}
                      {(
                        choices.find((el) => el.get(0) === val) ||
                        Immutable.List()
                      )
                        .get(1, "")
                        .replace(/\s+\(\d+\)$/, "")}
                    </button>
                  </li>
                );
              })
              .toArray();
          }
          return (
            <li key={name}>
              <button
                onClick={() =>
                  this.props.clearFilters({
                    name: name,
                    value: data,
                  })
                }
                type="button"
              >
                × {label}
              </button>
            </li>
          );
        })
        .toArray();
      filters = [
        <hr key="filters-divider" />,
        <div key="filters">
          <p>&hellip;or removing some filter options&hellip;</p>
          <ul className="inline-list">
            {singleFilters}
            {singleFilters.length > 1 ? (
              <li>
                <button onClick={() => this.props.clearFilters()} type="button">
                  × Clear All Filters
                </button>
              </li>
            ) : null}
          </ul>
        </div>,
      ];
    }

    return (
      <div className="txt-left lap-one-half lap-offset--one-quarter">
        <h2>{"Oops! We couldn't find anything."}</h2>
        <hr />
        <p>Maybe you could try…</p>
        <ul>{choices}</ul>
        {filters}
        <hr />
        <p>
          <strong>Search Tips:</strong>
        </p>
        <ul>
          <li>Check your search query for typos.</li>
          <li>
            Try putting exact phrases in quotes, e.g.{" "}
            <code>&quot;Game of Thrones&quot;</code> instead of just{" "}
            <code>Game of Thrones</code>
          </li>
          {this.props.query && this.props.query.split(/\s+/).length > 2 ? (
            <li>Try using fewer search terms to return more matches</li>
          ) : (
            ""
          )}
          <li>
            Try using different queries, e.g. <code>hoodie</code> instead of{" "}
            <code>jumper</code>
          </li>
          <li>
            Try alternate hyphenation, e.g. <code>Spider-man</code> instead of{" "}
            <code>Spiderman</code>
          </li>
          <li>
            <details>
              <summary>Try using advanced search features&hellip;</summary>
              <ul>
                <li>
                  Search by ISBN using e.g. <code>isbn:0123456789012</code>
                </li>
                <li>
                  Search by EAN or GTIN using e.g.{" "}
                  <code>ean:0123456789012</code> or{" "}
                  <code>gtin:0123456789012</code>
                </li>
                <li>
                  Search by Forbidden Planet catalogue number using e.g.{" "}
                  <code>cat_number:012345</code>
                </li>
                <li>
                  Search by category using e.g.{" "}
                  <code>category:comics-and-graphic-novels/comics</code>
                </li>
                <li>
                  Search for items within subscriptions using{" "}
                  <code>subscribable:true</code>
                </li>
                <li>
                  Search for signed items using <code>signed:true</code>
                </li>
                <li>
                  Search for items that are on sale using <code>sale:true</code>
                </li>
                <li>
                  Search for Forbidden Planet exclusives using{" "}
                  <code>exclusive:true</code>
                </li>
                <li>
                  Search Limited Edition items using{" "}
                  <code>limited_edition:true</code>
                </li>
              </ul>
            </details>
          </li>
        </ul>
      </div>
    );
  }
}
NoResults.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  showOOS: PropTypes.func,
  filters: ImmutablePropTypes.listOf(FilterType),
  category: ProductCategoryType,
  query: PropTypes.string,
};

export class ProductListing extends SimpleImmutableFluxComponent {
  constructor(props) {
    super(props);
    this.bulkPageCount = 3;
    this.state = _.extend(this.state || {}, { online: true, offline: false });
    this.handleScroll = _.debounce(this.handleScroll, 350);
    this.jumpToLastProduct = _.debounce(this.jumpToLastProduct);
    [
      "handleScroll",
      "handleFetchError",
      "fetchPrevious",
      "fetchNext",
      "clearFilters",
      "jumpToLastProduct",
    ].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  jumpToLastProduct() {
    try {
      const lastProduct = window.Alpine.store("recentlyViewed").products[0];
      const elem = document.getElementById(`product-${lastProduct.id}`);
      if (elem) {
        elem.scrollIntoView();
      }
    } catch (e) {
      return;
    }
  }

  componentDidMount() {
    super.componentDidMount();
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("wheel", this.handleScroll);
    this.flux
      .store("ProductListingStore")
      .addListener("fetch:error", this.handleFetchError);

    // Jump to last product.
    // We set a 1 second timeout in case we fail to catch load events
    const fallbackJump = setTimeout(this.jumpToLastProduct, 1000);
    // ... then make up to 7 jumps 200ms apart to keep the product in view.
    const loadHandler = _.debounce(
      _.before(8, () => {
        this.jumpToLastProduct();
        clearTimeout(fallbackJump);
      }),
      200,
      { leading: true },
    );
    this.wrapper.addEventListener("load", loadHandler);
    // If we have a last viewed product jump to it...
    this.jumpToLastProduct();
    // ... then cancel the load handler if someone loads a new page so we
    // don't move back up the page as the new page loads in.
    this.flux.store("ProductListingStore").once("fetch:start", () => {
      if (this.wrapper) {
        this.wrapper.removeEventListener("load", loadHandler);
      }
    });
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("wheel", this.handleScroll);
    this.flux
      .store("ProductListingStore")
      .removeListener("fetch:error", this.handleFetchError);
  }

  getStateFromFlux() {
    const { cart, loading, lastForceUpdate, updatingProductId } = this.flux
      .store("CartStore")
      .getState();
    return _.extend(
      this.flux.store("ProductListingStore").getState() || {},
      this.flux.store("NetworkStore").getState(),
      {
        cart: cart,
        cartLoading: loading,
        cartLastForceUpdate: lastForceUpdate,
        cartUpdatingProductId: updatingProductId,
      },
      _.pick(this.flux.store("AuthStore").getState() || {}, ["user"]),
    );
  }

  handleFetchError(err, resp) {
    if (!resp || !resp.statusCode) {
      window.Hooks.showToast({
        msg: "You appear to be offline…",
        level: "error",
        timeout: 30,
      });
    } else {
      window.Hooks.showToast({
        msg: "There was an error, please try again…",
        level: "error",
        timeout: 30,
      });
    }
  }

  handleScroll() {
    const vpHeight = windowHeight();
    const pageElems = document.querySelectorAll(
      '.product-list__list__item[id^="page-"]',
    );
    const aboveVP = Array.prototype.filter.call(
      pageElems,
      (elem) => elem.getBoundingClientRect().top < 0,
    );
    const inVP = Array.prototype.filter.call(pageElems, (elem) => {
      const top = elem.getBoundingClientRect().top;
      return top >= 0 && top <= vpHeight;
    });
    const belowVP = Array.prototype.filter.call(
      pageElems,
      (elem) => elem.getBoundingClientRect().top > vpHeight,
    );
    let pageElem;
    if (inVP.length) {
      pageElem = inVP.shift();
    } else if (aboveVP.length) {
      pageElem = aboveVP.pop();
    } else if (belowVP.length) {
      pageElem = belowVP.shift();
    }
    if (!pageElem) {
      return;
    }
    const pageMatch = pageElem.id.match(/page-(\d+)/);
    if (!pageMatch) {
      return;
    }
    const page = parseInt(pageMatch[1]);
    let newLocation;
    if (window.location.search.search(/page=\d+/) >= 0) {
      newLocation = window.location.search.replace(/page=\d+/, "page=" + page);
    } else {
      newLocation = window.location.search
        ? window.location.search + "&page=" + page
        : "?page=" + page;
    }
    const state = history.state || {};
    if (state && state.url) {
      if (state.url.search(/page=\d+/) >= 0) {
        state.url = state.url.replace(/page=\d+/, "page=" + page);
      } else {
        state.url =
          state.url.indexOf("?") >= 0
            ? state.url + "&page=" + page
            : state.url + "?page=" + page;
      }
    }
    state.page = page;
    if (newLocation !== window.location.search) {
      history.pushState(state, "", newLocation);
      this.flux.actions.catalog.productListing.savePage(page);
    }
  }

  fetchPrevious(ev, pages = 1) {
    if (ev !== undefined) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    const page = document.getElementById("page-" + this.state.startPage);
    if (page) {
      this.flux.store("ProductListingStore").once("parse:end", () => {
        jump(page, { offset: -200, duration: 100 });
      });
    }
    this.flux.actions.catalog.productListing.fetchPrevious(pages);
  }

  fetchNext(ev, pages = 1) {
    if (ev !== undefined) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    this.flux.actions.catalog.productListing.fetchNext(pages);
  }

  clearFilters(spec) {
    if (spec === undefined) {
      this.flux.actions.catalog.productListing.clearFilters();
      this.flux.actions.catalog.productListing.setCategory(null);
    } else {
      this.flux.actions.catalog.productListing.removeFilter(
        spec.name,
        spec.value,
      );
    }
  }

  renderNextNav() {
    if (!this.state.hasNext) {
      return "";
    }
    const content = [];
    const pagesLeft = this.state.pageCount - this.state.endPage;
    if (pagesLeft > 1) {
      const message =
        pagesLeft <= this.bulkPageCount
          ? this.state.startPage === 1
            ? `Load all (${this.state.count})`
            : "Load all"
          : `Load more × ${this.bulkPageCount}`;
      content.push(
        <button
          key="load-all-button"
          disabled={this.state.loading || this.state.offline}
          className="load-all button--brand brad--sm"
          onClick={_.partial(
            this.fetchNext,
            _,
            Math.min(pagesLeft, this.bulkPageCount),
          )}
        >
          {this.state.online ? message : "You appear to be offline…"}
        </button>,
      );
    }
    content.push(
      <button
        key="load-next-button"
        disabled={this.state.loading || this.state.offline}
        className="load-more button--brand brad--sm"
        onClick={this.fetchNext}
      >
        {this.state.online ? "Load more…" : "You appear to be offline…"}
      </button>,
    );
    return (
      <div className="pht pb mzt bg-white rw">
        <div className="load-more-navigation m-t pl pr pb pht bg-white">
          {content}
          <Waypoint
            onEnter={this.flux.actions.catalog.productListing.preloadNext}
            scrollableAncestor={window}
            bottomOffset="-50%"
          />
        </div>
      </div>
    );
  }

  renderPreviousNav() {
    if (!this.state.hasPrevious) {
      return "";
    }
    const content = [];
    const pagesLeft = this.state.startPage;
    if (pagesLeft > 1) {
      const message =
        pagesLeft <= this.bulkPageCount
          ? "Load all previous"
          : `Load previous × ${this.bulkPageCount}`;
      content.push(
        <button
          key="load-all-button"
          disabled={this.state.loading || this.state.offline}
          className="load-all button--brand brad--sm"
          onClick={_.partial(
            this.fetchPrevious,
            _,
            Math.min(pagesLeft, this.bulkPageCount),
          )}
        >
          {this.state.online ? message : "You appear to be offline…"}
        </button>,
      );
    }
    content.push(
      <button
        key="load-previous-button"
        disabled={this.state.loading || this.state.offline}
        className="load-more button--brand brad--sm"
        onClick={this.fetchPrevious}
      >
        {this.state.online ? "Load previous…" : "You appear to be offline…"}
      </button>,
    );
    return <div className="load-more-navigation ph rw">{content}</div>;
  }

  render() {
    const childProps = Immutable.Map({
      filters: this.state.filters.filter((filter) => {
        const data = filter.get("data");
        if (Immutable.Map.isMap(data)) {
          const values = data.values();
          return values.length && values.every((value) => new Boolean(value));
        }
        return Immutable.List.isList(data)
          ? data.size && data.every((i) => new Boolean(i))
          : !!data;
      }),
      sorts: this.state.sorts.filter((sort) => !_.isNull(sort.get("data"))),
      showCategory: !this.state.category,
    });
    const oosFilter = this.state.filters.find(
      (flt) => flt.get("name") == "show_out_of_stock",
    );
    let showOOS = null;
    if (oosFilter && !oosFilter.get("data")) {
      showOOS = (ev) => {
        ev.preventDefault();
        this.flux.actions.catalog.productListing.addFilter(
          "show_out_of_stock",
          "on",
        );
      };
    }
    return (
      <div
        className={this.state.listType}
        ref={(node) => (this.wrapper = node)}
      >
        {this.renderPreviousNav()}
        {this.state.products.size ? (
          <ProductList
            startPage={
              _.isFinite(this.state.startPage) ? this.state.startPage : 1
            }
            endPage={_.isFinite(this.state.endPage) ? this.state.endPage : 1}
            perPage={this.state.perPage}
            products={this.state.products}
            cart={this.state.cart}
            cartLoading={this.state.cartLoading}
            cartLastForceUpdate={this.state.cartLastForceUpdate}
            cartUpdatingProductId={this.state.cartUpdatingProductId}
            offline={this.state.offline}
            childProps={childProps}
            flux={this.flux}
            userAllowListingBuy={
              this.state.user ? this.state.user.get("allowListingBuy") : false
            }
          />
        ) : (
          <NoResults
            category={this.state.category}
            query={this.state.query}
            clearFilters={this.clearFilters}
            addFilter={this.flux.actions.catalog.productListing.addFilter}
            showOOS={showOOS}
            {...childProps.toObject()}
          />
        )}
        {this.renderNextNav()}
      </div>
    );
  }
}
ProductListing.watchedStores = [
  "ProductListingStore",
  "CartStore",
  "NetworkStore",
  "AuthStore",
];

export class ProductCartCount extends SimpleImmutableFluxComponent {
  getStateFromFlux() {
    return _.pick(this.flux.store("CartStore").getState() || {}, ["cart"]);
  }

  render() {
    const numberInCart = _.sum(
      this.state.cart
        .get("items")
        .filter((cartItem) =>
          _.endsWith(cartItem.get("product"), "/" + this.props.productId + "/"),
        )
        .map((cartItem) => cartItem.get("quantity") || 1)
        .toArray(),
    );

    if (numberInCart) {
      return <CartCountIcon numberInCart={numberInCart} />;
    }
    return null;
  }
}
ProductCartCount.propTypes = _.extend(
  {
    productId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
  },
  _.clone(FluxComponent.propTypes),
);
ProductCartCount.watchedStores = ["CartStore"];

export class ProductListingTypeSwitch extends FluxComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    if (!this.wrapper) {
      this.forceUpdate();
    }
  }

  onChange(ev) {
    if (ev.target.checked) {
      this.flux.actions.catalog.productListing.setListType("mini");
    } else {
      this.flux.actions.catalog.productListing.setListType("full");
    }
  }

  getStateFromFlux() {
    return _.pick(this.flux.store("ProductListingStore").getState() || {}, [
      "listType",
    ]);
  }

  render() {
    return (
      <div className="centered center" ref={(node) => (this.wrapper = node)}>
        <CheckboxInput
          defaultChecked={this.state.listType === "mini"}
          name="mini-listing"
          label="View less product info?"
          onChange={this.onChange}
          id="mini-listing"
        />
      </div>
    );
  }
}
ProductListingTypeSwitch.watchedStores = ["ProductListingStore"];
