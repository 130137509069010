import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

const WishListItemType = ImmutablePropTypes.contains({
  id: PropTypes.number,
  product: PropTypes.number,
  dateAdded: PropTypes.date,
  wishlistId: PropTypes.number,
  catNumber: PropTypes.string,
});

export const WishListType = ImmutablePropTypes.contains({
  id: PropTypes.number,
  name: PropTypes.string,
  public: PropTypes.bool,
  publicUrl: PropTypes.string,
  secret: PropTypes.bool,
  dateAdded: PropTypes.date,
  eventDate: PropTypes.date,
  christmasSpecial: PropTypes.bool,
  items: ImmutablePropTypes.listOf(WishListItemType),
});
