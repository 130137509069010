import _ from 'lodash';
import PropTypes from 'prop-types';
import * as React from 'react';

import SimpleImmutableComponent from 'prometheus/components/immutable.jsx';

import {WalletAddressType, WalletAddressesType} from './types.js';

class WalletAddressItem extends SimpleImmutableComponent {
  constructor(props) {
    super(props);
    ['updateTarget'].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  updateTarget(ev) {
    ev.stopPropagation();
    ev.preventDefault();
    const attrs = this.props.walletAddress.toObject();
    document.querySelectorAll(this.props.targetSelector).forEach((elem) => {
      _.toPairs(attrs).forEach((data) => {
        const [key, value] = data;
        const name = key.startsWith('line') ? key : _.snakeCase(key);
        const inputElem = elem.querySelector(`[name=${name}]`);
        if (inputElem) {
          inputElem.value = value;
        }
      });
    });
    this.props.closeDialogCallback(ev);
  }

  render() {
    return (
      <article className="pq owl-off dfbx dfbx--fdc dfbx--sb h--100 ">
        <ul className="vert-list txt-left owl-off vat">
          <li className="vert-list__item vat bold">
            {this.props.walletAddress.get('occupierName')}
          </li>
          <li className="vert-list__item vat ">
            {this.props.walletAddress.get('line1')}
          </li>
          <li className="vert-list__item vat ">
            {this.props.walletAddress.get('line2')}
          </li>
          <li className="vert-list__item vat ">
            {this.props.walletAddress.get('city')}
          </li>
          <li className="vert-list__item vat ">
            {this.props.walletAddress.get('postcode')}
          </li>
          <li className="vert-list__item vat ">
            {this.props.walletAddress.get('country')}
          </li>
        </ul>

        <button className="bg-brand mht brad" onClick={this.updateTarget}>
          {this.props.buttonText}
        </button>
      </article>
    );
  }
}
WalletAddressItem.propTypes = {
  walletAddress: WalletAddressType.isRequired,
  targetSelector: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  closeDialogCallback: PropTypes.func,
};
WalletAddressItem.defaultProps = {
  closeDialogCallback: _.noop,
};

export class WalletAddressSelector extends SimpleImmutableComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    ['handleBodyClick', 'close', 'toggle'].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  componentDidMount() {
    if (super.componentDidMount) {
      super.componentDidMount();
    }
    document.addEventListener('click', this.handleBodyClick);
  }

  componentWillUnmount() {
    if (super.componentWillUnmount) {
      super.componentWillUnmount();
    }
    document.removeEventListener('click', this.handleBodyClick);
  }

  handleBodyClick(ev) {
    if (
      document.body.contains(ev.target) &&
      this.container &&
      !this.container.contains(ev.target)
    ) {
      this.setState({
        open: false,
      });
    }
  }

  toggle(ev) {
    ev.preventDefault();
    this.setState({
      open: !this.state.open,
    });
  }

  close(ev) {
    ev.preventDefault();
    this.setState({
      open: false,
    });
  }

  render() {
    const children = this.props.walletAddresses
      .map((walletAddress) => {
        return (
          <li
            className="inline-list__item vat"
            key={_.uniqueId(walletAddress.get('id'))}
          >
            <WalletAddressItem
              walletAddress={walletAddress}
              targetSelector={this.props.targetSelector}
              closeDialogCallback={this.close}
              buttonText={this.props.buttonText}
            />
          </li>
        );
      })
      .toArray();
    const dialogId = _.uniqueId('select-wallet-address');
    return (
      <div className="dib" ref={(node) => (this.container = node)}>
        <button
          className="button--link"
          onClick={this.toggle}
          aria-controls={dialogId}
          aria-haspopup="dialog"
        >
          Wallet Addresses
        </button>
        <dialog id={dialogId} open={this.state.open} style={{zIndex: 1}}>
          <ul className="inline-list dfbx--aist g-site-width">{children}</ul>
          <button className="mht brad" onClick={this.close}>
            Cancel
          </button>
        </dialog>
      </div>
    );
  }
}

WalletAddressSelector.propTypes = {
  walletAddresses: WalletAddressesType.isRequired,
  targetSelector: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};
