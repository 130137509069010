import _ from 'lodash';
import * as React from 'react';
import classNames from 'classnames';

import {SimpleImmutableFluxComponent} from 'prometheus/flux/components.jsx';
import {InputGroup} from 'prometheus/forms/components/inputs/input.jsx';
import {RadioInput} from 'prometheus/forms/components/inputs/input-radio.jsx';

export class SortWidget extends SimpleImmutableFluxComponent {
  constructor(props) {
    super(props);
    this.state = _.extend(this.state || {}, {
      open: true,
      online: true,
      offline: false,
    });
    ['toggle', 'resetOpenState'].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  getStateFromFlux() {
    return _.extend(
      {activeMedia: this.flux.store('MediaStore').getState().active},
      this.flux.store('NetworkStore').getState(),
      _.pick(this.flux.store('ProductListingStore').getState(), [
        'sorts',
        'loading',
        'count',
      ])
    );
  }

  componentDidMount() {
    super.componentDidMount();
    this.resetOpenState();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  resetOpenState() {
    this.setState({
      open: this.state.activeMedia && this.state.activeMedia.get('lap'),
    });
  }

  toggle(ev) {
    if (ev) {
      ev.preventDefault();
    }
    this.setState({open: !this.state.open});
  }

  onChange(name, value) {
    if (this.state.offline) {
      window.Hooks.showToast({
        msg: 'You appear to be offline…',
        level: 'error',
        timeout: 30,
      });
      return;
    }
    this.flux.actions.catalog.productListing.setSort(name, value);
  }

  render() {
    if (this.state.count <= 2) {
      return null;
    }
    const choices = this.state.sorts.flatMap((sort) => {
      const data = sort.get('data');
      const name = sort.get('name');
      return sort.get('choices').map((choice) => {
        const className = classNames('nobrdr', {
          bold: choice.get(0) === data,
        });
        return (
          <RadioInput
            key={
              'sort-' +
              _.words(name.toLowerCase()).join('-') +
              '-' +
              choice.get(0)
            }
            id={
              'sort-' +
              _.words(name.toLowerCase()).join('-') +
              '-' +
              choice.get(0)
            }
            name={name}
            defaultChecked={choice.get(0) === data}
            disabled={this.state.loading}
            onChange={this.onChange.bind(this, name, choice.get(0))}
            className={className}
            value={choice.get(0)}
            label={choice.get(1)}
          />
        );
      });
    });
    const selected = this.state.sorts.flatMap((sort) => {
      const data = sort.get('data');
      const defaultChoice = sort.get('default');
      return sort.get('choices').filter((choice) => {
        return choice.get(0) === (data || defaultChoice);
      });
    });
    return (
      <details
        open={this.state.open}
        ref={(node) => (this.details = node)}
        className="owl-off details_group"
      >
        <summary className="one-whole">
          <span>Sort by</span>{' '}
          <span className="mql mzt t-thin t-small clr-neutral">
            {selected.map((choice) => choice.get(1)).join(', ')}
          </span>
        </summary>
        <InputGroup
          className="owl-off"
          labelClassName="visually-hidden"
          label="Sort by"
        >
          {choices}
        </InputGroup>
      </details>
    );
  }
}
SortWidget.watchedStores = [
  'MediaStore',
  'ProductListingStore',
  'NetworkStore',
];
