import _ from "lodash";
import Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import jump from "jump.js";
import LazyLoad from "react-lazy-load";
import PropTypes from "prop-types";
import * as React from "react";
import classNames from "classnames";
import htmlToReact from "html-to-react";

import SimpleImmutableComponent from "prometheus/components/immutable.jsx";
import { SimpleImmutableFluxComponent } from "prometheus/flux/components.jsx";
import { Slide, Carousel } from "prometheus/carousel/components.jsx";

import { ProductType, ProductImageType } from "../types.js";
import { PlaceholderFigure } from "../../components.jsx";
import Thumbor from "../../thumbor.js";

function getImageCombos(product) {
  const imageCombosBase = product
    .get("images")
    .map((image) => Immutable.List([image]));

  const mainStandardImage = product
    .get("images", Immutable.List())
    .filter((image) => image.get("imageType") !== "mini_print")
    .get(0);
  const miniPrintImages = product
    .get("images", Immutable.List())
    .filter((image) => image.get("imageType") === "mini_print");
  var imageCombos = Immutable.List();
  if (miniPrintImages.size > 0 && mainStandardImage) {
    imageCombos = Immutable.List([
      miniPrintImages.insert(0, mainStandardImage),
    ]);
  }
  return imageCombos.concat(imageCombosBase);
}

export class ProductImage extends SimpleImmutableComponent {
  constructor(props) {
    super(props);
    this.state = { loaded: false };
    ["handleLoad"].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  handleLoad() {
    this.setState({ loaded: true });
  }

  getFlourishFlags() {
    let flourishBookmark = false;
    let flourishDisc = false;
    let flourishMount = false;
    let flourishStaples = false;
    if (this.props.flourishes) {
      const productCategories = this.props.product.get(
        "productCategories",
        Immutable.List([]),
      );
      flourishBookmark = productCategories.some((category) => {
        return category.get("flourishBookmark");
      });
      flourishDisc = productCategories.some((category) => {
        return category.get("flourishDisc");
      });
      flourishMount = productCategories.some((category) => {
        return category.get("flourishMount");
      });
      flourishStaples = productCategories.some((category) => {
        return category.get("flourishStaples");
      });
    }
    const suppress = this.props.image.get("imageType") === "pack_shot";
    return {
      bookmark: flourishBookmark && !suppress,
      disc: flourishDisc && !suppress,
      mount: flourishMount && !suppress,
      staples: flourishStaples && !suppress,
    };
  }

  getImageFlags() {
    const productCategories = this.props.product.get(
      "productCategories",
      Immutable.List([]),
    );
    const imageShadow01 = productCategories.some((category) => {
      return category.get("imageShadow01");
    });
    const imageShadow02 = productCategories.some((category) => {
      return category.get("imageShadow02");
    });
    const imageShadow03 = productCategories.some((category) => {
      return category.get("imageShadow03");
    });
    const imageTrim = productCategories.some((category) => {
      return category.get("imageTrim");
    });
    const suppress = this.props.image.get("imageType") === "pack_shot";
    return {
      shadow01: imageShadow01 && !suppress,
      shadow02: imageShadow02 && !suppress,
      shadow03: imageShadow03 && !suppress,
      trim: imageTrim && !suppress,
    };
  }

  render() {
    const sizes = this.props.sizes.toObject();
    const fallbackSize = this.props.fallbackSize;
    const image = this.props.image;

    // Dimension calculations
    let width = image.get("width");
    let height = image.get("height");
    if (this.state.loaded && this.imgElement) {
      // If we've loaded our image we can pull the actual loaded image size
      // from it rather than using the (potentially wrong if thumbor has
      // trimmed it) size from the image on disk
      if (this.imgElement.naturalWidth) {
        width = this.imgElement.naturalWidth;
      }
      if (this.imgElement.naturalHeight) {
        height = this.imgElement.naturalHeight;
      }
    }

    const portrait = height > width;
    const portraitDouble = height > width * 2;
    const landscape = width > height;
    const landscapeDouble = width > height * 2;
    const aspectRatio = width / height;
    const nearlySquare = 0.95 < aspectRatio && aspectRatio < 1.05;
    const flourishFlags = this.getFlourishFlags();
    const imageFlags = this.getImageFlags();

    // Image
    const url = image.get("image").replace(/ /g, "%20");
    let thumbor = new Thumbor()
      .setup()
      .setImagePath(url)
      .filter("max_age(604800)");
    let thumborWebp = new Thumbor()
      .setup()
      .setImagePath(url)
      .filter("max_age(604800)")
      .filter("format(webp)");
    if (imageFlags.trim) {
      thumbor.trim();
      thumborWebp.trim();
    } else if (!this.props.noFill) {
      thumbor.filter("fill(white)");
      thumborWebp.filter("fill(white)");
    }
    const sources = [];
    for (const mq in sizes) {
      thumbor.fitIn(
        Math.min(this.props.square ? sizes[mq] : width, sizes[mq]),
        Math.min(this.props.square ? sizes[mq] : height, sizes[mq]),
      );
      thumborWebp.fitIn(
        Math.min(this.props.square ? sizes[mq] : width, sizes[mq]),
        Math.min(this.props.square ? sizes[mq] : height, sizes[mq]),
      );
      sources.push(
        <source
          key={mq}
          type="image/webp"
          srcSet={thumborWebp.buildUrl()}
          media={mq}
        />,
      );
      sources.push(
        <source key={mq + "-webp"} srcSet={thumbor.buildUrl()} media={mq} />,
      );
    }
    thumbor.fitIn(fallbackSize, fallbackSize);

    // Classes / Style
    let aspectClassName = "g-ratio--nrm";
    if (nearlySquare) {
      aspectClassName = "g-ratio--nrm";
    } else if (portraitDouble) {
      aspectClassName = "g-ratio--h2gtw";
    } else if (landscapeDouble) {
      aspectClassName = "g-ratio--w2gth";
    } else if (portrait) {
      aspectClassName = "g-ratio--hgtw";
    } else if (landscape) {
      aspectClassName = "g-ratio--wgth";
    }
    const pictureClassName = classNames(
      "product-listing-image",
      "c block",
      "ra-fade ra-fade--enter",
      this.state.loaded ? aspectClassName : null,
      this.props.extraPictureClassName,
      {
        "product-listing-image--loaded": this.state.loaded,
        "product-listing-image--loading": !this.state.loaded,
        "has-bookmark":
          this.props.flourishes && flourishFlags.bookmark && portrait,
        "has-disc": this.props.flourishes && flourishFlags.disc,
        "has-mount": this.props.flourishes && flourishFlags.mount,
        "staples relative": this.props.flourishes && flourishFlags.staples,
        "ra-fade--enter-active": this.state.loaded,
      },
    );
    const defaultPictureStyle = {
      maxWidth: `${Math.min(aspectRatio * 100, 100)}%`,
    };
    let pictureStyle = _.defaults(
      this.props.pictureStyle ? this.props.pictureStyle.toObject() : {},
      defaultPictureStyle,
    );

    const imgClassName = classNames("img--r mzt", {
      "zshd-01": imageFlags.shadow01 && this.state.loaded,
      "zshd-02": imageFlags.shadow02 && this.state.loaded,
      "zshd-03": imageFlags.shadow03 && this.state.loaded,
    });

    let picture = (
      <picture
        style={pictureStyle}
        className={pictureClassName}
        onLoad={this.handleLoad}
      >
        {sources}
        <img
          loading="lazy"
          style={this.props.imgStyle.toObject()}
          alt={`[${this.props.product.get("title")} (Product Image)]`}
          className={imgClassName}
          src={thumbor.buildUrl()}
          ref={(node) => (this.imgElement = node)}
        />
      </picture>
    );
    if (this.props.lazyLoad) {
      picture = (
        <LazyLoad
          debounce={false}
          throttle={200}
          offset={this.props.lazyLoadOffset}
        >
          {picture}
        </LazyLoad>
      );
    }
    return picture;
  }
}
ProductImage.propTypes = {
  fallbackSize: PropTypes.number,
  flourishes: PropTypes.bool,
  lazyLoad: PropTypes.bool,
  lazyLoadOffset: PropTypes.number,
  extraPictureClassName: PropTypes.string,
  image: ProductImageType.isRequired,
  pictureStyle: ImmutablePropTypes.map,
  product: ProductType.isRequired,
  sizes: ImmutablePropTypes.map,
  square: PropTypes.bool,
  noFill: PropTypes.bool,
  imgStyle: ImmutablePropTypes.map,
};
ProductImage.defaultProps = {
  fallbackSize: 300,
  flourishes: false,
  lazyLoad: true,
  lazyLoadOffset: 1000,
  sizes: Immutable.Map({
    "(min-width: 64em)": 600,
    "(min-width: 48em)": 400,
    "(min-width: 40em)": 400,
    "(max-width: 39em) and (orientation: landscape)": 400,
    "(max-width: 39em)": 300,
  }),
  square: false,
  noFill: false,
  imgStyle: Immutable.Map({
    height: "auto",
    maxWidth: "100%",
    width: "100%",
  }),
};

export class ComposedProductImage extends SimpleImmutableComponent {
  render() {
    const product = this.props.product;
    const hasImages = product.get("images", Immutable.List()).size > 0;
    const firstImage = product.get("images", Immutable.List()).get(0);
    const miniPrintImage = product
      .get("images", Immutable.List())
      .filter((image) => image.get("imageType") === "mini_print")
      .get(0);
    const nonMiniPrintImage = product
      .get("images", Immutable.List())
      .filter((image) => image.get("imageType") !== "mini_print")
      .get(0);
    const imagery = [];
    if (!hasImages) {
      imagery.push(
        <figure key="no-image" className="placeholder-image mz centered">
          <img
            loading="lazy"
            className="placeholder-product-image img--r"
            src={product.get("primaryImage", Immutable.Map()).get("url", "")}
            alt="Awaiting product image"
          />
        </figure>,
      );
    } else if (miniPrintImage && nonMiniPrintImage) {
      imagery.push(
        <ProductImage
          key="mini-print"
          product={product}
          image={miniPrintImage}
          extraPictureClassName="placeholder-image--mini-print mt"
          pictureStyle={Immutable.Map({
            maxWidth: miniPrintImage.get("aspectRatio") > 1 ? "62%" : "42%",
            maxHeight: "100%",
            zIndex: "2",
          })}
          flourishes={this.props.imageFlourishes}
          imgStyle={Immutable.Map()}
          noFill={true}
          sizes={this.props.sizes}
          fallbackSize={this.props.fallbackSize}
          lazyLoad={this.props.lazyLoad}
        />,
      );
      imagery.push(
        <PlaceholderFigure
          key="non-mini-print"
          className="placeholder-image mz"
        >
          <ProductImage
            product={product}
            image={nonMiniPrintImage}
            extraPictureClassName="mt"
            pictureStyle={Immutable.Map({
              maxHeight: "100%",
            })}
            flourishes={this.props.imageFlourishes}
            imgStyle={Immutable.Map()}
            noFill={true}
            sizes={this.props.sizes}
            fallbackSize={this.props.fallbackSize}
            lazyLoad={this.props.lazyLoad}
          />
        </PlaceholderFigure>,
      );
    } else {
      imagery.push(
        <PlaceholderFigure key="first-image" className="placeholder-image mz">
          <ProductImage
            product={product}
            image={firstImage}
            extraPictureClassName="mt placeholder-image--first-image"
            flourishes={this.props.imageFlourishes}
            imgStyle={Immutable.Map()}
            sizes={this.props.sizes}
            fallbackSize={this.props.fallbackSize}
            lazyLoad={this.props.lazyLoad}
            noFill={this.props.noFill}
          />
        </PlaceholderFigure>,
      );
    }
    return <React.Fragment>{imagery}</React.Fragment>;
  }
}
ComposedProductImage.propTypes = {
  product: ProductType.isRequired,
  sizes: ImmutablePropTypes.map,
  fallbackSize: PropTypes.number,
  lazyLoad: PropTypes.bool,
  noFill: PropTypes.bool,
};
ComposedProductImage.defaultProps = {
  sizes: ProductImage.defaultProps.sizes,
  fallbackSize: ProductImage.defaultProps.fallbackSize,
  lazyLoad: ProductImage.defaultProps.lazyLoad,
  noFill: ProductImage.defaultProps.noFill,
};

export class ProductImageModal extends SimpleImmutableFluxComponent {
  constructor(props) {
    super(props);
    this.getImages = this.getImages.bind(this);
    this.jumpToIndex = _.debounce(this.jumpToIndex).bind(this);
    this.close = this.close.bind(this);
    this.state = {
      open: false,
      index: 0,
    };
  }

  getStateFromFlux() {
    const storeState = this.flux.store("ProductStore").getState();
    return {
      open: storeState.enlargedImages,
      index: storeState.enlargedImageIndex,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    document.body.setAttribute("data-enlarged-images", this.state.open);
  }

  componentDidUpdate() {
    document.body.setAttribute("data-enlarged-images", this.state.open);
    if (this.state.open) {
      this.jumpToIndex();
    }
  }

  jumpToIndex() {
    if (_.isNumber(this.state.index)) {
      const imageCombosSize = getImageCombos(this.props.product).size;
      const imagesSize = this.props.product.get("images").size;
      const index = this.props.useCombos
        ? this.state.index
        : Math.max(this.state.index - (imageCombosSize - imagesSize), 0);
      if (this.images[index]) {
        jump(this.images[index], { duration: 0 });
      }
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    document.body.removeAttribute("data-enlarged-images");
  }

  getImages() {
    this.images = [];
    const imageCombos = getImageCombos(this.props.product);

    const figureFromImage = (image, idx) => {
      const figureStyle =
        idx > 0 && this.props.useCombos
          ? {
              position: "absolute",
              bottom: 0,
              left: "50%",
              maxWidth: "60%",
            }
          : {};
      const figureClassName = classNames("relative mz", {
        "has-mini-print": image.get("imageType") === "mini_print",
      });
      return (
        <PlaceholderFigure
          key={`subimage-${idx}`}
          className={figureClassName}
          style={figureStyle}
        >
          <ProductImage
            product={this.props.product}
            image={image}
            lazyLoad={true}
            fallbackSize={600}
            sizes={Immutable.Map({
              "(min-width: 64em)": 1024,
              "(min-width: 48em)": 900,
              "(min-width: 40em)": 768,
              "(max-width: 39em) and (orientation: landscape)": 768,
              "(max-width: 39em)": 600,
            })}
          />
        </PlaceholderFigure>
      );
    };

    let items = [];
    if (this.props.useCombos) {
      items = imageCombos.map((imageCombo) => imageCombo.map(figureFromImage));
    } else {
      items = this.props.product.get("images").map(figureFromImage);
    }

    return items.map((item, idx) => (
      <li
        key={`image-${idx}`}
        className="relative vert-list__item"
        ref={(node) => this.images.push(node)}
      >
        {item}
      </li>
    ));
  }

  close(ev) {
    ev.preventDefault();
    jump("main", { duration: 0 });
    this.flux.actions.catalog.product.hideEnlargedImages();
  }

  render() {
    if (!this.state.open) {
      return null;
    }
    return (
      <div
        ref={(node) => (this.container = node)}
        className="product-detail-large-images"
        onClick={this.close}
      >
        <div className="product-detail-large-images__close pf pf--tr">
          <button onClick={this.close}>
            <i className="icon icon-62 icon--med" />
            Close
          </button>
        </div>
        <ul className="striplist vert-list">{this.getImages()}</ul>
      </div>
    );
  }
}
ProductImageModal.watchedStores = ["ProductStore"];
ProductImageModal.propTypes = _.extend(
  {
    product: ProductType.isRequired,
    useCombos: PropTypes.bool,
  },
  SimpleImmutableFluxComponent.propTypes,
);
ProductImageModal.defaultProps = _.extend(
  {
    useCombos: false,
  },
  SimpleImmutableFluxComponent.defaultProps || {},
);

export class ProductImageCarousel extends SimpleImmutableFluxComponent {
  constructor(props) {
    super(props);
    this.getMultimediaSlides = _.memoize(this.getMultimediaSlides);
    this.getImagesSlides = _.memoize(this.getImageSlides);
    ["getMultimediaSlides", "getImagesSlides", "showEnlarged"].forEach(
      (method) => {
        this[method] = this[method].bind(this);
      },
    );
  }

  componentDidMount() {
    super.componentDidMount();
    if (this.carousel && this.carousel.refs.titles) {
      setTimeout(
        this.carousel.refs.titles.calculateControlState.bind(
          this.carousel.refs.titles,
        ),
        100,
      );
    }
  }

  getMultimediaSlides() {
    const parser = new htmlToReact.Parser();
    return this.props.product.get("multimedia").map((media, idx) => {
      const thumbnail = (
        <figure className="video-thumb">
          <img src={media.get("thumbnail")} width={52} />
        </figure>
      );
      return (
        <Slide key={`media-${idx}`} className="relative" thumbnail={thumbnail}>
          <div>{parser.parse(media.get("rendered"))}</div>
        </Slide>
      );
    });
  }

  getImageSlides() {
    const imageCombos = getImageCombos(this.props.product);
    return imageCombos.map((imageCombo, idx) => {
      const thumbnailImages = imageCombo.map((image, idx) => {
        const thumbnailUrl =
          idx == 0
            ? new Thumbor()
                .setup()
                .setImagePath(image.get("image").replace(/ /g, "%20"))
                .fitIn(50, 50)
                .filter("fill(white)")
                .buildUrl()
            : new Thumbor()
                .setup()
                .setImagePath(image.get("image").replace(/ /g, "%20"))
                .fitIn(50, 50)
                .buildUrl();

        const imageStyle =
          idx > 0
            ? {
                border: "0px",
                position: "absolute",
                bottom: 0,
                right: 0,
                maxWidth: "60%",
              }
            : {};
        const figureClassName = classNames("mz", {
          "has-mini-print": image.get("imageType") === "mini_print",
        });
        const thumbnail = (
          <PlaceholderFigure
            className={figureClassName}
            key={`subimage-${idx}`}
          >
            <img src={thumbnailUrl} width={50} style={imageStyle} />
          </PlaceholderFigure>
        );
        return thumbnail;
      });

      const showEnlarged = (ev) => {
        ev.preventDefault();
        this.flux.actions.catalog.product.showEnlargedImages(idx);
      };
      const slideImageContent = imageCombo.map((image, idx) => {
        const figureStyle =
          idx > 0
            ? {
                position: "absolute",
                bottom: 0,
                right: 0,
                maxWidth: "60%",
              }
            : {};
        const figureClassName = classNames({
          "has-mini-print": image.get("imageType") === "mini_print",
        });
        return (
          <PlaceholderFigure
            className={figureClassName}
            key={`subimage-${idx}`}
            style={figureStyle}
          >
            <ProductImage
              product={this.props.product}
              image={image}
              lazyLoad={false}
              noFill={true}
              pictureStyle={Immutable.Map({ maxWidth: "100%" })}
            />
          </PlaceholderFigure>
        );
      });
      return (
        <Slide
          key={`image-${idx}`}
          className="relative"
          thumbnail={<div>{thumbnailImages}</div>}
        >
          <div onClick={showEnlarged}>{slideImageContent}</div>
        </Slide>
      );
    });
  }

  showEnlarged(ev) {
    ev.preventDefault();
    let index = 0;
    if (this.carousel && this.carousel.state) {
      index = this.carousel.state.currentSlide || 0;
    }
    this.flux.actions.catalog.product.showEnlargedImages(index);
  }

  render() {
    const slides = this.getImagesSlides().concat(this.getMultimediaSlides());
    return (
      <div className="relative one-whole">
        <Carousel
          ref={(node) => (this.carousel = node)}
          slideTitles={slides.count() > 1}
          slideTitlesUseSlider={false}
          slideTitlesClassName="row row--gutter row--gutter-half"
          nextPrevControls={slides.count() > 1}
          horizontalNav={true}
          rotate={false}
          flux={this.flux}
          slideChooserControls={false}
          className="striplist cf pz mz js-carousel--product-detail-images relative"
        >
          {slides.toArray()}
        </Carousel>
        <button
          className="btn-show-enlarged owloff"
          onClick={this.showEnlarged}
        >
          <i className="icon icon-61" />
          Enlarge
        </button>
      </div>
    );
  }
}
ProductImageCarousel.propTypes = _.extend(
  { product: ProductType.isRequired },
  SimpleImmutableFluxComponent.propTypes,
);
