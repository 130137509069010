import {Model, types} from '../models/base.js';
import {ModelArray} from '../models/arrays.js';
import {pagedRestArrayMixin} from '../models/rest.js';

const itemImageShape = types.shape({
  id: types.int,
  imageUrl: types.string,
  primary: types.bool,
  internal: types.bool,
  url: types.string,
});

export class Item extends Model.withSpec({
  id: types.int,
  title: types.string,
  shortDescription: types.string,
  description: types.string,
  link: types.string,
  sticky: types.bool,
  hero: types.bool,
  itemId: types.string,
  dateCreated: types.date,
  dateModified: types.date,
  date: types.date,
  extra: types.object,
  primaryImage: itemImageShape,
  images: types.arrayOf(itemImageShape),
  tags: types.arrayOf(
    types.shape({
      name: types.string,
      namespace: types.string,
      slug: types.string,
    })
  ),
  itemType: types.shape({
    id: types.int,
    name: types.string,
    slug: types.string,
  }),
}) {}

export class ItemArray extends pagedRestArrayMixin(ModelArray.ofModel(Item)) {}
