import _ from 'lodash';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import * as React from 'react';
import classNames from 'classnames';

import SimpleImmutableComponent from 'prometheus/components/immutable.jsx';
import {SimpleImmutableFluxComponent} from 'prometheus/flux/components.jsx';
import {Form} from 'prometheus/forms/components/form.jsx';
import {Fieldset} from 'prometheus/forms/components/fieldset.jsx';
import {Input} from 'prometheus/forms/components/inputs/input.jsx';

import {Loading} from '../components.jsx';

export class LoginForm extends SimpleImmutableFluxComponent {
  constructor(props) {
    super(props);
    this.state = _.extend(this.state || {}, {
      online: true,
      error: null,
      loading: false,
      loggedOut: false,
    });
    this.form = null;
    this.email = null;
    this.password = null;
    [
      'handleLogout',
      'handleLogoutError',
      'handleLogoutSuccess',
      'handleLogin',
      'handleLoginError',
      'handleLoginSuccess',
      'reset',
    ].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  getStateFromFlux() {
    return _.extend(
      {},
      this.flux.store('AuthStore').getState(),
      this.flux.store('NetworkStore').getState()
    );
  }

  componentDidMount() {
    super.componentDidMount();
    const authStore = this.flux.store('AuthStore');
    authStore.addListener('login:success', this.handleLoginSuccess);
    authStore.addListener('login:error', this.handleLoginError);
    authStore.addListener('logout:success', this.handleLogoutSuccess);
    authStore.addListener('logout:error', this.handleLogoutError);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const authStore = this.flux.store('AuthStore');
    authStore.removeListener('login:success', this.handleLoginSuccess);
    authStore.removeListener('login:error', this.handleLoginError);
    authStore.removeListener('logout:success', this.handleLogoutSuccess);
    authStore.removeListener('logout:error', this.handleLogoutError);
  }

  handleLogout(ev) {
    ev.stopPropagation();
    ev.preventDefault();
    this.setState({
      error: null,
    });
    this.flux.actions.auth.logout();
    this.props.handleLogout();
  }

  handleLogoutError() {
    this.setState({
      error: 'Sorry, there was a problem logging you out.',
      loggedOut: false,
    });
    this.props.handleLogoutError();
  }

  handleLogoutSuccess() {
    this.setState({
      error: null,
      loggedOut: true,
    });
    this.props.handleLogoutSuccess();
  }

  handleLogin() {
    this.setState({
      error: null,
      loggedOut: false,
    });
    this.flux.actions.auth.login({
      email: this.email.state.value,
      password: this.password.state.value,
    });
    this.props.handleLogin();
  }

  handleLoginError() {
    this.setState({
      error: 'Sorry, there was a problem logging you in with those details.',
      loggedOut: false,
    });
    this.props.handleLoginError();
  }

  handleLoginSuccess() {
    this.setState({
      error: null,
      loggedOut: false,
    });
    this.props.handleLoginSuccess();
  }

  reset(ev) {
    if (ev) {
      ev.stopPropagation();
      ev.preventDefault();
    }
    this.setState({
      loggedOut: false,
      error: null,
    });
  }

  renderLogoutMessage() {
    const className = classNames(
      'login-form--logged-out',
      this.props.className
    );
    return (
      <div className={className}>
        You have been logged out.
        <button className="mql button--link" onClick={this.reset}>
          Login again
        </button>
      </div>
    );
  }

  renderLoggedInMessage() {
    const className = classNames('login-form--welcome', this.props.className);
    const name = this.state.user.get('firstName')
      ? this.state.user.get('firstName')
      : this.state.user.get('displayName')
      ? this.state.user.get('displayName')
      : this.state.user.get('email');
    return (
      <div className={className}>
        {this.state.error ? (
          <div className="bg-error">
            <p>{this.state.error}</p>
          </div>
        ) : null}
        <p>
          Hi,&nbsp;
          {_.isUndefined(this.props.nameLimit) ||
          name.length < this.props.nameLimit + 1 ? (
            name
          ) : (
            <abbr title={name}>
              {name.slice(0, this.props.nameLimit - 1) + '…'}
            </abbr>
          )}
        </p>
        <p className="t-small">
          Not you?
          <button className="mql button--link" onClick={this.handleLogout}>
            Logout
          </button>
        </p>
      </div>
    );
  }

  renderLoginForm() {
    const className = classNames('login-form--form', this.props.className);
    if (this.state.loginBlocked) {
      return (
        <div className={className}>
          <div className="bg-warning ph">
            <p>
              <strong>Too many failed login attempts</strong>
            </p>
            <p>
              Sorry, you made too many failed login attempts so we&apos;ve had
              to block you for a while. Please try again after an hour.
            </p>
          </div>
        </div>
      );
    }
    return (
      <div className={className}>
        {this.state.error ? (
          <div className="bg-error ph">
            <p>{this.state.error}</p>
          </div>
        ) : null}
        <Form
          ref={(node) => (this.form = node)}
          action="/account/login/"
          method="post"
          onSubmit={this.handleLogin}
        >
          <Input
            ref={(node) => (this.email = node)}
            attrs={Immutable.Map({
              type: 'email',
              placeholder: 'leia@alderaan.com',
            })}
            name="email"
            label="Email address"
            invalidMessage="Please enter a valid email address"
          />
          <Input
            ref={(node) => (this.password = node)}
            attrs={Immutable.Map({type: 'password'})}
            name="password"
            label="Password"
          />
          <button
            className="bg-brand button--lg"
            type="submit"
            disabled={this.state.offline}
          >
            {this.state.online ? 'Login' : 'You appear to be offline…'}
          </button>
          <div>
            <p>
              <a href="/account/login/">Create an account?</a>
            </p>
            <p>
              <a href="/account/password/reset/">Forgotten your password?</a>
            </p>
          </div>
        </Form>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      const className = classNames('login-form--loading', this.props.className);
      return <Loading aria-busy={true} className={className} />;
    }
    if (this.state.loggedOut && this.props.displayLoggedOut) {
      return this.renderLogoutMessage();
    }
    if (this.state.user.get('isAuthenticatedOrIsGuest')) {
      return this.renderLoggedInMessage();
    }
    return this.renderLoginForm();
  }
}
LoginForm.propTypes = _.extend(
  _.clone(SimpleImmutableFluxComponent.propTypes),
  {
    className: PropTypes.string,
    displayLoggedOut: PropTypes.bool,
    handleLogin: PropTypes.func,
    handleLoginError: PropTypes.func,
    handleLoginSuccess: PropTypes.func,
    handleLogout: PropTypes.func,
    handleLogoutError: PropTypes.func,
    handleLogoutSuccess: PropTypes.func,
    nameLimit: PropTypes.number,
  }
);
LoginForm.defaultProps = {
  displayLoggedOut: true,
  handleLogin: _.identity,
  handleLoginError: _.identity,
  handleLoginSuccess: _.identity,
  handleLogout: _.identity,
  handleLogoutError: _.identity,
  handleLogoutSuccess: _.identity,
};
LoginForm.contextTypes = _.clone(SimpleImmutableFluxComponent.contextTypes);
LoginForm.childContextTypes = _.clone(
  SimpleImmutableFluxComponent.childContextTypes
);
LoginForm.watchedStores = ['AuthStore', 'NetworkStore'];

class SetPasswordFieldset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
    };
    [
      'handlePasswordChange',
      'handleConfirmPasswordChange',
      'updatePassword',
    ].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(
      _.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState)
    );
  }

  handlePasswordChange(ev) {
    this.setState({password: ev.target.value}, this.updatePassword);
  }

  handleConfirmPasswordChange(ev) {
    this.setState({confirmPassword: ev.target.value}, this.updatePassword);
  }

  updatePassword() {
    if (this.state.password === this.state.confirmPassword) {
      this.props.onPasswordChange(this.state.password);
    } else {
      this.props.onPasswordChange('');
    }
  }

  render() {
    const passwordMatchMessage =
      this.state.password === this.state.confirmPassword ? null : (
        <p className="bg-error">the passwords do not match.</p>
      );

    return (
      <div>
        <Input
          key="password"
          name="password"
          label="Password"
          attrs={Immutable.Map({type: 'password'})}
          value={this.state.password}
          onChange={this.handlePasswordChange}
        />
        <Input
          key="confirm-password"
          name="confirm_password"
          label="Confirm Password"
          attrs={Immutable.Map({type: 'password'})}
          value={this.state.confirmPassword}
          onChange={this.handleConfirmPasswordChange}
        />
        {passwordMatchMessage}
      </div>
    );
  }
}
SetPasswordFieldset.propTypes = {
  onPasswordChange: PropTypes.func,
};
SetPasswordFieldset.defaultProps = {
  onPasswordChange: _.noop,
};

class LoginEmailForm extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props, nextProps);
  }

  render() {
    return (
      <Form
        action="/account/login/"
        method="post"
        onSubmit={this.props.onFormSubmit}
      >
        <Input
          attrs={Immutable.Map({
            autoFocus: true,
            placeholder: 'leia@alderaan.com',
            type: 'email',
          })}
          name="email"
          label="Email address"
          invalidMessage="Please enter a valid email address"
          value={this.props.email}
          onChange={this.props.onEmailInputChange}
        />
        <button
          type="submit"
          className="bg-brand button--lg"
          disabled={!this.props.email}
        >
          Next
        </button>
      </Form>
    );
  }
}

LoginEmailForm.propTypes = {
  email: PropTypes.string,
  onFormSubmit: PropTypes.func,
  onEmailInputChange: PropTypes.func,
};
LoginEmailForm.defaultProps = {
  onFormSubmit: _.noop,
  onEmailInputChange: _.noop,
};

class LoginPasswordForm extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props, nextProps);
  }

  render() {
    return (
      <Form
        className="owlh"
        action="/account/login/authenticate/"
        method="post"
        onSubmit={this.props.onFormSubmit}
      >
        <Input
          attrs={Immutable.Map({
            autoFocus: true,
            type: 'password',
          })}
          name="password"
          label="Password"
          value={this.props.password}
          onChange={this.props.onPasswordInputChange}
        />
        <ul className="inline-list">
          <li>
            <button
              type="submit"
              className="button--lg bg-brand"
              disabled={!this.props.password}
            >
              Login
            </button>
          </li>
          <li>
            <button type="button" onClick={this.props.onUndoClick}>
              Back
            </button>
          </li>
        </ul>
        <div className="mb">
          <p>
            <a href="/account/password/reset/">Forgotten your password?</a>
          </p>
        </div>
      </Form>
    );
  }
}

LoginPasswordForm.propTypes = {
  password: PropTypes.string,
  onFormSubmit: PropTypes.func,
  onPasswordInputChange: PropTypes.func,
  onUndoClick: PropTypes.func,
};
LoginPasswordForm.defaultProps = {
  onFormSubmit: _.noop,
  onPasswordInputChange: _.noop,
  onUndoClick: _.noop,
};

class NewUserRegistrationForm extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props, nextProps);
  }

  render() {
    return (
      <Form
        className="owlh"
        action="/account/register/"
        method="post"
        onSubmit={this.props.onFormSubmit}
      >
        <button type="button" onClick={this.props.onGuestLoginClick}>
          Continue As Guest
        </button>
        <hr />
        <SetPasswordFieldset onPasswordChange={this.props.onPasswordChange} />
        <ul className="inline-list">
          <li>
            <button type="submit" disabled={!this.props.password}>
              Create Account
            </button>
          </li>
          <li>
            <button
              type="button"
              className="button--link"
              onClick={this.props.onUndoClick}
            >
              Back
            </button>
          </li>
        </ul>
      </Form>
    );
  }
}

NewUserRegistrationForm.propTypes = {
  password: PropTypes.string,
  onFormSubmit: PropTypes.func,
  onPasswordChange: PropTypes.func,
  onGuestLoginClick: PropTypes.func,
  onUndoClick: PropTypes.func,
};
NewUserRegistrationForm.defaultProps = {
  onFormSubmit: _.noop,
  onPasswordChange: _.noop,
  onGuestLoginClick: _.noop,
  onUndoClick: _.noop,
};

class GuestUserUpgradeRequestForm extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props, nextProps);
  }

  render() {
    return (
      <Form
        action="/account/login/continue/"
        method="post"
        onSubmit={this.props.onFormSubmit}
      >
        <button type="button" onClick={this.props.onGuestLoginClick}>
          Continue As Guest
        </button>
        <br />
        <button type="submit">Register an Account and Continue</button>
        <p>We will email a verification code to {this.props.email}</p>
        <button type="button" onClick={this.props.onUndoClick}>
          Back
        </button>
      </Form>
    );
  }
}

GuestUserUpgradeRequestForm.propTypes = {
  email: PropTypes.string,
  onFormSubmit: PropTypes.func,
  onGuestLoginClick: PropTypes.func,
  onUndoClick: PropTypes.func,
};
GuestUserUpgradeRequestForm.defaultProps = {
  onFormSubmit: _.noop,
  onGuestLoginClick: _.noop,
  onUndoClick: _.noop,
};

class UpgradeGuestAccountForm extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props, nextProps);
  }

  render() {
    return (
      <Form
        action="/account/login/confirm/"
        method="post"
        onSubmit={this.props.onFormSubmit}
      >
        <SetPasswordFieldset onPasswordChange={this.props.onPasswordChange} />
        <Input
          name="token"
          label="token"
          value={this.props.token}
          onChange={this.props.onTokenInputChange}
        />
        <button
          type="submit"
          disabled={!this.props.password || !this.props.token}
        >
          Upgrade Account
        </button>
        <button type="button" onClick={this.props.onUndoClick}>
          Back
        </button>
      </Form>
    );
  }
}

UpgradeGuestAccountForm.propTypes = {
  password: PropTypes.string,
  token: PropTypes.string,
  onFormSubmit: PropTypes.func,
  onPasswordChange: PropTypes.func,
  onTokenInputChange: PropTypes.func,
  onUndoClick: PropTypes.func,
};
UpgradeGuestAccountForm.defaultProps = {
  onFormSubmit: _.noop,
  onPasswordChange: _.noop,
  onTokenInputChange: _.noop,
  onUndoClick: _.noop,
};

export class ComplexLoginForm extends LoginForm {
  constructor(props) {
    super(props);
    this.state = _.extend(this.state || {}, this.newStates());
    [
      'handleActionSuccess',
      'handleActionError',
      'handleEmailStatusChange',
      'handleLoginEmail',
      'handleUndoEmail',
      'handleLogin',
      'handleGuestLogin',
      'handleRegistration',
      'handleGuestUpgradeRequest',
      'handleGuestUpgrade',
      'handlePasswordChange',
      {
        boundName: 'handleTextInputChangeEmail',
        originalName: 'handleTextInputChange',
        args: 'email',
      },
      {
        boundName: 'handleTextInputChangePassword',
        originalName: 'handleTextInputChange',
        args: 'password',
      },
      {
        boundName: 'handleTextInputChangeToken',
        originalName: 'handleTextInputChange',
        args: 'token',
      },
    ].forEach((method) => {
      if (_.isPlainObject(method)) {
        this[method.boundName] = this[method.originalName].bind(
          this,
          method.args
        );
      } else {
        this[method] = this[method].bind(this);
      }
    });
  }

  newStates() {
    return {
      email: '',
      emailStatus: null,
      password: '',
      token: '',
      guestUpgradeRequest: false,
      error: null,
      loggedOut: false,
    };
  }

  clearStateStatus() {
    this.setState({
      error: null,
      loggedOut: false,
    });
  }

  componentDidMount() {
    super.componentDidMount();
    const authStore = this.flux.store('AuthStore');
    authStore.addListener('account:action:success', this.handleActionSuccess);
    authStore.addListener('account:action:error', this.handleActionError);
    authStore.addListener('change:user', this.props.onUserUpdate);
  }

  componentDidUpdate(prevProps) {
    if (this.props.onUserUpdate !== prevProps.onUserUpdate) {
      const authStore = this.flux.store('AuthStore');
      authStore.removeListener('change:user', prevProps.onUserUpdate);
      authStore.addListener('change:user', this.props.onUserUpdate);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const authStore = this.flux.store('AuthStore');
    authStore.removeListener(
      'account:action:success',
      this.handleActionSuccess
    );
    authStore.removeListener('account:action:error', this.handleActionError);
    authStore.removeListener('change:user', this.props.onUserUpdate);
  }

  handleActionSuccess(action) {
    if (action === 'guestUpgradeRequest') {
      this.setState({guestUpgradeRequest: true});
    }
    if (action === 'guestUpgrade') {
      this.setState({guestUpgradeRequest: false});
    }
    return;
  }

  handleActionError(actionName) {
    const errorMessageLookup = {
      emailLogin: 'Problem with your email',
      register: 'Problem registering your account',
      guestUpgradeRequest: 'Problem upgrading your account',
      guestUpgrade: 'Problem upgrading the account',
    };
    if (actionName in errorMessageLookup) {
      this.setState({error: errorMessageLookup[actionName]});
    } else {
      this.setState({error: 'Unknown Error'});
    }
  }

  handleEmailStatusChange(emailStatus) {
    this.setState({emailStatus: emailStatus});
  }

  handleLoginEmail() {
    this.clearStateStatus();
    this.flux.actions.auth.loginEmail({
      email: this.state.email,
      successCallback: this.handleEmailStatusChange,
    });
  }

  handleUndoEmail() {
    this.clearStateStatus();
    this.setState({emailStatus: null});
  }

  handleLogin() {
    this.clearStateStatus();
    this.flux.actions.auth.login({
      email: this.state.email,
      password: this.state.password,
    });
  }

  handleGuestLogin() {
    this.clearStateStatus();
    this.flux.actions.auth.guestLogin({
      email: this.state.email,
    });
  }

  handleRegistration() {
    this.clearStateStatus();
    this.flux.actions.auth.register({
      email: this.state.email,
      password: this.state.password,
    });
  }

  handleGuestUpgradeRequest() {
    this.clearStateStatus();
    this.flux.actions.auth.guestUpgradeRequest({
      email: this.state.email,
    });
  }

  handleGuestUpgrade() {
    this.clearStateStatus();
    this.flux.actions.auth.guestUpgrade({
      email: this.state.email,
      password: this.state.password,
      token: this.state.token,
    });
  }

  handlePasswordChange(password) {
    this.setState({password: password});
  }

  handleTextInputChange(name, ev) {
    this.setState({[name]: ev.target.value});
  }

  renderForm() {
    if (!this.state.emailStatus) {
      return (
        <LoginEmailForm
          onFormSubmit={this.handleLoginEmail}
          onEmailInputChange={this.handleTextInputChangeEmail}
          email={this.state.email}
        />
      );
    }
    if (this.state.emailStatus === 'standard') {
      return (
        <LoginPasswordForm
          onFormSubmit={this.handleLogin}
          onPasswordInputChange={this.handleTextInputChangePassword}
          onUndoClick={this.handleUndoEmail}
          password={this.state.password}
        />
      );
    }
    if (this.state.emailStatus === 'new') {
      return (
        <NewUserRegistrationForm
          onFormSubmit={this.handleRegistration}
          onPasswordChange={this.handlePasswordChange}
          onUndoClick={this.handleUndoEmail}
          onGuestLoginClick={this.handleGuestLogin}
          password={this.state.password}
        />
      );
    }
    if (this.state.emailStatus === 'guest' && !this.state.guestUpgradeRequest) {
      return (
        <GuestUserUpgradeRequestForm
          onFormSubmit={this.handleGuestUpgradeRequest}
          onGuestLoginClick={this.handleGuestLogin}
          onUndoClick={this.handleUndoEmail}
          email={this.state.email}
        />
      );
    }
    if (this.state.emailStatus === 'guest' && this.state.guestUpgradeRequest) {
      return (
        <UpgradeGuestAccountForm
          onFormSubmit={this.handleGuestUpgrade}
          onPasswordChange={this.handlePasswordChange}
          onUndoClick={this.handleUndoEmail}
          onTokenInputChange={this.handleTextInputChangeToken}
          password={this.state.password}
          token={this.state.token}
        />
      );
    }
    return null;
  }

  render() {
    const className = classNames('login-form--form', this.props.className);
    if (this.state.loading) {
      const className = classNames('login-form--loading', this.props.className);
      return <Loading aria-busy={true} className={className} />;
    }
    if (this.state.loggedOut && this.props.displayLoggedOut) {
      return this.renderLogoutMessage();
    }
    if (this.state.user.get('isAuthenticatedOrIsGuest')) {
      return this.renderLoggedInMessage();
    }
    return (
      <div className={className}>
        {this.state.error ? (
          <p className="bg-error pq">{this.state.error}</p>
        ) : null}
        {this.renderForm()}
      </div>
    );
  }
}
ComplexLoginForm.propTypes = _.extend(_.clone(LoginForm.propTypes), {
  onUserUpdate: PropTypes.func.isRequired,
});
ComplexLoginForm.defaultProps = _.extend(_.clone(LoginForm.defaultProps), {
  onUserUpdate: _.noop,
  displayLoggedOut: false,
});

export class ComplexLoginFormDialog extends SimpleImmutableComponent {
  constructor(props) {
    super(props);
    this.dialogId = _.uniqueId('complex-login-form-dialog');
  }

  componentDidUpdate(prevProps) {
    if (this.props.isDialogOpen !== prevProps.isDialogOpen) {
      if (this.dialog) {
        if (this.props.isDialogOpen && !this.dialog.open) {
          this.dialog.showModal();
        } else if (!this.props.isDialogOpen && this.dialog.open) {
          this.dialog.close();
        }
      }
      this.form.setState(this.form.newStates());
    }
  }

  render() {
    return (
      <dialog
        ref={(node) => (this.dialog = node)}
        id={this.dialogId}
        style={{zIndex: 2, width: '20rem'}}
      >
        <ComplexLoginForm ref={(node) => (this.form = node)} {...this.props} />
        <br />
        <button
          type="button"
          className="button--link"
          onClick={this.props.onRequestCancel}
        >
          Cancel
        </button>
      </dialog>
    );
  }
}
ComplexLoginFormDialog.propTypes = _.extend(
  _.clone(ComplexLoginForm.propTypes),
  {
    isDialogOpen: PropTypes.bool.isRequired,
    onRequestCancel: PropTypes.func.isRequired,
  }
);
ComplexLoginFormDialog.defaultProps = _.extend(
  _.clone(ComplexLoginForm.defaultProps),
  {
    onRequestCancel: _.noop,
  }
);

export class PasswordChangeForm extends SimpleImmutableFluxComponent {
  constructor(props) {
    super(props);
    [
      'handleSubmit',
      'handleActionSuccess',
      'handleActionError',
      'componentDidMount',
      'componentWillUnmount',
      'close',
    ].forEach((method) => {
      this[method] = this[method].bind(this);
    });
    this.state = {
      errors: false,
      completed: false,
      loading: false,
    };
  }

  getStateFromFlux() {
    return _.extend({}, this.flux.store('AuthStore').getState());
  }

  componentDidMount() {
    super.componentDidMount();
    const authStore = this.flux.store('AuthStore');
    authStore.addListener(
      'account:action:changePassword:success',
      this.handleActionSuccess
    );
    authStore.addListener(
      'account:action:changePassword:error',
      this.handleActionError
    );
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const authStore = this.flux.store('AuthStore');
    authStore.removeListener(
      'account:action:changePassword:success',
      this.handleActionSuccess
    );
    authStore.removeListener(
      'account:action:changePassword:error',
      this.handleActionError
    );
  }

  handleActionSuccess() {
    this.setState({errors: false, loading: false, completed: true});
  }

  handleActionError(err) {
    this.setState({errors: true});
    const response = err.response;
    if (response.status === 400) {
      response
        .json()
        .then((data) => {
          if ('errors' in data) {
            if ('current_password' in data.errors) {
              this.currentPassword.input.setCustomValidity(
                data.errors.current_password
              );
            }
            if ('password' in data.errors) {
              this.newPassword.input.setCustomValidity(data.errors.password);
            }
          }
          this.form.form.reportValidity();
        })
        .catch(() => {
          this.form.form.reportValidity();
        });
    } else if (response.status === 401) {
      this.currentPassword.input.setCustomValidity(
        'That password was not correct.'
      );
      this.form.form.reportValidity();
    }
  }

  handleSubmit() {
    this.setState({errors: false});
    const formData = new FormData(this.form.form);
    const payload = {
      current_password: formData.get('current_password'),
      password: formData.get('password'),
    };
    this.flux.actions.auth.changePassword(payload);
  }

  validatePassword(value) {
    const hasLower = value.search(/[a-z]/) !== -1;
    const hasUpper = value.search(/[A-Z]/) !== -1;
    const hasNumeric = value.search(/[0-9]/) !== -1;
    return hasUpper && hasLower && hasNumeric;
  }

  close(ev) {
    ev.preventDefault();
    this.props.dialog.close();
  }

  render() {
    if (this.state.completed) {
      return (
        <div>
          <div className="oh dfbx dfbx--ctr">
            <svg
              id="circle_tick"
              className="js-circle-tick"
              viewBox="0 0 100 100"
              aria-hidden="true"
              focusable="false"
            >
              <g>
                <ellipse
                  className="tick_outer"
                  stroke="#40a629"
                  stroke-Width="3"
                  transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.7107 50.0001)"
                  cx="50"
                  cy="50"
                  rx="42.6"
                  ry="42.6"
                />
              </g>
              <path
                className="tick_inner"
                d="m68.1 33.7-20.4 27-14.4-11.3-1.5 1.9 16.4 12.8L70 35.2z"
              />
            </svg>
          </div>

          <p className="bg-success p">Your password has been changed.</p>
          <button
            onClick={this.close}
            className="button bg-brand button--xl one-whole brad mht mb"
          >
            Close
          </button>
        </div>
      );
    } else {
      return (
        <Form
          ref={(elem) => (this.form = elem)}
          onSubmit={this.handleSubmit}
          action={this.props.action}
          method="post"
          key="form"
        >
          <Fieldset>
            <legend className="h3 t-thin block pht">
              Change Your Password
            </legend>
            <p className="bg-info ph txt-left">
              We have strengthened our password rules. Please update your
              password to something more secure.
            </p>
            {this.state.errors ? (
              <p className="bg-error ph txt-left">
                There was a problem updating your password
              </p>
            ) : null}
            <Input
              ref={(elem) => (this.currentPassword = elem)}
              type="password"
              name="current_password"
              label="Current Password"
              attrs={Immutable.Map({required: true})}
            />
          </Fieldset>
          <Fieldset>
            <legend className="h3 t-thin block pht">Your New Password</legend>
            {this.props.helptext}
            <Input
              ref={(elem) => (this.newPassword = elem)}
              type="password"
              name="password"
              label="New Password"
              validationMessage="Your password must contain at least one lowercase letter, one uppercase letter, and one number."
              validator={this.validatePassword}
              attrs={Immutable.Map({required: true})}
            />
            <Input
              ref={(elem) => (this.confirmPassword = elem)}
              type="password"
              name="password"
              label="Confirm New Password"
              validationMessage="The passwords must match"
              validator={(value) => {
                return value === this.newPassword.state.value;
              }}
              attrs={Immutable.Map({required: true})}
            />
          </Fieldset>
          <div className="dfbx--fdc">
            <button
              type="submit"
              disabled={this.state.loading}
              className="button bg-brand button--xl smart-two-thirds phablet-one-half brad mht mb"
            >
              {this.state.loading ? <Loading /> : 'Change Password'}
            </button>
            <button
              onClick={this.close}
              className="button button--link mt dfbx--asfs mr"
            >
              Skip this for now
            </button>
          </div>
        </Form>
      );
    }
  }
}
PasswordChangeForm.propTypes = _.extend(
  _.clone(SimpleImmutableFluxComponent.propTypes),
  {
    action: PropTypes.string.isRequired,
    dialog: PropTypes.instanceOf(HTMLElement).isRequired,
    helptext: PropTypes.node,
  }
);
PasswordChangeForm.defaultProps = _.extend(
  _.clone(SimpleImmutableFluxComponent.defaultProps || {}),
  {
    helptext: (
      <div className="bg-info txt-left mzt ph" data-password-help="">
        <p className="mzt clr-black">
          Your password must contain a minimum of <strong>8 characters</strong>{' '}
          and a mix of <strong>numbers</strong>; and <strong>uppercase</strong>,{' '}
          and <strong>lowercase</strong> letters.
        </p>
      </div>
    ),
  }
);
PasswordChangeForm.watchedStores = ['AuthStore'];
