import _ from 'lodash';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import * as React from 'react';
import classNames from 'classnames';
import {CSSTransition} from 'react-transition-group';

import FluxComponent from 'prometheus/flux/components.jsx';
import {Form} from 'prometheus/forms/components/form.jsx';
import {Fieldset} from 'prometheus/forms/components/fieldset.jsx';
import {Input} from 'prometheus/forms/components/inputs/input.jsx';
import {TextareaInput} from 'prometheus/forms/components/inputs/input-textarea.jsx';

import {checkStatus} from '../fetch.js';
import {getCSRFToken} from '../csrf.js';
import {LoginForm} from '../auth/components.jsx';

export class AddCommentForm extends FluxComponent {
  constructor(props) {
    super(props);
    this.state = _.extend(this.state || {}, {
      formData: null,
      message: null,
      openFormDialog: false,
      openLoginDialog: false,
    });
    let formData = {};
    this.initialFormData = props.initialFormData.toArray();
    this.initialFormData.forEach((field) => {
      // Ensure value is empty string if falsey.
      const value = field.value ? field.value : '';
      // Ensure reply_to id is set.
      formData[field.name] =
        props.replyTo && field.name === 'reply_to' ? props.replyTo : value;
    });
    this.state.formData = Immutable.Map(formData);
    this.timeouts = [];
    [
      'clearMessage',
      'handleBodyClick',
      'close',
      'handleSubmit',
      'showFormDialog',
      'toggle',
      {
        boundName: 'handleChangeComment',
        originalName: 'handleChange',
        args: 'comment',
      },
    ].forEach((method) => {
      if (_.isPlainObject(method)) {
        this[method.boundName] = this[method.originalName].bind(
          this,
          method.args
        );
      } else {
        this[method] = this[method].bind(this);
      }
    });
  }

  getStateFromFlux() {
    return this.flux.store('AuthStore').getState();
  }

  componentDidMount() {
    super.componentDidMount();
    document.addEventListener('click', this.handleBodyClick);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    document.removeEventListener('click', this.handleBodyClick);
  }

  handleBodyClick(ev) {
    if (
      document.body.contains(ev.target) &&
      this.container &&
      !this.container.contains(ev.target)
    ) {
      this.setState({
        openFormDialog: false,
        openLoginDialog: false,
      });
    }
  }

  handleChange(field, ev) {
    this.setState({
      formData: this.state.formData.set(field, ev.target.value),
    });
  }

  handleSubmit(ev) {
    ev.preventDefault();
    fetch('/api/comments/', {
      method: 'POST',
      headers: {
        'x-csrftoken': getCSRFToken(),
        'content-type': 'application/json',
      },
      body: JSON.stringify(this.state.formData.toJSON()),
      credentials: 'same-origin',
    })
      .then(checkStatus)
      .then(() => {
        // Message and reset form comment field state
        let state = {
          formData: this.state.formData,
          message: Immutable.Map({
            status: 'success',
            response: this.props.messages.get('success'),
          }),
        };
        state.formData.set('comment', '');
        this.setState(state, this.clearMessage);
      })
      .catch(() => {
        this.setState(
          {
            message: Immutable.Map({
              status: 'error',
              response: this.props.messages.get('error'),
            }),
          },
          this.clearMessage
        );
      });
  }

  clearMessage() {
    this.timeouts.forEach((timeout) => clearTimeout(timeout));
    this.timeouts.push(
      setTimeout(this.setState.bind(this, {message: null}, this.close), 5000)
    );
  }

  toggle() {
    this.setState({
      openFormDialog: !this.state.openFormDialog,
      openLoginDialog: !this.state.openLoginDialog,
    });
  }

  close() {
    this.setState({
      openFormDialog: false,
      openLoginDialog: false,
    });
  }

  showFormDialog() {
    this.setState({
      openFormDialog: true,
      openLoginDialog: false,
    });
  }

  renderHiddenInputs() {
    return this.initialFormData.map((field) => {
      if (field.hidden) {
        return (
          <Input
            key={'input-' + field.name}
            className="visually-hidden"
            name={field.name}
            id={field.name}
            attrs={Immutable.Map({
              type: 'hidden',
            })}
            value={field.value ? field.value : ''}
          />
        );
      }
    });
  }

  renderMessage() {
    if (_.isNull(this.state.message) || this.state.message.isEmpty()) {
      return null;
    }
    const messageStatus = this.state.message.get('status');
    const messageClassNames = classNames('"mz ph', {
      'bg-success': messageStatus === 'success',
      'bg-error': messageStatus === 'error',
    });
    return (
      <p key={messageStatus} className={messageClassNames}>
        {this.state.message.get('response')}
      </p>
    );
  }

  renderPostingUser() {
    const userDisplayName = this.state.user.get('displayName');
    const userEmail = this.state.user.get('email');
    const postingUserDisplay = userDisplayName
      ? `${userDisplayName}`
      : `${userEmail}`;

    const avatarPlaceholder = this.flux.store('SettingsStore').getState()
      .avatarPlaceholder;
    return (
      <ul key="comment-posting-user" className="inline-list ml mr mzt mzb">
        <li className="inline-list__item">
          <img
            className="brad-100 mqr bg-light-grey"
            width="40"
            height="40"
            src={avatarPlaceholder}
            alt={postingUserDisplay}
            title={'Posting as ' + postingUserDisplay}
          />
        </li>
        <li className="inline-list__item">
          <h5 className="mz txt-left">
            Posting as {postingUserDisplay}
            {!userDisplayName ? (
              <span className="block t-small clr-neutral">
                (your email address will not be visible to other users)
              </span>
            ) : null}
          </h5>
        </li>
      </ul>
    );
  }

  render() {
    let dialogId;
    // User not logged in
    if (!this.state.user.get('isAuthenticated')) {
      dialogId = _.uniqueId('comment-login');
      return (
        <div ref={(node) => (this.container = node)}>
          <dialog
            open={this.state.openLoginDialog}
            id={dialogId}
            style={{zIndex: 1}}
          >
            {this.state.openLoginDialog ? (
              <LoginForm
                handleLoginSuccess={this.showFormDialog}
                handleLogout={this.close}
                handleLogoutSuccess={this.close}
              />
            ) : null}
          </dialog>
          <button
            className="brad bg clr-neutral"
            onClick={this.toggle}
            aria-controls={dialogId}
            aria-haspopup="dialog"
          >
            Log in to {this.props.replyTo ? 'reply' : 'add a comment'}
          </button>
        </div>
      );
    }
    // Add Comment Form
    dialogId = _.uniqueId('comment-form');
    const hiddenInputs = this.renderHiddenInputs();
    const message = this.renderMessage();
    const postingUser = this.renderPostingUser();
    const form = (
      <Form key="comment-form" onSubmit={this.handleSubmit}>
        <Fieldset>
          {hiddenInputs}
          <TextareaInput
            name="comment"
            id="comment"
            label="Comment"
            attrs={Immutable.Map({
              maxLength: 3000,
              required: true,
              rows: 3,
            })}
            value={this.state.formData.get('comment')}
            onChange={this.handleChangeComment}
          />
        </Fieldset>
        <div className="pht pr phl phb mz">
          <button
            className="button button--lg bg-brand one-whole brad mt"
            type="submit"
          >
            Post {this.props.replyTo ? 'Reply' : 'Comment'}
          </button>
        </div>
      </Form>
    );
    return (
      <div
        ref={(node) => (this.container = node)}
        style={{zIndex: 1}}
        aria-busy={this.state.loading}
      >
        <dialog
          id={dialogId}
          open={this.state.openFormDialog}
          style={{
            zIndex: 4,
            display: this.state.openFormDialog || message ? 'block' : 'none',
          }}
        >
          <CSSTransition
            appear={true}
            in={this.state.openFormDialog}
            classNames="ra-comment-button-message-"
            timeout={200}
          >
            {message ? message : (postingUser, form)}
          </CSSTransition>
        </dialog>
        <button
          className="brad bg clr-neutral"
          onClick={this.toggle}
          aria-controls={dialogId}
          aria-haspopup="dialog"
        >
          {this.props.replyTo ? 'Reply' : 'Add a comment'}
        </button>
      </div>
    );
  }
}
AddCommentForm.watchedStores = ['AuthStore'];
AddCommentForm.propTypes = _.extend(_.clone(FluxComponent.propTypes), {
  initialFormData: ImmutablePropTypes.listOf(
    PropTypes.shape({
      name: PropTypes.string,
      hidden: PropTypes.boolean,
      value: PropTypes.string,
    })
  ).isRequired,
  messages: ImmutablePropTypes.mapContains({
    success: PropTypes.string,
    error: PropTypes.string,
  }),
  replyTo: PropTypes.string,
});
AddCommentForm.defaultProps = _.extend(_.clone(FluxComponent.defaultProps), {
  messages: Immutable.Map({
    success:
      'Thanks! Your comment has been posted and will appear as soon as it ' +
      'has been moderated by one of our team.',
    error: 'Sorry, there was an issue sending your comment.',
  }),
});
