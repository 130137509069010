import * as React from 'react';
import ReactDOM from 'react-dom';

import {AddToWishListForm, MoveToWishListForm} from './components.jsx';

export function addToWishListForm(elem, options) {
  if (!options || !options.flux) {
    return;
  }
  ReactDOM.render(<AddToWishListForm {...options} />, elem);
}

export function moveToWishListForm(elem, options) {
  if (!options || !options.flux) {
    return;
  }
  ReactDOM.render(<MoveToWishListForm {...options} />, elem);
}
