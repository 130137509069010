import Immutable from 'immutable';
import * as React from 'react';

import FluxComponent from 'prometheus/flux/components.jsx';

import {SiteMessage} from '../sitemessages/components.jsx';

export class FeedbackSurveyMessages extends FluxComponent {
  shouldComponentUpdate(nextProps, nextState) {
    return !(
      Immutable.isImmutable(this.state.messages) &&
      Immutable.isImmutable(nextState.messages) &&
      this.state.messages.equals(nextState.messages)
    );
  }

  getStateFromFlux() {
    return this.flux.store('FeedbackSurveyMessagesStore').getState();
  }

  render() {
    if (this.state.messages.size <= 0) {
      return null;
    }
    const messages = this.state.messages.map((message) => {
      const siteMessage = Immutable.Map({
        id: message.get('id'),
        message: message.get('linkedMessage'),
      });
      return (
        <SiteMessage
          message={siteMessage}
          key={message.get('id')}
          dismiss={this.flux.actions.feedbackSurveyMessages.dismiss}
        />
      );
    });
    return <ul className="striplist pz mz">{messages}</ul>;
  }
}
FeedbackSurveyMessages.watchedStores = ['FeedbackSurveyMessagesStore'];
