import _ from 'lodash';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import jump from 'jump.js';
import LazyLoad from 'react-lazy-load';
import PropTypes from 'prop-types';
import * as React from 'react';
import classNames from 'classnames';

import SimpleImmutableComponent from 'prometheus/components/immutable.jsx';
import {SimpleImmutableFluxComponent} from 'prometheus/flux/components.jsx';
import {Form} from 'prometheus/forms/components/form.jsx';
import {Fieldset} from 'prometheus/forms/components/fieldset.jsx';
import {Input, InputGroup} from 'prometheus/forms/components/inputs/input.jsx';
import {
  CheckboxInput,
  RadioInput,
} from 'prometheus/forms/components/inputs/input-radio.jsx';
import {TextareaInput} from 'prometheus/forms/components/inputs/input-textarea.jsx';

import {Loading} from '../../components.jsx';
import {
  DisplayReviewType,
  DisplayReviewRatingType,
  ProductType,
} from '../types.js';
import {
  ComplexLoginFormDialog,
  ComplexLoginForm,
} from '../../auth/components.jsx';
import {UserType} from '../../auth/types.js';

export class ReviewStars extends React.PureComponent {
  render() {
    const className = classNames(
      this.props.className,
      'stars owl-off clearfix',
      'stars-' +
        this.props.score.toFixed(1).toString().replace('.', '') +
        '--50'
    );
    const text =
      this.props.score +
      ' out of 5 (' +
      this.props.count +
      ' ' +
      (this.props.count === 1 ? 'review' : 'reviews') +
      ')';
    const content = (
      <div>
        <div className={className}>
          <span>★</span>
          <span>★</span>
          <span>★</span>
          <span>★</span>
          <span>★</span>
          <p className="dib">({this.props.count})</p>
        </div>
        <span className="visually-hidden">{text}</span>
      </div>
    );

    if (this.props.url) {
      return (
        <a href={this.props.url} title={text}>
          {content}
        </a>
      );
    } else {
      return <span>{content}</span>;
    }
  }
}
ReviewStars.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number.isRequired,
  score: PropTypes.number.isRequired,
  url: PropTypes.string,
};
ReviewStars.defaultProps = {
  className: 't-small',
};

class SingleReviewStars extends React.PureComponent {
  render() {
    const className = classNames(
      this.props.className,
      'stars t-small owl-off clearfix',
      'stars-' +
        this.props.score.toFixed(1).toString().replace('.', '') +
        '--50'
    );
    const text = this.props.score + ' out of 5';
    return (
      <div className={className} title={text}>
        <span>★</span>
        <span>★</span>
        <span>★</span>
        <span>★</span>
        <span>★</span>
        <span className="visually-hidden">{text}</span>
      </div>
    );
  }
}
SingleReviewStars.propTypes = {
  className: PropTypes.string,
  score: PropTypes.number.isRequired,
};
SingleReviewStars.defaultProps = {
  className: '',
};

class ReviewVotes extends React.PureComponent {
  render() {
    return (
      <div className={this.props.className}>
        (<span className="clr-success">{this.props.upVotes} ↑</span> |{' '}
        <span className="clr-error">{this.props.downVotes} ↓</span>)
      </div>
    );
  }
}
ReviewVotes.propTypes = {
  className: PropTypes.string,
  downVotes: PropTypes.number.isRequired,
  upVotes: PropTypes.number.isRequired,
};
ReviewVotes.defaultProps = {
  className: '',
};

class ReviewCastVote extends SimpleImmutableComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showLoginDialog: false,
      voteInstruction: null,
      error: null,
    };
    [
      'closeErrorDialog',
      'closeLoginDialog',
      'handleLoaded',
      'handleVoteError',
      'handleLoginUserUpdate',
      {
        boundName: 'handleVoteClickClear',
        originalName: 'handleVoteClick',
        args: 'clear',
      },
      {
        boundName: 'handleVoteClickUp',
        originalName: 'handleVoteClick',
        args: 'up',
      },
      {
        boundName: 'handleVoteClickDown',
        originalName: 'handleVoteClick',
        args: 'down',
      },
    ].forEach((method) => {
      if (_.isPlainObject(method)) {
        this[method.boundName] = this[method.originalName].bind(
          this,
          method.args
        );
      } else {
        this[method] = this[method].bind(this);
      }
    });
  }

  handleLoaded() {
    this.setState({loading: false});
  }

  handleVoteClick(voteInstruction) {
    if (!this.props.user.get('isAuthenticatedOrIsGuest')) {
      this.setState({
        showLoginDialog: true,
        voteInstruction: voteInstruction,
      });
    } else {
      this.setState({loading: true}, () => {
        this.props.vote.call(
          this,
          this.props.review,
          voteInstruction,
          this.handleVoteError,
          this.handleLoaded
        );
      });
    }
  }

  handleLoginUserUpdate() {
    const voteInstruction = this.state.voteInstruction;
    this.setState({showLoginDialog: false, voteInstruction: null}, () => {
      this.props.vote.call(
        this,
        this.props.review,
        voteInstruction,
        this.handleVoteError
      );
    });
  }

  closeLoginDialog() {
    this.setState({showLoginDialog: false});
  }

  handleVoteError(message) {
    this.setState(
      {error: message || null},
      function () {
        this.errorDialog.showModal();
      }.bind(this)
    );
  }

  closeErrorDialog() {
    this.errorDialog.close();
    this.setState({loading: false, error: null});
  }

  renderContent() {
    if (this.state.loading) {
      return <Loading />;
    }
    if (this.props.review.get('voteStatus')) {
      return (
        <p>
          You said this review was{' '}
          {this.props.review.get('voteStatus') === 'up'
            ? 'useful'
            : 'not useful'}
          .
          <button
            className="mql button--brdr"
            onClick={this.handleVoteClickClear}
          >
            Changed your mind?
          </button>
        </p>
      );
    }
    return (
      <ul className="inline-list">
        <li className="inline-list__item">Was this review useful?</li>
        <li className="inline-list__item">
          <button className="button--brdr" onClick={this.handleVoteClickUp}>
            Yes
          </button>
        </li>
        <li className="inline-list__item">
          <button className="button--brdr" onClick={this.handleVoteClickDown}>
            No
          </button>
        </li>
      </ul>
    );
  }

  render() {
    const content = this.renderContent();
    return (
      <span>
        {content}
        <ComplexLoginFormDialog
          isDialogOpen={this.state.showLoginDialog}
          onUserUpdate={
            this.state.voteInstruction ? this.handleLoginUserUpdate : _.noop
          }
          onRequestCancel={this.closeLoginDialog}
        />
        <dialog ref={(node) => (this.errorDialog = node)} style={{zIndex: 2}}>
          {this.state.error || 'Error Voting'}
          <br />
          <button onClick={this.closeErrorDialog}>Close</button>
        </dialog>
      </span>
    );
  }
}
ReviewCastVote.propTypes = {
  review: DisplayReviewType.isRequired,
  user: UserType.isRequired,
  vote: PropTypes.func.isRequired,
};

class ProductPageReviewRatingsFilter extends SimpleImmutableComponent {
  render() {
    if (
      !this.props.reviewsRatings ||
      !this.props.reviewsRatings.get('data') ||
      this.props.reviewsRatings.get('data').isEmpty() ||
      _.sum(
        this.props.reviewsRatings
          .get('data')
          .toArray()
          .map((rating) => {
            return rating.get('count');
          })
      ) === 0
    ) {
      return null;
    }
    const ratings = this.props.reviewsRatings
      .get('data')
      .toArray()
      .map((rating) => {
        return (
          <li
            key={'reviews-ratings-li-' + rating.get('rating')}
            className="vert-list__item"
          >
            <label className="brdr brdr--thin clr-neutral--lighter owl-off ph">
              <input
                className="bg-white"
                key={'reviews-rating-input-' + rating.get('rating')}
                type="checkbox"
                name="rating"
                value={rating.get('rating')}
                checked={
                  this.props.selectedRatings
                    ? _.includes(
                        this.props.selectedRatings.toArray(),
                        rating.get('rating').toString()
                      )
                    : false
                }
                onChange={this.props.handleChange}
                style={{color: 'black'}}
              />
              <p
                className="t-small bold pqr clr-black"
                style={{
                  display: 'inline-block',
                }}
              >
                {rating.get('rating')} star
              </p>
              <div
                className="product-review-stats__percentage"
                style={{
                  display: 'inline-block',
                  width: '100px',
                  backgroundColor: 'lightgrey',
                }}
              >
                <div
                  className="product-review-stats__percentage-amount"
                  style={{
                    width: rating.get('percentage') + '%',
                    backgroundColor: '#FFA500',
                  }}
                >
                  &nbsp;
                </div>
              </div>
              <span className="t-small mhl"> ({rating.get('count')})</span>
            </label>
          </li>
        );
      });
    return <ul className="vert-list product-review-stats owlh">{ratings}</ul>;
  }
}

ProductPageReviewRatingsFilter.propTypes = {
  handleChange: PropTypes.func,
  reviewsRatings: ImmutablePropTypes.contains({
    data: ImmutablePropTypes.listOf(DisplayReviewRatingType),
    state: ImmutablePropTypes.contains({
      loading: PropTypes.bool,
    }),
  }).isRequired,
  selectedRatings: ImmutablePropTypes.list,
};

class ProductPageReviewFilterPanel extends SimpleImmutableComponent {
  constructor(props) {
    super(props);
    this.state = {
      ageGroup: Immutable.List(),
      verifiedPurchase: false,
      rating: Immutable.List(),
      qReview: '',
      sort: 'newest',
      inputText: '',
    };
    [
      'handleChange',
      'handleSearch',
      'handleTextChange',
      'handleSingleCheckbox',
    ].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  handleChange(ev) {
    const {value, checked, name} = ev.target;
    this.updateReviewsFilter({name: name, value: value, checked: checked});
  }

  handleSingleCheckbox(ev) {
    const {checked, name} = ev.target;
    if (checked) {
      this.updateReviewsFilter({name: name, value: true});
    } else {
      this.updateReviewsFilter({name: name, value: false});
    }
  }

  handleTextChange(ev) {
    this.setState({inputText: ev.target.value});
  }

  handleSearch() {
    this.updateReviewsFilter({name: 'qReview', value: this.state.inputText});
  }

  updateReviewsFilter(opts) {
    const {name, value, checked} = opts;
    if (!(name in this.state)) {
      return;
    }
    const newState = _.omit(_.cloneDeep(this.state), ['inputText']);
    if (Immutable.List.isList(newState[name])) {
      if (checked) {
        if (!newState[name].includes(value)) {
          newState[name] = newState[name].push(value);
        }
      } else {
        newState[name] = newState[name].pop(value);
      }
    } else {
      newState[name] = value;
    }
    this.setState(
      newState,
      function () {
        this.props.updateFilter(_.omit(_.cloneDeep(this.state), ['inputText']));
      }.bind(this)
    );
  }

  renderMultiChoices(
    fieldName,
    choices,
    selectedValue,
    widgetType,
    inputGroupLabel
  ) {
    if (Immutable.isImmutable(choices)) {
      choices = choices.toJS();
    }
    if (Immutable.isImmutable(selectedValue)) {
      selectedValue = selectedValue.toArray();
    }
    const inputs = choices.map((choice) => {
      const [value, label] = choice;
      const checked =
        widgetType === 'checkbox'
          ? _.includes(selectedValue, value)
          : value === selectedValue;
      const props = {
        name: fieldName,
        value: value,
        label: label,
        className: 'mzt',
        checked: checked,
        key: fieldName + '-' + value,
        id: fieldName + '-' + value,
        onChange: this.handleChange,
      };
      if (widgetType === 'checkbox') {
        return <CheckboxInput {...props} />;
      } else {
        return <RadioInput {...props} />;
      }
    });
    return <InputGroup label={inputGroupLabel}>{inputs}</InputGroup>;
  }

  renderRatingsFilter() {
    if (
      !this.props.reviewsRatings ||
      !this.props.reviewsRatings.get('data') ||
      this.props.reviewsRatings.get('data').isEmpty() ||
      _.sum(
        this.props.reviewsRatings
          .get('data')
          .toArray()
          .map((rating) => {
            return rating.get('count');
          })
      ) === 0
    ) {
      return null;
    }
    const ratings = this.props.reviewsRatings
      .get('data')
      .toArray()
      .map((rating) => {
        return (
          <li
            key={'reviews-ratings-li-' + rating.get('rating')}
            className="vert-list__item"
          >
            <label className="brdr brdr--thin clr-neutral--lighter ph owl-off">
              <input
                className="bg-white"
                key={'reviews-rating-input-' + rating.get('rating')}
                type="checkbox"
                name="rating"
                value={rating.get('rating')}
                checked={
                  this.state.rating
                    ? _.includes(
                        this.state.rating.toArray(),
                        rating.get('rating').toString()
                      )
                    : false
                }
                onChange={this.handleChange}
                style={{color: 'black'}}
              />
              <p
                className="t-small bold pqr clr-black"
                style={{
                  display: 'inline-block',
                }}
              >
                {rating.get('rating')} star
              </p>
              <div
                className="product-review-stats__percentage"
                style={{
                  display: 'inline-block',
                  width: '100px',
                  backgroundColor: 'lightgrey',
                }}
              >
                <div
                  className="product-review-stats__percentage-amount"
                  style={{
                    width: rating.get('percentage') + '%',
                    backgroundColor: '#FFA500',
                  }}
                >
                  &nbsp;
                </div>
              </div>
              <span className="t-small mhl"> ({rating.get('count')})</span>
            </label>
          </li>
        );
      });
    return <ul className="vert-list product-review-stats owlh">{ratings}</ul>;
  }

  render() {
    const ratingFilter = this.renderRatingsFilter();
    return (
      <div>
        {ratingFilter}
        <details className="ph brdr clr-neutral brdr--thin zshd-00">
          <summary>Refine</summary>
          <Form onSubmit={this.handleSearch}>
            <Fieldset>
              <Input
                name="qReview"
                id="qReview"
                value={this.state.inputText}
                onChange={this.handleTextChange}
              />
              <button type="submit">Search</button>
              {this.props.hasAgeGroup
                ? this.renderMultiChoices(
                    'ageGroup',
                    this.props.ageGroupChoices,
                    this.state.ageGroup,
                    'checkbox',
                    'Age Group'
                  )
                : null}
              {this.props.hasVerifiedPurchase ? (
                <InputGroup label="Verified Purchase">
                  <CheckboxInput
                    name="verifiedPurchase"
                    value={this.state.verifiedPurchase}
                    checked={this.state.verifiedPurchase}
                    id="verifiedPurchase"
                    label="Verified Purchase"
                    onChange={this.handleSingleCheckbox}
                  />
                </InputGroup>
              ) : null}
              {this.renderMultiChoices(
                'sort',
                this.props.sortChoices,
                this.state.sort,
                'radiobox',
                'Sort Order'
              )}
            </Fieldset>
          </Form>
        </details>
      </div>
    );
  }
}
ProductPageReviewFilterPanel.propTypes = {
  ageGroupChoices: ImmutablePropTypes.list.isRequired,
  reviewsRatings: ImmutablePropTypes.contains({
    data: ImmutablePropTypes.listOf(DisplayReviewRatingType),
    state: ImmutablePropTypes.contains({
      loading: PropTypes.bool,
    }),
  }).isRequired,
  sortChoices: ImmutablePropTypes.list.isRequired,
  hasAgeGroup: PropTypes.bool,
  hasVerifiedPurchase: PropTypes.bool,
  updateFilter: PropTypes.func,
};
ProductPageReviewFilterPanel.defaultProps = {
  updateFilter: _.noop,
};

class ProductPageReview extends SimpleImmutableComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
    ['toggleExpanded'].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  toggleExpanded() {
    this.setState({expanded: !this.state.expanded});
  }

  render() {
    const paragraphs = this.props.review
      .get('content')
      .split(/(\r?\n){2}/gm)
      .map((content, idx) => {
        return <p key={'review-content' + idx}>{content}</p>;
      });

    const expander = (
      <button className="button--link mt" onClick={this.toggleExpanded}>
        {this.state.expanded ? 'Read less' : 'Read more'}
      </button>
    );
    return (
      <li className="brdr--top brdr--top--thin">
        <p className="bold dib owl-off">
          <span>{this.props.review.get('title')}</span>
          <span className="t-small t-thin clr-neutral--light"> by</span>{' '}
          {this.props.review.get('author')}
          {this.props.review.get('ageGroup') && (
            <span> {this.props.review.get('ageGroup')}</span>
          )}
          {this.props.review.get('verifiedPurchase') && (
            <span className="block t-thin t-small">Verified Purchase</span>
          )}
        </p>
        <SingleReviewStars
          className="pl dib vat"
          score={this.props.review.get('rating')}
        />
        <ReviewVotes
          className=" block bold mzt"
          upVotes={this.props.review.get('upVotes')}
          downVotes={this.props.review.get('downVotes')}
        />

        <div>{this.state.expanded ? paragraphs : paragraphs[0]}</div>
        {paragraphs.length > 1 ? expander : ''}
        <ReviewCastVote
          user={this.props.user}
          review={this.props.review}
          vote={this.props.vote}
        />
      </li>
    );
  }
}
ProductPageReview.propTypes = {
  product: ProductType.isRequired,
  review: DisplayReviewType.isRequired,
  user: UserType.isRequired,
  vote: PropTypes.func.isRequired,
};

class ProductPageReviewList extends SimpleImmutableComponent {
  constructor(props) {
    super(props);
    this.id = _.uniqueId('review-list');
  }

  render() {
    if (
      !this.props.reviews.get('data') ||
      this.props.reviews.getIn(['state', 'loading'])
    ) {
      return <Loading />;
    }

    const reviews = this.props.reviews
      .get('data')
      .toArray()
      .map((review) => {
        return (
          <ProductPageReview
            key={review.get('id')}
            user={this.props.user}
            vote={this.props.vote}
            product={this.props.product}
            review={review}
          />
        );
      });
    const emptyMessage =
      this.props.product.get('reviewCount') > 0 ? (
        <li>No results found.</li>
      ) : (
        <li>No reviews yet. Why not be the first to write one?</li>
      );
    return (
      <div>
        <ul className="striplist pzl pb" id={this.id}>
          {reviews.length ? reviews : emptyMessage}
        </ul>
        {this.props.reviews.get('hasNextPage') ? (
          <button
            className="button--brdr"
            aria-controls={this.id}
            onClick={this.props.loadMore}
          >
            Load more reviews
          </button>
        ) : (
          ''
        )}
      </div>
    );
  }
}
ProductPageReviewList.propTypes = {
  loadMore: PropTypes.func.isRequired,
  product: ProductType.isRequired,
  reviews: ImmutablePropTypes.contains({
    data: ImmutablePropTypes.listOf(DisplayReviewType),
    meta: ImmutablePropTypes.map,
    state: ImmutablePropTypes.contains({
      loading: PropTypes.bool,
    }),
  }).isRequired,
  user: UserType.isRequired,
  vote: PropTypes.func.isRequired,
};

class ProductReviewPreview extends React.PureComponent {
  render() {
    const paragraphs = this.props.content
      .split(/(\r?\n){2}/gm)
      .map(function (content, idx) {
        return <p key={'p-' + idx}>{content}</p>;
      });
    return (
      <div>
        <p className="h5 dib">
          <span>
            {this.props.title ? (
              this.props.title
            ) : (
              <em className="clr-neutral">You need to add a title</em>
            )}
          </span>
          <span className="t-small clr-neutral--light">
            {' '}
            <em>by</em> {this.props.reviewer}
          </span>
        </p>
        <p className="t-small dib">
          {this.props.ageGroup && <span> {this.props.ageGroup}</span>}
        </p>
        {this.props.rating ? (
          <SingleReviewStars className="pl dib" score={this.props.rating} />
        ) : (
          <p>
            <em>? out of ?</em>
          </p>
        )}
        <div>
          {this.props.content.trim() ? (
            paragraphs
          ) : (
            <em>You need to add a review</em>
          )}
        </div>
      </div>
    );
  }
}
ProductReviewPreview.propTypes = {
  ageGroup: PropTypes.string,
  content: PropTypes.string,
  rating: PropTypes.number,
  reviewer: PropTypes.string.isRequired,
  title: PropTypes.string,
};

class CreateProductReviewForm extends SimpleImmutableFluxComponent {
  constructor(props) {
    super(props);
    this.contentId = _.uniqueId('review-panel');
    this.state = _.extend(this.state || {}, {
      error: null,
      loading: false,
      preview: false,
      submitted: false,
      rating: 5,
      content: '',
      title: '',
      ageGroup: '',
      displayName:
        this.props.user.get('isAuthenticated') &&
        this.props.user.get('displayName')
          ? this.props.user.get('displayName')
          : '',
    });
    [
      'setSubmitted',
      'setError',
      'setReviewUserData',
      'handleSubmit',
      'togglePreview',
      {
        boundName: 'handleChangeRating',
        originalName: 'handleChange',
        args: 'rating',
      },
      {
        boundName: 'handleChangeTitle',
        originalName: 'handleChange',
        args: 'title',
      },
      {
        boundName: 'handleChangeContent',
        originalName: 'handleChange',
        args: 'content',
      },
      {
        boundName: 'handleChangeAgeGroup',
        originalName: 'handleChange',
        args: 'ageGroup',
      },
      {
        boundName: 'handleChangeDisplayName',
        originalName: 'handleChange',
        args: 'displayName',
      },
    ].forEach((method) => {
      if (_.isPlainObject(method)) {
        this[method.boundName] = this[method.originalName].bind(
          this,
          method.args
        );
      } else {
        this[method] = this[method].bind(this);
      }
    });
  }

  setReviewUserData() {
    const user =
      this.props && this.props.user
        ? this.props.user
        : this.flux.store('AuthStore').getState().user;
    this.setState({
      displayName:
        user.get('isAuthenticated') && user.get('displayName')
          ? user.get('displayName')
          : '',
    });
  }

  componentDidMount() {
    super.componentDidMount();
    this.setReviewUserData();
    this.flux
      .store('ProductStore')
      .addListener('review:submitted', this.setSubmitted);
    this.flux.store('ProductStore').addListener('review:error', this.setError);
    this.flux
      .store('ProductStore')
      .addListener('review:invalid', this.setError);
    this.flux
      .store('AuthStore')
      .addListener('change:user', this.setReviewUserData);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.flux
      .store('ProductStore')
      .removeListener('review:submitted', this.setSubmitted);
    this.flux
      .store('ProductStore')
      .removeListener('review:error', this.setError);
    this.flux
      .store('ProductStore')
      .removeListener('review:invalid', this.setError);
    this.flux
      .store('AuthStore')
      .removeListener('change:user', this.setReviewUserData);
  }

  isValid() {
    if (!this.props.user.get('isAuthenticatedOrIsGuest')) {
      return false;
    }
    if (
      !(
        _.isFinite(this.state.rating) &&
        1 <= this.state.rating &&
        this.state.rating <= 5
      )
    ) {
      return false;
    }
    if (!this.state.content) {
      return false;
    }
    if (!this.state.title) {
      return false;
    }
    return true;
  }

  setSubmitted() {
    this.setState({
      submitted: true,
      loading: false,
      preview: false,
    });
  }

  setError() {
    this.setState({
      submitted: false,
      error: 'There was an error submitting your review',
      loading: false,
      preview: false,
    });
  }

  togglePreview() {
    this.setState({preview: !this.state.preview}, () =>
      jump(this.container.form, {offset: -100, duration: 500})
    );
  }

  handleSubmit(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    this.setState(
      {
        loading: true,
        error: null,
        submitted: false,
      },
      this.flux.actions.catalog.product.submitReview({
        age_group: this.state.ageGroup,
        content: this.state.content,
        display_name: this.state.displayName,
        rating: this.state.rating,
        title: this.state.title,
      })
    );
  }

  handleChange(field, ev) {
    const state = {};
    state[field] =
      field === 'rating' ? parseInt(ev.target.value) : ev.target.value;
    this.setState(state);
  }

  renderSubmitted() {
    return (
      <div>
        <p>Your review has been submitted.</p>
        <p>
          Please wait just a while for us to approve it before it appears on the
          product page.
        </p>
        <hr />
        {this.props.close ? (
          <button type="button" className="right" onClick={this.props.close}>
            Done
          </button>
        ) : null}
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <Loading />
        <hr />
        {this.props.close ? (
          <button
            type="button"
            className="right button--link"
            onClick={this.props.close}
          >
            Cancel
          </button>
        ) : null}
      </div>
    );
  }

  renderPreviousSubmitted() {
    return (
      <div>
        <p>You have previously submitted a review for this product already.</p>
        <hr />
        {this.props.close ? (
          <button type="button" className="right" onClick={this.props.close}>
            Close
          </button>
        ) : null}
      </div>
    );
  }

  renderReviewDataFieldset() {
    const ratings = _.range(1, 6)
      .map((rating) => {
        return (
          <RadioInput
            name="rating"
            label={rating.toString()}
            value={rating}
            checked={this.state.rating === rating}
            key={'rating-' + rating}
            id={'rating-' + rating}
            onChange={this.handleChangeRating}
            className="input--radio--stars"
          />
        );
      })
      .reverse();
    return (
      <Fieldset key="review-data-fieldset">
        <InputGroup
          id="review-rating"
          className="row"
          label="Rating"
          labelClassName="one-whole"
        >
          {ratings}
        </InputGroup>
        <Input
          name="title"
          key="review-title"
          id="review-title"
          label="Title"
          attrs={Immutable.Map({
            required: true,
          })}
          value={this.state.title}
          onChange={this.handleChangeTitle}
        />
        <TextareaInput
          name="content"
          id="review-content"
          label="Review"
          attrs={Immutable.Map({
            required: true,
            cols: 60,
          })}
          value={this.state.content}
          onChange={this.handleChangeContent}
        />
      </Fieldset>
    );
  }

  renderUserDataFieldset() {
    const ageGroupInputs = this.props.ageGroupChoices.toJS().map((item) => {
      const [key, val] = item;
      return (
        <RadioInput
          name="age-group"
          label={val}
          value={key}
          checked={this.state.ageGroup === key}
          key={'agegroup-' + key}
          id={'agegroup-' + key}
          onChange={this.handleChangeAgeGroup}
        />
      );
    });
    const DISPLAY_NAME_HELP_TEXT = `
      The display name you provide below will be used to identify your
      reviews and comments on the site. This name will appear on any reviews
      you write, so please avoid use of any inappropriate words or names
      (such as those containing profanities). Using an inappropriate name
      will prevent your reviews from getting published.
    `;
    return (
      <Fieldset key="review-user-data-fieldset">
        <Input
          name="display-name"
          key="review-display-name"
          id="review-display-name"
          label="Display Name"
          helpText={DISPLAY_NAME_HELP_TEXT}
          value={this.state.displayName}
          onChange={this.handleChangeDisplayName}
        />
        <InputGroup id="review-age-group">
          <label htmlFor="review-age-group" className="one-whole">
            Age Group
          </label>
          {ageGroupInputs}
        </InputGroup>
      </Fieldset>
    );
  }

  render() {
    if (
      this.props.user.get('isAuthenticatedOrIsGuest') &&
      this.props.user.get('reviews').some((review) => {
        return (
          review.get('productId') === this.props.product.get('id') &&
          !review.get('moderated') &&
          !review.get('approved')
        );
      })
    ) {
      return this.renderPreviousSubmitted();
    }
    if (this.state.submitted) {
      return this.renderSubmitted();
    }
    if (this.state.loading) {
      return this.renderLoading();
    }

    const reviewer = this.state.displayName || this.props.user.get('email');
    let content = null;
    if (this.state.preview) {
      content = (
        <ProductReviewPreview
          rating={this.state.rating}
          content={this.state.content}
          title={this.state.title}
          reviewer={reviewer}
          ageGroup={this.state.ageGroup}
        />
      );
    } else {
      content = [
        this.renderReviewDataFieldset(),
        this.renderUserDataFieldset(),
      ];
    }

    return (
      <Form
        ref={(node) => (this.container = node)}
        className="form--review-submit pb"
        onSubmit={this.handleSubmit}
      >
        {this.state.error ? <div className="">{this.state.error}</div> : null}
        <div id={this.contentId}>{content}</div>
        <hr />
        <button
          type="button"
          className="mr button--brdr"
          onClick={this.togglePreview}
          aria-controls={this.contentId}
        >
          {this.state.preview ? 'Edit' : 'Preview'}
        </button>
        <button type="submit" disabled={!this.isValid()}>
          Submit
        </button>
      </Form>
    );
  }
}
CreateProductReviewForm.propTypes = {
  ageGroupChoices: ImmutablePropTypes.list.isRequired,
  close: PropTypes.bool,
  product: ProductType.isRequired,
  reviews: ImmutablePropTypes.contains({
    data: ImmutablePropTypes.listOf(DisplayReviewType),
    meta: ImmutablePropTypes.map,
    state: ImmutablePropTypes.contains({
      loading: PropTypes.bool,
    }),
  }).isRequired,
  reviewsRatings: ImmutablePropTypes.contains({
    data: ImmutablePropTypes.listOf(DisplayReviewRatingType),
    state: ImmutablePropTypes.contains({
      loading: PropTypes.bool,
    }),
  }).isRequired,
  user: UserType.isRequired,
};
CreateProductReviewForm.defaultProps = {
  close: false,
};

class CreateProductReviewPanel extends SimpleImmutableComponent {
  render() {
    return (
      <details className="phl phr owl-off brdr brdr--thin zshd-00" open={false}>
        <summary className="button--lg clr-black pz">
          Write your own review
        </summary>
        {this.props.user.get('isAuthenticatedOrIsGuest') ? (
          <CreateProductReviewForm {...this.props} />
        ) : (
          <ComplexLoginForm />
        )}
      </details>
    );
  }
}
CreateProductReviewPanel.propTypes = {
  ageGroupChoices: ImmutablePropTypes.list.isRequired,
  product: ProductType.isRequired,
  reviews: ImmutablePropTypes.contains({
    data: ImmutablePropTypes.listOf(DisplayReviewType),
    meta: ImmutablePropTypes.map,
    state: ImmutablePropTypes.contains({
      loading: PropTypes.bool,
    }),
  }).isRequired,
  reviewsRatings: ImmutablePropTypes.contains({
    data: ImmutablePropTypes.listOf(DisplayReviewRatingType),
    state: ImmutablePropTypes.contains({
      loading: PropTypes.bool,
    }),
  }).isRequired,
  user: UserType.isRequired,
};

export class ProductReviewPanel extends SimpleImmutableFluxComponent {
  constructor(props) {
    super(props);
    this.state = this.state || {};
    this.updateFilter = _.debounce(
      this.flux.actions.catalog.product.updateFilter
    ).bind(this);
    this.loadMoreReviews = _.debounce(
      this.flux.actions.catalog.product.loadMoreReviews
    ).bind(this);
    this.flux.actions.catalog.product.loadReviews();
  }

  getStateFromFlux() {
    const data = this.flux.store('DataStore').getState();
    return _.extend(
      {
        ageGroupChoices: Immutable.fromJS(
          data.productReviewChoices.ageGroupChoices
        ),
        sortChoices: Immutable.fromJS(data.productReviewChoices.sortChoices),
      },
      _.pick(this.flux.store('ProductStore').getState(), [
        'product',
        'reviews',
        'reviewsRatings',
        'reviewsFilterInfo',
      ]),
      _.pick(this.flux.store('AuthStore').getState(), ['user'])
    );
  }

  render() {
    if (!this.state.product) {
      return null;
    }
    const reviewCount = this.state.product.get('reviewCount');
    return (
      <LazyLoad
        offset={500}
        throttle={200}
        debounce={false}
        elementType="aside"
        className="one-whole rw"
      >
        <div className="one-whole">
          <h3 className="t-normal">Reviews</h3>
          {reviewCount ? (
            <ReviewStars
              className="pl dib"
              count={reviewCount}
              score={this.state.product.get('reviewScore')}
            />
          ) : null}
          <div className="[ centered ] [ smart-pl smart-pr tablet-phl tablet-phr lap-pz ] txt-left">
            <CreateProductReviewPanel
              ageGroupChoices={this.state.ageGroupChoices}
              product={this.state.product}
              reviews={this.state.reviews}
              reviewsRatings={this.state.reviewsRatings}
              user={this.state.user}
            />
            {reviewCount ? (
              <ProductPageReviewFilterPanel
                reviewsRatings={this.state.reviewsRatings}
                updateFilter={this.updateFilter}
                ageGroupChoices={this.state.ageGroupChoices}
                hasAgeGroup={this.state.reviewsFilterInfo.get('hasAgeGroup')}
                hasVerifiedPurchase={this.state.reviewsFilterInfo.get(
                  'hasVerifiedPurchase'
                )}
                sortChoices={this.state.sortChoices}
              />
            ) : null}
            <ProductPageReviewList
              product={this.state.product}
              user={this.state.user}
              reviews={this.state.reviews}
              vote={this.flux.actions.catalog.product.voteOnReview}
              loadMore={this.loadMoreReviews}
            />
          </div>
        </div>
      </LazyLoad>
    );
  }
}
ProductReviewPanel.watchedStores = ['ProductStore', 'AuthStore'];
