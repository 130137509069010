import PropTypes from 'prop-types';
import * as React from 'react';
import _ from 'lodash';

import {Loading} from '../../../components.jsx';
import FluxComponent from 'prometheus/flux/components.jsx';

export class ProductListingLoading extends FluxComponent {
  constructor(props) {
    super(props);
    this.state = _.extend(this.state || {}, {loading: false});
  }

  getStateFromFlux() {
    return _.pick(this.flux.store('ProductListingStore').getState(), [
      'loading',
    ]);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.loading !== nextState.loading;
  }

  componentDidMount() {
    super.componentDidMount();
    this.setRootClass();
  }

  componentDidUpdate() {
    this.setRootClass();
  }

  setRootClass() {
    if (this.props.root) {
      if (this.state.loading) {
        this.props.root.classList.add('loading');
      } else {
        this.props.root.classList.remove('loading');
      }
    }
  }

  render() {
    if (!this.state.loading) {
      return null;
    }
    return (
      <div className="product-list__loading-background ">
        <Loading className="mt pa pa--center" />
      </div>
    );
  }
}
ProductListingLoading.propTypes = _.assign(
  {},
  FluxComponent.constructor.propTypes,
  {
    root: PropTypes.oneOfType([
      PropTypes.instanceOf(window.Node),
      PropTypes.instanceOf(window.Element),
    ]),
  }
);
ProductListingLoading.watchedStores = ['ProductListingStore'];
