import _ from 'lodash';
import classNames from 'classnames';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import * as React from 'react';

import {FormErrorMessage} from '../form.jsx';

export class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
      changed: false,
      error: '',
      validated: false
    };
    [
      'validate',
      'handleReset',
      'handleInvalid',
      'handleValid',
      'handleChange',
      'handleBlur'
    ].forEach(method => {
      this[method] = this[method].bind(this);
    });
    this.delayValidate = _.debounce(this.validate, 1000);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(
      Immutable.is(
        Immutable.fromJS(this.props || {}),
        Immutable.fromJS(nextProps || {})
      ) &&
      Immutable.is(
        Immutable.fromJS(this.state || {}),
        Immutable.fromJS(nextState || {})
      )
    );
  }

  componentDidMount() {
    const elem = this.label;
    if (elem) {
      const form = elem.closest('form');
      const fieldset = elem.closest('fieldset');
      if (form) {
        form.addEventListener('submit', this.validate);
        form.addEventListener('-pr-validate', this.validate);
        form.addEventListener('reset', this.handleReset);
      }
      if (fieldset) {
        fieldset.addEventListener('-pr-validate', this.validate);
      }
      if (this.input) {
        const input = this.input;
        input.addEventListener('valid', this.handleValid);
        input.addEventListener('invalid', this.handleInvalid);
      }
      if (
        this.state.value &&
        !_.includes(['checkbox', 'radio'], this.getType())
      ) {
        this.validate();
      }
    }
  }

  componentWillUnmount() {
    const elem = this.node;
    if (elem) {
      const form = elem.closest('form');
      const fieldset = elem.closest('fieldset');
      if (form) {
        form.removeEventListener('submit', this.validate);
        form.removeEventListener('-pr-validate', this.validate);
        form.removeEventListener('reset', this.handleReset);
      }
      if (fieldset) {
        fieldset.removeEventListener('-pr-validate', this.validate);
      }
      if (this.input) {
        const input = this.input;
        input.removeEventListener('valid', this.handleValid);
        input.removeEventListener('invalid', this.handleInvalid);
      }
    }
  }

  handleReset() {
    this.setState({
      changed: false,
      validated: false,
      error: '',
      value: this.props.value || ''
    });
  }

  handleChange(ev) {
    if (!_.isUndefined(ev.target.value)) {
      const value = this.props.filter(ev.target.value);
      this.props.onChange(ev);
      this.setState(
        {value: value, changed: true, validated: false},
        this.delayValidate
      );
    }
  }

  handleBlur() {
    if (this.state.changed) {
      this.validate();
    }
  }

  handleValid() {
    this.validate();
  }

  handleInvalid() {
    const elem = this.input;
    this.setState({
      error: elem.validationMessage || this.props.invalidMessage,
      validated: false
    });
  }

  validator() {
    // Is passed this.state.value during validation.
    // Override this in other fields for field specific validation, e.g.
    // checking dates are valid, etc.
    return true;
  }

  validate(ev) {
    const elem = this.input;
    if (!elem) {
      return false;
    }
    elem.setCustomValidity('');
    let validated = true;
    if (!elem.checkValidity()) {
      this.setState({
        error: elem.validationMessage || this.props.invalidMessage,
        validated: false
      });
      validated = false;
    }
    if (
      !this.validator(this.state.value) ||
      !this.props.validator.bind(this)(this.state.value)
    ) {
      this.setState({
        error: this.props.validationMessage,
        validated: false
      });
      elem.setCustomValidity(this.props.validationMessage);
      validated = false;
    }
    if (validated) {
      this.setState({error: '', validated: true});
      elem.setCustomValidity('');
    }
    if (ev && !validated) {
      ev.preventDefault();
    }
    return validated;
  }

  getId() {
    return this.props.id || this.props.name;
  }

  getLabelId() {
    return this.getId() + '-label';
  }

  getHelpTextId() {
    return this.getId() + '-help-text';
  }

  getType() {
    return this.props.attrs.get('type') || this.props.type || 'text';
  }

  hasValue() {
    if (_.isBoolean(this.state.value)) {
      return true;
    }
    return !!this.state.value;
  }

  getLabelProps() {
    return {
      'aria-live': 'polite',
      htmlFor: this.getId(),
      className: classNames(
        this.props.className,
        'form__field',
        'form__field--' + this.getType(),
        {
          'form__field--required': this.props.attrs.get('required'),
          'form__field--error': !!this.state.error,
          'form__field--valid': this.state.validated && this.hasValue(),
          'form__field--initial': this.state.value === this.props.value,
          'form__field--changed': this.state.changed,
          'form__field--text-input': !_.includes(
            ['radio', 'checkbox'],
            this.getType()
          )
        }
      )
    };
  }

  getInputElement() {
    const attrs = _.defaults(this.props.attrs.toObject(), {
      type: this.getType()
    });
    return (
      <input
        {...attrs}
        ref={node => (this.input = node)}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        name={this.props.name}
        id={this.getId()}
        aria-labelledby={this.getLabelId()}
        aria-describedby={this.props.helpText ? this.getHelpTextId() : ''}
        value={this.state.value}
      />
    );
  }

  render() {
    let datalist = null;
    if (this.props.datalist && !this.props.datalist.isEmpty()) {
      const options = this.props.datalist
        .get('options')
        .toArray()
        .map((option, idx) => {
          const key = 'datalist-option-' + idx;
          return <option key={key} value={option} />;
        });
      datalist = (
        <datalist id={this.props.datalist.get('id')}>{options}</datalist>
      );
    }
    return (
      <label ref={node => (this.label = node)} {...this.getLabelProps()}>
        <div className="form__field__label" id={this.getLabelId()}>
          {this.props.label}
        </div>
        <div
          className={classNames(
            'form__field__input',
            this.props.inputWrapperClassName
          )}
        >
          {this.getInputElement()}
          {datalist}
        </div>
        {this.state.error ? (
          <FormErrorMessage
            className="form__field__error"
            error={this.state.error}
          />
        ) : this.props.helpText ? (
          <span
            className="form__field__help-text"
            id={this.getHelpTextId()}
            dangerouslySetInnerHTML={{__html: this.props.helpText}}
          />
        ) : (
          ''
        )}
      </label>
    );
  }
}
Input.propTypes = {
  attrs: ImmutablePropTypes.map,
  className: PropTypes.string,
  datalist: ImmutablePropTypes.contains({
    id: PropTypes.string,
    options: ImmutablePropTypes.list
  }),
  filter: PropTypes.func,
  helpText: PropTypes.node,
  id: PropTypes.string,
  type: PropTypes.string,
  inputWrapperClassName: PropTypes.string,
  invalidMessage: PropTypes.node,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  strictDatalist: PropTypes.bool.isRequired,
  validationMessage: PropTypes.node,
  validator: PropTypes.func,
  value: PropTypes.any
};
Input.defaultProps = {
  attrs: Immutable.Map(),
  filter: function(value) {
    return value;
  },
  invalidMessage: 'Please enter a valid value.',
  onChange: _.identity,
  strictDatalist: false,
  validator: function() {
    return true;
  },
  validationMessage: 'This field is invalid.',
  value: ''
};

export class InputGroup extends React.Component {
  render() {
    const children = React.Children.map(this.props.children, child => {
      const className = classNames(
        this.props.listItemClassName,
        child.props.listClassName,
        'form__input-group__fields__field'
      );
      const key = _.uniqueId('input-group--input-');
      return (
        <li className={className} key={key}>
          {child}
        </li>
      );
    });
    const className = classNames(this.props.className, 'form__input-group');
    const labelClassName = classNames(
      this.props.labelClassName,
      'form__input-group__label'
    );
    const listClassName = classNames(
      this.props.listClassName,
      'form__input-group__fields'
    );
    return (
      <div className={className}>
        <p className={labelClassName}>{this.props.label || ''}</p>
        <ul className={listClassName}>{children}</ul>
        {this.props.helpText ? (
          <span
            className="form__input-group__help-text"
            dangerouslySetInnerHTML={{__html: this.props.helpText}}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}
InputGroup.propTypes = {
  label: PropTypes.node,
  helpText: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  listClassName: PropTypes.string,
  listItemClassName: PropTypes.string,
  children: PropTypes.node
};
