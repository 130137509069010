import _ from 'lodash';

export function write() {
  _.defer(this.dispatch, 'SETTINGS:WRITE');
}

export function set(keyOrObject, value) {
  let payload = {};
  if (_.isPlainObject(keyOrObject) && _.isUndefined(value)) {
    payload = keyOrObject;
  } else {
    payload[keyOrObject] = value;
  }
  _.defer(this.dispatch, 'SETTINGS:SET', payload);
}

export function unset(key) {
  _.defer(this.dispatch, 'SETTINGS:UNSET', key);
}
