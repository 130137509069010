import _ from 'lodash';
import domready from 'domready';

/*
 * Global Variables.
 */
export const SHOULD_LOG = process.env.NODE_ENV === 'development';

/* eslint-disable no-console */
const HAS_TIMER =
  console && _.isFunction(console.time) && _.isFunction(console.timeEnd);
const HAS_GROUPS =
  console && _.isFunction(console.group) && _.isFunction(console.groupEnd);

/*
 * Handle the init of modules.
 */
export function run(name, func) {
  if (!window.Modernizr.supported) {
    return;
  }

  if (SHOULD_LOG) {
    if (HAS_GROUPS) {
      console.group(name);
    }
    if (HAS_TIMER) {
      console.time(name);
    }
  }

  try {
    func();
    if (SHOULD_LOG && console && _.isFunction(console.debug)) {
      console.debug('[SUCCESS]', name);
    }
  } catch (e) {
    if (console) {
      if (_.isFunction(console.error)) {
        console.error('[ERROR]', name, e);
      } else if (_.isFunction(console.log)) {
        console.log('[ERROR]', name, e);
      }
    }
    // Raven support if available.
    if (window.Raven) {
      window.Raven.captureException(e);
    }
  }
  if (SHOULD_LOG) {
    if (HAS_TIMER) {
      console.timeEnd(name);
    }
    if (HAS_GROUPS) {
      console.groupEnd();
    }
  }
}

export function ready(name, func) {
  if (SHOULD_LOG && HAS_GROUPS) {
    console.group(name);
  }
  if (SHOULD_LOG && console && _.isFunction(console.debug)) {
    console.debug('[QUEUED]', name);
  }
  domready(_.partial(run, name, func));
  if (SHOULD_LOG && HAS_GROUPS) {
    console.groupEnd();
  }
}
/* eslint-enable no-console */

export function applyAndObserve(selector, init, flux) {
  const seen = [];
  function _init(elem) {
    if (seen.indexOf(elem) === -1) {
      seen.push(elem);
      try {
        return init(elem);
      } catch (e) {
        flux.actions.console.error('[ERROR]', selector, init.name, e);
        return null;
      }
    }
    return elem;
  }

  Array.prototype.map.call(document.querySelectorAll(selector), _init);
  const options = {
    childList: true,
    subtree: true
  };
  const observer = new window.MutationObserver(
    _.debounce(
      function() {
        Array.prototype.map.call(document.querySelectorAll(selector), _init);
      },
      {leading: true}
    )
  );
  observer.observe(document.body, options);
}

export function jsonConfigView(elem, name, view, options) {
  return view(
    elem,
    _.extend(
      window.JSON.parse(elem.getAttribute('data-' + name) || '{}'),
      options || {}
    )
  );
}

export function jsonConfigViewAll(name, view, options) {
  return Array.prototype.map.call(
    document.querySelectorAll('[data-' + name + ']'),
    function(elem) {
      return jsonConfigView(elem, name, view, options);
    }
  );
}

export function windowHeight() {
  if (_.isNumber(document.documentElement.clientHeight)) {
    return document.documentElement.clientHeight;
  }
  if (_.isNumber(document.body.clientHeight)) {
    return document.body.clientHeight;
  }
  return window.innerHeight;
}

/*
 * Faciliate the remap of object keys. Can be performed recursively.
 */
export function mapKeys(obj, keyFunc = _.identity, recurse = false) {
  if (_.isArray(obj) && recurse) {
    return obj.map(x => mapKeys(x, keyFunc, recurse));
  }
  if (!_.isPlainObject(obj)) {
    return obj;
  }
  obj = _.mapKeys(obj, function(value, key) {
    return keyFunc(key);
  });
  if (recurse) {
    obj = _.mapValues(obj, function(value) {
      if (_.isArray(value)) {
        return value.map(x => mapKeys(x, keyFunc, recurse));
      }
      if (_.isPlainObject(value)) {
        return mapKeys(value, keyFunc, recurse);
      }
      return value;
    });
  }
  return obj;
}

/*
 * Remap of object keys to be camelCase. Can be performed recursively.
 */
export function camelKeys(obj, recurse = false) {
  return mapKeys(obj, _.camelCase, recurse);
}

/*
 * Remap of object keys to be snakeCase. Can be performed recursively.
 */
export function snakeKeys(obj, recurse = false) {
  return mapKeys(obj, _.snakeCase, recurse);
}
