import _ from "lodash";
import Fluxxor from "fluxxor";
import Immutable from "immutable";

import { camelKeys } from "prometheus/utils.js";

import { getCSRFToken } from "../csrf.js";
import { checkStatus } from "../fetch.js";
import { ItemArray } from "../timeline/models.js";
import {
  Product,
  ProductArray,
  ProductCategory,
  ProductCategoryArray,
  RelatedProductArray,
  DisplayReviewArray,
  DisplayReviewFilterInfo,
} from "./models.js";

const PAGE_REGEX = /[&?]page=(\d+)/;
const SKIP_CHOICES_KEY = "skip_choices";

function pageFromUrl(url) {
  if (!_.isString(url)) {
    return null;
  }
  const pageMatch = url.match(PAGE_REGEX);
  if (pageMatch) {
    return parseInt(pageMatch[1]);
  }
  return 1;
}

export const ProductListingStore = Fluxxor.createStore({
  LAST_SEARCH_RESULTS_KEY: "lastSearchResults",
  LIST_TYPE_KEY: "listType",

  STORED_STATE: [
    "fetchedPages",
    "query",
    "actualQuery",
    "isCategoryRelaxed",
    "categoryRecommendations",
    "products",
    "fauxProducts",
    "filters",
    "sorts",
    "categories",
    "category",
    "count",
    "next",
    "previous",
    "minPrice",
    "maxPrice",
  ],

  LIST_TYPES: ["full", "mini"],
  DEFAULT_LIST_TYPE: "full",

  actions: {
    INITIALIZED: "initializeHandler",
    "PRODUCT_LISTING:SET_QUERY": "setQuery",
    "PRODUCT_LISTING:SET_CATEGORY": "setCategory",
    "PRODUCT_LISTING:ADD_FILTER": "addFilter",
    "PRODUCT_LISTING:REMOVE_FILTER": "removeFilter",
    "PRODUCT_LISTING:CLEAR_FILTERS": "clearFilters",
    "PRODUCT_LISTING:SET_SORT": "setSort",
    "PRODUCT_LISTING:SET_MULTI": "setMulti",
    "PRODUCT_LISTING:FETCH_NEXT": "fetchNext",
    "PRODUCT_LISTING:FETCH_PREVIOUS": "fetchPrevious",
    "PRODUCT_LISTING:PRELOAD_NEXT": "preloadNext",
    "PRODUCT_LISTING:PRELOAD_PREVIOUS": "preloadPrevious",
    "PRODUCT_LISTING:SAVE_PAGE": "saveLastSearchResults",
    "PRODUCT_LISTING:SET_LIST_TYPE": "setListType",
  },

  initialize: function (options) {
    this.options = options;
    this.initialized = false;
    this._state = [];
    this.perPage = 24;
    this.fetchedPages = [];
    this.query = null;
    this.actualQuery = null;
    this.isCategoryRelaxed = false;
    this.categoryRecommendations = [];
    this.products = new ProductArray();
    this.filters = [];
    this.sorts = [];
    this.categories = [];
    this.category = null;
    this.count = null;
    this.next = null;
    this.previous = null;
    this.minPrice = null;
    this.maxPrice = null;
    this.listType = this.DEFAULT_LIST_TYPE;
    this.loading = false;
    this.rootCategories = [];
    this.fauxProducts = {
      searchTerm: "",
      items: [],
    };
    this.forceDisplayCounts = false;
    this.setTransientAll();

    this.setLoadingTrue = this.setLoading.bind(this, true);
    this.setLoadingFalse = this.setLoading.bind(this, false);
    [
      "emitChange",
      "handlePopstate",
      "saveLastSearchResults",
      "saveState",
      "restoreState",
      "setLoading",
      "maybeLoadFauxProducts",
      "loadFauxProducts",
    ].forEach((method) => (this[method] = this[method].bind(this)));
  },

  initializeHandler: function () {
    this.setListType();
    const listingData =
      this.flux.store("DataStore").getState().productListing || {};
    this.endpoint = listingData.endpoint;
    this.rootCategories = new ProductCategoryArray().parse(
      this.flux.store("DataStore").getState().rootCategories || [],
    );

    if (!this.endpoint) {
      return;
    }
    if (_.isFinite(listingData.page)) {
      this.fetchedPages.push(listingData.page);
    }
    this.query = listingData.query;
    if (listingData.category) {
      this.category = ProductCategory.parse(listingData.category);
    }
    if (listingData.fauxProducts) {
      this.fauxProducts = {
        searchTerm: this.query,
        items: camelKeys(listingData.fauxProducts, true),
      };
    }
    this.parse(listingData.data || {});

    window.addEventListener("popstate", this.handlePopstate);
    this.addListener("fetch:start", this.setLoadingTrue);
    this.addListener("parse:start", this.setLoadingTrue);
    this.addListener("parse:end", this.setLoadingFalse);
    this.addListener("parse:end", this.saveState);
    this.addListener("parse:end", (resp, strategy) => {
      return strategy === "reset"
        ? (document.getElementById("main") || document.body).scrollIntoView(
            true,
          )
        : null;
    });
    this.addListener("fetch:end", this.saveLastSearchResults);
    this.addListener("fetch:end", (...params) => {
      const [resp, strategy, skipHistory] = params;
      if (!skipHistory && resp) {
        this.pushState(
          resp,
          strategy === "append" || strategy == "prepend"
            ? "replaceState"
            : "pushState",
        );
      }
    });
    this.addListener("fetch:error", this.setLoadingFalse);
    this.addListener("fetch:error", this.restoreState);
    this.addListener("change", this.maybeLoadFauxProducts);
    this.saveLastSearchResults(
      _.isFinite(listingData.page) ? listingData.page : 1,
    );
    this.initialized = true;
    this.emit("initialized");
  },

  emitChange: _.debounce(function () {
    this.emit("change");
  }, 200),

  setLoading: function (loading) {
    const previousValue = this.loading;
    this.loading = loading;
    if (this.loading !== previousValue) {
      this.emitChange();
    }
  },

  setTransientSort: function () {
    const sortDefaults = { choices: [] };
    _.assign(this.transientStates, {
      sorts: Immutable.fromJS(
        this.sorts.map((sort) => _.defaults(sort, sortDefaults)),
      ),
    });
  },

  setTransientFilters: function () {
    const filterDefaults = {
      choices: [],
      isHidden: false,
      isRange: false,
      isMultipleChoice: false,
    };
    _.assign(this.transientStates, {
      filters: Immutable.fromJS(
        this.filters.map((filter) => _.defaults(filter, filterDefaults)),
      ),
    });
  },

  setTransientCategory: function () {
    _.assign(this.transientStates, {
      category: this.category ? Immutable.fromJS(this.category.toJSON()) : null,
    });
  },

  setTransientProducts: function () {
    const oldTransientStateProducts =
      this.transientStates.products || Immutable.List();
    const transientProductsIds = oldTransientStateProducts.map((product) =>
      product.get("id"),
    );

    const toMerge = transientProductsIds
      .toSet()
      .isSubset(this.products.map((product) => product.id));
    const newTransientStateProducts = Immutable.List(
      this.products.map((product) => {
        const found = oldTransientStateProducts.find(
          (oldProduct) =>
            toMerge &&
            oldProduct.get("id") == product.id &&
            oldProduct.get("lastModified") == product.lastModified.valueOf(),
        );
        return !_.isUndefined(found)
          ? found
          : Immutable.fromJS(product.toJSON());
      }),
    );
    _.assign(this.transientStates, {
      products: newTransientStateProducts,
    });
  },

  setTransientOthers: function () {
    _.assign(this.transientStates, {
      categories: Immutable.fromJS(
        this.categories.map((category) => category.toJSON()),
      ),
      isCategoryRelaxed: this.isCategoryRelaxed,
      categoryRecommendations: Immutable.fromJS(
        this.categoryRecommendations.map((category) => category.toJSON()),
      ),
      count: this.count,
      minPrice: this.minPrice,
      maxPrice: this.maxPrice,
      perPage: this.perPage,
      pageCount: Math.ceil(this.count / this.perPage),
      forceDisplayCounts: this.forceDisplayCounts,
    });
  },

  setTransientAll: function () {
    this.transientStates = {};
    this.setTransientSort();
    this.setTransientFilters();
    this.setTransientCategory();
    this.setTransientProducts();
    this.setTransientOthers();
  },

  getState: function () {
    return _.assign(
      {
        loading: this.loading,
        query: this.query,
        actualQuery: this.actualQuery,
        fauxProducts: Immutable.fromJS(this.fauxProducts.items),
        listType: this.listType,
        startPage: this.fetchedPages ? Math.min(...this.fetchedPages) : 1,
        endPage: this.fetchedPages ? Math.max(...this.fetchedPages) : 1,
        hasNext:
          _.isFinite(this.next) && !_.includes(this.fetchedPages, this.next),
        hasPrevious:
          _.isFinite(this.previous) &&
          !_.includes(this.fetchedPages, this.previous),
        lastSearchResults: localStorage.getItem(this.LAST_SEARCH_RESULTS_KEY),
      },
      this.transientStates,
    );
  },

  saveState: function () {
    this._state.push(
      _.cloneDeepWith(_.pick(this, this.STORED_STATE), (value) => {
        if (value && value.isState) {
          return new value.constructor(value.serialize(), { parse: true });
        }
        return undefined;
      }),
    );
    this._state = this._state.slice(-2);
  },

  restoreState: function () {
    if (this._state) {
      _.assign(this, this._state[this._state.length - 1]);
    }
    this.emitChange();
  },

  buildUrlQuery: function (data) {
    const url = [];
    if (this.query) {
      url.push("q=" + encodeURIComponent(this.query));
    }
    this.filters.forEach((filter) => {
      if (_.isNull(filter.data)) {
        return;
      }
      if (_.isPlainObject(filter.data)) {
        for (const k in filter.data) {
          const value = filter.data[k];
          if (!_.isNull(value)) {
            url.push(
              filter.name + "_" + k + "=" + encodeURIComponent(filter.data[k]),
            );
          }
        }
        return;
      }

      let values;
      if (Array.isArray(filter.data)) {
        values = filter.data;
      } else {
        values = [filter.data];
      }
      values.forEach((value) =>
        url.push(filter.name + "=" + encodeURIComponent(value)),
      );
    });
    this.sorts.forEach((sort) => {
      let values;
      if (Array.isArray(sort.data)) {
        values = sort.data;
      } else {
        values = [sort.data];
      }
      values.forEach((value) =>
        url.push(sort.name + "=" + encodeURIComponent(value)),
      );
    });
    for (const k in data) {
      url.push(k + "=" + encodeURIComponent(data[k].toString()));
    }
    return url.join("&");
  },

  buildUrl: function (data) {
    let url = this.endpoint;
    if (this.category) {
      url = url + this.category.slug + "/";
    }
    return url + "?" + this.buildUrlQuery(data);
  },

  buildPushUrl: function () {
    let url = "/catalog/";
    if (this.category) {
      url = url + this.category.slug + "/";
    }
    return url + "?" + this.buildUrlQuery();
  },

  pushState: function (response, method = "pushState") {
    let page = pageFromUrl(response.url);
    page = _.isFinite(page) ? "page=" + page : "";
    let pushUrl = this.buildPushUrl();
    if (page) {
      pushUrl = pushUrl + (pushUrl.indexOf("?") >= 0 ? "&" : "?") + page;
    }
    history[method]({ url: response.url }, "", pushUrl);
  },

  handlePopstate: function (ev) {
    if (ev.state && ev.state.url) {
      if (ev.state.url.startsWith("/catalog")) {
        this.fetch(ev.state.url, "reset", true);
      } else {
        window.location.reload();
      }
    }
  },

  saveLastSearchResults: function (page_or_response) {
    let url = this.buildPushUrl();
    let page = null;
    if (page_or_response && _.isFinite(page_or_response)) {
      page = page_or_response;
    } else if (page_or_response && page_or_response.url) {
      page = pageFromUrl(page_or_response.url);
    }
    if (_.isFinite(page)) {
      url = url + (url.indexOf("?") >= 0 ? "&" : "?") + "page=" + page;
    }
    try {
      localStorage.setItem(this.LAST_SEARCH_RESULTS_KEY, url);
    } catch (e) {
      this.flux.actions.console.error(e);
    }
  },

  fetch: function (url, strategy, skipHistory) {
    strategy = strategy || "reset";
    url = url || this.buildUrl();
    this.emit("fetch:start");
    fetch(url, {
      credentials: "same-origin",
    })
      .then(checkStatus)
      .then((response) => Promise.all([response, response.json()]))
      .then((parsed) => {
        const [response, data] = parsed;
        const page = pageFromUrl(response.url);
        if (_.isFinite(page)) {
          this.fetchedPages.push(page);
        }
        this.parse(data, strategy);
        this.emit("fetch:end", response, strategy, skipHistory);
      })
      .catch((err) => {
        this.emit("fetch:error", err);
      });
  },

  parse: function (data, strategy) {
    this.emit("parse:start");
    strategy = strategy || "reset";
    this.count = data.count || null;
    const next = pageFromUrl(data.next);
    const previous = pageFromUrl(data.previous);
    const skipFilterChoices = _.defaultTo(
      _.defaultTo(data.skip_filter_choices, data.skipFilterChoices),
      false,
    );
    if (
      _.isFinite(previous) &&
      (!_.isFinite(this.previous) || previous < this.previous)
    ) {
      this.previous = previous;
    } else if (previous === null && this.previous === 2) {
      this.previous = 1;
    }
    if (_.isFinite(next) && (!_.isFinite(this.next) || next > this.next)) {
      this.next = next;
    }

    const results = data.results || [];
    if (strategy === "append") {
      this.products.append(this.products.deserialize(results));
    } else if (strategy === "prepend") {
      this.products.prepend(this.products.deserialize(results));
    } else {
      this.products.reset(this.products.deserialize(results));
    }

    if (!skipFilterChoices) {
      this.categories = new ProductCategoryArray().parse(data.categories || []);
      this.filters = (data.filters || []).map((filter) =>
        camelKeys(filter, true),
      );
      this.sorts = (data.sorts || []).map((sort) => camelKeys(sort, true));
    }
    this.query = data.query;
    this.actualQuery = data.actual_query || data.actualQuery;
    this.isCategoryRelaxed = data.is_category_relaxed || data.isCategoryRelaxed;
    this.categoryRecommendations = new ProductCategoryArray().parse(
      data.category_recommendations || data.categoryRecommendations || [],
    );
    this.minPrice = data.min_price || data.minPrice;
    this.maxPrice = data.max_price || data.maxPrice;
    this.forceDisplayCounts =
      data.force_display_counts || data.forceDisplayCounts || false;
    if (skipFilterChoices) {
      this.setTransientProducts();
      this.setTransientOthers();
    } else {
      this.setTransientAll();
    }
    this.emit("parse:end", data, strategy);
    this.emitChange();
  },

  fetchNext: function (pages = 1) {
    if (!_.isFinite(this.next)) {
      return;
    }
    pages -= 1;
    if (pages > 0) {
      this.once("parse:end", () => this.fetchNext(pages));
    }
    this.fetch(
      this.buildUrl({ page: this.next, [SKIP_CHOICES_KEY]: "true" }),
      "append",
    );
  },

  fetchPrevious: function (pages = 1) {
    if (!_.isFinite(this.previous)) {
      return;
    }
    pages -= 1;
    if (pages > 0) {
      this.once("parse:end", () => this.fetchPrevious(pages));
    }
    this.fetch(
      this.buildUrl({ page: this.previous, [SKIP_CHOICES_KEY]: "true" }),
      "prepend",
    );
  },

  preloadNext: function () {
    if (!_.isFinite(this.next)) {
      return;
    }
    const url = this.buildUrl({ page: this.next, [SKIP_CHOICES_KEY]: "true" });
    const link = document.createElement("link");
    link.href = url;
    link.rel = "prefetch";
    if (!document.head.querySelector(`link[rel="prefetch"][href="${url}"]`)) {
      document.head.appendChild(link);
    }
  },

  preloadPrevious: function () {
    if (!_.isFinite(this.previous)) {
      return;
    }
    const url = this.buildUrl({
      page: this.previous,
      [SKIP_CHOICES_KEY]: "true",
    });
    const link = document.createElement("link");
    link.href = url;
    link.rel = "prefetch";
    if (!document.head.querySelector(`link[rel="prefetch"][href="${url}"]`)) {
      document.head.appendChild(link);
    }
  },

  update: _.debounce(function () {
    this.fetchedPages = [];
    this.next = null;
    this.previous = null;
    this.fetch(this.buildUrl());
  }, 200),

  setQuery: function (query) {
    this.setLoading(true);
    this.query = query;
    this.actualQuery = query;
    this.emitChange();
    this.update();
  },

  setCategory: function (category) {
    this.setLoading(true);
    if (!category) {
      this.category = null;
    } else {
      const slug = Immutable.Map.isMap(category)
        ? category.get("slug")
        : category.slug;
      this.category =
        this.categories.find((cat) => cat.slug === slug) ||
        this.rootCategories.find((cat) => cat.slug === slug) ||
        null;
    }
    this.isCategoryRelaxed = false;
    this.clearSamples();
    this.setTransientCategory();
    this.emitChange();
    this.update();
  },

  clearSamples: function () {
    const filter = _.find(this.filters, (filter) => filter.name === "samples");
    if (!filter) {
      return;
    }
    filter.data = null;
  },

  addFilter: function (payload) {
    const { value, name } = payload;
    const filter = _.find(this.filters, (filter) => filter.name === name);
    if (!filter) {
      return;
    }
    this.clearSamples();
    if (filter.isMultipleChoice || Array.isArray(filter.data)) {
      if (_.isNull(filter.data)) {
        filter.data = [value];
        this.setTransientFilters();
        this.emitChange();
        this.update();
      } else if (!_.includes(filter.data, value)) {
        filter.data.push(value);
        this.setTransientFilters();
        this.emitChange();
        this.update();
      }
    } else {
      if (filter.data !== value) {
        filter.data = value;
        this.setTransientFilters();
        this.emitChange();
        this.update();
      }
    }
  },

  removeFilter: function (payload) {
    const { value, name } = payload;
    const filter = _.find(this.filters, (filter) => filter.name === name);
    if (!filter) {
      return;
    }
    this.clearSamples();
    if (filter.isMultipleChoice || Array.isArray(filter.data)) {
      const removed = _.remove(filter.data, (val) => val === value);
      if (removed.length) {
        this.setTransientFilters();
        this.emitChange();
        this.update();
      }
    } else {
      if (filter.data === value) {
        if (_.isPlainObject(filter.data)) {
          for (const prop in filter.data) {
            filter.data[prop] = null;
          }
        } else {
          filter.data = null;
        }
        this.setTransientFilters();
        this.emitChange();
        this.update();
      }
    }
  },

  clearFilters: function () {
    this.filters = [];
    this.setTransientFilters();
    this.emitChange();
    this.update();
  },

  setSort: function (payload) {
    const { value, name } = payload;
    const sort = _.find(this.sorts, (sort) => sort.name === name);
    if (!sort) {
      return;
    }
    const valid = sort.choices.map((choice) => choice[0]);
    this.clearSamples();
    if (!value && sort.data !== sort["default"]) {
      sort.data = sort["default"];
      this.setTransientSort();
      this.emitChange();
      this.update();
    } else if (valid.indexOf(value) !== -1 && sort.data !== value) {
      sort.data = value;
      this.setTransientSort();
      this.emitChange();
      this.update();
    }
  },

  setMulti: function (payload) {
    if ("sort" in payload) {
      this.setSort({
        name: "sort",
        value: payload.sort,
      });
    }
    if ("category" in payload) {
      this.setCategory(payload.category);
    }

    if ("query" in payload) {
      this.query = payload.query;
    }

    if (payload.filters === null) {
      this.clearFilters();
    } else if (_.isPlainObject(payload.filters)) {
      if (_.isArray(payload.filters.add)) {
        payload.filters.add.forEach((filter) => this.addFilter(filter));
      }
      if (_.isArray(payload.filters.remove)) {
        payload.filters.remove.forEach((filter) => this.removeFilter(filter));
      }
    } else if (_.isArray(payload.filters)) {
      this.filters = payload.filters;
    }
    this.clearSamples();
    this.setTransientSort();
    this.setTransientFilters();
    this.setTransientCategory();
    this.emitChange();
    this.update();
  },

  maybeLoadFauxProducts: function () {
    if (
      _.defaultTo(this.query, "") !==
      _.defaultTo(this.fauxProducts.searchTerm, "")
    ) {
      this.loadFauxProducts();
    }
  },

  loadFauxProducts: function () {
    if (this.query) {
      fetch(
        `/api/promotion/faux-products-search/?search_term=${encodeURIComponent(
          this.query,
        )}`,
        {
          credentials: "same-origin",
        },
      )
        .then(checkStatus)
        .then((response) => response.json())
        .then((data) => {
          this.fauxProducts = {
            searchTerm: this.query,
            items: camelKeys(data.results || [], true),
          };
          this.emitChange();
        })
        .catch(() => {
          // pass
        });
    } else {
      this.fauxProducts = { searchTerm: "", items: [] };
      this.emitChange();
    }
  },

  setListType(type) {
    if (type === undefined || this.LIST_TYPES.indexOf(type) === -1) {
      try {
        type =
          localStorage.getItem(this.LIST_TYPE_KEY) || this.DEFAULT_LIST_TYPE;
      } catch (e) {
        this.flux.actions.console.error(e);
        type = this.DEFAULT_LIST_TYPE;
      }
    }
    this.listType = type;
    try {
      localStorage.setItem(this.LIST_TYPE_KEY, type);
    } catch (e) {
      this.flux.actions.console.error(e);
    }
    this.emitChange();
  },
});

export const ProductStore = Fluxxor.createStore({
  /*
   * Contains data for a single product and it's related data structures.
   *
   * Used primarily on the product page
   *
   */

  actions: {
    INITIALIZED: "initializeHandler",
    "PRODUCT:SET_VARIANT": "setVariant",
    "PRODUCT:LOAD_ORDER_RELATED_PRODUCTS": "loadOrderRelatedProducts",
    "PRODUCT:LOAD_SEARCH_RELATED_PRODUCTS": "loadSearchRelatedProducts",
    "PRODUCT:LOAD_EVENT_RELATED_CONTENT": "loadEventRelatedContent",
    "PRODUCT:LOAD_POST_RELATED_CONTENT": "loadPostRelatedContent",
    "PRODUCT:REVIEW:VOTE": "voteOnReview",
    "PRODUCT:REVIEW:SUBMIT": "submitReview",
    "PRODUCT:REVIEWS:LOAD": "loadReviews",
    "PRODUCT:REVIEWS:UPDATE_FILTER": "updateReviewsFilter",
    "PRODUCT:REVIEWS:LOAD_MORE": "loadReviews",
    "PRODUCT:SET_NAV_STICKY_VISIBILITY": "setNavStickyVisibility",
    "PRODUCT:SHOW_ENLARGED_IMAGES": "showEnlargedImages",
    "PRODUCT:HIDE_ENLARGED_IMAGES": "hideEnlargedImages",
  },

  emitChange: _.debounce(function () {
    this.emit("change");
  }, 200),

  initialize: function (options) {
    this.options = options;
    if (this.options.product) {
      this.product = this.options.product;
    } else if (this.options.productData) {
      this.product = Product.parse(this.options.productData);
    }
    this.reviews = new DisplayReviewArray();
    this.initialized = false;
    this.enlargedImages = false;
    this.enlargedImageIndex = null;
    ["emitChange"].forEach(
      (method) => (this[method] = this[method].bind(this)),
    );
  },

  initializeHandler: function () {
    if (_.isUndefined(this.product)) {
      const productData = this.flux.store("DataStore").getState().product;
      if (!_.isUndefined(productData)) {
        this.product = Product.parse(productData);
      }
    }
    if (this.product && _.isFinite(this.product.id)) {
      this.reviews = new DisplayReviewArray([], {
        url: `/api/products/${this.product.id}/reviews/`,
      });
    }
    this.variant = null;
    this.initialized = true;
    this.emit("initialized");
  },

  getState: function () {
    return {
      product: this.product ? Immutable.fromJS(this.product.toJSON()) : null,
      variant: this.variant ? Immutable.fromJS(this.variant) : null,
      reviews: Immutable.Map({
        data: this.reviews ? Immutable.fromJS(this.reviews.toJSON()) : null,
        state: this.reviews ? Immutable.fromJS(this.reviews.state) : null,
        hasNextPage: this.reviews._hasNextPage,
      }),
      reviewsRatings: Immutable.Map({
        data:
          this.reviewsFilterInfo && this.reviewsFilterInfo.ratings
            ? Immutable.fromJS(this.reviewsFilterInfo.ratings.toJSON())
            : null,
        state: this.reviewsFilterInfo
          ? Immutable.fromJS(this.reviewsFilterInfo.state)
          : null,
      }),
      reviewsFilterInfo: Immutable.Map(
        this.reviewsFilterInfo
          ? {
              hasAgeGroup: this.reviewsFilterInfo.hasAgeGroup,
              hasVerifiedPurchase: this.reviewsFilterInfo.hasVerifiedPurchase,
            }
          : {
              hasAgeGroup: false,
              hasVerifiedPurchase: false,
            },
      ),
      orderRelatedProducts: Immutable.Map({
        data: this.orderRelatedProducts
          ? Immutable.fromJS(this.orderRelatedProducts.toJSON())
          : null,
        state: this.orderRelatedProducts
          ? Immutable.fromJS(this.orderRelatedProducts.state)
          : null,
      }),
      searchRelatedProducts: Immutable.Map({
        data: this.searchRelatedProducts
          ? Immutable.fromJS(this.searchRelatedProducts.toJSON())
          : null,
        state: this.searchRelatedProducts
          ? Immutable.fromJS(this.searchRelatedProducts.state)
          : null,
      }),
      eventRelatedContent: Immutable.Map({
        data: this.eventRelatedContent
          ? Immutable.fromJS(this.eventRelatedContent.toJSON())
          : null,
        state: this.eventRelatedContent
          ? Immutable.fromJS(this.eventRelatedContent.state)
          : null,
      }),
      postRelatedContent: Immutable.Map({
        data: this.postRelatedContent
          ? Immutable.fromJS(this.postRelatedContent.toObject())
          : null,
        state: this.postRelatedContent
          ? Immutable.fromJS(this.postRelatedContent.state)
          : null,
      }),
      navStickyVisible: this.navStickyVisible,
      enlargedImages: this.enlargedImages,
      enlargedImageIndex: this.enlargedImageIndex,
    };
  },

  showEnlargedImages: function (index) {
    if (!this.enlargedImages) {
      this.enlargedImages = true;
      this.enlargedImageIndex = index || 0;
      this.emitChange();
    }
  },

  hideEnlargedImages: function () {
    if (this.enlargedImages) {
      this.enlargedImages = false;
      this.enlargedImageIndex = null;
      this.emitChange();
    }
  },

  setVariant: function (payload) {
    this.variant = this.product.variants.find(_.matches(payload));
    this.emitChange();
  },

  loadOrderRelatedProducts: function () {
    const postFetch = () => {
      this.orderRelatedProducts.state.loading = false;
      this.emitChange();
    };
    if (!_.isUndefined(this.orderRelatedProducts)) {
      if (this.orderRelatedProducts._hasNextPage) {
        this.orderRelatedProducts.state.loading = true;
        this.emitChange();
        this.orderRelatedProducts
          .fetchNextPage()
          .then(postFetch)
          .catch(postFetch);
      }
      return;
    }
    this.orderRelatedProducts = new RelatedProductArray([], {
      url: `/api/products/${this.product.id}/related/order/`,
    });
    this.orderRelatedProducts.state = { loading: false };
    this.orderRelatedProducts.read().then(postFetch).catch(postFetch);
  },

  loadSearchRelatedProducts: function () {
    const postFetch = () => {
      this.searchRelatedProducts.state.loading = false;
      this.emitChange();
    };
    if (!_.isUndefined(this.searchRelatedProducts)) {
      if (this.searchRelatedProducts._hasNextPage) {
        this.searchRelatedProducts.state.loading = true;
        this.emitChange();
        this.searchRelatedProducts
          .fetchNextPage()
          .then(postFetch)
          .catch(postFetch);
      }
      return;
    }
    this.searchRelatedProducts = new RelatedProductArray([], {
      url: `/api/products/${this.product.id}/related/search/`,
    });
    this.searchRelatedProducts.state = { loading: false };
    this.searchRelatedProducts.read().then(postFetch).catch(postFetch);
  },

  loadEventRelatedContent: function () {
    const postFetch = () => {
      this.eventRelatedContent.state.loading = false;
      this.emitChange();
    };
    if (!_.isUndefined(this.eventRelatedContent)) {
      if (this.eventRelatedContent._hasNextPage) {
        this.eventRelatedContent.state.loading = true;
        this.emitChange();
        this.eventRelatedContent
          .fetchNextPage()
          .then(postFetch)
          .catch(postFetch);
      }
      return;
    }
    this.eventRelatedContent = new ItemArray([], {
      url: `/api/products/${this.product.id}/related-content/event/`,
    });
    this.eventRelatedContent.state = { loading: true };
    this.emitChange();
    this.eventRelatedContent.read().then(postFetch).catch(postFetch);
  },

  loadPostRelatedContent: function () {
    const postFetch = () => {
      this.postRelatedContent.state.loading = false;
      this.emitChange();
    };
    if (!_.isUndefined(this.postRelatedContent)) {
      if (this.postRelatedContent._hasNextPage) {
        this.postRelatedContent.state.loading = true;
        this.emitChange();
        this.postRelatedContent
          .fetchNextPage()
          .then(postFetch)
          .catch(postFetch);
      }
      return;
    }
    this.postRelatedContent = new ItemArray([], {
      url: `/api/products/${this.product.id}/related-content/event/`,
    });
    this.postRelatedContent.state = { loading: true };
    this.emitChange();
    this.postRelatedContent.read().then(postFetch).catch(postFetch);
  },

  loadReviewsFilterInfo: function () {
    if (!_.isUndefined(this.reviewsFilterInfo)) {
      return;
    }
    this.reviewsFilterInfo = new DisplayReviewFilterInfo(
      { reviews: [] },
      { url: `/api/products/${this.product.id}/reviews/filter-info/` },
    );
    const postFetch = () => {
      this.reviewsFilterInfo.state.loading = false;
      this.emitChange();
    };
    this.reviewsFilterInfo.state = { loading: true };
    this.emitChange();
    this.reviewsFilterInfo.read().then(postFetch).catch(postFetch);
  },

  loadReviews: function () {
    const postFetch = () => {
      this.reviews.state.loading = false;
      this.emitChange();
    };
    if (_.isUndefined(this.reviewsQuery)) {
      this.reviewsQuery = {};
    }
    if (_.isUndefined(this.reviewsPageNumber)) {
      this.reviewsPageNumber = 1;
    }
    if (!_.isUndefined(this.reviews)) {
      if (this.reviews._hasNextPage) {
        this.reviews.state.loading = true;
        this.emitChange();
        this.reviews.fetchNextPage().then(postFetch).catch(postFetch);
        return;
      }
    }

    this.reviews = new DisplayReviewArray([], {
      url: `/api/products/${
        this.product.id
      }/reviews/?${this._buildReviewsUrlQuery(this.reviewsQuery)}`,
    });
    this.reviews.state = { loading: true };
    this.emitChange();
    this.reviews.read().then(postFetch).catch(postFetch);
    this.loadReviewsFilterInfo();
  },

  _buildReviewsUrlQuery: function (data) {
    let url = [];
    const dataList = _.flatten(
      _.map(
        _.filter(_.toPairs(data), (item) => {
          // Check for valid value
          const value = item[1];
          return !(
            _.isNull(value) ||
            ((_.isString(value) || _.isArray(value)) && value.length === 0) ||
            (Immutable.isImmutable(value) && value.isEmpty())
          );
        }),
        (item) => {
          // Set Key/Value Pairs.
          const key = _.snakeCase(item[0]);
          let value = item[1];
          if (Immutable.List.isList(value) || _.isArray(value)) {
            if (Immutable.List.isList(value)) {
              value = value.toJS();
            }
            return _.map(value, (subval) => {
              return [key, subval];
            });
          } else {
            return [[key, value]];
          }
        },
      ),
    );
    dataList.forEach((item) => {
      url.push(item[0] + "=" + encodeURIComponent(item[1]));
    });
    return url.join("&");
  },

  updateReviewsFilter: function (payload) {
    delete this.reviews;
    this.reviewsQuery = payload.query;
    this.reviewsPageNumber = 1;
    this.loadReviews();
  },

  submitReview: function (payload) {
    payload.product = this.product.id;
    fetch("/api/product-reviews/", {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "x-csrftoken": getCSRFToken(),
        "content-type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then(checkStatus)
      .then(() => {
        this.emit("review:submitted");
        this.emitChange();
      })
      .catch(() => {
        this.emit("review:error");
        this.emitChange();
      });
  },

  voteOnReview: function (payload) {
    if (Immutable.isImmutable(payload.review)) {
      payload.review = payload.review.toJS();
    }
    const review = _.isUndefined(this.reviews)
      ? undefined
      : this.reviews.find(
          (review) =>
            (_.isFinite(payload.review.id) &&
              review.id === payload.review.id) ||
            _.isMatch(review, payload.review),
        );
    const errorCallback = payload.errorCallback;
    const successCallback = payload.successCallback;
    if (!review) {
      this.flux.actions.console.error("No review matching " + payload.review);
      return;
    }
    if (!_.includes(["up", "down", "clear"], payload.direction)) {
      this.flux.actions.console.error(
        'direction must be one of "up", "down", or "clear"',
        payload.direction,
      );
      return;
    }
    fetch(`/api/product-reviews/${review.id}/vote/${payload.direction}/`, {
      credentials: "same-origin",
      headers: {
        "x-csrftoken": getCSRFToken(),
      },
    })
      .then((response) => Promise.all([response, response.json()]))
      .then((parsed) => {
        const [response, data] = parsed;
        if (response.ok) {
          // We register the review and update the model in place, we don't
          // particularly care if there have been other votes since the time
          // the review was refreshed from the server, just that the user sees
          // that their vote has been counted.
          if (payload.direction === "up" && review.voteStatus !== "up") {
            review.setMany({
              upVotes: review.upVotes + 1,
              downVotes:
                review.voteStatus === "down"
                  ? review.downVotes - 1
                  : review.downVotes,
              votes:
                review.voteStatus === "down" ? review.votes : review.votes + 1,
              voteStatus: "up",
            });
          } else if (
            payload.direction === "down" &&
            review.voteStatus !== "down"
          ) {
            review.setMany({
              downVotes: review.downVotes + 1,
              upVotes:
                review.voteStatus === "up"
                  ? review.upVotes - 1
                  : review.upVotes,
              votes:
                review.voteStatus === "up" ? review.votes : review.votes + 1,
              voteStatus: "down",
            });
          } else if (
            payload.direction === "clear" &&
            _.includes(["up", "down"], review.voteStatus)
          ) {
            review.setMany({
              votes: review.votes - 1,
              upVotes:
                review.voteStatus === "up"
                  ? review.upVotes - 1
                  : review.upVotes,
              downVotes:
                review.voteStatus === "down"
                  ? review.downVotes - 1
                  : review.downVotes,
              voteStatus: null,
            });
          }
          if (_.isFunction(successCallback)) {
            successCallback();
          }
        } else {
          if (_.isFunction(errorCallback) && data) {
            if (data.detail === "Already downvoted.") {
              review.set("voteStatus", "down");
            } else if (data.detail === "Already upvoted.") {
              review.set("voteStatus", "up");
            }
            errorCallback(data.detail);
          }
        }
      })
      .catch((err) => {
        this.flux.actions.console.error(err);
      });
  },

  setNavStickyVisibility: function (status) {
    this.navStickyVisible = status;
    this.emitChange();
  },
});

export const RecommendedProductStore = Fluxxor.createStore({
  actions: {
    INITIALIZED: "initializeHandler",
  },

  initialize: function (options) {
    this.options = options;
    this.products = new ProductArray();
    this.cartProducts = new ProductArray();
    this.category = null;
  },

  getState: function () {
    return {
      products: Immutable.fromJS(
        this.products.map((product) => Immutable.fromJS(product.toObject())),
      ),
      cartProducts: Immutable.fromJS(
        this.cartProducts.map((product) =>
          Immutable.fromJS(product.toObject()),
        ),
      ),
      category: Immutable.fromJS(this.category),
    };
  },

  emitChange: _.debounce(function () {
    this.emit("change");
  }, 200),

  initializeHandler: function () {
    this.waitFor(["DataStore", "CartStore"], (dataStore, cartStore) => {
      const categoryData = dataStore.getState().landingCategory;
      if (categoryData) {
        try {
          this.category = ProductCategory.parse(categoryData);
        } catch (e) {
          // pass
        }
      }
      if (cartStore.getState().cart.get("items").size) {
        this.updateCartProducts();
      }
      cartStore.on("change", this.updateCartProducts);
    });
  },

  fetch: function (url, assignTo) {
    this[assignTo] = new ProductArray([], { url: url });
    this[assignTo]
      .read()
      .then(() => {
        this.emitChange();
      })
      .catch(() => {
        // pass
      });
  },

  updateCartProducts: _.debounce(function () {
    let updated;
    try {
      updated = this.flux
        .store("CartStore")
        .getState()
        .cart.get("lastModified")
        .toISOString();
    } catch (e) {
      updated = "";
    }
    this.fetch(`/api/products/recommended/cart/?${updated}`, "cartProducts");
  }, 4000),
});
