import Fluxxor from 'fluxxor';

export const TooltipStore = Fluxxor.createStore({
  actions: {
    'TOOLTIP:OPENED': 'handleOpened'
  },

  handleOpened: function(payload) {
    this.emit('opened', payload);
  }
});
