import _ from 'lodash';
import Fluxxor from 'fluxxor';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import * as React from 'react';

export default class FluxComponent extends React.Component {
  constructor(props) {
    super(props);
    ['setStateFromFlux', 'getStateFromFlux'].forEach(method => {
      this[method] = this[method].bind(this);
    });
    this.state = {};
    if (this.props.flux) {
      this.flux = this.props.flux;
      this.state = this.getStateFromFlux();
    }
  }

  UNSAFE_componentWillMount() {
    if (!this.flux) {
      console.error('Falling back to deprecated context API'); // eslint-disable-line no-console
      if (!this.context || !this.context.flux) {
        const namePart = this.constructor.displayName
          ? ' of ' + this.constructor.displayName
          : '';
        throw new Error(
          'Could not find flux on this.props or this.context' + namePart
        );
      }
      this.flux = this.context.flux;
      this.setStateFromFlux();
    }
  }

  componentDidMount() {
    this.setState(this.getStateFromFlux());
    this.constructor.watchedStores.forEach(function(store) {
      this.flux.store(store).addListener('change', this.setStateFromFlux);
    }, this);
  }

  componentWillUnmount() {
    this.constructor.watchedStores.forEach(function(store) {
      this.flux.store(store).removeListener('change', this.setStateFromFlux);
    }, this);
  }

  getChildContext() {
    return {
      flux: this.flux
    };
  }

  setStateFromFlux() {
    this.setState(this.getStateFromFlux());
  }

  getStateFromFlux() {
    return {};
  }
}
FluxComponent.propTypes = {
  flux: PropTypes.instanceOf(Fluxxor.Flux)
};
FluxComponent.contextTypes = {
  flux: PropTypes.instanceOf(Fluxxor.Flux)
};
FluxComponent.childContextTypes = {
  flux: PropTypes.instanceOf(Fluxxor.Flux)
};
FluxComponent.watchedStores = [];

export class SimpleImmutableFluxComponent extends FluxComponent {
  shouldComponentUpdate(nextProps, nextState) {
    return !(
      Immutable.fromJS(_.omit(this.props || {}, ['children', 'flux'])).equals(
        Immutable.fromJS(_.omit(nextProps || {}, ['children', 'flux']))
      ) &&
      Immutable.fromJS(this.state || {}).equals(
        Immutable.fromJS(nextState || {})
      )
    );
  }
}
SimpleImmutableFluxComponent.propTypes = _.clone(FluxComponent.propTypes);
SimpleImmutableFluxComponent.contextTypes = _.clone(FluxComponent.contextTypes);
SimpleImmutableFluxComponent.childContextTypes = _.clone(
  FluxComponent.childContextTypes
);
SimpleImmutableFluxComponent.watchedStores = [];
