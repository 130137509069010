import _ from 'lodash';
import Fluxxor from 'fluxxor';
import Immutable from 'immutable';

export const MediaStore = Fluxxor.createStore({
  actions: {
    INITIALIZED: 'initializeHandler',
    'MEDIA:BREAKPOINT': 'handleBreakpointChange',
    'MEDIA:ORIENTATION': 'handleOrientationChange'
  },

  initialize: function(options) {
    const root = options.namespace ? window[options.namespace] : window;
    this.active = null;
    this.breakpoints = null;
    this.orientation = null;
    this.orientations = null;
    this.initialized = false;
    this.previousState = null;
    this.previousActive = null;
    this.previousOrientation = null;
    this.breakpointsConfig =
      root.media && root.media.breakpoints
        ? root.media.breakpoints
        : {
            mini: 'only screen',
            smart: 'only screen and (min-width: 20em)',
            phablet: 'only screen and (min-width: 40em)',
            tablet: 'only screen and (min-width: 48em)',
            lap: 'only screen and (min-width: 64em)',
            desk: 'only screen and (min-width: 120em)'
          };
  },

  initializeHandler: function() {
    if (_.isUndefined(window.matchMedia)) {
      return;
    }

    if (this.breakpointsConfig) {
      this.breakpoints = _.mapValues(this.breakpointsConfig, v =>
        window.matchMedia(v)
      );
    }
    function breakpointListener(mq) {
      _.defer(this.flux.actions.media.changeBreakpoint, mq, this.breakpoints);
    }
    for (const breakpoint in this.breakpoints) {
      const mq = this.breakpoints[breakpoint];
      mq.addListener(breakpointListener.bind(this));
    }

    this.orientations = {
      landscape: window.matchMedia('(orientation: landscape)'),
      portrait: window.matchMedia('(orientation: portrait)')
    };
    function orientationListener(mq) {
      _.defer(this.flux.actions.media.changeOrientation, mq, this.orientations);
    }
    for (const o in this.orientations) {
      const mq = this.orientations[o];
      mq.addListener(orientationListener.bind(this));
    }

    this.handleBreakpointChange();
    this.handleOrientationChange();

    this.initialized = true;
    this.emit('initialized');
  },

  getState: function() {
    return {
      previousOrientation: this.previousOrientation,
      previousActive: this.previousActive,
      active: this.active ? Immutable.fromJS(this.active) : null,
      breakpoints: this.breakpoints ? Immutable.fromJS(this.breakpoints) : null,
      orientation: this.orientation,
      orientations: this.orientations
        ? Immutable.fromJS(this.orientations)
        : null
    };
  },

  handleBreakpointChange: function() {
    this.previousState = this.getState();
    this.previousActive = this.active ? Immutable.fromJS(this.active) : null;
    this.active = {};
    for (const breakpoint in this.breakpoints) {
      this.active[breakpoint] = this.breakpoints[breakpoint].matches;
    }
    this.emit('change');
    this.emit('change:breakpoint');
  },

  handleOrientationChange: function() {
    this.previousState = this.getState();
    this.previousOrientation = this.orientation;
    this.orientation = null;
    for (const orientation in this.orientations) {
      if (this.orientations[orientation].matches) {
        this.orientation = orientation;
      }
    }
    this.emit('change');
    this.emit('change:orientation');
  }
});
