export function isRequired(value) {
  return !!value;
}

export function notNull(value) {
  return value !== null && value !== undefined;
}

export function oneOf(values) {
  return (value) => value === null || values.includes(value);
}
