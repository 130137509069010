import _ from 'lodash';
import Fluxxor from 'fluxxor';
import Immutable from 'immutable';

export const FeedbackSurveyMessagesStore = Fluxxor.createStore({
  actions: {
    INITIALIZED: 'initializeHandler',
    'FEEDBACK_MESSAGES:DISMISS': 'dismiss',
  },

  initialize: function (options) {
    this.options = options;
    this.initialized = false;
    this._name = '__feedbacksurveymessages-store__';
  },

  initializeHandler: function () {
    this.dismissedKey = `${this._name}.dismissed`;
    try {
      const dismissed = JSON.parse(
        window.localStorage.getItem(this.dismissedKey)
      );
      this.dismissed = _.isArray(dismissed) ? dismissed : [];
    } catch (e) {
      this.dismissed = null;
      this.flux.actions.console.error(e);
    }
    this.messages =
      this.flux.store('DataStore').getState().feedbackSurveyMessages || [];
    this.initialized = true;
    this.emit('initialized');
    if (this.messages || this.dismissed) {
      this.emit('change');
    }
  },

  getState: function () {
    return {
      allMessages: this.messages ? Immutable.fromJS(this.messages) : null,
      dismissed: this.dismissed ? Immutable.fromJS(this.dismissed) : null,
      messages:
        this.messages && this.dismissed
          ? Immutable.fromJS(
              this.messages.filter(
                (message) => !_.includes(this.dismissed, message.id)
              )
            )
          : null,
    };
  },

  dismiss: function (messageOrId) {
    const id = messageOrId.id || messageOrId;
    if (!_.includes(this.dismissed, id)) {
      this.dismissed.push(id);
      try {
        window.localStorage.setItem(
          this.dismissedKey,
          JSON.stringify(this.dismissed)
        );
      } catch (e) {
        this.flux.actions.console.error(e);
      }
      this.emit('change');
    }
  },
});
