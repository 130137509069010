// core deps
const crypto = require('crypto');

// locals
const consts = {
  TOP: 'top',
  MIDDLE: 'middle',
  BOTTOM: 'bottom',

  RIGHT: 'right',
  CENTER: 'center',
  LEFT: 'left',
};

class Thumbor {
  /**
   * constructor
   *
   * @param {String}      thumborServerUrl
   * @param {String|null} securityKey
   */
  constructor(thumborServerUrl, securityKey) {
    this.THUMBOR_SECURITY_KEY = securityKey;
    this.THUMBOR_URL_SERVER = thumborServerUrl;

    this.metaFlag = false;
    this.trimFlag = false;
    this.trimOptions = '';
    this.cropValues = null;
    this.fitInFlag = false;
    this.width = 0;
    this.height = 0;
    this.flipHorizontallyFlag = false;
    this.flipVerticallyflag = false;
    this.halignValue = null;
    this.valignValue = null;
    this.smartFlag = false;
    this.filtersCalls = [];
    this.imagePath = '';
  }

  /**
   * Specify that JSON metadata should be returned instead of the thumbnailed
   * image.
   */
  meta() {
    this.metaFlag = true;
    return this;
  }

  /**
   * Removing surrounding space in images can be done using the trim option.
   * Unless specified trim assumes the top-left pixel color and no tolerance
   * (more on tolerance below).
   *
   * If you need to specify the orientation from where to get the pixel
   * color, just pass 'top-left' in options for the
   * top-left pixel color or' bottom-right' for the bottom-right pixel color.
   *
   * Trim also supports color tolerance. The euclidian distance between the
   * colors of the reference pixel and the surrounding pixels is used. If
   * the distance is within the tolerance they’ll get trimmed. For a RGB
   * image the tolerance would be within the range 0-442.
   *
   * @param  {String} orientation
   */
  trim(orientation, colorTolerance) {
    this.trimFlag = true;
    if (orientation || colorTolerance) {
      this.trimOptions = {
        orientation: orientation ? orientation : null,
        colorTolerance: colorTolerance ? colorTolerance : null,
      };
    }
    return this;
  }

  /**
   * Manually specify crop window.
   *
   * @param  {Integer} left
   * @param  {Integer} top
   * @param  {Integer} right
   * @param  {Integer} bottom
   * @return {[type]}
   */
  crop(left, top, right, bottom) {
    this.cropValues = {
      left: left,
      top: top,
      right: right,
      bottom: bottom,
    };
    return this;
  }

  /**
   * Resize the image to fit in a box of the specified dimensions. Overrides
   * any previous call to `fitIn` or `resize`.
   *
   * @param  {String} width
   * @param  {String} height
   */
  fitIn(width, height) {
    this.fitInFlag = true;
    return this.resize(width, height);
  }

  /**
   * Resize the image to the specified dimensions. Overrides any previous call
   * to `fitIn` or `resize`.
   *
   * Use a value of 0 for proportional resizing. E.g. for a 640 x 480 image,
   * `.resize(320, 0)` yields a 320 x 240 thumbnail.
   *
   * Use a value of 'orig' to use an original image dimension. E.g. for a 640
   * x 480 image, `.resize(320, 'orig')` yields a 320 x 480 thumbnail.
   * @param  {String} width
   * @param  {String} height
   */
  resize(width, height) {
    this.width = width || '';
    this.height = height || '';
    return this;
  }

  /**
   * Flip image horizontally
   */
  flipHorizontally() {
    this.flipHorizontallyFlag = true;
    return this;
  }

  /**
   * Flip image vertically
   */
  flipVertically() {
    this.flipVerticallyFlag = true;
    return this;
  }

  /**
   * Specify horizontal alignment used if width is altered due to cropping
   *
   * @param  {String} halign 'left', 'center', 'right'
   */
  halign(halign) {
    if (
      halign !== consts.LEFT &&
      halign !== consts.RIGHT &&
      halign !== consts.CENTER
    ) {
      throw new Error(
        `Horizontal align must be ${consts.LEFT}, ${consts.RIGHT} or ${consts.CENTER}.`
      );
    }
    this.halignValue = halign;
    return this;
  }

  /**
   * Specify vertical alignment used if height is altered due to cropping
   *
   * @param  {String} valign 'top', 'middle', 'bottom'
   */
  valign(valign) {
    if (
      valign !== consts.TOP &&
      valign !== consts.BOTTOM &&
      valign !== consts.MIDDLE
    ) {
      throw new Error(
        `Vertical align must be ${consts.TOP}, ${consts.BOTTOM} or ${consts.MIDDLE}.`
      );
    }
    this.valignValue = valign;
    return this;
  }

  /**
   * Smart-crop
   */
  smartCrop() {
    this.smartFlag = true;
    return this;
  }

  /**
   * Append a filter, e.g. quality(80)
   *
   * @param  {String} filterCall
   */
  filter(filterCall) {
    if (this.filtersCalls.indexOf(filterCall) == -1)
      this.filtersCalls.push(filterCall);
    return this;
  }

  /**
   * Set path of image
   *
   * @param {String} imagePath
   */
  setImagePath(imagePath) {
    this.imagePath =
      imagePath.charAt(0) === '/'
        ? imagePath.substring(1, imagePath.length)
        : imagePath;
    return this;
  }

  /**
   * Build operation path
   *
   * @return {Array}
   */
  getOperationPath() {
    if (!this.imagePath)
      throw new Error(
        "The image url can't be null or empty (call `setImagePath()` first)."
      );

    const parts = [];

    // The metadata endpoint has ALL the options that the image one has, but
    // instead of actually performing the operations in the image, it just
    // simulates the operations.
    if (this.metaFlag) {
      parts.push('meta');
    }
    // trim removes surrounding space in images based on orientation and color
    // values;
    if (this.trimFlag) {
      let trim = 'trim';
      if (this.trimOptions) {
        if (this.trimOptions.orientation) {
          trim = trim + ':' + this.trimOptions.orientation;
        }
        if (this.trimOptions.colorTolerance) {
          trim = trim + ':' + this.trimOptions.colorTolerance;
        }
      }
      parts.push(trim);
    }
    // Manually crop the image
    if (this.cropValues) {
      parts.push(
        this.cropValues.left +
          'x' +
          this.cropValues.top +
          ':' +
          this.cropValues.right +
          'x' +
          this.cropValues.bottom
      );
    }
    // fit-in means that the generated image should not be auto-cropped and
    // otherwise just fit in an imaginary box specified by ExF
    if (this.fitInFlag) {
      parts.push('fit-in');
    }
    // -Ex-F means resize the image to be ExF of width per height size.
    // The minus signs mean flip horizontally and vertically
    if (
      this.width ||
      this.height ||
      this.flipHorizontallyFlag ||
      this.flipVerticallyFlag
    ) {
      let sizeString = '';
      if (this.flipHorizontallyFlag) {
        sizeString += '-';
      }
      sizeString += this.width;
      sizeString += 'x';
      if (this.flipVerticallyFlag) {
        sizeString += '-';
      }
      sizeString += this.height;
      parts.push(sizeString);
    }
    // HALIGN is horizontal alignment of crop
    if (this.halignValue) {
      parts.push(this.halignValue);
    }
    // VALIGN is vertical alignment of crop
    if (this.valignValue) {
      parts.push(this.valignValue);
    }
    // smart means using smart detection of focal points
    if (this.smartFlag) {
      parts.push('smart');
    }
    // filters can be applied sequentially to the image before returning
    if (this.filtersCalls.length) {
      parts.push('filters:' + this.filtersCalls.join(':'));
    }

    return parts.join('/');
  }

  /**
   * Generate and return the encryption hash part of a thumbor URL,
   * based on the "operation" settings currently defined in this Thumbor
   * instance, or the explicit `operation` string (if given).
   *
   * @param  {String} operation - optional, will auto-generate it if not
   * supplied
   * @return {String}
   */
  getHash(operation) {
    if (this.THUMBOR_SECURITY_KEY) {
      return crypto
        .createHmac('sha1', this.THUMBOR_SECURITY_KEY)
        .update(operation + '/' + this.imagePath)
        .digest('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_');
    } else {
      return 'unsafe'; // no security key set
    }
  }

  /**
   * Generate a thumbor image url
   *
   * @return {String}
   * @throws {Error}
   *   - thrown if an "image path" iss not currently set
   */
  buildUrl() {
    const operation = this.getOperationPath();
    return (
      this.THUMBOR_URL_SERVER +
      '/' +
      this.getHash(operation) +
      '/' +
      operation +
      '/' +
      this.imagePath
    );
  }
}

// expose "class constants"
Object.keys(consts).forEach((k) =>
  Object.defineProperty(Thumbor, k, {
    configurable: false,
    writable: false,
    value: consts[k],
  })
);

// export class
module.exports = Thumbor;
