import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

export const PRIORITIES = Immutable.Set([
  'default',
  'info',
  'warn',
  'warning',
  'error',
  'success'
]);

export const ToastType = ImmutablePropTypes.contains({
  identifier: PropTypes.string,
  message: PropTypes.node.isRequired,
  stringMessage: PropTypes.string,
  expires: PropTypes.any,
  priority: PropTypes.oneOf(PRIORITIES.toArray())
});
