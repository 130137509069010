import * as React from 'react';
import ReactDOM from 'react-dom';
import {ProductReviewPanel} from '../components/product-reviews.jsx';

export function productReviewPanel(elem, options) {
  if (!options || !options.flux) {
    return;
  }
  ReactDOM.render(<ProductReviewPanel flux={options.flux} />, elem);
}
