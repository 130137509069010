import _ from 'lodash';

import {OffCanvasState} from './models.js';

export function offCanvas(elem, options) {
  const state = new OffCanvasState(
    _.pick(options, ['leftOpen', 'rightOpen', 'allowBothOpen'])
  );
  const left = elem.querySelector('.off-canvas__panel--left');
  const main = elem.querySelector('.off-canvas__panel--main');
  const right = elem.querySelector('.off-canvas__panel--right');

  if (left) {
    left.classList.add('off-canvas__panel');
  }
  if (main) {
    main.classList.add('off-canvas__panel');
  }
  if (right) {
    right.classList.add('off-canvas__panel');
  }

  if (options.flux) {
    const mediaStore = options.flux.store('MediaStore');

    const handleMediaChange = () => {
      if (options.allowBothOpenAt) {
        state.allowBothOpen = mediaStore.active[options.allowBothOpenAt];
      }
      if (options.leftOpenAt) {
        if (mediaStore.active[options.leftOpenAt]) {
          state.actions.openLeft();
        } else {
          state.actions.closeLeft();
        }
      }
      if (options.rightOpenAt) {
        if (mediaStore.active[options.rightOpenAt]) {
          state.actions.openRight();
        } else {
          state.actions.closeRight();
        }
      }
    };
    mediaStore.addListener('change:breakpoint', handleMediaChange);
    handleMediaChange();
  }

  let selector =
    '[data-off-canvas-open{}], [data-off-canvas-close{}], [data-off-canvas-toggle{}]';
  selector = elem.id
    ? selector.replace(/\{\}/g, '~="' + elem.id + '"')
    : selector.replace(/\{\}/g, '');

  Array.prototype.forEach.call(document.querySelectorAll(selector), function(
    el
  ) {
    let clickHandler = function(ev) {
      ev.preventDefault();
      return ev;
    };
    if (left) {
      if (el.matches('[data-off-canvas-open~="left"]')) {
        clickHandler = _.flowRight(clickHandler, function(ev) {
          state.actions.openLeft();
          return ev;
        });
      }
      if (el.matches('[data-off-canvas-close~="left"]')) {
        clickHandler = _.flowRight(clickHandler, function(ev) {
          state.actions.closeLeft();
          return ev;
        });
      }
      if (el.matches('[data-off-canvas-toggle~="left"]')) {
        clickHandler = _.flowRight(clickHandler, function(ev) {
          state.actions.toggleLeft();
          return ev;
        });
      }
    }
    if (right) {
      if (el.matches('[data-off-canvas-open~="right"]')) {
        clickHandler = _.flowRight(clickHandler, function(ev) {
          state.actions.openRight();
          return ev;
        });
      }
      if (el.matches('[data-off-canvas-close~="right"]')) {
        clickHandler = _.flowRight(clickHandler, function(ev) {
          state.actions.closeRight();
          return ev;
        });
      }
      if (el.matches('[data-off-canvas-toggle~="right"]')) {
        clickHandler = _.flowRight(clickHandler, function(ev) {
          state.actions.toggleRight();
          return ev;
        });
      }
    }

    el.addEventListener('click', clickHandler);
    let changeHandler = _.identity;
    if (left) {
      if (
        el.matches(
          '[data-off-canvas-open~="left"], ' +
            '[data-off-canvas-close~="left"], ' +
            '[data-off-canvas-toggle~="left"]'
        )
      ) {
        changeHandler = _.flowRight(changeHandler, function() {
          el.classList.toggle('off-canvas__control--left-open', state.leftOpen);
        });
      }
    }
    if (right) {
      if (
        el.matches(
          '[data-off-canvas-open~="right"], ' +
            '[data-off-canvas-close~="right"], ' +
            '[data-off-canvas-toggle~="right"]'
        )
      ) {
        changeHandler = _.flowRight(changeHandler, function() {
          el.classList.toggle(
            'off-canvas__control--right-open',
            state.rightOpen
          );
        });
      }
    }
    state.on('change', changeHandler);
  });

  state.on('change', function() {
    elem.classList.add('off-canvas__container');
    elem.classList.toggle('off-canvas__container--left-open', state.leftOpen);
    document.body.classList.toggle(
      'jsm-off-canvas__left--open',
      state.leftOpen
    );
    elem.classList.toggle('off-canvas__container--right-open', state.rightOpen);
    document.body.classList.toggle(
      'jsm-off-canvas__right--open',
      state.rightOpen
    );
    if (left) {
      left.classList.toggle('off-canvas__panel--open', state.leftOpen);
    }
    if (right) {
      right.classList.toggle('off-canvas__panel--open', state.rightOpen);
    }
  });

  state.emit('change');
}
