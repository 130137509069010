import _ from 'lodash';
import classNames from 'classnames';
import Fluxxor from 'fluxxor';
import PropTypes from 'prop-types';
import * as React from 'react';

import SimpleImmutableComponent from '../components/immutable.jsx';
import {Slider} from '../slider/components.jsx';

export class Slide extends SimpleImmutableComponent {}
Slide.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  thumbnail: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  wordCount: PropTypes.number
};
Slide.defaultProps = {
  wordCount: 1
};

export class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id || _.uniqueId('carousel-'),
      paused: !this.props.rotate,
      currentSlide: this.props.currentSlide,
      next: null,
      prev: null
    };
    if (!_.isUndefined(this.props.rotateTime)) {
      this.rotateTime = this.props.rotateTime;
    } else {
      // Average reading speed for a "slide presentation" is around 100-125wpm
      // so as a default we'll use this value or 5 seconds, whichever is
      // greater.
      this.rotateTime = Math.max(
        Math.max.apply(null, _.map(this.props.children, 'props.wordCount')) *
          (60 / 100 * 1000),
        5000
      );
    }
    [
      'handleMouseUp',
      'showNext',
      'showPrev',
      'rotate',
      'handleTouchStart',
      'handleTouchEnd',
      'handleTouchMove',
      'handleWheel',
      'handleMouseUp',
      'handleMouseDown',
      'handleMouseMove',
      'handleMouseOver',
      'handleMouseOut',
      'handleMouseOver',
      'handleMouseOut'
    ].forEach(method => {
      this[method] = this[method].bind(this);
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(
      _.isEqual(this.props.children, nextProps.children) &&
      _.isEqual(this.state, nextState)
    );
  }

  componentDidMount() {
    this.interval = window.setInterval(this.rotate, this.rotateTime);
    window.addEventListener('mouseup', this.handleMouseUp);
  }

  componentWillUnmount() {
    window.clearInterval(this.interval);
    window.clearTimeout(this.interactionTimeout);
    window.removeEventListener('mouseup', this.handleMouseUp);
  }

  componentDidUpdate(prevProps) {
    if (this.state.interaction && prevProps.interactionTimeout >= 0) {
      window.clearTimeout(this.interactionTimeout);
      this.interactionTimeout = window.setTimeout(
        this.handleInteractionTimeout.bind(this),
        prevProps.interactionTimeout
      );
    }
  }

  handleInteractionTimeout() {
    this.setState({interaction: false});
  }

  rotate() {
    if (
      this.props.rotate &&
      !this.state.paused &&
      !this.state.interaction &&
      React.Children.count(this.props.children) > 1
    ) {
      this.showNext();
    }
  }

  pause() {
    this.setState({paused: true});
  }

  play() {
    this.setState({paused: false});
  }

  getNext() {
    if (_.isFinite(this.state.next)) {
      return this.state.next;
    }
    if (
      this.state.currentSlide ===
      React.Children.count(this.props.children) - 1
    ) {
      return 0;
    }
    return this.state.currentSlide + 1;
  }

  getPrev() {
    if (_.isFinite(this.state.prev)) {
      return this.state.prev;
    }
    if (this.state.currentSlide === 0) {
      return React.Children.count(this.props.children) - 1;
    }
    return this.state.currentSlide - 1;
  }

  showNext() {
    this.setState({currentSlide: this.getNext()});
  }

  showPrev() {
    this.setState({currentSlide: this.getPrev()});
  }

  showSlide(slide) {
    slide = Math.max(
      0,
      Math.min(slide, React.Children.count(this.props.children) - 1)
    );
    this.setState({currentSlide: slide});
  }

  handleMouseOver() {
    if (this.props.stopRotationOnHover && this.props.rotate) {
      this.pause();
    }
  }

  handleMouseOut() {
    if (this.props.stopRotationOnHover && this.props.rotate) {
      this.play();
    }
  }

  handleMouseDown(ev) {
    this.setState({dragStart: ev.clientX, interaction: true});
  }

  handleMouseUp() {
    this.setState({dragStart: undefined});
  }

  handleMouseMove(ev) {
    if (_.isUndefined(this.state.dragStart) || !this.container) {
      return;
    }
    const delta = ev.clientX - this.state.dragStart;
    if (Math.abs(delta) > this.container.getBoundingClientRect().width / 4) {
      if (delta > 0) {
        this.showPrev();
      } else {
        this.showNext();
      }
      this.setState({dragStart: undefined});
    }
  }

  handleTouchStart(ev) {
    this.setState({touchStart: ev.touches[0].clientX, interaction: true});
  }

  handleTouchEnd() {
    this.setState({touchStart: undefined});
  }

  handleTouchMove(ev) {
    if (_.isUndefined(this.state.touchStart) || !this.container) {
      return;
    }
    const delta = ev.touches[0].clientX - this.state.touchStart;
    if (Math.abs(delta) > this.container.getBoundingClientRect().width / 4) {
      if (delta > 0) {
        this.showPrev();
      } else {
        this.showNext();
      }
      this.setState({touchStart: undefined});
    }
  }

  handleWheel(ev) {
    if (!this.props.hijackScroll) {
      return;
    }
    ev.preventDefault();
    ev.stopPropagation();
    let wheelDelta;
    if (Math.abs(ev.deltaX) > Math.abs(ev.deltaY)) {
      wheelDelta = ev.deltaX;
    } else {
      wheelDelta = ev.deltaY;
    }
    if (wheelDelta > 0) {
      this.showNext();
    } else {
      this.showPrev();
    }
  }

  renderSlideChooserControls() {
    const slideChooserClassName = classNames({
      'js-carousel__controls--slide-chooser': true,
      'lap-hidden': this.props.slideTitles
    });
    let slideChooserControls = React.Children.map(
      this.props.children,
      (slide, idx) => {
        let handleClick;
        if (idx === this.getNext()) {
          handleClick = this.showNext;
        } else if (idx === this.getPrev()) {
          handleClick = this.showPrev;
        } else if (idx === this.state.currentSlide) {
          handleClick = function() {};
        } else {
          handleClick = this.showSlide.bind(this, idx);
        }
        const className = classNames(
          'js-carousel__controls--slide-chooser__chooser mzt',
          {
            'js-carousel__controls--slide-chooser__chooser--current':
              this.state.currentSlide === idx
          }
        );
        return (
          <li
            key={'chooser-' + idx}
            className={className}
            onClick={handleClick}
            aria-label={'Go to slide ' + idx}
            aria-controls={
              this.state.id + '-current ' + this.state.id + '-slides'
            }
          >
            Go to slide {idx + 1}
          </li>
        );
      }
    );
    return <ul className={slideChooserClassName}>{slideChooserControls}</ul>;
  }

  renderSlideTitles() {
    let slideTitles = '';
    if (this.props.slideTitles) {
      slideTitles = React.Children.map(this.props.children, (slide, idx) => {
        let handleClick;
        if (idx === this.getNext()) {
          handleClick = this.showNext;
        } else if (idx === this.getPrev()) {
          handleClick = this.showPrev;
        } else if (idx === this.state.currentSlide) {
          handleClick = function() {};
        } else {
          handleClick = this.showSlide.bind(this, idx);
        }
        const current = this.state.currentSlide === idx;
        const titleOnly = !slide.props.thumbnail && slide.props.title;
        const className = classNames({
          'js-slider__item-list__item': true,
          'js-slider__item-list__item--current': current,
          'js-carousel__controls--slide-titles__title': titleOnly,
          'js-carousel__controls--slide-titles__title--current':
            current && titleOnly
        });
        let thumbnail = slide.props.thumbnail || null;
        if (_.isString(thumbnail)) {
          thumbnail = <img width={100} src={slide.props.thumbnail} />;
        }
        return (
          <li
            key={'title-' + idx}
            ref={node => (this['title-' + idx] = node)}
            className={className}
            onClick={handleClick}
            aria-controls={
              this.state.id + '-current ' + this.state.id + '-slides'
            }
          >
            {thumbnail}
            <span>{slide.props.title ? slide.props.title : ''}</span>
          </li>
        );
      });
      if (this.props.slideTitlesUseSlider) {
        slideTitles = (
          <Slider
            ref={node => (this.titles = node)}
            flux={this.props.flux}
            hijackScroll={this.props.hijackScroll}
            ensureVisible={this.state.currentSlide}
            vertical={this.props.verticalNav}
            className={this.props.slideTitlesClassName}
          >
            {slideTitles}
          </Slider>
        );
      } else {
        slideTitles = (
          <div className="slide-titles">
            <ul
              ref={node => (this.titles = node)}
              className={this.props.slideTitlesClassName}
            >
              {slideTitles}
            </ul>
          </div>
        );
      }
    }
    return slideTitles;
  }

  renderSlides() {
    const slides = React.Children.map(this.props.children, (child, idx) => {
      const className = classNames(child.props.className, 'js-carousel__slide');
      return (
        <li
          key={idx}
          aria-hidden={idx !== this.state.currentSlide}
          className={className}
          style={child.props.style}
        >
          {child.props.children}
        </li>
      );
    });
    return slides;
  }

  render() {
    const slideChooserControls = this.renderSlideChooserControls();
    const slideTitles = this.renderSlideTitles();
    const slides = this.renderSlides();

    const className = classNames('js-carousel', this.props.className, {
      'js-carousel--vertical-nav': this.props.verticalNav
    });
    const slidesStyle = {};
    if (this.wrapper) {
      const wrapperStyle = window.getComputedStyle(this.wrapper);
      const offset =
        (parseFloat(wrapperStyle.width) -
          parseFloat(wrapperStyle.marginLeft || 0) -
          parseFloat(wrapperStyle.marginRight || 0) -
          parseFloat(wrapperStyle.paddingLeft || 0) -
          parseFloat(wrapperStyle.paddingRight || 0)) *
        this.state.currentSlide;
      slidesStyle.transform = `translateX(-${offset}px)`;
    }
    return (
      <div
        className={className}
        ref={node => (this.container = node)}
        id={this.state.id}
        onTouchStart={this.handleTouchStart}
        onTouchEnd={this.handleTouchEnd}
        onTouchMove={this.handleTouchMove}
        onWheel={this.handleWheel}
        onMouseUp={this.handleMouseUp}
        onMouseDown={this.handleMouseDown}
        onMouseMove={this.handleMouseMove}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        onMouseEnter={this.handleMouseOver}
        onMouseLeave={this.handleMouseOut}
      >
        <div
          ref={node => (this.wrapper = node)}
          className="js-carousel__slide-wrapper"
        >
          <ul
            ref={node => (this.slides = node)}
            aria-live="polite"
            className="js-carousel__slides"
            style={slidesStyle}
            id={this.state.id + '-slides'}
          >
            {slides}
          </ul>
          {this.props.nextPrevControls ? (
            <button
              className="js-carousel__controls__np__button js-carousel__controls__np__button--prev"
              aria-controls={this.state.id + '-slides'}
              aria-label="Go to next slide"
              onClick={this.showPrev}
            >
              <span aria-hidden={true}>
                <span className="button__text">&lt;</span>
                <span className="icn-ctrl icn-ctrl--l" />
              </span>
            </button>
          ) : (
            ''
          )}
          {this.props.nextPrevControls ? (
            <button
              className="js-carousel__controls__np__button js-carousel__controls__np__button--next"
              aria-controls={this.state.id + '-slides'}
              aria-label="Go to previous slide"
              onClick={this.showNext}
            >
              <span aria-hidden={true}>
                <span className="button__text">&gt;</span>
                <span className="icn-ctrl icn-ctrl--r" />
              </span>
            </button>
          ) : (
            ''
          )}
        </div>
        {this.props.slideTitles ? slideTitles : ''}
        {this.props.slideChooserControls ? slideChooserControls : ''}
      </div>
    );
  }
}
Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  currentSlide: PropTypes.number.isRequired,
  flux: PropTypes.instanceOf(Fluxxor.Flux),
  hijackScroll: PropTypes.bool.isRequired,
  id: PropTypes.string,
  interactionTimeout: PropTypes.number.isRequired,
  nextPrevControls: PropTypes.bool.isRequired,
  rotate: PropTypes.bool.isRequired,
  rotateTime: PropTypes.number,
  slideChooserControls: PropTypes.bool.isRequired,
  slideTitles: PropTypes.bool.isRequired,
  slideTitlesUseSlider: PropTypes.bool.isRequired,
  slideTitlesClassName: PropTypes.string,
  stopRotationOnHover: PropTypes.bool.isRequired,
  verticalNav: PropTypes.bool
};
Carousel.defaultProps = {
  currentSlide: 0,
  hijackScroll: false,
  interactionTimeout: 20000,
  nextPrevControls: true,
  rotate: true,
  slideChooserControls: true,
  slideTitles: false,
  slideTitlesUseSlider: true,
  slideTitlesClassName: 'up-to-tablet-hidden',
  stopRotationOnHover: true,
  verticalNav: false
};
