import Fluxxor from 'fluxxor';

export const CompatStore = Fluxxor.createStore({
  initialize: function() {
    this.modernizr = window.Modernizr;
    this.isIE9 = /MSIE 9\./.test(window.navigator.userAgent);
    this.isIE10 = /MSIE 10\./.test(window.navigator.userAgent);
    this.isIE11 =
      /Trident\/7\.0/.test(window.navigator.userAgent) &&
      /rv:11\./.test(window.navigator.userAgent);
    this.isIE = this.isIE9 || this.isIE10 || this.isIE11;
    this.transitionEndEvents = this.detectTransitionEndEvents();
  },

  detectTransitionEndEvents: function() {
    const EVENT_NAME_MAP = {
      transitionend: {
        transition: 'transitionend',
        WebkitTransition: 'webkitTransitionEnd',
        MozTransition: 'mozTransitionEnd',
        OTransition: 'oTransitionEnd',
        msTransition: 'MSTransitionEnd'
      },

      animationend: {
        animation: 'animationend',
        WebkitAnimation: 'webkitAnimationEnd',
        MozAnimation: 'mozAnimationEnd',
        OAnimation: 'oAnimationEnd',
        msAnimation: 'MSAnimationEnd'
      }
    };

    const endEvents = [];

    const testEl = document.createElement('div');
    const style = testEl.style;

    // On some platforms, in particular some releases of Android 4.x,
    // the un-prefixed "animation" and "transition" properties are defined on the
    // style object but the events that fire will still be prefixed, so we need
    // to check if the un-prefixed events are useable, and if not remove them
    // from the map
    if (!('AnimationEvent' in window)) {
      delete EVENT_NAME_MAP.animationend.animation;
    }

    if (!('TransitionEvent' in window)) {
      delete EVENT_NAME_MAP.transitionend.transition;
    }

    for (const baseEventName in EVENT_NAME_MAP) {
      const baseEvents = EVENT_NAME_MAP[baseEventName];
      for (const styleName in baseEvents) {
        if (styleName in style) {
          endEvents.push(baseEvents[styleName]);
          break;
        }
      }
    }
    return endEvents;
  },

  getState: function() {
    return {
      supported: this.modernizr.supported,
      supportsTextareaPlaceholder: !this.isIE,
      supportsAnimatedSVG: !this.isIE,
      supportsTransitionEndEvent: !!this.transitionEndEvents
    };
  }
});
