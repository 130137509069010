import _ from 'lodash';
import Fluxxor from 'fluxxor';
import Immutable from 'immutable';
import moment from 'moment';

import {PRIORITIES} from './types.js';

export const ToastStore = Fluxxor.createStore({
  STORAGE_KEY: 'toasts',
  PRIORITIES: PRIORITIES,

  actions: {
    INITIALIZED: 'initializeHandler',
    'TOAST:ADD': 'add',
    'TOAST:DISMISS': 'dismiss'
  },

  initialize: function(options) {
    this.windowUnloading = false;
    this.options = _.defaults(options, {
      defaultTimeout: 60,
      defaultPriority: 'default'
    });
    [
      'add',
      'cancelTick',
      'dismiss',
      'emitChange',
      'makeToast',
      'maybeUpdate',
      'save',
      'setTick'
    ].forEach(method => {
      this[method] = this[method].bind(this);
    });
    this.toasts = Immutable.Map();
  },

  getState: function() {
    return {
      toasts: this.toasts
        .filter(toast => toast.get('expires').isAfter())
        .toList()
    };
  },

  initializeHandler: function() {
    this.maybeUpdate(this.load());
    this.setTick();
    window.addEventListener('beforeunload', this.handleUnload);
  },

  emitChange: _.debounce(function() {
    this.emit('change');
  }),

  handleUnload: function() {
    this.windowUnloading = true;
    this.save();
    this.toasts = Immutable.Map();
  },

  maybeUpdate: function(newCollection) {
    if (
      Immutable.Map.isMap(newCollection) &&
      !Immutable.is(this.toasts, newCollection)
    ) {
      this.toasts = newCollection;
      this.emitChange();
      this.save();
    }
  },

  setTick: function() {
    if (this.tick === undefined) {
      this.tick = setInterval(this.emitChange, 10 * 1000);
    }
  },

  cancelTick: function() {
    if (this.tick !== undefined) {
      clearInterval(this.tick);
      this.tick = undefined;
    }
  },

  save: function() {
    localStorage.setItem(
      this.STORAGE_KEY,
      JSON.stringify(
        this.toasts
          .toList()
          .filter(
            toast =>
              toast.get('expires').isAfter() &&
              (_.isString(toast.get('message')) || toast.get('stringMessage'))
          )
          .toJS()
          .map(toast => {
            if (!_.isString(toast.message) && toast.stringMessage) {
              toast.message = toast.stringMessage;
              delete toast.stringMessage;
            }
            return toast;
          })
      )
    );
  },

  load: function() {
    let data = [];
    try {
      data = JSON.parse(localStorage.getItem(this.STORAGE_KEY)) || [];
    } catch (e) {
      localStorage.setItem(this.STORAGE_KEY, '');
    }
    if (data && Array.isArray(data)) {
      data = _.compact(
        data.map(toast => {
          if (_.isPlainObject(toast)) {
            return this.makeToast(toast);
          }
          return undefined;
        })
      ).filter(toast => toast.get('expires').isAfter());
      return Immutable.Map(data.map(toast => [toast.get('identifier'), toast]));
    }
    return Immutable.Map();
  },

  makeToast: function(data) {
    if (this.windowUnloading) {
      return;
    }
    let defaultIdentifier = '';
    if (data.stringMessage) {
      defaultIdentifier = data.stringMessage;
    } else if (data.message && _.isString(data.message)) {
      defaultIdentifier = data.message;
    } else {
      defaultIdentifier = _.uniqueId('toast-');
    }
    data = _.defaults(
      _.pick(data, ['identifier', 'expires', 'message', 'priority']),
      {
        identifier: defaultIdentifier,
        expires: moment().add(this.options.defaultTimeout, 'seconds'),
        priority: this.options.defaultPriority
      }
    );
    if (_.isString(data.expires) && moment(data.expires).isValid()) {
      data.expires = moment(data.expires);
    } else if (!_.isDate(data.expires)) {
      data.expires = moment().add(
        _.isFinite(data.expires) ? data.expires : this.options.defaultTimeout,
        'seconds'
      );
    }
    if (!this.PRIORITIES.includes(data.priority)) {
      data.priority = this.options.defaultPriority;
    }
    return Immutable.Map(data);
  },

  add: function(data) {
    if (this.windowUnloading) {
      return;
    }
    if (Immutable.Map.isMap(data)) {
      data = data.toJS();
    }
    if (!_.isPlainObject(data)) {
      return;
    }
    let toast = this.makeToast(data);
    if (!toast.get('message')) {
      return;
    }
    const identifier = toast.get('identifier');
    if (this.toasts.has('identifier')) {
      toast = toast.set(
        'expires',
        moment.max(
          this.toasts.get(identifier).get('expires'),
          toast.get('expires')
        )
      );
    }
    this.maybeUpdate(this.toasts.set(identifier, toast));
  },

  dismiss: function(toast) {
    if (!_.isPlainObject(toast)) {
      toast = Immutable.Map(toast);
    }
    if (_.isString(toast)) {
      toast = this.toasts.get(toast);
    }
    if (!Immutable.Map.isMap(toast)) {
      return;
    }
    this.maybeUpdate(this.toasts.remove(toast.get('identifier')));
  }
});
