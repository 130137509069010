import _ from 'lodash';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import * as React from 'react';
import classNames from 'classnames';

import SimpleImmutableComponent from 'prometheus/components/immutable.jsx';
import {SimpleImmutableFluxComponent} from 'prometheus/flux/components.jsx';
import {Form} from 'prometheus/forms/components/form.jsx';
import {InputGroup} from 'prometheus/forms/components/inputs/input.jsx';
import {RadioInput} from 'prometheus/forms/components/inputs/input-radio.jsx';

import {Loading} from '../components.jsx';
import {Product} from '../catalog/models.js';
import {ProductVariantType} from '../catalog/types.js';

import {CartItemType} from './types.js';
import {ProductType} from '../catalog/types.js';

/*
 * Add to Cart
 */

class VariantSelect extends SimpleImmutableComponent {
  render() {
    const choices = this.props.variants.toArray().map((variant, idx) => {
      const props = {
        attrs: Immutable.Map({
          'aria-label': !variant.get('inStock')
            ? variant.get('name') + ' (Sold Out)'
            : undefined,
          disabled: !variant.get('inStock'),
          selected: variant.equals(this.props.selected),
        }),
        checked: variant.equals(this.props.selected),
        className: classNames(
          'form__field--radio-cart-variants inline-list__item',
          {
            'form__field--radio-cart-variants__checked': variant.equals(
              this.props.selected,
            ),
            'form__field--radio-cart-variants__disabled':
              !variant.get('inStock'),
          },
        ),
        id: 'variant-' + idx,
        name: 'variant',
        value: variant.get('catNumber'),
        label: variant.get('name'),
        onChange: this.props.onChange,
      };
      return <RadioInput key={variant.get('name')} {...props} />;
    });
    const label = ['Select Size'];
    if (document.getElementById('product-size-chart')) {
      label.push(
        <a
          key="variant-size-chart-link"
          className="right t-normal"
          href="#product-size-chart"
        >
          Size Chart
        </a>,
      );
    }
    return (
      <InputGroup
        key="variants-input-group"
        id="variants"
        listClassName="inline-list inline-list--z owlq form__field one-whole"
        labelClassName="inline-list__item form__field__label"
        label={label}
      >
        {choices}
      </InputGroup>
    );
  }
}
VariantSelect.propTypes = {
  variants: ImmutablePropTypes.listOf(ProductVariantType).isRequired,
  selected: ProductVariantType,
  onChange: PropTypes.func.isRequired,
};

export class AdjustQuantityComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    ['handleIncrementClick', 'handleDecrementClick', 'handleChange'].forEach(
      (method) => {
        this[method] = this[method].bind(this);
      },
    );
    this.state = {
      value: this.props.defaultValue,
    };
  }

  updateValue(value, override = false) {
    this.setState(
      (state) => {
        return {value: override ? value : state.value + value};
      },
      () => {
        this.props.updateValue(this.state.value);
      },
    );
  }

  handleIncrementClick() {
    this.updateValue(1, false);
  }

  handleDecrementClick() {
    this.updateValue(-1, false);
  }

  handleChange(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    const value = _.toInteger(ev.target.value);
    if (
      (this.props.min && this.props.min > value) ||
      (this.props.max && this.props.max < value)
    ) {
      return;
    }
    this.updateValue(value, true);
  }

  render() {
    const disabled = this.props.disabled;
    const visuallyDisabled = _.defaultTo(
      this.props.visuallyDisabled,
      this.props.disabled,
    );

    const canIncrement =
      !_.isNil(this.props.max) && this.state.value + 1 > this.props.max;
    const canDecrement = this.state.value - 1 < this.props.min;

    return (
      <div
        className={classNames(
          'product-list__list_item_buying-options__adjuster dfbx--ctr ',
          this.props.className,
        )}
      >
        <button
          type="button"
          onClick={this.handleDecrementClick}
          disabled={disabled || canDecrement}
          className={classNames(
            'brad button--brand button--lg mzt',
            visuallyDisabled || canDecrement ? 'is-disabled' : null,
          )}
        >
          -
        </button>
        <div className="form__input-group__fields__field mzt one-whole">
          <div className="form__field__input ">
            <input
              key={'item-' + this.props.defaultValue}
              value={this.state.value}
              onChange={this.handleChange}
              max={this.props.max}
              min={this.props.min}
              disabled={disabled}
              pattern="[0-9]{0,3}"
              inputMode="numeric"
              className={visuallyDisabled ? 'is-disabled' : null}
            />
          </div>
        </div>
        <button
          type="button"
          onClick={this.handleIncrementClick}
          disabled={disabled || canIncrement}
          className={classNames(
            'brad button--brand button--lg mzt',
            visuallyDisabled || canIncrement ? 'is-disabled' : null,
          )}
        >
          +
        </button>
      </div>
    );
  }
}
AdjustQuantityComponent.propTypes = {
  defaultValue: PropTypes.number,
  updateValue: PropTypes.func,
  disabled: PropTypes.bool,
  visuallyDisabled: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  className: PropTypes.string,
};
AdjustQuantityComponent.defaultProps = {
  defaultValue: 0,
  disabled: false,
  min: 0,
};

export class AddToCartForm extends SimpleImmutableFluxComponent {
  constructor(props) {
    super(props);
    this.state = _.extend(this.state || {}, {
      quantity: 1,
    });
    this.timeouts = [];
    ['handleSubmit', 'handleVariantChange'].forEach((method) => {
      this[method] = this[method].bind(this);
    });
    this.updateValue = _.debounce(this.updateValue, 750).bind(this);
  }

  getStateFromFlux() {
    return _.extend(
      {},
      _.pick(this.flux.store('ProductStore').getState(), [
        'product',
        'variant',
      ]),
      this.flux.store('CartStore').getState(),
      this.flux.store('NetworkStore').getState(),
    );
  }

  componentDidMount() {
    super.componentDidMount();
    // Capture variant name from query sting.
    // Then attempt to set variant based on this.
    const variantNameRegex = window.location.search.match(
      '(\\?|&)variant_name(\\[\\])?=([^&]*)',
    );
    const variantName = variantNameRegex ? variantNameRegex[3] : null;
    if (variantName) {
      this.flux.actions.catalog.product.setVariant({
        name: variantName,
      });
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.timeouts.forEach(clearTimeout);
  }

  targetCartItem() {
    return {
      product:
        window.location.protocol +
        '//' +
        window.location.host +
        '/api/products/' +
        this.state.product.get('id') +
        '/',
      wishlistItem: this.props.wishlistItem,
      catNumber:
        this.state.variant && this.state.variant.get('catNumber')
          ? this.state.variant.get('catNumber')
          : this.state.product.get('catNumber'),
    };
  }

  handleSubmit() {
    this.flux.actions.cart.addItem(this.targetCartItem(), {wait: false});
  }

  handleVariantChange(ev) {
    this.flux.actions.catalog.product.setVariant({
      catNumber: ev.target.value,
    });
  }

  updateValue(quantity) {
    if (quantity > 0) {
      this.flux.actions.cart.updateItem(this.targetCartItem(), {
        quantity: quantity,
      });
    } else {
      this.flux.actions.cart.removeItem(this.targetCartItem());
    }
  }

  render() {
    let variants = null;
    if (this.state.product.get('variants').count() > 0) {
      variants = (
        <VariantSelect
          variants={this.state.product.get('variants')}
          selected={
            this.state.variant && this.state.variant.get('inStock')
              ? this.state.variant
              : null
          }
          onChange={this.handleVariantChange}
        />
      );
    }

    const numberAllVariantInCart = _.sum(
      this.state.cart
        .get('items')
        .filter(
          (cartItem) =>
            cartItem
              .get('product')
              .indexOf(`/${this.state.product.get('id')}`) >= 0,
        )
        .map((cartItem) => cartItem.get('quantity') || 1)
        .toArray(),
    );

    const mustSelectVariant =
      this.state.product.get('variants').count() > 0 &&
      (!this.state.variant ||
        (this.state.variant && !this.state.variant.get('inStock')));

    const salesQuantityConstraint = this.state.product.get(
      'salesQuantityConstraint',
      null,
    );

    const stockCount = this.state.product.get('variants').isEmpty()
      ? this.state.product.get('stockCount', null)
      : this.state.variant?.get('stockCount', null);
    const limitHit =
      _.isFinite(numberAllVariantInCart) &&
      _.isFinite(salesQuantityConstraint) &&
      numberAllVariantInCart >= salesQuantityConstraint;

    let buttonContent;
    if (this.state.offline) {
      buttonContent = 'You appear to be offline…';
    } else if (limitHit) {
      buttonContent = `This product is limited to ${salesQuantityConstraint} per customer`;
    } else if (mustSelectVariant) {
      buttonContent = 'Select an option';
    } else {
      buttonContent = 'Add to Basket';
    }
    const max = _.min(
      _.filter([salesQuantityConstraint, stockCount, 999], _.isFinite),
    );
    const disabled =
      mustSelectVariant || this.state.loading || this.state.offline;
    return (
      <Form
        ref={(node) => (this.form = node)}
        onSubmit={this.handleSubmit}
        action="/cart/"
        className="txt-left clearfix js-cart-buy-form product-form row mz pz"
      >
        <div className="relative row one-whole mzb">{variants}</div>
        <div
          className={classNames(
            'txt-center row one-whole centered valign-center',
            {'is-disabled': disabled},
          )}
        >
          {numberAllVariantInCart ? (
            <div className="row bg-info one-whole ph mhb brad">
              <p className="bold centered">
                {numberAllVariantInCart} in your basket
              </p>
            </div>
          ) : null}
          {!variants && numberAllVariantInCart > 0 ? (
            <AdjustQuantityComponent
              key={`adjust-quantity-${numberAllVariantInCart}-${this.state.lastForceUpdate}`}
              updateValue={this.updateValue}
              defaultValue={numberAllVariantInCart}
              min={0}
              max={max}
              disabled={disabled}
              className="mqt one-whole"
            />
          ) : (
            <button
              type="submit"
              disabled={disabled || max <= numberAllVariantInCart}
              className="brad--sm button--brand zshd-00 one-whole button--xl"
              data-buy-button-source={this.props.source}
            >
              {buttonContent}
            </button>
          )}
        </div>
      </Form>
    );
  }
}
AddToCartForm.propTypes = _.extend(
  {
    wishlistItem: PropTypes.number,
    rocketImage: PropTypes.string.isRequired,
    source: PropTypes.string,
  },
  _.clone(SimpleImmutableFluxComponent.propTypes),
);
AddToCartForm.defaultProps = {
  wishlistItem: null,
  source: '',
};
AddToCartForm.watchedStores = [
  'CartStore',
  'AuthStore',
  'ProductStore',
  'NetworkStore',
];

export class AddToCartButtonComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    ['handleIncrementClick'].forEach((method) => {
      this[method] = this[method].bind(this);
    });
    this.updateValue = _.debounce(this.updateValue, 750).bind(this);
  }

  productUrl() {
    const productId = this.props.product && this.props.product.get('id');
    return productId
      ? window.location.protocol +
          '//' +
          window.location.host +
          '/api/products/' +
          productId +
          '/'
      : null;
  }

  targetCartItem() {
    return {
      product: this.productUrl(),
      catNumber: this.props.catNumber,
      wishlistItem: this.props.wishlistItem,
    };
  }

  handleIncrementClick() {
    return this.props.addItem(this.targetCartItem(), {quiet: this.props.quiet});
  }

  updateValue(quantity) {
    if (quantity > 0) {
      this.props.updateItem(this.props.cartItem.toJS(), {
        quantity: quantity,
        quiet: this.props.quiet,
      });
    } else {
      this.props.removeItem(this.props.cartItem.toJS(), {
        quiet: this.props.quiet,
      });
    }
  }

  getSalesQuantityConstraint() {
    return _.defaultTo(
      this.props.cartItem?.get('salesQuantityConstraint'),
      this.props.product.get('salesQuantityConstraint'),
    );
  }

  getItemSalesQuantityConstraint() {
    const salesQuantityConstraint = this.getSalesQuantityConstraint();
    const originalCartItemQuantity = _.defaultTo(
      this.props.cartItem?.get('quantity'),
      0,
    );
    const nonSelfQuantity =
      _.defaultTo(this.props.cartItemProductTotal, originalCartItemQuantity) -
      originalCartItemQuantity;
    return _.isFinite(salesQuantityConstraint)
      ? _.max([salesQuantityConstraint - nonSelfQuantity], 0)
      : null;
  }

  getQuantityAvailable() {
    const variant = this.getVariant();
    const stockCount = this.props.product.get('variants').isEmpty()
      ? this.props.product.get('stockCount')
      : variant?.get('stockCount');
    return _.defaultTo(
      this.props.cartItem?.get('quantityAvailable'),
      stockCount,
    );
  }

  getVariant() {
    return this.props.product
      .get('variants')
      .find((variant) => variant.get('catNumber') === this.props.catNumber);
  }

  validateVariantOption() {
    return this.props.product.get('variants').isEmpty() || !!this.getVariant();
  }

  validateQuantityConstraint(quantity) {
    const itemSalesQuantityConstraint = this.getItemSalesQuantityConstraint();
    return (
      !_.isFinite(itemSalesQuantityConstraint) &&
      quantity > itemSalesQuantityConstraint
    );
  }

  validateStockCount(quantity) {
    const stockCount = this.getQuantityAvailable();
    return !_.isFinite(stockCount) && quantity > stockCount;
  }

  validateQuantityNonZero(quantity) {
    const stockCount = this.props.product.get('stockCount');
    return !(_.isFinite(stockCount) && quantity > stockCount);
  }

  validateQuantityAll(quantity) {
    return (
      this.validateQuantityConstraint(quantity) &&
      this.validateStockCount(quantity) &&
      quantity >= 0
    );
  }

  getButtonContent() {
    if (this.props.offline) {
      return 'You appear to be offline…';
    } else {
      return 'Add to Basket';
    }
  }

  getStockMessageContent() {
    const stockMessage = this.props.product.get('stockMessage');
    return stockMessage ? (
      <p className={'mqt clr-' + stockMessage.get('level')}>
        <span>{stockMessage.get('message')}</span>
      </p>
    ) : null;
  }

  render() {
    if (
      !this.props.product ||
      !this.props.product.get('absoluteUrl') ||
      !this.props.product.get('inStock')
    ) {
      return null;
    }

    if (!this.validateVariantOption()) {
      return null;
    }

    const numberInCart = _.defaultTo(this.props.cartItem?.get('quantity'), 0);

    const salesQuantityConstraint = this.getSalesQuantityConstraint();
    const itemSalesQuantityConstraint =
      this.getItemSalesQuantityConstraint(numberInCart);
    const quantityAvailable = this.getQuantityAvailable();
    const max = _.min(
      _.filter(
        [itemSalesQuantityConstraint, quantityAvailable, 999],
        _.isFinite,
      ),
    );

    // disabled and visuallyDisabled refers to disable
    // due to loading/networking.
    // Cart Quantity constranints etc will be applied on top
    // of these
    const disabled = this.props.loading || this.props.offline;
    const visuallyDisabled =
      disabled &&
      this.props.updatingProductId &&
      this.props.updatingProductId === this.props.product.get('id');

    let cartControl = null;
    if (visuallyDisabled) {
      cartControl = <Loading size={80} />;
    } else if (numberInCart > 0) {
      cartControl = (
        <React.Fragment>
          <div>
            <strong>{numberInCart} in Basket</strong>
          </div>
          <AdjustQuantityComponent
            key={
              'adjust-quantity-' +
              numberInCart +
              '-' +
              this.props.lastForceUpdate
            }
            updateValue={this.updateValue}
            defaultValue={numberInCart}
            min={0}
            max={max}
            disabled={disabled}
            visuallyDisabled={visuallyDisabled}
            className="mqt"
          />
        </React.Fragment>
      );
    } else {
      cartControl = (
        <button
          ref={(node) => (this.button = node)}
          className={classNames(
            'brad button--brand button--lg one-whole',
            visuallyDisabled || max <= 0 ? 'is-disabled' : '',
          )}
          type="button"
          onClick={this.handleIncrementClick}
          disabled={disabled || max <= 0}
          data-buy-button-source={this.props.source}
        >
          {this.getButtonContent()}
        </button>
      );
    }

    return (
      <div className="product-list__list_item_buying-options  dfbx--aic dfbx--ctr dfbx--fdc mb pl pr">
        {this.getStockMessageContent()}
        {salesQuantityConstraint ? (
          <p className="mqt pht phb mqb brdr--top brdr--top--thin brdr--bottom brdr--bottom--thin">
            <strong>limited to {salesQuantityConstraint} per customer.</strong>
          </p>
        ) : null}
        {cartControl}
      </div>
    );
  }
}

AddToCartButtonComponent.propTypes = {
  product: ProductType.isRequired,
  catNumber: PropTypes.string.isRequired,
  wishlistItem: PropTypes.number,
  cartItem: CartItemType,
  cartItemProductTotal: PropTypes.number.isRequired,
  addItem: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  offline: PropTypes.bool,
  lastForceUpdate: PropTypes.number.isRequired,
  updatingProductId: PropTypes.number,
  quiet: PropTypes.bool.isRequired,
  source: PropTypes.string,
};
AddToCartButtonComponent.defaultProps = {
  wishlistItem: null,
  loading: false,
  offline: false,
  disableMessage: null,
  quiet: true,
  source: '',
};

export class AddToCartButton extends SimpleImmutableFluxComponent {
  constructor(props) {
    super(props);
    this.productModel = new Product({id: parseInt(this.props.productId)});
    this.fetchProduct();
  }

  getStateFromFlux() {
    return _.extend(
      {},
      _.pick(this.flux.store('CartStore').getState(), [
        'cart',
        'loading',
        'lastForceUpdate',
        'updatingProductId',
      ]),
      this.flux.store('NetworkStore').getState(),
    );
  }

  fetchProduct() {
    this.productModel
      .read()
      .then(() => {
        this.setState({
          product: Immutable.fromJS(this.productModel.toObject()),
        });
      })
      .catch(() => {
        this.setState({
          product: null,
        });
      });
  }

  render() {
    if (!this.state.product) {
      return null;
    }
    const cartItem = this.state.cart
      ?.get('items')
      .filter(
        (cartItem) =>
          _.endsWith(
            cartItem.get('product'),
            '/' + this.state.product.get('id') + '/',
          ) && this.props.catNumber === cartItem.get('catNumber'),
      )
      .first();

    const cartItemProductTotal = this.state.cart
      ?.get('items')
      .filter((cartItem) =>
        _.endsWith(
          cartItem.get('product'),
          '/' + this.state.product.get('id') + '/',
        ),
      )
      .reduce((sum, cartItem) => sum + cartItem.get('quantity'), 0);

    return (
      <AddToCartButtonComponent
        product={this.state.product}
        catNumber={this.props.catNumber}
        wishlistItem={this.props.wishlistItem}
        cartItem={cartItem}
        cartItemProductTotal={cartItemProductTotal}
        lastForceUpdate={this.state.lastForceUpdate}
        updatingProductId={this.state.updatingProductId}
        addItem={this.flux.actions.cart.addItem}
        updateItem={this.flux.actions.cart.updateItem}
        removeItem={this.flux.actions.cart.removeItem}
        loading={this.state.loading}
        offline={this.state.offline}
        source={this.props.source}
      />
    );
  }
}

AddToCartButton.propTypes = _.extend(
  {
    wishlistItem: PropTypes.number,
    rocketImage: PropTypes.string.isRequired,
    productId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    catNumber: PropTypes.string.isRequired,
    quantity: PropTypes.number,
    source: PropTypes.string,
  },
  _.clone(SimpleImmutableFluxComponent.propTypes),
);
AddToCartButton.defaultProps = {
  wishlistItem: null,
  quantity: 1,
  source: '',
};
AddToCartButton.watchedStores = ['CartStore', 'NetworkStore'];
