import Fluxxor from 'fluxxor';

import {checkStatus} from '../../fetch.js';
import {getCSRFToken} from '../../csrf.js';

export const LedgerStore = Fluxxor.createStore({
  actions: {
    'EVOUCHER:REDEEM': 'redeemEvoucher',
  },

  redeemEvoucher: function (payload) {
    fetch('/api/evouchers/redeem/', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'x-csrftoken': getCSRFToken(),
        'content-type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(checkStatus)
      .then((response) => response.json())
      .then((data) => {
        this.emit('evoucher-redeem:success', data);
      })
      .catch((err) => {
        this.emit('evoucher-redeem:error', err);
      });
  },
});
