import _ from "lodash";
import Fluxxor from "fluxxor";
import Immutable from "immutable";
import moment from "moment";
import AwaitLock from "await-lock";

import { camelKeys } from "prometheus/utils.js";
import { getCSRFToken } from "../csrf.js";
import { checkStatus } from "../fetch.js";

import { Cart, CartItem, CartItemArray } from "./models.js";

export const CartStore = Fluxxor.createStore({
  actions: {
    INITIALIZED: "initializeHandler",
    "CART:SET_POSTAGE_COUNTRY": "setPostageCountry",
    "CART:ADD_ITEM": "addItem",
    "CART:UPDATE_ITEM": "updateItem",
    "CART:REMOVE_ITEM": "removeItem",
    "CART:OPEN": "handleOpen",
    "CART:CLOSE": "handleClose",
    "CART:TOGGLE": "handleToggle",
    "CART:APPLY_COUPON": "applyCoupon",
  },

  localStorageKey: "fp.cart",
  localStorageCouponKey: "fp.coupon",

  emitChange: _.debounce(function () {
    this.emit("change");
  }, 200),

  _fetchCart: async function () {
    await this.fetchCartLock.acquireAsync();
    try {
      const headers = {
        "Cache-Control": "no-store",
      };
      if (_.isDate(this.cart.lastModified)) {
        headers["If-Modified-Since"] = this.cart.lastModified.toISOString();
      }
      this.incrementLoadingCounter();
      try {
        const responseData = await this.cart.read({
          fetchOptions: { headers: headers },
        });
        this.decrementLoadingCounter();
        this.saveCart();
        return Promise.all(responseData);
      } catch (err) {
        if (!(err.response && err.response.status === 304)) {
          this.deleteSavedCart();
        }
      } finally {
        this.decrementLoadingCounter();
      }
    } finally {
      this.fetchCartLock.release();
      this.emitChange();
    }
  },

  fetchCart: function () {
    return this._fetchCart();
  },

  initialize: function (options) {
    this.options = options || {};
    this.initialized = false;
    this._name = "__cart-store__";
    this.postageCountries = Immutable.Map();
    this.cart = new Cart();
    this.removed = new CartItemArray([], { url: null });
    this.removedTimeouts = {};
    // This is used to indicate the product
    // updating
    this.updatingProductId = null;

    this.fetchCartLock = new AwaitLock();
    this.cartItemModifyLock = new AwaitLock();

    [
      "addItem",
      "applyCoupon",
      "decrementLoadingCounter",
      "emitChange",
      "fetchCart",
      "handleVisibilityChange",
      "incrementLoadingCounter",
      "removeCoupon",
      "removeItem",
      "updateItem",
    ].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  },

  /*
   * State serialization
   */

  getState: function () {
    let lastForceUpdate;
    try {
      lastForceUpdate = _.max([
        _.isDate(this.cart.lastUpdated)
          ? this.cart.lastUpdated.getTime()
          : Date.now(),
        ...this.cart.items.map((item) => {
          return _.isDate(item.lastModified)
            ? item.lastModified.getTime()
            : Date.now();
        }),
      ]);
    } catch (e) {
      lastForceUpdate = Date.now();
    }
    return {
      cart: Immutable.fromJS(this.cart.toObject()),
      coupon: Immutable.Map(this.coupon),
      loading: this.loadingCounter > 0,
      open: this.open,
      removed: Immutable.List(
        this.removed && this.removed.length
          ? this.removed.map((removedItem) =>
              Immutable.fromJS(removedItem.toObject()),
            )
          : null,
      ),
      lastForceUpdate: lastForceUpdate,
      updatingProductId: this.updatingProductId,
    };
  },

  /*
   * Initialization, request, and change callbacks
   */

  initializeHandler: function () {
    this.postageCountries = Immutable.Map(
      _.flattenDepth(
        this.flux.store("SettingsStore").getState().countryChoices,
        2,
      ).filter(Array.isArray),
    );

    // Set up cart data
    const cartData = this.flux.store("DataStore").getState().cart;
    let savedCart = {};
    try {
      savedCart = JSON.parse(window.localStorage.getItem(this.localStorageKey));
    } catch (e) {
      // pass
    }
    this.cart.setMany(this.cart.deserialize(cartData || savedCart || {}));

    // Set up loading state handling
    this.loadingCounter = 0;

    // Set up coupon and related timeout
    this.coupon = this.flux.store("DataStore").getState().coupon;
    let storedCoupon;
    try {
      storedCoupon = window.localStorage.getItem(this.localStorageCouponKey);
    } catch (e) {
      // pass
    }
    if (this.coupon === undefined) {
      if (storedCoupon) {
        try {
          this.coupon = JSON.parse(storedCoupon);
        } catch (e) {
          this.getCoupon();
        }
      } else {
        this.getCoupon();
      }
    }
    if (this.coupon) {
      this.setupCoupon();
    }

    // Set open
    this.open = false;

    // Set up user log{in,out} handling
    this.user = this.flux.store("AuthStore").getState().user;
    this.flux.store("AuthStore").addListener("change", this.handleAuthChange);

    // Set up ledger/evoucher redemption handling
    this.flux
      .store("LedgerStore")
      .addListener("evoucher-redeem:success", this.fetchCart);

    // Set up handling of page visibility events
    this.lastVisibilityChange = moment();
    window.addEventListener("visibilitychange", this.handleVisibilityChange);

    // Fetch cart if changed in other windows
    window.addEventListener("storage", this.handleStorageChange);

    // Fetch cart if cart flyout is closed
    window.addEventListener("cart-close", this.fetchCart);

    if (!cartData) {
      this.fetchCart();
    }

    this.initialized = true;
    this.emit("initialized");
    this.emitChange();
  },

  /*
   * Request/response handlers
   */

  incrementLoadingCounter: function () {
    const previousValue = this.loadingCounter;
    this.loadingCounter += 1;
    if (previousValue === 0 && this.loadingCounter) {
      this.emitChange();
    }
  },

  decrementLoadingCounter: function () {
    const previousValue = this.loadingCounter;
    this.loadingCounter -= 1;
    this.loadingCounter = Math.max(this.loadingCounter, 0);
    if (this.loadingCounter === 0 && previousValue !== 0) {
      this.emitChange();
    }
  },

  saveCart: _.debounce(function () {
    try {
      window.localStorage.setItem(
        this.localStorageKey,
        JSON.stringify(this.cart.toJSON()),
      );
    } catch (e) {
      // pass
    }
  }, 500),

  deleteSavedCart: function () {
    try {
      window.localStorage.removeItem(this.localStorageKey);
    } catch (e) {
      // pass
    }
  },

  /*
   * Reload on tab change and storage change
   */

  handleAuthChange: function () {
    const user = this.flux.store("AuthStore").getState().user;
    if (!Immutable.is(user, this.user)) {
      this.fetchCart();
    }
    this.user = user;
  },

  handleVisibilityChange: function () {
    if (
      moment() - this.lastVisibilityChange > moment.duration(300, "seconds") &&
      !document.hidden
    ) {
      this.fetchCart();
    }
    this.lastVisibilityChange = moment();
  },

  handleStorageChange: function (ev) {
    if (ev.key === this.localStorageKey) {
      try {
        const newValue = JSON.parse(ev.newValue);
        if (newValue) {
          this.flux.actions.console.info(
            "Resetting cart from localStorage",
            newValue,
          );
          this.cart.setMany(this.cart.deserialize(newValue));
          this.recalculateInternals();
          this.emitChange();
        }
      } catch (e) {
        this.flux.actions.console.error(e);
      }
    }
  },

  /*
   * Open/close state handlers
   */
  handleOpen: function () {
    if (!this.open) {
      this.open = true;
      this.emitChange();
      this.emit("cart-opened");
    }
  },

  handleClose: function () {
    if (this.open) {
      this.open = false;
      this.emitChange();
      this.emit("cart-closed");
    }
  },

  handleToggle: function (toggle) {
    const expected = _.isBoolean(toggle) ? toggle : !this.open;
    if (this.open !== expected) {
      this.open = expected;
      this.emitChange();
      this.emit(this.open ? "cart-opened" : "cart-closed");
    }
  },

  /*
   * Postage management
   */
  _setPostageCountry: async function (country) {
    if (
      this.cart &&
      this.cart.postage &&
      country === this.cart.postage.country
    ) {
      return;
    }
    this.cart.postage.country = country;
    this.incrementLoadingCounter();
    try {
      await this.cart.postage.update();
      this.saveCart();
      window.Hooks.showToast({
        tag: "cart_postage_changed",
        msg: `Postage country set to ${this.postageCountries.get(
          country,
          country,
        )}`,
        level: "success",
        timeout: 5,
      });
    } catch (e) {
      this.deleteSavedCart();
      await this.fetchCart();
    } finally {
      this.decrementLoadingCounter();
    }
  },

  setPostageCountry: function (country) {
    return this._setPostageCountry(country);
  },

  /*
   * Coupon management
   */
  setupCoupon: function () {
    this.coupon.startDate = new Date(this.coupon.startDate);
    this.coupon.endDate = new Date(this.coupon.endDate);
    clearTimeout(this.couponTimeout);
    const delay = this.coupon.endDate - new Date();
    if (delay < 2147483647) {
      this.couponTimeout = setTimeout(this.removeCoupon, delay);
    } else {
      this.couponTimeout = setTimeout(
        this.applyCoupon.bind(this, this.coupon.code),
        2147483646,
      );
    }
  },

  _getCoupon: async function () {
    this.incrementLoadingCounter();
    try {
      const response = await fetch("/api/coupon/", {
        credentials: "same-origin",
      });
      checkStatus(response);
      const data = await response.json();
      if (data) {
        this.coupon = camelKeys(data, true);
        this.setupCoupon();
      } else {
        this.coupon = null;
      }
      window.localStorage.setItem(
        this.localStorageCouponKey,
        JSON.stringify(this.coupon),
      );
    } catch (e) {
      // pass
    } finally {
      this.decrementLoadingCounter();
    }
  },

  getCoupon: function () {
    return this._getCoupon();
  },

  _applyCoupon: async function (code) {
    this.incrementLoadingCounter();
    try {
      const response = await fetch("/api/coupon/", {
        method: "POST",
        body: JSON.stringify({ code: code }),
        headers: {
          "x-csrftoken": getCSRFToken(),
          "content-type": "application/json",
        },
        credentials: "same-origin",
      });
      checkStatus(response);
      const data = await response.json();
      this.coupon = camelKeys(data, true);
      this.setupCoupon();
      this.emitChange();
      window.Hooks.showToast({
        msg: "Coupon applied",
        level: "success",
        timeout: 5,
      });
      try {
        window.localStorage.setItem(
          this.localStorageCouponKey,
          JSON.stringify(this.coupon),
        );
      } catch (e) {
        // pass
      }
    } catch (e) {
      window.Hooks.showToast({
        msg: "Error applying coupon",
        level: "error",
      });
    } finally {
      this.decrementLoadingCounter();
    }
  },

  applyCoupon: function (code) {
    return this._applyCoupon(code);
  },

  removeCoupon: function () {
    this.coupon = null;
    try {
      window.localStorage.removeItem(this.localStorageCouponKey);
    } catch (e) {
      // pass
    }
    clearTimeout(this.couponTimeout);
    this.emitChange();
    window.Hooks.showToast({
      msg: "Coupon removed",
      timeout: 5,
    });
  },

  /*
   * Item management
   */
  recalculateInternals: function () {
    this.cart.totalItems = _.sumBy(this.cart.items, "quantity");
    this.cart.totalLines = this.cart.items.length;
    this.cart.subTotal = _.sumBy(
      this.cart.items,
      (item) => item.sitePrice * item.quantity,
    );
    this.cart.total = this.cart.subTotal + this.cart.postage.amount;
    this.cart.totalExcludingPostage =
      this.cart.total - this.cart.postage.amount;
    this.emitChange();
  },

  _addItem: async function (payload) {
    /* NOTE:  this is called with payload.options.wait = false
     *         in the case where a lock cannot be acquired
     *         this call will be drop quietly and
     *         not be honered
     */
    payload.options = payload.options || {};
    let { item, options } = payload;
    options = Object.assign({ quiet: true, wait: true }, options);

    if (options.wait) {
      await this.cartItemModifyLock.acquireAsync();
    } else if (!this.cartItemModifyLock.tryAcquire()) {
      return;
    }

    try {
      const existing = this.cart.items.find(
        _.matches({
          product: item.product,
          catNumber: item.catNumber,
        }),
      );
      let newItem;
      if (existing) {
        existing.wishlistItem = item.wishlistItem;
        existing.quantity += 1;
      } else {
        newItem = new CartItem(item);
      }

      if (this.loadingCounter !== 0) {
        return;
      }
      this.updatingProductId = existing
        ? existing.productId
        : newItem.productId;
      this.incrementLoadingCounter();

      try {
        await (existing ? existing.update() : newItem.create());
        const toRemove = this.removed.findIndex(_.matches(item));
        if (toRemove) {
          this.removed.splice(toRemove, 1);
        }
        if (newItem) {
          this.cart.items.unshift(newItem);
        }
        window.htmx.trigger(
          document.body,
          existing ? "cart_item_updated" : "cart_item_created",
          existing || newItem,
        );
        await this.cart.postage.read();
        if (this.open) {
          this.fetchCart();
        } else {
          this.recalculateInternals();
        }
        if (!options.quiet) {
          window.Hooks.showToast({
            msg: "Basket item added",
            level: "success",
            timeout: 5,
          });
        }
      } catch (e) {
        await this.fetchCart();
      } finally {
        this.saveCart();
        this.updatingProductId = null;
        this.decrementLoadingCounter();
      }
    } finally {
      this.cartItemModifyLock.release();
      this.emitChange();
    }
  },

  addItem: function (payload) {
    return this._addItem(payload);
  },

  _updateItem: async function (payload) {
    await this.cartItemModifyLock.acquireAsync();
    try {
      payload.options = payload.options || {};
      let { item, options } = payload;
      options = Object.assign({ quiet: true }, options);
      let existing = this.cart.items.find(
        _.matches({
          product: item.product,
          catNumber: item.catNumber,
        }),
      );
      existing = existing ? new CartItem(existing) : null;
      let newItem;
      if (existing) {
        existing.wishlistItem = item.wishlistItem;
        existing.setMany(options);
      } else {
        newItem = new CartItem(Object.assign({}, item, options));
      }

      if (this.loadingCounter !== 0) {
        return;
      }
      this.updatingProductId = item.productId;
      this.incrementLoadingCounter();
      try {
        await (existing ? existing.update() : newItem.create());
        if (newItem) {
          this.cart.items.unshift(newItem);
        } else if (existing) {
          this.cart.items
            .find(
              _.matches({
                product: existing.product,
                catNumber: existing.catNumber,
              }),
            )
            ?.setMany(_.pick(existing, ["wishlistItem", "quantity"]));
        }
        window.htmx.trigger(
          document.body,
          existing ? "cart_item_updated" : "cart_item_created",
          existing || newItem,
        );
        await this.cart.postage.read();
        if (this.open) {
          this.fetchCart();
        } else {
          this.recalculateInternals();
        }
        if (!options.quiet) {
          window.Hooks.showToast({
            msg: "Basket updated",
            level: "success",
            timeout: 5,
          });
        }
        this.saveCart();
      } catch (e) {
        await this.fetchCart();
      } finally {
        this.updatingProductId = null;
        this.decrementLoadingCounter();
      }
    } finally {
      this.cartItemModifyLock.release();
      this.emitChange();
    }
  },

  updateItem: function (payload) {
    return this._updateItem(payload);
  },

  _removeItem: async function (payload) {
    await this.cartItemModifyLock.acquireAsync();
    try {
      payload.options = payload.options || {};
      let { item, options } = payload;
      options = Object.assign({ undo: true, quiet: true }, options);
      // Default to allowing undo of removed item.
      const removedItem = _.pick(item, [
        "availability",
        "basePrice",
        "catNumber",
        "price",
        "product",
        "productDetail",
        "productListed",
        "quantity",
      ]);
      const removeMatch = _.pick(item, [
        "catNumber",
        "product",
        "quantity",
        "wishlistItem",
      ]);
      if (this.loadingCounter !== 0) {
        return;
      }
      this.updatingProductId = item.productId;
      this.incrementLoadingCounter();
      try {
        await item.delete();
        const removeIndex = this.cart.items.findIndex(_.matches(removeMatch));
        if (removeIndex >= 0) {
          this.cart.items.splice(removeIndex, 1);
        }
        if (!options.quiet) {
          window.Hooks.showToast({
            msg: "Basket item removed",
            undo: _.partial(this.flux.actions.cart.addItem, removedItem),
            level: "success",
          });
        }
        await this.cart.postage.read();
        if (this.open) {
          this.fetchCart();
        } else {
          this.recalculateInternals();
        }
        this.saveCart();
      } catch (e) {
        await this.fetchCart();
      } finally {
        this.updatingProductId = null;
        this.decrementLoadingCounter();
      }

      // Add the product to removedItem to allow user to undo.
      if (options.undo) {
        this.removed.append(removedItem);
        const key = [
          removedItem.product,
          removedItem.catNumber,
          removedItem.wishlistItem,
        ].join("-");
        clearTimeout(this.removedTimeouts[key]);
        this.removedTimeouts[key] = setTimeout(() => {
          const removed = this.removed.findIndex(_.matches(removedItem));
          if (removed >= 0) {
            this.removed.splice(removed, 1);
          }
        }, 5000);
      }
    } finally {
      this.cartItemModifyLock.release();
      this.emitChange();
    }
  },

  removeItem: function (payload) {
    return this._removeItem(payload);
  },
});
