import * as auth from './auth/actions.js';
import * as cart from './cart/actions.js';
import * as catalog from './catalog/actions.js';
import * as evoucher from './evoucher/actions.js';
import * as feedbackSurveyMessages from './feedback/actions.js';
import * as locations from './locations/actions.js';
import * as orders from './account/orders/actions.js';
import * as siteMessages from './sitemessages/actions.js';
import * as watch from './watch/actions.js';
import * as wishlist from './wishlist/actions.js';

export const actions = {
  auth: auth,
  cart: cart,
  catalog: catalog,
  evoucher: evoucher,
  feedbackSurveyMessages: feedbackSurveyMessages,
  locations: locations,
  orders: orders,
  siteMessages: siteMessages,
  watches: watch,
  wishlist: wishlist,
};
