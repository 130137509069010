import Immutable from 'immutable';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import * as React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import SimpleImmutableComponent from 'prometheus/components/immutable.jsx';
import {SimpleImmutableFluxComponent} from 'prometheus/flux/components.jsx';
import {camelKeys} from 'prometheus/utils.js';

import {checkStatus} from '../../fetch.js';

class SearchSuggestionMatch extends React.PureComponent {
  render() {
    const queryTokens = _.words(this.props.query);
    const suggestionTokens = _.split(this.props.suggestion, /\b/);
    const displayTokens = _.compact(
      _.map(suggestionTokens, (suggestionToken, suggestionIdx) => {
        return _.maxBy(
          _.map(queryTokens, (queryToken, queryIdx) => {
            const matched =
              suggestionToken.startsWith(queryToken) &&
              (this.props.query.length < 5
                ? Math.abs(queryIdx * 2 - suggestionIdx) === 0
                : true);
            return {
              token: suggestionToken,
              matchLength: matched ? queryToken.length : 0,
            };
          }),
          'matchLength'
        );
      })
    );
    if (!this.props.query) {
      return <span>{this.props.suggestion}</span>;
    }
    return (
      <span>
        {_.map(displayTokens, (displayToken) => {
          return [
            <b key={_.uniqueId('search_suggestion_match')}>
              {displayToken.token.slice(0, displayToken.matchLength)}
            </b>,
            displayToken.token.slice(displayToken.matchLength),
          ];
        })}
      </span>
    );
  }
}
SearchSuggestionMatch.propTypes = {
  query: PropTypes.string.isRequired,
  suggestion: PropTypes.string.isRequired,
};

class SearchSpellingSuggestion extends React.PureComponent {
  constructor(props) {
    super(props);
    ['handleClick'].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  handleClick(ev) {
    ev.stopPropagation();
    ev.preventDefault();
    this.props.callback({q: this.props.suggestion});
  }

  render() {
    return (
      <dd className="mz form-search__suggest-item">
        <button
          type="button"
          className={classNames(
            'block',
            'ph',
            'txt-left',
            'one-whole',
            'form-search__suggest-item__button',
            this.props.highlighted
              ? 'form-search__suggest-item__button--highlight'
              : null
          )}
          onClick={this.handleClick}
          tabIndex={-1}
        >
          <SearchSuggestionMatch
            query={this.props.query}
            suggestion={this.props.suggestion}
          />
        </button>
      </dd>
    );
  }
}
SearchSpellingSuggestion.propTypes = {
  query: PropTypes.string.isRequired,
  suggestion: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  highlighted: PropTypes.bool.isRequired,
};

class SearchCategorySuggestion extends SimpleImmutableComponent {
  constructor(props) {
    super(props);
    ['handleClick'].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  handleClick(ev) {
    ev.stopPropagation();
    ev.preventDefault();
    const category = this.props.suggestion.get('category');
    this.props.callback({
      q: this.props.suggestion.get('query'),
      domain: category ? category.get('slug') : 'all',
    });
  }

  render() {
    const category = this.props.suggestion.get('category');
    const query = this.props.suggestion.get('query');
    return (
      <dd className="mz form-search__suggest-item">
        <button
          type="button"
          className={classNames(
            'block',
            'ph',
            'txt-left',
            'one-whole',
            'form-search__suggest-item__button',
            this.props.highlighted
              ? 'form-search__suggest-item__button--highlight'
              : null
          )}
          onClick={this.handleClick.bind(this)}
          tabIndex={-1}
        >
          <SearchSuggestionMatch query={this.props.query} suggestion={query} />
          <span className="clr-black"> in </span>
          <span className="clr-comp">
            {category ? category.get('name') : 'All Categories'}
          </span>
        </button>
      </dd>
    );
  }
}
SearchCategorySuggestion.propTypes = {
  query: PropTypes.string.isRequired,
  suggestion: ImmutablePropTypes.map.isRequired,
  callback: PropTypes.func.isRequired,
  highlighted: PropTypes.bool.isRequired,
};

class SearchSuggestions extends SimpleImmutableComponent {
  render() {
    const spellingSuggestion = this.props.suggestions.get('spellingSuggestion')
      ? [
          <dt key="spell-head" className="pht pl form-search__suggest-heading">
            Did you mean?
          </dt>,
          <SearchSpellingSuggestion
            key="spell-suggest"
            query={this.props.query}
            suggestion={this.props.suggestions.get('spellingSuggestion')}
            callback={this.props.callback}
            highlighted={
              this.props.highlightedSuggestion.get('suggestionType') ===
              'spellingSuggestion'
                ? true
                : false
            }
          />,
        ]
      : null;
    let categorySuggestions = null;
    if (
      this.props.suggestions.get('categorySuggestions') &&
      this.props.suggestions.get('categorySuggestions').size > 0
    ) {
      categorySuggestions = [
        <dt
          key="category-head"
          className="clr-neutral--light txt-right pr pht form-search__suggest-heading"
        >
          Search in Categories
        </dt>,
      ].concat(
        this.props.suggestions
          .get('categorySuggestions')
          .map((suggestion, index) => {
            return (
              <SearchCategorySuggestion
                key={
                  suggestion.get('category')
                    ? suggestion.get('category').get('slug')
                    : 'null'
                }
                query={this.props.query}
                suggestion={suggestion}
                callback={this.props.callback}
                highlighted={
                  this.props.highlightedSuggestion.get('suggestionType') ===
                    'categorySuggestions' &&
                  this.props.highlightedSuggestion.get('position') === index
                    ? true
                    : false
                }
              />
            );
          })
      );
    }
    let termSuggestions = null;
    if (
      this.props.suggestions.get('termSuggestions') &&
      this.props.suggestions.get('termSuggestions').size > 0
    ) {
      termSuggestions = [
        <dt
          key="term-head"
          className="clr-neutral--light txt-right pr pht form-search__suggest-heading"
        >
          Search Suggestions
        </dt>,
      ].concat(
        this.props.suggestions
          .get('termSuggestions')
          .map((suggestion, index) => {
            return (
              <SearchSpellingSuggestion
                key={suggestion}
                query={this.props.query}
                suggestion={suggestion}
                callback={this.props.callback}
                highlighted={
                  this.props.highlightedSuggestion.get('suggestionType') ===
                    'termSuggestions' &&
                  this.props.highlightedSuggestion.get('position') === index
                    ? true
                    : false
                }
              />
            );
          })
      );
    }
    if (!spellingSuggestion && !categorySuggestions && !termSuggestions) {
      return null;
    }
    return (
      <dl aria-live="polite" className="form-search__suggest">
        {spellingSuggestion}
        {categorySuggestions}
        {termSuggestions}
      </dl>
    );
  }
}
SearchSuggestions.propTypes = {
  query: PropTypes.string.isRequired,
  suggestions: ImmutablePropTypes.map,
  callback: PropTypes.func,
  highlightedSuggestion: ImmutablePropTypes.map,
};
SearchSuggestions.defaultProps = {
  suggestions: Immutable.Map(),
  callback: _.identity,
  highlightedSuggestion: Immutable.Map(),
};

class DomainSelect extends SimpleImmutableComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: _.uniqueId('search__domain-select__'),
    };
  }

  componentDidMount() {
    this.updateWidth();
  }

  componentDidUpdate() {
    this.updateWidth();
  }

  updateWidth() {
    const style = window.getComputedStyle(this.input);
    const options = this.input.options;
    const text = options.item(options.selectedIndex).innerHTML;
    const ruler = document.createElement('span');
    ruler.style.visibility = 'hidden';
    ruler.style.whiteSpace = 'no-wrap';
    ruler.style.background = 'transparent';
    ruler.style.color = 'transparent';
    ruler.style.width = 'auto';
    ruler.style.display = 'inline-block';
    ruler.style.padding = '0.75em';
    ruler.style.fontSize = style.fontSize;
    ruler.style.fontFamily = style.fontFamily;
    ruler.style.fontWeight = style.fontWeight;
    ruler.innerHTML = text;
    document.body.appendChild(ruler);
    const width = ruler.getBoundingClientRect().width;
    document.body.removeChild(ruler);
    const cssWidth = `${width + 50}px`;
    this.input.style.width = cssWidth;
    const label = this.input.closest('label');
    if (label) {
      label.style.width = cssWidth;
    }
  }

  render() {
    const optionGroups = this.props.optionGroups
      .map((optGroup) => {
        const options = optGroup
          .get('options')
          .map((option) => {
            return (
              <option
                key={option.get('value')}
                defaultValue={this.props.default}
                value={option.get('value')}
              >
                {option.get('name')}
              </option>
            );
          })
          .toArray();
        return (
          <optgroup key={optGroup.get('label')} label={optGroup.get('label')}>
            {options}
          </optgroup>
        );
      })
      .toArray();
    return (
      <label
        htmlFor={this.state.id}
        className="form__field form-search__field-select bg-light-grey"
      >
        <div className="form__field__input form__field__input__css-select">
          <select
            ref={(node) => (this.input = node)}
            name="domain"
            id={this.state.id}
            aria-label="Search in…"
            value={this.props.value}
            onChange={this.props.onChange}
          >
            {optionGroups}
          </select>
          <i className="icon icon-46 bg-light-grey pseudo-select--button" />
        </div>
      </label>
    );
  }
}
DomainSelect.propTypes = {
  onChange: PropTypes.func,
  optionGroups: ImmutablePropTypes.listOf(
    ImmutablePropTypes.contains({
      label: PropTypes.string,
      options: ImmutablePropTypes.listOf(
        ImmutablePropTypes.contains({
          name: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    })
  ),
  default: PropTypes.string,
  value: PropTypes.string,
};
DomainSelect.defaultProps = {
  default: 'all',
  onChange: _.identity,
};

class QueryInput extends SimpleImmutableComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: _.uniqueId('search__query-input__'),
    };
    this.startDictation = this.startDictation.bind(this);
  }

  handleChange(ev) {
    this.setState({value: ev.target.value});
    this.props.onChange(ev);
  }

  startDictation() {
    const recognition = new window.SpeechRecognition();

    recognition.continuous = false;
    recognition.interimResults = false;
    recognition.lang = 'en-GB';

    recognition.addEventListener('result', (ev) => {
      try {
        this.input.value = ev.results[0][0].transcript;
      } catch (e) {
        return;
      }
      recognition.stop();
      this.input.form.submit();
    });

    recognition.addEventListener('error', () => {
      recognition.stop();
    });

    recognition.start();
  }

  render() {
    const speechInput = this.props.speech ? (
      <i className="icon icon-70" onClick={this.startDictation} />
    ) : null;
    return (
      <label
        htmlFor={this.state.id}
        className="form__field form__field--search form__field--text-field"
      >
        <div className="form__field__input">
          {speechInput}
          <input
            ref={(node) => (this.input = node)}
            type="search"
            aria-label="Search this website"
            name="q"
            id={this.state.id}
            className=""
            value={this.props.value}
            onChange={this.props.onChange}
            onFocus={this.props.onFocus}
            onKeyDown={this.props.onKeyDown}
            placeholder={this.props.placeholder}
            autoComplete="off"
          />
          <span />
        </div>
      </label>
    );
  }
}
QueryInput.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  enteredValue: PropTypes.string,
  speech: PropTypes.bool,
};
QueryInput.defaultProps = {
  onChange: _.identity,
  onFocus: _.identity,
  onKeyDown: _.identity,
  placeholder: '',
  speech: true,
};

export class SearchForm extends SimpleImmutableFluxComponent {
  constructor(props) {
    super(props);
    const defaultDomain = this.getDefaultDomain();
    this.state = _.extend(this.state || {}, {
      originalSuggestions: Immutable.Map(),
      suggestions: Immutable.Map(),
      showSuggestions: false,
      suggestionPosition: 0,
      domain: defaultDomain,
      selectedDomain: defaultDomain,
      id: _.uniqueId('search-form-'),
      online: true,
      offline: false,
    });
    this.getSuggestions = _.debounce(this.getSuggestions, 500, this);
    this.handleBlur = _.debounce(this.handleBlur, 250, this);
    [
      'handleSubmit',
      'handleBlur',
      'handleFocus',
      'handleDomainChange',
      'handleQueryChange',
      'handleQueryKeyDown',
      'updateFromSuggestion',
      'handleCategoryChange',
    ].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  componentDidMount() {
    super.componentDidMount();
    const data = this.props.flux.store('DataStore').getState();
    if (data.productListing && this.props.syncCategory) {
      this.flux
        .store('ProductListingStore')
        .addListener('change', this.handleCategoryChange);
    }
  }

  componentWillUnmount() {
    const data = this.props.flux.store('DataStore').getState();
    if (data.productListing) {
      this.flux
        .store('ProductListingStore')
        .removeListener('change', this.handleCategoryChange);
    }
    super.componentWillUnmount();
  }

  handleCategoryChange() {
    const newCategory = this.flux
      .store('ProductListingStore')
      .getState().category;
    if (!Immutable.is(this.state.category, newCategory)) {
      this.setState({
        category: newCategory,
        domain: newCategory ? newCategory.get('slug').split('/')[0] : 'all',
      });
    }
  }

  getDefaultDomain() {
    const data = this.props.flux.store('DataStore').getState();
    const rootCategories = data.rootCategories || [];
    let defaultDomain = 'all';
    if (this.props.dynamicDomain) {
      if (data.productListing && data.productListing.category) {
        const rootSlug = data.productListing.category.slug.split('/')[0];
        const rootCategory = _.find(rootCategories, {slug: rootSlug});
        defaultDomain = rootCategory ? rootCategory.slug : defaultDomain;
      } else if (window.location.pathname.startsWith('/plu/events/')) {
        defaultDomain = 'events';
      } else if (window.location.pathname.startsWith('/plu/')) {
        defaultDomain = 'posts';
      } else if (window.location.pathname.startsWith('/subscriptions/')) {
        defaultDomain = 'subscriptions';
      } else {
        const pathSlug = window.location.pathname.replace(/(^[/]|[/]$)/g, '');
        const possibleDomain = rootCategories.find(
          (cat) => cat.slug === pathSlug
        );
        defaultDomain = possibleDomain ? possibleDomain.slug : defaultDomain;
      }
    }
    return defaultDomain;
  }

  getStateFromFlux() {
    let defaultQ = '';
    if (this.state && this.state.q) {
      defaultQ = this.state.q;
    }
    return _.extend(
      {
        q: defaultQ || this.flux.store('DataStore').getState().query || '',
        activeMedia: this.flux.store('MediaStore').getState().active,
      },
      this.flux.store('NetworkStore').getState()
    );
  }

  getSuggestions() {
    if (!this._suggestionAbortController && window.AbortController) {
      this._suggestionAbortController = new window.AbortController();
    }

    if (this._suggestionRequestInProgress && this._suggestionAbortController) {
      this._suggestionAbortController.abort();
      this._suggestionAbortController = new window.AbortController();
    }

    if (!this.state.q || this.state.q.length < 3) {
      this.setState({suggestions: Immutable.Map({})});
      return;
    }

    this._suggestionRequestInProgress = true;
    const url =
      '/api/search-suggestions/?q=' +
      encodeURIComponent(this.state.q.trim()) +
      '&domain=' +
      encodeURIComponent(this.state.domain);
    fetch(url, {
      signal: this._suggestionAbortController
        ? this._suggestionAbortController.signal
        : undefined,
      credentials: 'same-origin',
    })
      .then(checkStatus)
      .then((response) => response.json())
      .then((data) => {
        this._suggestionRequestInProgress = false;
        const originalSuggestions = Immutable.fromJS(camelKeys(data, true));

        const lapUp = _.some(['lap', 'desk'], (x) =>
          this.state.activeMedia.get(x)
        );
        // Here we get the
        const suggestions = lapUp
          ? originalSuggestions
          : Immutable.fromJS({
              spellingSuggestion: null,
              categorySuggestions: [],
              termSuggestions: originalSuggestions
                .get('categorySuggestions')
                .map((x) => x.get('query'))
                .toOrderedSet()
                .union(originalSuggestions.get('termSuggestions'))
                .toList(),
            });
        this.setState({
          originalSuggestions: originalSuggestions,
          suggestions: suggestions,
          suggestionPosition: 0,
        });
      })
      .catch(() => {
        this._suggestionRequestInProgress = false;
        this.setState({suggestions: Immutable.Map()});
      });
  }

  handleSubmit(ev) {
    if (ev) {
      ev.preventDefault();
    }
    this.setState({showSuggestions: false});
    const q = this.queryInput.input.value;
    const domain = this.domainInput.input.value;
    const data = this.props.flux.store('DataStore').getState();
    if (domain === 'events') {
      window.location = '/plu/events/?q=' + encodeURIComponent(q);
      return;
    }
    if (domain === 'posts') {
      window.location = '/plu/posts/?q=' + encodeURIComponent(q);
      return;
    }
    if (domain === 'subscriptions') {
      window.location = '/subscriptions/series/?q=' + encodeURIComponent(q);
      return;
    }
    if (data.productListing) {
      this.props.flux.actions.catalog.productListing.setMulti({
        query: q,
        category:
          !domain || domain === 'all'
            ? null
            : data.rootCategories.find((cat) => cat.slug === domain),
        filters: null,
      });
      return;
    }
    if (domain && domain !== 'all') {
      window.location = '/catalog/' + domain + '/?q=' + encodeURIComponent(q);
      return;
    }
    window.location = '/catalog/?q=' + encodeURIComponent(q);
    return;
  }

  handleDomainChange(ev) {
    this.setState({domain: ev.target.value, selectedDomain: ev.target.value});
  }

  handleQueryChange(ev) {
    const prevQ = _.clone(this.state.q) || '';
    this.setState(
      {
        q: ev.target.value,
        enteredQ: ev.target.value,
        showSuggestions: true,
      },
      () => {
        if (prevQ.trim() !== this.state.q.trim()) {
          this.getSuggestions();
        }
      }
    );
  }

  getHighlightedSuggestion(position) {
    if (!this.state.suggestions || !this.state.suggestions.size) {
      return Immutable.Map();
    }
    const suggestionCounts = Immutable.OrderedMap({
      spellingSuggestion: this.state.suggestions.get('spellingSuggestion')
        ? 1
        : 0,
      categorySuggestions: this.state.suggestions.get('categorySuggestions')
        .size,
      termSuggestions: this.state.suggestions.get('termSuggestions').size,
    })
      .entrySeq()
      .reduce((r, v) => {
        const lastValue = r.valueSeq().last();
        const cumValue = lastValue ? lastValue.get('maxValue') : 0;
        return r.set(
          v[0],
          Immutable.OrderedMap({minValue: cumValue, maxValue: cumValue + v[1]})
        );
      }, Immutable.OrderedMap());
    const suggestionType =
      position > 0
        ? suggestionCounts.findKey(
            (suggestionCount) =>
              position - 1 >= suggestionCount.get('minValue') &&
              position - 1 < suggestionCount.get('maxValue')
          )
        : null;

    if (!suggestionType) {
      return Immutable.Map();
    } else if (suggestionType === 'spellingSuggestion') {
      return Immutable.Map({
        suggestionType: suggestionType,
      });
    } else {
      const localPosition =
        position - 1 - suggestionCounts.get(suggestionType).get('minValue');
      return Immutable.Map({
        suggestionType: suggestionType,
        position: localPosition,
      });
    }
  }

  handleQueryKeyDown(ev) {
    const delta = ev.key === 'ArrowUp' ? -1 : ev.key === 'ArrowDown' ? 1 : 0;
    if (
      !this.state.showSuggestions ||
      delta == 0 ||
      !this.state.suggestions ||
      !this.state.suggestions.size
    ) {
      return;
    }
    ev.stopPropagation();
    ev.preventDefault();
    const total =
      this.state.suggestions.get('categorySuggestions').size +
      this.state.suggestions.get('termSuggestions').size +
      (this.state.suggestions.get('spellingSuggestion') ? 1 : 0);
    const newPosition =
      (this.state.suggestionPosition + delta + total + 1) % (total + 1);
    const highlightedSuggestion = this.getHighlightedSuggestion(newPosition);
    const additionalState = ((highlightedSuggestion) => {
      if (!highlightedSuggestion.size) {
        if (this.state.enteredQ && this.state.enteredQ !== this.state.q) {
          return {q: this.state.enteredQ};
        }
        return {};
      }
      if (
        highlightedSuggestion.get('suggestionType') === 'categorySuggestions'
      ) {
        const suggestion = this.state.suggestions
          .get('categorySuggestions')
          .get(highlightedSuggestion.get('position'));
        const domain = suggestion.get('category')
          ? suggestion
              .get('category')
              .get('slug')
              .replace(/(^[/]|[/]$)/g, '')
          : 'all';
        return {
          q: suggestion.get('query'),
          domain: domain,
        };
      } else if (
        highlightedSuggestion.get('suggestionType') === 'spellingSuggestion'
      ) {
        return {
          q: this.state.suggestions.get('spellingSuggestion'),
          domain: this.state.selectedDomain,
        };
      } else {
        return {
          q: this.state.suggestions
            .get('termSuggestions')
            .get(highlightedSuggestion.get('position')),
          domain: this.state.selectedDomain,
        };
      }
    })(highlightedSuggestion);
    this.setState(
      _.assignIn({suggestionPosition: newPosition}, additionalState)
    );
  }

  handleQueryFocus() {
    this.setState({showSuggestions: true});
  }

  updateFromSuggestion(options) {
    const newState = {showSuggestions: false};
    if (options.domain) {
      newState.domain = options.domain;
    }
    if (options.q) {
      newState.q = options.q;
    }
    this.queryInput.input.focus();
    this.setState(newState, this.handleSubmit);
  }

  handleFocus() {
    document.body.classList.add('jsm-search--focus');
  }

  handleBlur() {
    if (!this.form || !this.form.querySelector(':focus')) {
      this.setState({showSuggestions: false});
      document.body.classList.remove('jsm-search--focus');
    }
  }

  render() {
    const data = this.props.flux.store('DataStore').getState();
    const categories = Immutable.List(
      (data.rootCategories || []).map((category) => {
        return Immutable.Map({name: category.name, value: category.slug});
      })
    ).unshift(Immutable.Map({name: 'All categories', value: 'all'}));
    const extras = Immutable.List([
      Immutable.Map({name: 'Blog', value: 'posts'}),
      Immutable.Map({name: 'Events', value: 'events'}),
      Immutable.Map({name: 'Subscriptions', value: 'subscriptions'}),
    ]);
    const domains = Immutable.List([
      Immutable.Map({
        label: 'Categories…',
        options: categories,
      }),
      Immutable.Map({
        label: 'Also from Forbidden Planet…',
        options: extras,
      }),
    ]);
    let placeholder;
    if (!this.state.activeMedia || this.state.activeMedia.get('desk')) {
      placeholder = 'Search forbiddenplanet.com';
    } else if (this.state.activeMedia.get('lap')) {
      placeholder = 'Search here';
    } else {
      placeholder = 'Search';
    }
    return (
      <form
        onSubmit={this.handleSubmit}
        className="form-search bg-neutral--info one-whole row"
        id={this.state.id}
        ref={(node) => (this.form = node)}
        action="/search/"
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        method="get"
      >
        <input type="hidden" name="frm" value={window.location} />
        <QueryInput
          ref={(node) => (this.queryInput = node)}
          value={this.state.q}
          placeholder={placeholder}
          enteredValue={this.state.enteredQ}
          onChange={this.handleQueryChange}
          onKeyDown={this.handleQueryKeyDown}
          speech={!!window.SpeechRecognition}
        />
        {this.state.showSuggestions && this.state.online ? (
          <SearchSuggestions
            query={this.state.enteredQ.toLowerCase()}
            callback={this.updateFromSuggestion}
            suggestions={this.state.suggestions}
            highlightedSuggestion={this.getHighlightedSuggestion(
              this.state.suggestionPosition
            )}
          />
        ) : null}
        <DomainSelect
          ref={(node) => (this.domainInput = node)}
          value={this.state.domain}
          default={this.getDefaultDomain()}
          optionGroups={domains}
          onChange={this.handleDomainChange}
        />
        <button
          className="owl-off button--brand dfbx dfbx--ctr"
          type="submit"
          disabled={this.state.offline}
        >
          {this.state.online ? (
            [
              <span key="text" className="visually-hidden">
                Go
              </span>,
              <i key="icon" className="icon icon-19 icon--sml vam mzt" />,
            ]
          ) : (
            <span>Offline</span>
          )}
        </button>
      </form>
    );
  }
}
SearchForm.watchedStores = ['MediaStore', 'NetworkStore'];
SearchForm.propTypes = {
  syncCategory: PropTypes.bool,
  dynamicDomain: PropTypes.bool,
};
SearchForm.defaultProps = {
  syncCategory: false,
  dynamicDomain: false,
};
