import _ from 'lodash';
import Fluxxor from 'fluxxor';

export const SettingsStore = Fluxxor.createStore({
  initialize: function(options) {
    const root = options.namespace ? window[options.namespace] : window;
    this.settings = Object.freeze(root.settings || {});
  },

  getState: function() {
    return this.settings;
  }
});

export const UserSettingsStore = Fluxxor.createStore({
  actions: {
    INITIALIZED: 'initializeHandler',
    'SETTINGS:WRITE': 'write',
    'SETTINGS:SET': 'set',
    'SETTINGS:UNSET': 'unset'
  },

  initialize: function(options) {
    this.key = options.key || 'UserSettingsStore';
    this.initialized = false;
  },

  initializeHandler: function() {
    this.read();
    window.addEventListener('storage', this.handleStorageEvent.bind(this));

    this.initialized = true;
    this.emit('initialized');
  },

  handleStorageEvent: function(ev) {
    if (ev.key !== this.key) {
      return;
    }
    this.read();
  },

  checkChanges: function(prevSettings) {
    if (!_.isEqual(this.settings, prevSettings)) {
      this.emit('change');
    }
    const merged = _.extend(_.clone(prevSettings), _.clone(this.settings));
    for (const key in merged) {
      if (!_.isEqual(this.settings[key], prevSettings[key])) {
        this.emit('change:' + key);
      }
    }
  },

  read: function() {
    const prevSettings = _.clone(this.settings) || {};
    try {
      this.settings = window.JSON.parse(
        window.localStorage.getItem(this.key) || '{}'
      );
    } catch (e) {
      this.flux.actions.console.warn(
        'Error loading user settings from localStorage:',
        e.message
      );
      this.settings = {};
    }
    this.checkChanges(prevSettings);
  },

  write: function() {
    window.localStorage.setItem(this.key, window.JSON.stringify(this.settings));
    this.emit('write');
  },

  getState: function() {
    return this.settings;
  },

  get: function(key, defaultValue) {
    if (this.settings[key] !== void 0) {
      return this.settings[key];
    }
    return defaultValue;
  },

  set: function(payload) {
    const prevSettings = _.clone(this.settings) || {};
    this.settings = _.extend(this.settings, payload);
    this.checkChanges(prevSettings);
    this.write();
  },

  unset: function(key) {
    if (this.settings.hasOwnProperty(key)) {
      delete this.settings[key];
      this.emit('change');
      this.emit('change:' + key);
    }
  }
});
