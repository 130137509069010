import * as React from 'react';
import ReactDOM from 'react-dom';

import {Order} from './components.jsx';

export function order(elem, options) {
  if (!options || !options.flux) {
    return;
  }
  ReactDOM.render(<Order {...options} />, elem);
}
