import Fluxxor from 'fluxxor';

export const DataStore = Fluxxor.createStore({
  initialize: function(options) {
    const root = options.namespace ? window[options.namespace] : window;
    this.data = Object.freeze(root.data || {});
  },

  getState: function() {
    return this.data;
  }
});
