import Immutable from 'immutable';
import * as React from 'react';
import ReactDOM from 'react-dom';

import {AddToCartForm, AddToCartButton} from './components.jsx';

export function addToCartForm(elem, options) {
  if (!options || !options.flux) {
    return;
  }
  if (options.wishlistItem) {
    options.wishlistItem = Immutable.fromJS(options.wishlistItem);
  }
  ReactDOM.render(<AddToCartForm {...options} />, elem);
}

export function addToCartButton(elem, options) {
  if (!options || !options.flux) {
    return;
  }
  if (options.wishlistItem) {
    options.wishlistItem = Immutable.fromJS(options.wishlistItem);
  }
  ReactDOM.render(<AddToCartButton {...options} />, elem);
}
