import _ from 'lodash';
import classNames from 'classnames';
import jump from 'jump.js';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import PropTypes from 'prop-types';
import * as React from 'react';
import {Waypoint} from 'react-waypoint';

import FluxComponent from 'prometheus/flux/components.jsx';
import {SimpleImmutableFluxComponent} from 'prometheus/flux/components.jsx';

class ProductPageNavItem extends React.PureComponent {
  constructor(props) {
    super(props);
    ['handleClick'].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  handleClick(ev) {
    const target = document.querySelector(this.props.href);
    if (target) {
      ev.preventDefault();
      ev.stopPropagation();
      jump(target, {
        offset: -100,
        duration: 500,
      });
    }
  }

  render() {
    const className = classNames('inline-list__item', this.props.className);
    return (
      <li className={className}>
        <a
          className="brad bg-white pq"
          href={this.props.href}
          onClick={this.handleClick}
        >
          {this.props.text}
        </a>
      </li>
    );
  }
}
ProductPageNavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

class ProductPageNavItems extends React.PureComponent {
  render() {
    const items = [
      {id: 'product-buy', text: 'Buy Options'},
      {id: 'product-description', text: 'Description'},
      {id: 'product-specifications', text: 'Specifications'},
      {id: 'product-key-points', text: 'Key Points'},
      {id: 'product-size-chart', text: 'Size Chart'},
      {id: 'product-reviews', text: 'Reviews'},
      {id: 'product-related-content-events', text: 'Related Events'},
      {id: 'product-related-content-posts', text: 'Related Posts'},
      {id: 'product-related', text: 'Related Products'},
    ].map((obj) => {
      if (document.getElementById(obj.id)) {
        return (
          <CSSTransition
            key={obj.id}
            classNames="ra-product-page-nav-"
            in={true}
            appear={true}
            timeout={500}
          >
            <ProductPageNavItem href={'#' + obj.id} text={obj.text} />
          </CSSTransition>
        );
      }
    });
    return (
      <TransitionGroup
        component="ul"
        className="inline-list inline-list--half mhb owlh"
      >
        {items}
      </TransitionGroup>
    );
  }
}
ProductPageNavItems.propTypes = {
  navStickyVisible: PropTypes.bool,
};
ProductPageNavItems.defaultProps = {
  navStickyVisible: false,
};

export class ProductPageNav extends SimpleImmutableFluxComponent {
  constructor(props) {
    super(props);
    this.setStateFromFlux = _.debounce(this.setStateFromFlux, 500);
  }

  getStateFromFlux() {
    return _.pick(this.flux.store('ProductStore').getState(), [
      'product',
      'navStickyVisible',
    ]);
  }

  render() {
    const product = this.state.product;
    if (_.isUndefined(product)) {
      return null;
    }
    return (
      <nav className="mzt both owl-off centered">
        <ProductPageNavItems navStickyVisible={this.state.navStickyVisible} />
      </nav>
    );
  }
}
ProductPageNav.watchedStores = ['ProductStore'];

export class ProductPageNavStickyMenu extends FluxComponent {
  constructor(props) {
    super(props);
    ['handleOnPositionChange'].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  shouldComponentUpdate() {
    return false;
  }

  handleOnPositionChange(ev) {
    const element = document.getElementById('product-page-nav');
    element.classList.toggle(
      'jsm-fixed-product-page-nav',
      ev.currentPosition === Waypoint.above
    );
    this.flux.actions.catalog.product.setNavStickyVisibility(
      ev.currentPosition === Waypoint.above
    );
  }

  render() {
    return (
      <Waypoint onPositionChange={_.debounce(this.handleOnPositionChange)} />
    );
  }
}
