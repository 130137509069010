import _ from 'lodash';
import * as React from 'react';
import ReactDOM from 'react-dom';

import {CategoryNavigation} from '../components/product-listing/categories.jsx';
import {FilterWidget} from '../components/product-listing/filters.jsx';
import {ListingHeader} from '../components/product-listing/header.jsx';
import {ProductListingLoading} from '../components/product-listing/loading.jsx';
import {
  ProductListing,
  ProductCartCount,
  ProductListingTypeSwitch,
} from '../components/product-listing/product.jsx';
import {SortWidget} from '../components/product-listing/sorting.jsx';
import {FauxProductsList} from '../components/product-listing/faux-products.jsx';
import {RocketImageContext} from '../../rocket/components.jsx';

export function categoryNavigation(elem, options) {
  if (!options || !options.flux) {
    return;
  }
  ReactDOM.render(<CategoryNavigation {...options} />, elem);
}

export function productListingFilterWidget(elem, options) {
  if (!options || !options.flux) {
    return;
  }
  ReactDOM.render(<FilterWidget {...options} />, elem);
}

export function listingHeader(elem, options) {
  if (!options || !options.flux) {
    return;
  }
  ReactDOM.render(<ListingHeader {...options} />, elem);
}

export function productListingLoading(elem, options) {
  if (!options || !options.flux) {
    return;
  }
  ReactDOM.render(<ProductListingLoading {...options} />, elem);
}

export function productListing(elem, options) {
  if (!options || !options.flux) {
    return;
  }
  ReactDOM.render(
    <RocketImageContext.Provider value={options.rocketImage}>
      <ProductListing {...options} />
    </RocketImageContext.Provider>,
    elem
  );
}

export function productListingSortWidget(elem, options) {
  if (!options || !options.flux) {
    return;
  }
  ReactDOM.render(<SortWidget {...options} />, elem);
}

export function productCartCount(elem, options) {
  if (!options || !options.flux) {
    return;
  }
  ReactDOM.render(<ProductCartCount {...options} />, elem);
}

export function productListingClearFilters(elem, options) {
  if (!options || !options.flux) {
    return;
  }
  elem.addEventListener('click', (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    options.flux.actions.catalog.productListing.clearFilters();
  });

  const store = options.flux.store('ProductListingStore');
  const setDisabledState = () => {
    elem.disabled = !_.some(
      store.getState().filters.map((filter) => {
        if (filter.data && _.isArray(filter.data)) {
          return _.some(filter.data);
        }
        if (filter.data && _.isPlainObject(filter.data)) {
          return _.some(_.values(filter.data));
        }
        return filter.data;
      })
    );
  };
  store.addListener('change', setDisabledState);
  setDisabledState();
}

export function productListingFauxProducts(elem, options) {
  if (!options || !options.flux) {
    return;
  }
  ReactDOM.render(<FauxProductsList {...options} />, elem);
}

export function productListingTypeSwitch(elem, options) {
  if (!options || !options.flux) {
    return;
  }
  ReactDOM.render(<ProductListingTypeSwitch {...options} />, elem);
}
