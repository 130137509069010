import _ from "lodash";

import { getCSRFToken } from "../../csrf.js";
import { checkStatus } from "../../fetch.js";
import { ModelArray } from "../../models/arrays.js";
import { Model, types } from "../../models/base.js";
import { restMixin } from "../../models/rest.js";

class OrderItem extends Model.withSpec({
  id: types.int,
  dateCreated: types.date,
  itemType: types.string,
  itemId: types.int,
  itemTitle: types.string,
  extra: types.object,
  catNumber: types.string,
  value: types.float,
  progress: types.string,
  state: types.string,
  amountDiscounted: types.float,
  lastModified: types.date,
  taxableAtCheckout: types.bool,
  leadTimeAtCheckout: types.int,
  affiliate: types.string,
  coupon: types.int,
  appliedOffer: types.int,
}) {}

class Address extends Model.withSpec({
  id: types.int,
  occupierName: types.string,
  line1: types.string,
  line2: types.string,
  city: types.string,
  state: types.string,
  postcode: types.string,
  country: types.string,
  contactNumber: types.string,
  masked: types.bool,
}) {}

class PaymentMethod extends Model.withSpec({
  id: types.string,
  display_text: types.string,
  display_html: types.string,
  display_icon: types.string,
  payment_type: types.string,
  created: types.date,
  modified: types.date,
}) {}

class OrderPaymentMethod extends Model.withSpec({
  payment_method: types.model(PaymentMethod),
  priority: types.int,
  maximum_charge: types.float,
  created: types.date,
  modified: types.date,
}) {}

export class Order extends restMixin(Model) {
  constructor(data) {
    super(data, {
      url: (instance) => {
        if (_.isFinite(instance.id)) {
          return `/api/orders/${instance.id}/`;
        } else {
          return undefined;
        }
      },
      spec: {
        id: types.int,
        billingEditable: types.bool,
        credit: types.float,
        dateConfirmed: types.date,
        dateCreated: types.date,
        dateModified: types.date,
        deliveryAddressEditable: types.bool,
        isOpen: types.bool,
        isEvoucherOrder: types.bool,
        isSubscriptionOrder: types.bool,
        status: types.string,
        total: types.float,
        extra: types.object,
        deliveryAddress: types.model(Address),
        paymentMethods: types.modelArray(OrderPaymentMethod),
        items: types.modelArray(OrderItem),
      },
    });
  }

  cancelItems(itemIds) {
    if (!_.isFinite(this.id)) {
      return Promise.reject(
        new Error("Cannot cancel items on an unsaved order"),
      );
    }
    return fetch(`/api/orders/${this.id}/cancel_items/`, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "x-csrftoken": getCSRFToken(),
        "content-type": "application/json",
      },
      body: JSON.stringify({ item_ids: itemIds }),
    })
      .then(checkStatus)
      .then(() => this.read());
  }
}

export class OrderArray extends ModelArray.ofModel(Order) {}
