import _ from 'lodash';
import Fluxxor from 'fluxxor';

export const NetworkStore = Fluxxor.createStore({
  actions: {
    INITIALIZED: 'initializeHandler',
    'NETWORK:CHANGE': 'handleNetworkChange'
  },

  initialize: function() {
    this.online =
      window.navigator.onLine === undefined ? true : window.navigator.onLine;
    this.initialized = false;
  },

  initializeHandler: function() {
    if (window.navigator.onLine === undefined) {
      return;
    }
    window.addEventListener('online', () =>
      _.defer(this.flux.actions.network.changeNetwork, true)
    );
    window.addEventListener('offline', () =>
      _.defer(this.flux.actions.network.changeNetwork, true)
    );
    _.defer(this.flux.actions.network.changeNetwork);
    this.initialized = true;
    this.emit('initialized');
  },

  getState: function() {
    return {
      online: this.online,
      offline: !this.online
    };
  },

  handleNetworkChange: function(online) {
    if (!_.isBoolean(online)) {
      online = true;
    }
    if (this.online !== online) {
      this.online = online;
      this.emit('change');
    }
  }
});
