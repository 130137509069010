import {Model} from './base.js';

export class ModelArray extends Array {
  constructor(data, options) {
    if (data instanceof Model && options instanceof Model) {
      // If we're passing in an arguments list of models like
      // new ModelArray(modelInstance1, modelInstance2, ..., modelInstanceN)
      // we need to redefine data and options accordingly
      data = arguments;
      options = {};
    }
    options = Object.assign({model: Model}, options || {});
    if (Number.isFinite(data)) {
      super(data);
    } else {
      data = data || [];
      data.forEach((item) => {
        if (!(item instanceof options.model)) {
          throw new Error(`${item} is not an instance of ${options.model}`);
        }
      });
      super(...data);
    }
    Object.defineProperty(this, '_options', {
      value: options,
      enumerable: false,
      configurable: false,
      writable: false,
    });
  }

  toObject() {
    return Array.from(this.map((item) => item.toObject()));
  }

  toJSON() {
    return Array.from(this.map((item) => item.toJSON()));
  }

  serialize() {
    return Array.from(this.map((item) => item.serialize()));
  }

  deserialize(data) {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    return data.map(this._options.model.prototype.deserialize);
  }

  parse(data) {
    return new this.constructor(
      this.deserialize(data).map((item) => new this._options.model(item))
    );
  }

  concat() {
    return new this.constructor(super.concat(...arguments), this._options);
  }

  slice() {
    return new this.constructor(super.slice(...arguments), this._options);
  }

  reset() {
    let data = Array.from(arguments);
    if (data.length === 1 && Array.isArray(data[0])) {
      data = data[0];
    }
    data = data.map((item) => {
      if (!(item instanceof this._options.model)) {
        return this._options.model.parse(item);
      }
      return item;
    });
    this.splice(0, this.length, ...data);
  }

  append() {
    let data = Array.from(arguments);
    if (data.length === 1 && Array.isArray(data[0])) {
      data = data[0];
    }
    data = data.map((item) => {
      if (!(item instanceof this._options.model)) {
        return this._options.model.parse(item);
      }
      return item;
    });
    this.splice(this.length, 0, ...data);
  }

  prepend() {
    let data = Array.from(arguments);
    if (data.length === 1 && Array.isArray(data[0])) {
      data = data[0];
    }
    data = data.map((item) => {
      if (!(item instanceof this._options.model)) {
        return this._options.model.parse(item);
      }
      return item;
    });
    this.splice(0, 0, ...data);
  }

  static ofModel(Base, defaultOptions) {
    return class extends this {
      constructor(data, options) {
        options = Object.assign({model: Base}, defaultOptions, options);
        super(data, options);
      }
    };
  }
}
