import Immutable from 'immutable';
import * as React from 'react';
import ReactDOM from 'react-dom';

import {AddCommentForm} from './components.jsx';

export function addCommentForm(elem, options) {
  if (!options || (!options.flux && !options.initialFormData)) {
    return;
  }
  if (options.initialFormData) {
    options.initialFormData = Immutable.List(options.initialFormData);
  }
  ReactDOM.render(<AddCommentForm {...options} />, elem);
}

export function commentThreadPagination(elem, options) {
  const commentElems = elem.querySelectorAll('li[data-comment]');
  try {
    Array.prototype.map.call(commentElems, function (comment, idx) {
      if (idx === options.paginationCount) {
        // Create button
        const button = document.createElement('button');
        button.classList.add('brad', 'bg-brand', 'mt');
        button.textContent = 'Show more comments...';
        // Add button event
        button.addEventListener('click', (ev) => {
          ev.preventDefault();
          const hiddenCommentElems = elem.querySelectorAll(
            'li[data-comment].visually-hidden'
          );
          Array.prototype.map.call(hiddenCommentElems, (comment) => {
            comment.classList.remove('visually-hidden');
          });
          elem.removeChild(button);
        });
        // Add the button to the DOM
        comment.insertAdjacentElement('beforebegin', button);
      }
      if (idx >= options.paginationCount) {
        // Hide the comment
        comment.classList.add('visually-hidden');
      }
    });
  } catch (e) {
    return;
  }
}
