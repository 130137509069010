import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

export const WalletAddressType = ImmutablePropTypes.contains({
  id: PropTypes.number,
  occupierName: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postcode: PropTypes.string,
  country: PropTypes.string,
  contactNumber: PropTypes.string,
  defaultDeliveryAddress: PropTypes.bool,
  defaultAillingAddress: PropTypes.bool,
  created: PropTypes.date,
});

export const WalletAddressesType = ImmutablePropTypes.listOf(WalletAddressType);
