import Immutable from 'immutable';
import * as React from 'react';
import ReactDOM from 'react-dom';

import {Model, types} from '../../models/base.js';
import {WalletAddressSelector} from './components.jsx';

export function walletAddressSelector(elem, options) {
  if (!options) {
    return;
  }
  if (!options.walletAddresses || !options.walletAddresses.length) {
    return;
  }

  class WalletAddress extends Model {
    constructor(data) {
      super(data, {spec: {created: types.date}});
    }
  }

  const walletAddressesModels = options.walletAddresses.map((walletAddress) =>
    WalletAddress.parse(walletAddress)
  );
  options.walletAddresses = Immutable.List(
    walletAddressesModels.map((walletAddressModel) =>
      Immutable.fromJS(walletAddressModel.toObject())
    )
  );
  ReactDOM.render(<WalletAddressSelector {...options} />, elem);
}
