import _ from 'lodash';
import PropTypes from 'prop-types';
import * as React from 'react';

import {FormErrorMessage} from '../form.jsx';
import {Input} from './input.jsx';

export class RadioInput extends Input {
  getType() {
    return 'radio';
  }

  getInputElement() {
    const attrs = _.omit(this.props.attrs.toObject(), [
      'checked',
      'defaultChecked',
      'type'
    ]);
    return (
      <input
        {...attrs}
        type="radio"
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        ref={node => (this.input = node)}
        name={this.props.name}
        id={this.getId()}
        aria-labelledby={this.getLabelId()}
        aria-describedby={this.props.helpText ? this.getHelpTextId() : ''}
        value={this.state.value}
        checked={this.props.checked}
        defaultChecked={this.props.defaultChecked}
      />
    );
  }

  render() {
    const iconClassName = 'icon-' + this.getType();
    return (
      <label {...this.getLabelProps()}>
        <div className="form__field__input">
          {this.getInputElement()}
          <i className={iconClassName} />
        </div>
        <p className="form__field__label" id={this.getLabelId()}>
          {this.props.label}
        </p>
        {this.state.error ? (
          <FormErrorMessage
            className="form__field__error"
            error={this.state.error}
          />
        ) : this.props.helpText ? (
          <span
            className="form__field__help-text"
            dangerouslySetInnerHTML={{__html: this.props.helpText}}
          />
        ) : (
          ''
        )}
      </label>
    );
  }
}
RadioInput.propTypes = _.extend(Input.propTypes, {
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool
});
RadioInput.defaultProps = _.clone(Input.defaultProps);

export class CheckboxInput extends RadioInput {
  /*
   * Pass in form__field--checkbox--invert as a className prop to get an
   * inverted checkbox display
   */
  getType() {
    return 'checkbox';
  }

  getInputElement() {
    const attrs = _.omit(this.props.attrs.toObject(), [
      'checked',
      'defaultChecked',
      'type'
    ]);
    return (
      <input
        {...attrs}
        type="checkbox"
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        ref={node => (this.input = node)}
        name={this.props.name}
        id={this.getId()}
        aria-labelledby={this.getLabelId()}
        aria-describedby={this.props.helpText ? this.getHelpTextId() : ''}
        value={this.state.value}
        checked={this.props.checked}
        defaultChecked={this.props.defaultChecked}
      />
    );
  }
}
CheckboxInput.propTypes = _.extend(Input.propTypes, {
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool
});
CheckboxInput.defaultProps = _.clone(Input.defaultProps);
