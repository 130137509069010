import _ from 'lodash';

export function setVariant(payload) {
  this.dispatch('PRODUCT:SET_VARIANT', payload);
}

export function loadOrderRelatedProducts() {
  setTimeout(this.dispatch, 0, 'PRODUCT:LOAD_ORDER_RELATED_PRODUCTS');
}

export function loadSearchRelatedProducts() {
  setTimeout(this.dispatch, 0, 'PRODUCT:LOAD_SEARCH_RELATED_PRODUCTS');
}

export function loadEventRelatedContent() {
  setTimeout(this.dispatch, 0, 'PRODUCT:LOAD_EVENT_RELATED_CONTENT');
}

export function loadPostRelatedContent() {
  setTimeout(this.dispatch, 0, 'PRODUCT:LOAD_POST_RELATED_CONTENT');
}

export function loadReviews() {
  setTimeout(this.dispatch, 0, 'PRODUCT:REVIEWS:LOAD');
}

export function updateFilter(query) {
  this.dispatch('PRODUCT:REVIEWS:UPDATE_FILTER', {query: query});
}

export function loadMoreReviews() {
  this.dispatch('PRODUCT:REVIEWS:LOAD_MORE');
}

export function voteOnReview(
  review,
  direction,
  errorCallback,
  successCallback
) {
  this.dispatch('PRODUCT:REVIEW:VOTE', {
    review: review,
    direction: direction,
    errorCallback: errorCallback,
    successCallback: successCallback,
  });
}

export function submitReview(review) {
  review = _.defaults(review, {
    user: this.flux.store('AuthStore').getState().user.get('id'),
  });
  this.dispatch('PRODUCT:REVIEW:SUBMIT', review);
}

export function setNavStickyVisibility(status) {
  setTimeout(this.dispatch, 0, 'PRODUCT:SET_NAV_STICKY_VISIBILITY', status);
}

export function showEnlargedImages(index) {
  setTimeout(this.dispatch, 0, 'PRODUCT:SHOW_ENLARGED_IMAGES', index);
}

export function hideEnlargedImages() {
  setTimeout(this.dispatch, 0, 'PRODUCT:HIDE_ENLARGED_IMAGES');
}
