import _ from 'lodash';
import classNames from 'classnames';
import * as React from 'react';
import {CSSTransition} from 'react-transition-group';

import {FluxMenuComponent} from './flux-menu.jsx';

export class DropdownMenu extends FluxMenuComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: _.uniqueId(this.getName() + '-'),
      open: false,
    };
    ['toggle'].forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  getName() {
    return 'icon-dropdown-menu';
  }

  renderButton() {
    return null;
  }

  renderMenu() {
    return null;
  }

  getClassName() {
    return classNames('js-dropdown-menu', this.props.className, {
      'js-dropdown-menu--open': this.state.open,
      'js-dropdown-menu--closed': !this.state.open,
    });
  }

  getMenuClassName() {
    return classNames('js-dropdown-menu__container', this.props.menuClassName);
  }

  getButtonClassName() {
    return classNames('js-dropdown-menu__toggle', this.props.buttonClassName);
  }

  render() {
    const menu = (
      <div
        ref={(node) => (this.child = node)}
        key="menu"
        className={this.state.open ? this.getMenuClassName() : 'js-hidden'}
      >
        {this.renderMenu()}
      </div>
    );
    return (
      <div
        ref={(node) => (this.parent = node)}
        id={this.state.id}
        aria-haspopup={true}
        aria-expanded={this.state.open}
        aria-live="polite"
        className={this.getClassName()}
      >
        <button
          aria-controls={this.state.id}
          aria-pressed={this.state.open}
          className={this.getButtonClassName()}
          onClick={this.toggle}
        >
          {this.renderButton()}
        </button>
        <CSSTransition
          appear={true}
          in={this.state.open}
          classNames="ra-dropdown-menu-"
          timeout={200}
        >
          {menu}
        </CSSTransition>
      </div>
    );
  }
}
DropdownMenu.propTypes = _.clone(FluxMenuComponent.propTypes);
DropdownMenu.contextTypes = _.clone(FluxMenuComponent.contextTypes);
DropdownMenu.childContextTypes = _.clone(FluxMenuComponent.childContextTypes);
DropdownMenu.watchedStores = [];
