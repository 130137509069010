import _ from 'lodash';
import PropTypes from 'prop-types';
import * as React from 'react';

export class LoadingSVG extends React.PureComponent {
  constructor(props) {
    super(props);
    this.idPrefix = _.uniqueId('loading-svg-');
  }

  render() {
    const idPrefix = this.props.idPrefix || this.idPrefix;
    const css = `
      @keyframes g0  {
        0% {opacity: 1;}
        12.5% {opacity: 0.3;}
        25% {opacity: 0.4;}
        37.4% {opacity: 0.5;}
        50% {opacity: 0.6;}
        62.5% {opacity: 0.7;}
        75% {opacity: 0.8;}
        87.5% {opacity: 0.9;}
        100% {opacity: 1;}
      }
      @keyframes g1  {
        0% {opacity: 0;}
        12.5% {opacity: 0;}
        25% {opacity:  1;}
        37.4% {opacity:  1;}
        50% {opacity:  1;}
        62.5% {opacity:  1;}
        75% {opacity:  1;}
        87.5% {opacity:  1;}
        100% {opacity: 1;}
      }
      @keyframes g2  {
        0% {opacity: 0;}
        12.5% {opacity: 0;}
        25% {opacity:  0;}
        37.4% {opacity:  1;}
        50% {opacity:  1;}
        62.5% {opacity:  1;}
        75% {opacity:  1;}
        87.5% {opacity:  1;}
        100% {opacity: 1;}
      }
      @keyframes g3  {
        0% {opacity: 0;}
        12.5% {opacity: 0;}
        25% {opacity:  0;}
        37.4% {opacity:  0;}
        50% {opacity:  1;}
        62.5% {opacity:  1;}
        75% {opacity:  1;}
        87.5% {opacity:  1;}
        100% {opacity: 1;}
      }
      @keyframes g4  {
        0% {opacity: 0;}
        12.5% {opacity: 0;}
        25% {opacity:  0;}
        37.4% {opacity:  0;}
        50% {opacity:  0;}
        62.5% {opacity:  1;}
        75% {opacity:  1;}
        87.5% {opacity:  1;}
        100% {opacity: 1;}
      }
      @keyframes g5  {
        0% {opacity: 0;}
        12.5% {opacity: 0;}
        25% {opacity:  0;}
        37.4% {opacity:  0;}
        50% {opacity:  0;}
        62.5% {opacity:  0;}
        75% {opacity:  1;}
        87.5% {opacity:  1;}
        100% {opacity: 1;}
      }
      @keyframes g6  {
        0% {opacity: 0;}
        12.5% {opacity: 0;}
        25% {opacity:  0;}
        37.4% {opacity:  0;}
        50% {opacity:  0;}
        62.5% {opacity:  0;}
        75% {opacity:  0;}
        87.5% {opacity:  1;}
        100% {opacity: 1;}
      }
      #${idPrefix}frame-0 {
        animation: g0 1s infinite;
      }
      #${idPrefix}frame-1 {
        animation: g1 1s steps(8, end) infinite;
      }
      #${idPrefix}frame-2 {
        animation: g2 1s steps(8, end) infinite;
      }
      #${idPrefix}frame-3 {
        animation: g3 1s steps(8, end) infinite;
      }
      #${idPrefix}frame-4 {
        animation: g4 1s steps(8, end) infinite;
      }
      #${idPrefix}frame-5 {
        animation: g5 1s steps(8, end) infinite;
      }
      #${idPrefix}frame-6 {
        animation: g6 1s steps(8, end) infinite;
      }
    `;
    return (
      <svg
        viewBox="0 0 600 600"
        height={this.props.height || this.props.size}
        width={this.props.width || this.props.size}
        style={{maxWidth: '100%', maxHeight: '100%', opacity: 0.6}}
      >
        <style type="text/css" dangerouslySetInnerHTML={{__html: css}} />
        <style type="text/css">
          {`.st0{fill:#999999;}
            .st1{fill:#333333;}`}
        </style>
        <g id={idPrefix + 'frame-6'}>
          <path
            className="st0"
            d="M344.4,389.5c0,0-0.5,3.7-1.5,10.2l34.2,25.7l-7.6-34.8C361.2,389.1,352.8,388.8,344.4,389.5z"
          />
          <path
            className="st0"
            d="M396.1,312l37.8-23.9l-55.4-8.1l36.2-36.2l-51.7,5l0.4-1.6c6.2-22,10.3-36.2,12.3-42.2l-21.4,12.1
            c1,32,0.1,64.1-2.5,96c30.7,2.3,60.5,8.7,74.8,25.2l7.9-3.1L396.1,312z"
          />
          <path
            className="st0"
            d="M246.3,218.5L221,204.4l15,45l-51.7-9l37,40l-57.1,8l42.9,23.9l-43.6,22.3l9.8,4c13.7-17,44.5-23.9,75.5-26
            C246.2,281.3,245.4,249.9,246.3,218.5z"
          />
          <path
            className="st0"
            d="M229.3,391.2l-8.4,35.1l36.8-27c-0.9-6.1-1.4-9.6-1.4-9.6C245.4,388.9,236.5,389.5,229.3,391.2z"
          />
        </g>
        <g id={idPrefix + 'frame-5'}>
          <path
            className="st1"
            d="M431.2,343.4c-11.7-20-45-28-78.4-30.3c8.1-99.7-3.5-167.5-3.5-167.5h-47.4V132h-44l-2.3,14h46.2
            v182c-15.4-0.6-21.6,37.8-19,97.3s18.8,121.2,18.8,121.2l0.3-217.8l0,0c21.5,0.5,18.5,90.4,18,97.3c-5.7,78.9-18,120.5-18,120.5v38
            l6.2-3.8c18-50.7,37.3-190.6,37.3-190.6c65.3-4.7,58,40.9,58,57.6c-0.6,29.9-17.7,92.5-17.7,92.5s1.6-1,10.2-4.6
            C427.8,473.6,447.2,370.9,431.2,343.4z"
          />
        </g>
        <g id={idPrefix + 'frame-4'}>
          <path
            className="st1"
            d="M170.8,343.1c-16,27.5,3.4,130.2,35.3,192.1l10.6,4.3c0,0-16.6-62.4-17.7-92.4
            c0.1-17.5-7.6-62,57.3-57.5c-0.3-1-6.5-60.1-7.4-77C215.5,314.9,182.4,323.1,170.8,343.1z"
          />
        </g>
        <g id={idPrefix + 'frame-3'}>
          <path
            className="st1"
            d="M330.3,69C318.1,34.2,301,14,301,14v78.4c-12,0-21.9,5.8-21.9,13.1s9.9,13.1,21.9,13.1V132h45.3
            C345.5,127,340.2,94.9,330.3,69z M301.7,116.4h-0.6c0-6-0.1-12.2-0.1-20.4h0.7c10,0.2,18,4.7,18,10.2S311.6,116.1,301.7,116.4
            L301.7,116.4z"
          />
        </g>
        <g id={idPrefix + 'frame-2'}>
          <path
            className="st1"
            d="M334.8,493.7c20.6-4.1,40.4-11.6,58.5-22.3c0.4-3.7,1.8-14,2.4-22.8c-17.3,12.2-36.7,21-57.2,26
            C337.9,478,335.6,489.6,334.8,493.7z"
          />
          <path
            className="st1"
            d="M586,321v-15h-1.3h-100c-0.1-5-0.5-10-1.1-16H549v-14h-67.7c-13-67.4-62-122.1-127.5-142.4
            c2.1,11.1,2.7,16.7,2.9,19.7l7.4,2.9c85.5,36.1,125.7,134.6,89.6,220.2c-3.5,8.4-7.8,16.5-12.6,24.2c-0.4,6.5-1.7,18-5.4,36.5
            c22-24,37.4-53.3,44.6-85.1H549v-15h-66c0.7-5,1.2-10,1.5-16H586z"
          />
        </g>
        <g id={idPrefix + 'frame-1'}>
          <path
            className="st1"
            d="M263.7,475.9c-10.5-2.2-20.7-5.5-30.5-9.7c-7.8-3.3-15.4-7.2-22.6-11.6l-4.6-2.9c0.5,6.2,1.7,15.9,2.8,22.5
            l0.5,0.3c18,9.9,37.6,16.7,57.9,20.2C266.5,491.3,264.6,480.8,263.7,475.9z"
          />
          <path
            className="st1"
            d="M245.9,151.4c0.6-6.1,2.1-15,2.9-19.6c-67.7,18.9-119,74.3-132.7,143.2H50v15h63.9c-0.6,5-1,10-1.1,16H12v15
            h100.9c0.3,5,0.8,10,1.5,16H50v14h67c7.7,34.5,25,66.2,49.9,91.3v-0.5c-4.3-21.7-5.4-31.8-5.6-33.5
            c-53.6-75.9-35.6-180.9,40.4-234.6c9.9-7,20.4-12.8,31.5-17.5C237.4,154.4,241.6,152.8,245.9,151.4z"
          />
        </g>
        <g id={idPrefix + 'frame-0'}>
          <path
            className="st0"
            d="M301.5,3h0.7c3.5,0.4,6.6,4,7.8,5.6c21.2,27.5,36.4,66.1,45.3,114.8l0.3,1.8l1.7,0.5
            c23.7,7.4,45.7,19.3,64.9,35c32.2,26.5,55.1,62.6,65.3,103.1l0.6,2.3h69.6v30.7H596v31.5h-38.3V359h-70.5l-0.6,2.2
            c-8.8,32.5-25.8,62.2-49.5,86.1l-0.6,0.6l-0.2,0.8c-7.3,31.3-18.2,61.6-32.5,90.3l-1.5,3L370,556.6l6-19.9
            c0.3-0.9,6.6-22.5,11.6-47.8l1.2-5.9l-5.4,2.6c-14.4,6.9-29.6,12-45.2,15.2l-1.9,0.4l-0.4,1.9c-6.5,31.9-12.2,54.1-15.9,67.1
            l-0.5,1.6c-0.9,3.3-1.8,6.4-2.7,9l-1.2,3.9l-14.8,11.7l-13-10.7h-0.2l-0.8-0.5l-1-3.1c-5.9-19.2-12.8-47.7-19-78.1l-0.4-2l-2-0.4
            c-15.6-2.9-30.8-7.6-45.3-14.2l-5.3-2.4l1.2,5.7c5,24.9,11,45.2,11.1,45.4L232,556l-32.3-14.6l-1.5-3
            c-13.5-27.1-23.9-55.7-31.1-85.1l-0.2-0.9l-0.6-0.6c-26.1-24.6-44.9-56-54.2-90.7l-0.6-2.2h-69v-30.6H4.1v-31.5H42v-30.7h68.7
            l0.6-2.3c16.7-67.2,68.1-120.3,134.6-139.4l1.8-0.5l0.3-1.9c8.7-47.2,23.5-84.9,43.9-112.1l0,0l1.6-2.2L294,7h0.1
            c0.4-0.5,3.5-4,7.3-4l0,0 M301.4,0c-5.1,0-8.9,4.2-9.6,5h-0.1l-0.6,0.7l0,0c-21.5,27.7-37,66.6-46.1,115.6
            c-67.6,19.4-119.8,73.5-136.7,141.8H39v30.7H1.1v37.5h38.4V362h69.7c9.4,35.2,28.5,67.1,55,92.1c7.3,29.7,17.8,58.4,31.4,85.8l2,4
            l39.2,17.7l-7.8-26.3c-0.1-0.2-6.1-20.5-11-45.2c14.7,6.7,30.1,11.5,46,14.4c6.2,30.6,13.1,59,19,78.4l1.3,4.1l1.7,1.1l14.9,12.2
            l17.4-13.7l1.5-4.8c0.8-2.7,1.7-5.7,2.7-9.1l0.5-1.6c3.7-13.2,9.4-35.5,15.9-67.4c15.9-3.2,31.3-8.4,45.9-15.4
            c-5,25.2-11.2,46.6-11.5,47.6l-7.8,26.2l39.2-17.7l2-4c14.4-28.9,25.4-59.5,32.7-91c24-24.3,41.3-54.4,50.1-87.4h71.2v-30.7H599
            v-37.5h-38.3v-30.7h-70.3c-10.3-41.1-33.6-77.8-66.3-104.7c-19.5-16-41.8-28.1-65.9-35.6c-8.9-49.1-24.3-88.1-45.9-116.1
            c-1.7-2.3-5.3-6.3-9.8-6.8h-1L301.4,0z"
          />
        </g>
      </svg>
    );
  }
}
LoadingSVG.propTypes = {
  size: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  idPrefix: PropTypes.string,
};
